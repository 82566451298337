import NotificationsNoneOutlinedIcon from '@suid/icons-material/NotificationsNoneOutlined';
import { Badge } from '@suid/material';
import { createSignal } from 'solid-js';

export default function Notifications() {
  const [count] = createSignal(1);

  return (
    <Badge
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: '#C6182C',
        },
      }}
      color="warning"
      badgeContent={count()}
      class="cursor-pointer"
    >
      <NotificationsNoneOutlinedIcon />
    </Badge>
  );
}
