import { Popover } from '@suid/material';
import { JSXElement, Show, createSignal } from 'solid-js';

export const HoverPop = (props: {
  children: JSXElement;
  anchor: JSXElement;
  render: boolean;
}) => {
  //Popover Boilerplate
  const [anchorEl, setAnchorEl] = createSignal<HTMLButtonElement | null>(null);
  const open = () => Boolean(anchorEl());
  const handleHover = (
    event: MouseEvent & { currentTarget: HTMLButtonElement },
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //Popover Boilerplate
  return (
    <>
      <div
        // @ts-expect-error html click types...
        onMouseEnter={handleHover}
        onMouseLeave={handleClose}
      >
        {props.anchor}
      </div>
      <Show when={props.render}>
        <Popover
          open={open()}
          sx={{ pointerEvents: 'none' }}
          disableRestoreFocus
          anchorEl={anchorEl()}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {props.children}
        </Popover>
      </Show>
    </>
  );
};
