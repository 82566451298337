import { AddressCard, AddressCardVerificationProps } from '@components';
import { TextInput } from '@components/forms';
import { CarrierContactT } from '@store/orders/types';
import { ArrowDropDown, ArrowDropUp, Person } from '@suid/icons-material';
import { Box, Divider, Menu, MenuItem, StyledProps } from '@suid/material';
import { size } from 'lodash';
import { Show, createSignal } from 'solid-js';

import classes from './classes';

interface ContactProps {
  id: number;
  name: string;
}

export type ContactDropdownProps<T> = {
  label?: string;
  contacts: T[];
  onChange: (newContactId: number) => void;
  contactId: number | undefined;
  disabled?: boolean;
  respectVerification?: boolean;
  small?: boolean;
};

const ITEM_HEIGHT = 40;
const MENU_STYLES: StyledProps = {
  '& .MuiPaper-root': {
    paddingLeft: 1,
    paddingRight: 1,
    margin: 0,
  },
};
const PAPER_STYLES = {
  overflow: 'auto',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  minWidth: '500px',
  maxHeight: `${ITEM_HEIGHT * 10}px`,
};
const TEXTFIELD_STYLES: StyledProps = {
  '& .MuiInputBase-input::placeholder': {
    color: '#000 !important',
    opacity: 1,
  },
};

export const ContactDropdown = <T extends ContactProps>(
  props: ContactDropdownProps<T>,
) => {
  const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);
  const [inputElement, setInputElement] = createSignal<HTMLInputElement | null>(
    null,
  );
  const [menuWidth, setMenuWidth] = createSignal<number>(0);

  const open = () => Boolean(anchorEl());
  const close = () => setAnchorEl(null);

  function calculateWidth() {
    const textFieldElement = inputElement();
    if (textFieldElement) setMenuWidth(textFieldElement.clientWidth);
  }

  const findContactById = (contactId: number | undefined) =>
    (Boolean(props.contacts) &&
      props.contacts.find((c) => c.id === contactId)) ||
    ({} as ContactProps);

  const currentContact = () => findContactById(props.contactId);

  const handleContactClick = (contactId: number | undefined) => {
    const contact = findContactById(contactId);
    if (Boolean(contact)) {
      props.onChange(contact.id);
    }
    close();
  };

  const filteredContacts = () =>
    size(props.contacts) > 0
      ? props.contacts.filter((c) => c.id !== props.contactId)
      : [];

  const getVerificationInfo = <T extends ContactProps>(contact: T) => {
    if (
      !(props.respectVerification ?? false) ||
      !Object.hasOwn(contact, 'verificationStatus')
    )
      return undefined;

    const typedContact = contact as unknown as CarrierContactT;
    return {
      verificationStatus: typedContact.verificationStatus,
      phoneVerified: typedContact.phoneVerified,
      emailVerified: typedContact.emailVerified,
    } as AddressCardVerificationProps;
  };

  const arrowDropDown = (
    <Box
      onClick={() => {
        setAnchorEl(inputElement());
        calculateWidth();
      }}
      sx={{ cursor: 'pointer' }}
    >
      <Show when={open()}>
        <ArrowDropUp />
      </Show>
      <Show when={!open()}>
        <ArrowDropDown />
      </Show>
    </Box>
  );

  return (
    <>
      <TextInput
        noBackground
        label="Contact"
        variant="standard"
        inputRef={setInputElement}
        InputLabelProps={{
          sx: {
            fontSize: '18px !important',
          },
        }}
        onFocus={(event) => {
          setAnchorEl(event.currentTarget as HTMLElement);
          calculateWidth();
        }}
        value={currentContact().name || ''}
        disabled={props.disabled}
        InputProps={{
          readOnly: true,
          endAdornment: arrowDropDown,
          startAdornment: (
            <Person
              class={classes.personIconStyle}
              fontSize={Boolean(props.small) ? 'small' : undefined}
            />
          ),
        }}
        icon={
          <Person
            sx={{ color: '#026EA1' }}
            fontSize={Boolean(props.small) ? 'small' : undefined}
          />
        }
        startAdornmentFlag
        endAdornment={arrowDropDown}
        sxProps={TEXTFIELD_STYLES}
        size={Boolean(props.small) ? 'small' : undefined}
      />
      <Menu
        anchorEl={anchorEl()}
        open={open()}
        onClose={close}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        sx={MENU_STYLES}
        PaperProps={{
          sx: {
            ...PAPER_STYLES,
            width: menuWidth() + 24 + 'px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Show when={typeof props.contactId === 'number'}>
          <span class={classes.contactTitleText}>Current Contact</span>
          <MenuItem
            class={classes.menuItem}
            onClick={() => handleContactClick(props.contactId)}
          >
            <AddressCard
              contact={currentContact()}
              verificationInfo={getVerificationInfo(currentContact())}
              mode="warning"
              personIconSize={'40px'}
              showIcon
            />
          </MenuItem>
        </Show>
        <Show when={filteredContacts().length > 0}>
          <span class={classes.contactTitleText}>Assign New Contact</span>
          {filteredContacts().map((contact) => (
            <>
              <MenuItem
                class={classes.menuItem}
                onClick={() => handleContactClick(contact.id)}
              >
                <AddressCard
                  verificationInfo={getVerificationInfo(contact)}
                  contact={contact}
                  mode="regular"
                  showIcon
                />
              </MenuItem>
              <Divider class={classes.divider} />
            </>
          ))}
        </Show>
      </Menu>
    </>
  );
};
