import { PageHeader, Button, Typography } from '@components';
import { useNavigate } from '@solidjs/router';
import { Add, Save } from '@suid/icons-material';
import { Box } from '@suid/material';
import { routeNewOfficeDetails } from '@utils/routeOfficeDetails';

export const OfficeHeader = () => {
  const navigate = useNavigate();
  return (
    <PageHeader
      title={
        <Typography variant="h4" class="!text-[#0E2C3B]">
          Office
        </Typography>
      }
      headerControls={
        <Box displayRaw="flex" alignItems="center" gap={2} flexWrap="wrap">
          <Button
            variant="contained"
            color="primary"
            label="New Office"
            startIcon={<Add />}
            onClick={() => {
              const isNative = routeNewOfficeDetails();
              isNative && navigate('/office/details');
            }}
          />
          <Button
            disabled={true}
            variant="contained"
            color="primary"
            label="Save"
            startIcon={<Save />}
          />
        </Box>
      }
    />
  );
};
