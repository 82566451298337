import { Tab, TabsComponent } from '@components';
import { orderStore } from '@store/orders';
import { isSuperAdmin } from '@utils/utils';
import { Component, Match, Switch, createSignal } from 'solid-js';

import { calculateSettlementCount } from '../carrier/stops/stopsEditor/utilFunctions';
import { Adjustment } from './CustomerInformation/Adjustment';
import { Collections } from './CustomerInformation/Collections';
import { CustomerPay } from './CustomerInformation/CustomerPay';
import { Intact } from './CustomerInformation/Intact';
import { Settlement } from './CustomerInformation/Settlement';

const CustomerInformationTabs: Component = () => {
  const [activeTab, setActiveTab] = createSignal(0);

  const tabs = [
    'CUSTOMER PAY',
    `SETTLEMENTS(${calculateSettlementCount('lineItems')}) `,
    `ADJUSTMENTS(${orderStore.order.adjustments.length})`,
    'COLLECTIONS',
  ];
  if (isSuperAdmin()) tabs.push('INTACCT');

  return (
    <>
      <TabsComponent
        tabs={tabs.map((item) => (
          <Tab size="small">{item}</Tab>
        ))}
        activeTab={activeTab()}
        onTabClick={setActiveTab}
        tabContainerStyles="flex-wrap"
      />
      <Switch>
        <Match when={activeTab() === 0}>
          <CustomerPay />
        </Match>
        <Match when={activeTab() === 1}>
          <Settlement />
        </Match>
        <Match when={activeTab() === 2}>
          <Adjustment />
        </Match>
        <Match when={activeTab() === 3}>
          <Collections />
        </Match>
        <Match when={activeTab() == 4}>
          <Intact />
        </Match>
      </Switch>
    </>
  );
};

export default CustomerInformationTabs;
