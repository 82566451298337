import kebabCase from 'lodash/kebabCase';
import logo from '@assets/logo.svg';

import { ItemT, LoadItem } from './types';

export const packingGroupItems = [
  {
    label: 'No Packing Group Assigned',
    value: 'No Packing Group Assigned',
  },
  { label: 'I', value: 'I' },
  { label: 'II', value: 'II' },
  { label: 'III', value: 'III' },
];

export const hazardClassItems = [
  {
    label: '1.1 Massive Explosive Hazard',
    value: '1.1 Massive Explosive Hazard',
  },
  {
    label: '1.2 Projection Hazard',
    value: '1.2 Projection Hazard',
  },
  {
    label: '1.3 Fire and/or Minor Blast/Minor Projection Hazard',
    value: '1.3 Fire and/or Minor Blast/Minor Projection Hazard',
  },
  {
    label: '1.4 Minor Explosion Hazard',
    value: '1.4 Minor Explosion Hazard',
  },
  {
    label: '1.5 Very Insensitive with Mass Explosion Hazard',
    value: '1.5 Very Insensitive with Mass Explosion Hazard',
  },
  {
    label: '1.6 Extremely Insensitive; No Mass Explosion Hazard',
    value: '1.6 Extremely Insensitive; No Mass Explosion Hazard',
  },
  {
    label: '2.1 Flammable Gases',
    value: '2.1 Flammable Gases',
  },
  {
    label: '2.2 Nonflammable Gases',
    value: '2.2 Nonflammable Gases',
  },
  {
    label: '2.3 Toxic Gases',
    value: '2.3 Toxic Gases',
  },
  {
    label: '3 Flammable and Combustible Liquids',
    value: '3 Flammable and Combustible Liquids',
  },
  {
    label: '4.1 Flammable Solids',
    value: '4.1 Flammable Solids',
  },
  {
    label: '4.2 Spontaneously Combustible',
    value: '4.2 Spontaneously Combustible',
  },
  {
    label: '4.3 Dangerous When Wet',
    value: '4.3 Dangerous When Wet',
  },
  {
    label: '5.1 Oxiders',
    value: '5.1 Oxiders',
  },
  {
    label: '5.2 Organic Peroxides',
    value: '5.2 Organic Peroxides',
  },
  {
    label: '6.1 Toxic Substances',
    value: '6.1 Toxic Substances',
  },
  {
    label: '6.2 Infectious Substances',
    value: '6.2 Infectious Substances',
  },
  {
    label: '7 Radioactive Material',
    value: '7 Radioactive Material',
  },
  {
    label: '8 Corrosives',
    value: '8 Corrosives',
  },
];

export const pieceDescItems = [
  { label: 'A-Frame', value: 'A-Frame' },
  { label: 'Bags', value: 'Bags' },
  { label: 'Bales', value: 'Bales' },
  { label: 'Barrel', value: 'Barrel' },
  { label: 'Basket', value: 'Basket' },
  { label: 'Batteries', value: 'Batteries' },
  { label: 'Bins', value: 'Bins' },
  { label: 'Box', value: 'Box' },
  { label: 'Break Bulk', value: 'Break Bulk' },
  { label: 'Bundle', value: 'Bundle' },
  { label: 'Cans', value: 'Cans' },
  { label: 'Cart', value: 'Cart' },
  { label: 'Carton', value: 'Carton' },
  { label: 'Case', value: 'Case' },
  { label: 'Chest', value: 'Chest' },
  { label: 'Container', value: 'Container' },
  { label: 'Crate', value: 'Crate' },
  { label: 'Cylinder', value: 'Cylinder' },
  { label: 'Drum', value: 'Drum' },
  { label: 'Gallons', value: 'Gallons' },
  { label: 'Gaylords', value: 'Gaylords' },
  { label: 'HalfPallets', value: 'HalfPallets' },
  { label: 'Hopper', value: 'Hopper' },
  { label: 'Jerricans', value: 'Jerricans' },
  { label: 'Lift Van', value: 'Lift Van' },
  { label: 'Loose', value: 'Loose' },
  { label: 'Package', value: 'Package' },
  { label: 'Pails', value: 'Pails' },
  { label: 'Pallet', value: 'Pallet' },
  { label: 'Piece', value: 'Piece' },
  { label: 'Racks', value: 'Racks' },
  { label: 'Reel', value: 'Reel' },
  { label: 'Roll', value: 'Roll' },
  { label: 'Skid', value: 'Skid' },
  { label: 'Stacks', value: 'Stacks' },
  { label: 'Totes', value: 'Totes' },
  { label: 'Truckload', value: 'Truckload' },
  { label: 'Tube', value: 'Tube' },
  { label: 'U-Box', value: 'U-Box' },
];

export const pickupDeliveryCheckboxes: string[] = [
  'Airport',
  'Appointment',
  'CFS',
  'Inside',
  'Liftgate',
  'Limited Access',
  'Protect From Freeze',
  'Residential',
  'Sort & Segregate',
  'Trade Show',
];

export const pickupDeliveryIds = pickupDeliveryCheckboxes.map(kebabCase);

export const shipmentCheckboxes: string[] = [
  'Over Length 8 < 12',
  'Over Length 12 < 16',
  'Over Length 16 < 20',
];

export const shipmentIds = shipmentCheckboxes.map(kebabCase);

export const defaultItem: ItemT = {
  numOfUnits: 1,
  item: '',
  itemDesc: '',
  numOfPieces: 0,
  weight: 0,
  dimensions: [0, 0, 0],
  nmfc: '',
  class: 0,
  hazmat: false,
};

export const unitTypes = [
  'Bags',
  'Boxes',
  'Bundles',
  'Cases',
  'Crates',
  'Drums',
  'Items',
  'Pallets',
  'Parts',
  'Pieces',
  'Skids',
  'Totes',
  'Units',
];

export const freightClasses = [
  '50',
  '55',
  '60',
  '65',
  '70',
  '77.5',
  '85',
  '92.5',
  '100',
  '110',
  '125',
  '150',
  '175',
  '200',
  '250',
  '300',
  '400',
  '500',
];

export const LTLWeightLimit = {
  Single: 3000,
  Total: 10000,
};

export const DEFAULT_LOAD_ITEM: LoadItem = {
  quantity: 1,
  item: 'Pallets',
  uom: 'lbs',
  loadId: 0,
  pieces: null,
  weight: 0,
  class: null,
  hazmat: false,
  lengthInch: null,
  widthInch: null,
  heightInch: null,
  id: 0,
  density: '',
  suggestedClass: null,
  nmfc: '',
  description: '',
  charge: null,
  loadItemHazmatDetail: null,
  pickUpId: 0,
  dropOffId: 0,
  height: null,
  length: null,
  width: null,
  volume: null,
  createdDate: null,
  createdBy: null,
  modifiedDate: null,
  historyContext: null,
  modifiedBy: null,
  load: null,
  dropOff: null,
  pickUp: null,
  hazmatItems: null,
  hazmatDescription: null,
  type: '',
};

export const HAZMAT_ID = 'B419EE6D-312A-4E41-955C-7D761311D1A5';

export const atgLogo = `<img src=${logo} alt="" />`;
