export default {
  container: 'w-[650px] max-w-full h-[400px] overflow-auto',
  tabBox: 'h-[580px] overflow-y-auto p-2 m-2 bg-[#FFFFFF]',
  approvalCard:
    'flex rounded-sm border-l-[6px] mb-[10px] p-[15px] w-full cursor-pointer hover:bg-blue-200 hover:border-blue-200',
  withNeedAttentionBg: 'bg-[#EFCCD2] border-[#EFCCD2]',
  withoutNeedAttentionBg: 'bg-blue-50 border-blue-50',
  disputedBorderStyle: '!border-[#f59d25ba] hover:border-[#f59d25ba]',
  infoStyle: 'text-[24px] cursor-pointer m-2',
  needAttention: 'text-[#B00020]',
  withoutNeedAttention: 'text-[#026EA1]',
  boxStyle: 'flex justify-between items-center',
  disputedStyle:
    '!font-medium !bg-[#f59d25ba] text-[#ffff] !ml-[10px] !py-[1px] !px-[5px] rounded-sm',
  typoFont: '!font-medium',
};
