export default {
  playlistModalTitleContainer: 'flex justify-left items-center px-6',
  playListModalTitle:
    'text-black !text-xl !font-medium  !leading-8 !tracking-[0.15px]',
  dialogContentContainer: 'flex flex-col gap-[10px] items-center',
  heading: 'flex justify-center !text-4xl',
  ratingContainer: 'flex justify-center',
  divider: 'text-[#BDBDBD] w-[100%]',
  contentContainer: 'bg-[#E8F0F6] p-4 rounded-lg',
  buttonsContainer: 'flex mt-10 justify-end gap-5',
  btn: '!rounded-sm',
  assignLoadContentContainer:
    'flex flex-col gap-[10px] items-center p-10 cursor-pointer',
  playListsContainer:
    'flex border-solid border-2 hover:bg-[#CAE7F0] w-full pt-2 pb-1 rounded-lg mb-2 gap-10',
  playListsContainerActive:
    'flex border-solid border-2 hover:bg[#CAE7F0] w-full pt-2 pb-1 rounded-lg mb-2 gap-10 bg-[#FFE3BC]',
  playlistCardsContainer: 'overflow-y-auto w-[100%]',
  playlistNameOwner: 'flex flex-col w-[20rem]',
  playlistGroupCount:
    'bg-[#D9D9D9] w-[40px] h-[40px] flex items-center justify-center rounded-full ml-auto  mr-4 mt-1',
  peopleIcon: 'text-[#0277BD] ml-[15px]',
  ediTimeStamp: 'font-bold',
  ediContainer: 'lex flex-col gap-[5px]',
  ediLogParent:
    'flex border-l-4 p-2 flex gap-[50] justify-between border-b-2 border-[#CCCCCC]',
  ediTenderResponse: 'text-[#388E3C]',
  compareTopContainer: 'flex flex-row gap-5 items-center',
  backBtn: 'w-28 h-[52px]',
  notificationBoldText: 'text-base font-bold leading-[22px]',
  notificationText: 'text-base font-normal leading-[22px]',
  updateRate: 'h-[40px]',
  loadTenderSubHeading:
    'flex h-[52px] items-center justify-between p-[8px] w-[100%] mb-2 bg-[#EEEEEE]',
  currentChangesSubHeading:
    'flex h-[52px] items-center p-[8px] w-[100%] mb-2 bg-[#EEEEEE]',
  container: 'flex flex-col mr-[16px]',
  containerHeading: 'flex h-[52px] items-center p-[8px] mb-2',
  containerHeadingText: 'text-xl font-normal text-[#1B4960]',
  postLoadDetails: 'flex gap-10',
  drops: 'flex flex-col gap-1',
  fontBold: '!font-bold !pt-3',
  fullWidth: 'w-full',
  commentSection: 'flex flex-col w-[50%] ',
  internalNote: 'flex flex-col w-[35.2em] pl-[11px]',
  flexWithCol: 'flex flex-col',
  equipmentContainer: '!flex !flex-col gap-4 mt-4 w-full',
  carrierTable: 'mb-[15px] mt-[11px]',
  tableHead: 'bg-[#f1f1f1]',
  tableRow: 'cursor-pointer',
  tableOddRow: 'bg-[#f9f9f9] cursor-pointer',
  postLoadText:
    'text-black !text-xl !font-medium  !leading-8 !tracking-[0.15px]',
  lockedText: 'text-base font-normal leading-6',
  infoGridStyle:
    'border-2 border-[#468DB5] p-2 text-xs flex items-center rounded-md',
  warningInfoGrid:
    'border-2 border-[#F59D25] p-2 text-xs flex items-center rounded-md',
  infoIconStyle: ' text-lg mr-4',
  trackingModalContainer: 'flex justify-left items-center pb-5',
  modalTitle: 'text-black !text-xl !font-medium  !leading-8 !tracking-[0.15px]',
  modalMetaData: 'flex gap-5 flex-col',
  trackingInfoStyle: 'text-base ml-4',
  trackingProviderContainer: 'flex flex-wrap gap-4 pl-12 pr-12 justify-center',
  trackingProvidertCardStyles:
    'flex flex-col gap-4 w-[25vw] border-2  rounded-lg p-8',
  trackingFrequency: 'flex items-center flex-col',
  logo: 'flex w-[167px] h-[92px] self-center',
  project44Btn: 'mt-auto w-full',
  trackingBtn: 'flex justify-end ml-auto mt-4',
};

export const billingModalClasses = {
  modalTitle: 'text-black !text-xl !font-medium  !leading-8 !tracking-[0.15px]',
  billTitle: '!text-base text-[#b00020] !font-medium !pb-2',
  billDesc: ' !pb-2',
  billDisclaimer: 'text-[#b00020] !font-medium  !pb-2',
};

export const editBillingModalClasses = {
  modalTitle: 'text-black !text-xl !font-medium  !leading-8 !tracking-[0.15px]',
  subLabelText: '!text-sm !opacity-65 pb-1',
  messageHistoryTitle: '!font-medium !text-xl',
  messageHeader:
    'flex flex-col gap-2 sm:flex-row items-center bg-[#EEE] p-2 justify-between',
  messageHeaderRight: 'flex gap-4 items-center',
  messageUserName: '!text-base !font-semibold',
  messageDateTime: '!text-base',
  deleteIcon: 'curor-pointer text-[#B00020]',
  footerBtns: ' w-[100%] sm:w-auto',
};

export const confirmationModal = {
  backdrop: 'fixed inset-0 bg-black opacity-50 z-40',
  container:
    'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-300 shadow-md p-6 z-50',
  btnsContainer: 'flex justify-end',
};

export const isLTLModalClasses = {
  buttonFooterGrid: 'mt-5 p-1',
  titleTypography:
    'text-black !text-xl !font-medium !leading-8 !tracking-[0.15px]',
};

export const postingPricingDetailsClasses = {
  lowTotal: '!text-green-800 !font-semibold',
  avgTotal: '!font-semibold',
  highTotal: '!text-red-800 !font-semibold',
  deleteIcon: '!bg-[#B00020] !text-white',
  closeIcon: '!text-sm',
};
