import { Avatar, Divider, Stack } from '@suid/material';
import { Typography } from '@components/Typography';
import { Business, Person, Phone } from '@suid/icons-material';

import {
  CarrierType,
  CustomerType,
  FactoringCompanyType,
  OfficeType,
  PhoneType,
  ProspectType,
  ProType,
  ReferenceType,
  ShipmentMetadataType,
  UserType,
} from '../types';

export const ReferenceItemsRender = (props: ReferenceType) => {
  return (
    <Stack>
      <Typography variant="subtitle2">Order #{props.orderId}</Typography>
      <Typography variant="body2">{props.customer}</Typography>
      <Typography variant="body2">Ref: {props.referenceNum}</Typography>
      <Typography variant="body2">
        ShipmentId: {props.shipmentId ? props.shipmentId : 'N/A'}
      </Typography>
    </Stack>
  );
};

export const ShipmentMetaDataItemsRender = (props: ShipmentMetadataType) => {
  return (
    <Stack>
      <Typography variant="subtitle2">Order #{props.orderId}</Typography>
      <Stack direction="row">
        <Typography variant="body2">{props.metadataFieldName}: </Typography>
        <Typography variant="body2">{props.metadataFieldValue}</Typography>
      </Stack>
    </Stack>
  );
};

export const CarrierItemsRender = (props: CarrierType) => {
  return (
    <Stack>
      <Typography variant="subtitle2">{props.name}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2">
          MC: {props.mC ? props.mC : 'N/A'}
        </Typography>
        <Divider orientation="vertical" />
        <Typography variant="body2">
          DOT: {props.dOT ? props.dOT : 'N/A'}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2">
          City: {props.city ? props.city : 'N/A'}
        </Typography>
        <Divider orientation="vertical" />
        <Typography variant="body2">
          State: {props.state ? props.state : 'N/A'}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2">Status:</Typography>
        <Typography variant="subtitle2">{props.status}</Typography>
      </Stack>
    </Stack>
  );
};

export const CustomerItemsRender = (props: CustomerType) => {
  return (
    <Stack>
      <Stack direction={'row'} alignItems="center" spacing={2}>
        <Typography variant="subtitle2">{props.name}</Typography>
        <Typography
          variant="subtitle2"
          sxProps={{ color: props.status !== 'customer' ? 'red' : 'green' }}
        >
          {props.status}
        </Typography>
      </Stack>
      <Stack direction={'row'} alignItems="center">
        <Business />
        <Typography variant="body2">: {props.office}</Typography>
      </Stack>
      <Stack direction={'row'} alignItems="center">
        <Person />
        <Typography variant="body2">: {props.accountManager}</Typography>
      </Stack>
    </Stack>
  );
};

export const ProspectItemsRender = (props: ProspectType) => {
  return <Typography variant="body2">{props.name}</Typography>;
};

export const OfficeItemsRender = (props: OfficeType) => {
  return (
    <Stack>
      <Typography variant="subtitle2">{props.name}</Typography>
      <Stack direction="row" alignItems="center">
        <Person fontSize={'small'} />
        <Typography variant="body2">Manager: {props.manager}</Typography>
      </Stack>
      <Typography variant="body2">{props.address1}</Typography>
      <Typography variant="body2">{props.address2}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="body2">{props.city},</Typography>
        <Typography variant="body2">{props.state}</Typography>
        <Typography variant="body2">{props.zip}</Typography>
      </Stack>
    </Stack>
  );
};

export const UserItemsRender = (props: UserType) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar src={props.thumbnail} />
      <Stack>
        <Typography variant="subtitle2">Order #{props.name}</Typography>
        <Typography variant="body2">Office: {props.office}</Typography>
        <Typography variant="body2">Email: {props.userName}</Typography>
        <Typography variant="body2">Phone: {props.phone}</Typography>
      </Stack>
    </Stack>
  );
};

export const FactoringCompanyItemsRender = (props: FactoringCompanyType) => {
  return <Typography variant="body2">{props.name}</Typography>;
};

export const PhoneItemsRender = (props: PhoneType) => {
  return (
    <Stack>
      <Stack direction="row" spacing={2}>
        <Typography variant="subtitle2">
          Contact Name -{props.contactName}
        </Typography>
        <Typography variant="subtitle2">{props.contactType}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Business />
        <Typography variant="body2">{props.groupName}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Phone />
        <Typography variant="body2">{props.contactNumber}</Typography>
      </Stack>
    </Stack>
  );
};

export const ProItemsRender = (props: ProType) => {
  return (
    <Stack>
      <Typography variant="subtitle2">Order #{props.orderId}</Typography>
      <Typography variant="body2">{props.customer}</Typography>
      <Typography variant="body2">Pro: {props.proNumber}</Typography>
    </Stack>
  );
};
