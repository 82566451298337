import { createStore } from 'solid-js/store';

import { FactoringCompanyDetailsStore } from './types';
import { FACTORING_COMPANY_INTIAL_OBJECT } from './constants';

const [factoringCompanyDetailsStore, setFactoringCompanyDetailsStore] =
  createStore<FactoringCompanyDetailsStore>({
    isLoading: false,
    isError: false,
    factoringCompanyDetails: FACTORING_COMPANY_INTIAL_OBJECT,
    creationMode: false,
    factoringCompanyDetailsErrors: null,
    isDocumentsLoading: false,
    documents: [],
  });

export { factoringCompanyDetailsStore, setFactoringCompanyDetailsStore };
