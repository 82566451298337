import { createStore } from 'solid-js/store';

import {
  CreditRequestState,
  CustomerDetailState,
  CustomerSavedQuotesState,
  ediConfigurationStateType,
  ediConfigurationType,
  ICreditRequest,
} from './types';
import { INITIAL_CUSTOMER_DETAILS } from './defaultVals';

const [customerDetailsStore, setCustomerDetailsStore] =
  createStore<CustomerDetailState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    customer: INITIAL_CUSTOMER_DETAILS,
  });

const [ediConfigurationStore, setEdiConfigurationStore] =
  createStore<ediConfigurationStateType>({
    isLoading: true,
    isError: false,
    errorMessage: '',
    ediData: {} as ediConfigurationType,
    ediProviders: [] as string[],
    isSaveLoading: false,
  });

const [customerSavedQuotesStore, setCustomerSavedQuotesStore] =
  createStore<CustomerSavedQuotesState>({
    isLoading: true,
    isError: false,
    errorMessage: '',
    items: [],
    overlay: false,
  });

const [creditRequestStore, setCreditRequestStore] =
  createStore<CreditRequestState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    creditRequest: {} as ICreditRequest,
  });

export {
  customerDetailsStore,
  setCustomerDetailsStore,
  ediConfigurationStore,
  setEdiConfigurationStore,
  customerSavedQuotesStore,
  setCustomerSavedQuotesStore,
  creditRequestStore,
  setCreditRequestStore,
};
