import { TableRow, TableCell } from '@suid/material';
import {
  DragDropProvider,
  DragDropSensors,
  DragEvent,
  DragOverlay,
  SortableProvider,
  closestCenter,
  createSortable,
  useDragDropContext,
} from '@thisbeyond/solid-dnd';
import { For, JSXElement, createSignal } from 'solid-js';

import { Column } from './BasicTable';
import basicTableClasses from './classes';

type DragDropTableProps<T> = {
  rows: T[];
  columns: Column<T>[];
  onRowClick?: (rowData: T) => void;
  tableBodyRowClasses: string;
  onDragEnd: (updatedItems: T[]) => void;
  cellClasses?: string;
};

type SortableProps<T> = {
  rowItem: T;
  index: number;
  tableBodyRowClasses: string;
  onRowClick?: (rowData: T) => void;
  columns: Column<T>[];
  cellClasses?: string;
};

type TableCellProps<T> = {
  columnItem: Column<T>;
  rowItem: T;
  index: number;
  cellClasses?: string;
};

export const CustomTableCell = <T,>(props: TableCellProps<T>): JSXElement => {
  const content = props.columnItem.renderCell
    ? props.columnItem.renderCell(props.rowItem, props.index)
    : props.rowItem[props.columnItem.key];

  const cellContent =
    typeof content === 'string' ||
    typeof content === 'number' ||
    typeof content === 'boolean' ? (
      <span>{content}</span>
    ) : (
      content
    );

  return (
    <TableCell
      sx={{
        textAlign: props.columnItem.textAlign || 'left',
        minWidth: props.columnItem.minWidth,
        maxWidth: props.columnItem.maxWidth,
        background: props.columnItem.cellColor,
      }}
      class={props.cellClasses}
    >
      {cellContent ?? ''}
    </TableCell>
  );
};

const SortableRows = <T,>(props: SortableProps<T>) => {
  const sortable = createSortable(
    props.index,
    props.rowItem as Record<string, unknown>,
  );
  const [state] = useDragDropContext()!;
  const isDeleteOperation = props.rowItem.operationType === 'Delete';

  const rowClasses = `
  ${basicTableClasses.tableRowSx} 
  ${props.tableBodyRowClasses} 
  sortable 
  ${!!state.active.draggable && 'transition-transform'}
`;
  const handleClick = () => {
    if (props.onRowClick) {
      props.onRowClick(props.rowItem);
    }
  };

  return (
    <TableRow
      ref={sortable}
      class={rowClasses}
      sx={{
        display: isDeleteOperation ? 'none' : 'table-row',
      }}
      onClick={handleClick}
    >
      <For each={props.columns}>
        {(columnItem) => (
          <CustomTableCell
            columnItem={columnItem}
            rowItem={props.rowItem}
            index={props.index}
            cellClasses={`${props.cellClasses} ${columnItem.headerClasses}`}
          />
        )}
      </For>
    </TableRow>
  );
};

const DragDropTable = <T,>(props: DragDropTableProps<T>) => {
  const [activeItem, setActiveItem] = createSignal<JSXElement>();

  const ids = () => props.rows.map((_, index) => index);
  const rows = () => props.rows;

  const onDragStart = (e: DragEvent) => {
    setActiveItem(
      <TableRow
        class={`${basicTableClasses.tableRowSx} ${props.tableBodyRowClasses} cursor-grabbing bg-[#f4f6f8]`}
      >
        <For each={props.columns}>
          {(columnItem) => (
            <CustomTableCell
              columnItem={columnItem}
              rowItem={e.draggable.data as T}
              index={Number(e.draggable.id)}
              cellClasses={props.cellClasses}
            />
          )}
        </For>
      </TableRow>,
    );
  };

  const onDragEnd = ({ draggable, droppable }: DragEvent) => {
    const currentItems = rows();
    const fromIndex = Number(draggable.id);
    const toIndex = droppable ? Number(droppable.id) : 0;
    if (fromIndex !== toIndex) {
      const updatedItems = currentItems.slice();
      updatedItems.splice(toIndex, 0, ...updatedItems.splice(fromIndex, 1));
      props.onDragEnd(updatedItems);
    }
  };

  return (
    <DragDropProvider
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      collisionDetector={closestCenter}
    >
      <DragDropSensors />
      <SortableProvider ids={ids()}>
        <For each={rows()}>
          {(rowItem, index) => (
            <SortableRows
              columns={props.columns}
              index={index()}
              onRowClick={props.onRowClick}
              rowItem={rowItem}
              tableBodyRowClasses={props.tableBodyRowClasses}
              cellClasses={props.cellClasses}
            />
          )}
        </For>
      </SortableProvider>
      <DragOverlay>
        <div class="sortable">{activeItem()}</div>
      </DragOverlay>
    </DragDropProvider>
  );
};

export default DragDropTable;
