import { createStore, produce } from 'solid-js/store';

import { ClaimsState } from './types';
import { getClaims } from './services';

const [claimsStore, setClaimsStore] = createStore<ClaimsState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  items: [],
  overlay: false,
});

export const fetchClaims = async () => {
  try {
    setClaimsStore('isLoading', true);
    const resp = await getClaims();
    setClaimsStore(
      produce((draft: ClaimsState) => {
        draft.items = resp;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setClaimsStore(
      produce((draft: ClaimsState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading claims';
        draft.isLoading = false;
      }),
    );
  }
};

export { claimsStore, setClaimsStore };
