import { convertDateTimeV2 } from '@utils/utils';
import { DateTime } from 'luxon';
import * as yup from 'yup';
/*eslint-disable */
// Need to disable eslint to make function use generic type
const createConditionalSchema = <T>(
  schema: yup.ObjectSchema<T> | yup.Schema<T>,
  stopId: number,
  dropOffStop: boolean,
) =>
  yup.lazy((item) =>
    item.operationType === "Delete" ||
    (dropOffStop && stopId != item.dropOffId) ||
    (!dropOffStop && stopId !== item.pickUpId)
      ? yup.object().shape({})
      : schema,
  );
/*eslint-enable */

export const validations = () =>
  yup.object().shape({
    locationName: yup.string().required('Location Name is required'),
    address1: yup.string().required('Address Line 1 is required'),
    address2: yup.string(),
    countryCode: yup.string(),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zip: yup.string().required('Zip Code is required'),
    email: yup.string().email('Invalid email').nullable(),
    phone: yup.string().when('mode', ([mode]) => {
      return mode === 'LTL'
        ? yup
            .string()
            .required('Contact Phone is required')
            .test(
              'len',
              'Phone number must be at least 10 digists',
              (val) => Boolean(val) === false || val.length >= 10,
            )
        : yup
            .string()
            .nullable()
            .test(
              'len',
              'Phone number must be at least 10 digits',
              (val) =>
                Boolean(val) === false ||
                (val != undefined && val.length >= 10),
            );
    }),

    stopDateTime: yup
      .string()
      .test('invalidDateTime', 'Invalid DateTime', function (value) {
        return value !== 'Invalid DateTime';
      })
      .required('Stop Date Time is required'),
    driverDateIn: yup.string().when('mode', ([mode]) => {
      return mode === 'LTL'
        ? yup.string().required('Driver In Date is required')
        : yup.string().nullable();
    }),
    driverDateOut: yup.string().when('mode', ([mode]) => {
      return mode === 'LTL'
        ? yup.string().required('Driver Out Date is required')
        : yup.string().nullable();
    }),
    driverTimeIn: yup.string().when('mode', ([mode]) => {
      return mode === 'LTL'
        ? yup.string().test({
            name: 'driverTimeIn',
            message:
              'Shipping Date/Hours (open) should be earlier than Shipping Date/Hours (close)',
            test: function (value) {
              const { parent, createError } = this;
              const { driverTimeOut, driverDateIn, driverDateOut } = parent;
              if (!Boolean(value))
                return createError({
                  message: 'Driver Time In is required',
                });
              if (!Boolean(driverTimeOut as string)) return true;
              const shippingHourOpen = convertDateTimeV2(
                driverDateIn as string,
                value!,
              );
              const shippingHourClose = convertDateTimeV2(
                driverDateOut as string,
                driverTimeOut as string,
              );
              if (
                DateTime.fromISO(shippingHourOpen).diff(
                  DateTime.fromISO(shippingHourClose),
                ).milliseconds < 0
              ) {
                return true;
              }
              return createError({
                message:
                  'Shipping Date/Hours (open) should be earlier than Shipping Date/Hours (close)',
              });
            },
          })
        : yup.string().nullable();
    }),
    driverTimeOut: yup.string().when('mode', ([mode]) => {
      return mode === 'LTL'
        ? yup.string().required('Driver Time Out is required')
        : yup.string().nullable();
    }),
    contact: yup.string().when('mode', ([mode]) => {
      return mode === 'LTL'
        ? yup.string().required('Contact Name is required')
        : yup.string().nullable();
    }),
    loadItems: yup
      .array()
      .when(['mode', 'id', 'dropOff'], ([mode, id, dropOff]) => {
        return mode === 'LTL'
          ? yup.array().of(
              createConditionalSchema(
                yup.object().shape({
                  //In V3 none of this stuff is required even for LTL, w/ the exception of desc.
                  class: yup.string().required('Class is required'),
                  // nmfc: yup
                  //   .string()
                  //   .max(10, 'NMFC must be at most 10 characters'),
                  // pieces: yup
                  //   .number()
                  //   .max(9999999, 'Pieces must be at most 7 characters'),
                  description: yup
                    .string()
                    .required('Description Required')
                    .max(150, 'Description must be at most 150 characters'),
                  quantity: yup
                    .mixed()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .test(
                      'is-number-or-empty',
                      'Quantity must be a number',
                      (value) => {
                        return value === '' || !isNaN(Number(value));
                      },
                    )
                    .test(
                      'is-decimal',
                      'Quantity cannot be a decimal',
                      (value) => {
                        return value && !value.toString().includes('.');
                      },
                    )
                    .test(
                      'must-be-at-least-999',
                      'Quantity must be less than 99999',
                      (value) => {
                        return Number(value) <= 99999;
                      },
                    )
                    .nullable()
                    .required('Quantity is required'),
                  weight: yup
                    .mixed()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .test(
                      'is-number-or-empty',
                      'weight must be a number',
                      (value) => {
                        return (
                          value === '' ||
                          !isNaN(Number(value)) ||
                          value === undefined
                        );
                      },
                    )
                    .test('is-positive', 'Weight must be positive', (value) => {
                      return Number(value) > 0;
                    })
                    .test(
                      'must-be-at-least-999',
                      'Weight must be at most 6 digits',
                      (value) => {
                        return Number(value) <= 999999;
                      },
                    )
                    .nullable()
                    .required('Weight is required'),
                  // lengthInch: yup
                  //   .number()
                  //   .typeError('Length inches must be a number')
                  //   .positive('Length inches must be a positive number')
                  //   .max(999, 'Length inches must be at most 3 digits')
                  //   .required('Length inches is required'),
                  // heightInch: yup
                  //   .number()
                  //   .typeError('Height inches must be a number')
                  //   .positive('Height inches must be a positive number')
                  //   .max(999, 'Height inches must be at most 3 digits')
                  //   .required('Height inches is required'),
                  // widthInch: yup
                  //   .number()
                  //   .typeError('Width inches must be a number')
                  //   .positive('Width inches must be a positive number')
                  //   .max(999, 'Width inches must be at most 3 digits')
                  //   .required('Width inches is required'),
                }),
                id as number,
                dropOff as boolean,
              ),
            )
          : yup.array().of(
              createConditionalSchema(
                yup.object().shape({
                  // length: yup
                  //   .number()
                  //   .positive('Length must be a positive number')
                  //   .required('Length is required'),
                  // height: yup
                  //   .number()
                  //   .positive('Height must be a positive number')
                  //   .required('Height is required'),
                  // width: yup
                  //   .number()
                  //   .positive('Width must be a positive number')
                  //   .required('Width is required'),
                  quantity: yup
                    .mixed()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .test(
                      'is-number-or-empty',
                      'Quantity must be a number',
                      (value) => {
                        return value === '' || !isNaN(Number(value));
                      },
                    )
                    .test(
                      'is-decimal',
                      'Quantity cannot be a decimal',
                      (value) => {
                        return value && !value.toString().includes('.');
                      },
                    )
                    .nullable()
                    .required('Quantity is required'),
                  weight: yup
                    .mixed()
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .test(
                      'is-number-or-empty',
                      'weight must be a number',
                      (value) => {
                        return (
                          value === '' ||
                          !isNaN(Number(value)) ||
                          value === undefined
                        );
                      },
                    )
                    .test('is-positive', 'Weight must be positive', (value) => {
                      return Number(value) >= 0;
                    })
                    .nullable()
                    .test(
                      'must-be-at-least-999',
                      'Weight must be at most 6 digits',
                      (value) => {
                        return Number(value) <= 999999;
                      },
                    ),
                  // lengthInch: yup
                  //   .number()
                  //   .typeError('Length inches must be a number')
                  //   .positive('Length inches must be a positive number')
                  //   .max(999, 'Length inches must be at most 3 digits')
                  //   .required('Length inches is required'),
                  // heightInch: yup
                  //   .number()
                  //   .typeError('Height inches must be a number')
                  //   .positive('Height inches must be a positive number')
                  //   .max(999, 'Height inches must be at most 3 digits')
                  //   .required('Height inches is required'),
                  // widthInch: yup
                  //   .number()
                  //   .typeError('Width inches must be a number')
                  //   .positive('Width inches must be a positive number')
                  //   .max(999, 'Width inches must be at most 3 digits')
                  //   .required('Width inches is required'),
                }),
                id as number,
                dropOff as boolean,
              ),
            );
      }),
  });

export const HazardousMaterialSchema = yup.object().shape({
  unNumber: yup.string().required('UN Number is required'),
  packingGroup: yup.string().nullable(),
  hazardClass: yup.string().nullable(),
  piece: yup.string().nullable(),
  contactNumber: yup
    .string()
    .required('Contact Number is required')
    .min(10, 'Phone number must be at least 10 digits'),
  contactName: yup.string().required('Contact Name is required'),
});
