import { Card } from '@suid/material';
import { Component, Index } from 'solid-js';
import { Button, UncontrolledAccordion } from '@components';
import { SelectField, TextInput } from '@components/forms';
import CheckBoxOutlinedIcon from '@suid/icons-material/CheckBoxOutlined';
import {
  LastUsedTopStopModel,
  orderStore,
  PickUpDropOffItem,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { LoadItemTypes } from '@common/commonLists';

import cls from '../classes';

type UpdateButtonProps = {
  onClick: () => void;
  classes: string;
  noUpdate: boolean;
};

type EDICompareChangeItemsProps = {
  stop: LastUsedTopStopModel;
  noUpdate: boolean;
  items: PickUpDropOffItem[];
  index: number;
};

const EDICompareChangeItems = (props: EDICompareChangeItemsProps) => {
  const UpdateButton: Component<UpdateButtonProps> = (props) => {
    return !props.noUpdate ? (
      <div class={`${props.classes} text-right mt-auto mb-[2px]`}>
        <Button
          startIcon={<CheckBoxOutlinedIcon class="text-[#ffffff]" />}
          label="UPDATE"
          class="updateRateClass"
          variant="contained"
          sx={{ backgroundColor: '#388E3C' }}
          onClick={props.onClick}
        />
      </div>
    ) : null;
  };

  const handleItemChange = (
    partialVals: Partial<PickUpDropOffItem>,
    idx: number,
  ) => {
    const updatedItems = orderStore.order.loads[0].items?.map((itm, index) => {
      if (index === idx) {
        return { ...itm, ...partialVals };
      }
      return itm;
    });
    updateLoadPropertyAtIndex(
      {
        items: updatedItems,
      },
      0,
    );
  };

  const handleItemUpdateButtonClick = (
    item: Partial<PickUpDropOffItem>,
    idx: number,
  ) => {
    const storeEditableItems =
      orderStore.order.loads[0].items?.filter(
        (item) => item.operationType !== 'Delete',
      ) ?? [];
    if (storeEditableItems.length > 0) {
      Boolean(storeEditableItems[idx])
        ? (storeEditableItems[idx] = { ...storeEditableItems[idx], ...item })
        : null;
      const updatedItems = orderStore.order.loads[0].items?.map((item) => {
        if (item.id === storeEditableItems[idx].id) {
          return storeEditableItems[idx];
        }
        return item;
      });
      updateLoadPropertyAtIndex(
        {
          items: updatedItems,
        },
        0,
      );
    }
  };

  return (
    <Card>
      <div class={cls.currentChangesSubHeading}>Items</div>
      <div class="p-4">
        <Index each={props.items}>
          {(rowData, index) => {
            return rowData().operationType !== 'Delete' &&
              (rowData().pickUpId == props.stop.id ||
                rowData().dropOffId == props.stop.id) ? (
              <UncontrolledAccordion
                title={`${rowData().quantity} ${rowData().item} ${
                  rowData().description
                }`}
              >
                <>
                  <div class="flex mb-4">
                    <TextInput
                      type="number"
                      label="Quantity"
                      value={rowData().quantity}
                      onChange={(itm) =>
                        handleItemChange({ quantity: itm as number }, index)
                      }
                      disabled={!props.noUpdate}
                      classes="!w-[70%]"
                    />
                    <UpdateButton
                      onClick={() => {
                        handleItemUpdateButtonClick(
                          {
                            quantity: rowData().quantity,
                          },
                          index,
                        );
                      }}
                      classes="w-[30%]"
                      noUpdate={props.noUpdate || props.index != 0}
                    />
                  </div>
                  <div class="flex mb-4">
                    <div class="w-[70%]">
                      <SelectField
                        label=""
                        menuItems={LoadItemTypes}
                        value={rowData().item}
                        onChange={(e) => {
                          handleItemChange({ item: e.target.value }, index);
                        }}
                        disabled={!props.noUpdate}
                      />
                    </div>
                    <UpdateButton
                      onClick={() => {
                        handleItemUpdateButtonClick(
                          {
                            item: rowData().item,
                          },
                          index,
                        );
                      }}
                      classes="w-[30%]"
                      noUpdate={props.noUpdate || props.index != 0}
                    />
                  </div>
                  <div class="flex mb-4">
                    <TextInput
                      label="Description"
                      value={rowData().description}
                      onChange={(itm) =>
                        handleItemChange({ description: itm as string }, index)
                      }
                      disabled={!props.noUpdate}
                      classes="!w-[70%]"
                    />
                    <UpdateButton
                      onClick={() => {
                        handleItemUpdateButtonClick(
                          {
                            description: rowData().description,
                          },
                          index,
                        );
                      }}
                      classes="w-[30%]"
                      noUpdate={props.noUpdate || props.index != 0}
                    />
                  </div>
                  <div class="flex mb-4">
                    <TextInput
                      type="number"
                      label="Weight"
                      value={rowData().weight}
                      onChange={(itm) =>
                        handleItemChange({ weight: itm as number }, index)
                      }
                      disabled={!props.noUpdate}
                      classes="!w-[70%]"
                    />
                    <UpdateButton
                      onClick={() => {
                        handleItemUpdateButtonClick(
                          {
                            weight: rowData().weight,
                          },
                          index,
                        );
                      }}
                      classes="w-[30%]"
                      noUpdate={props.noUpdate || props.index != 0}
                    />
                  </div>
                </>
              </UncontrolledAccordion>
            ) : null;
          }}
        </Index>
      </div>
    </Card>
  );
};

export default EDICompareChangeItems;
