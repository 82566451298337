import { FactoringCompany } from '@store/carriers';
import { produce } from 'solid-js/store';

import {
  fetchCustomerFactoringCompanyDetails,
  fetchFactoringCompanyDetailsDocuments,
} from './services';
import { setFactoringCompanyDetailsStore } from './store';

export const getFactoringCompanyData = async (id: string) => {
  setFactoringCompanyDetailsStore('isLoading', true);

  const resp = await fetchCustomerFactoringCompanyDetails(id);
  if (resp?.isSuccess as boolean) {
    setFactoringCompanyDetailsStore(
      produce((draft) => {
        draft.factoringCompanyDetails = resp?.value as FactoringCompany;
        draft.isError = false;
        draft.isLoading = false;
      }),
    );
  } else {
    setFactoringCompanyDetailsStore('isLoading', false);
    setFactoringCompanyDetailsStore(
      produce((draft) => {
        draft.isError = true;
        draft.factoringCompanyDetails = {} as FactoringCompany;
      }),
    );
  }
};

export const updateFactoringCompanyDetailsState = (
  updates: Partial<FactoringCompany>,
) => {
  Object.entries(updates).forEach(([key, value]) => {
    setFactoringCompanyDetailsStore(
      'factoringCompanyDetails',
      key as keyof FactoringCompany,
      value,
    );
  });
};

export const getFactoringCompanyDetailsDocuments = async (id: string) => {
  setFactoringCompanyDetailsStore('isDocumentsLoading', true);
  const resp = await fetchFactoringCompanyDetailsDocuments(id);
  resp
    ? setFactoringCompanyDetailsStore('documents', resp)
    : setFactoringCompanyDetailsStore('documents', []);
  setFactoringCompanyDetailsStore('isDocumentsLoading', false);
};
