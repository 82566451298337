import { StyledProps } from '@suid/material/styles';
export const classes = {
  fileUploadInputStyles: (height: number, width: number): StyledProps => ({
    border: '1px dashed lightgrey',
    '& fieldset': {
      border: 'none',
    },
    '& input[type="file"]': {
      opacity: 0,
      cursor: 'pointer',
      zIndex: 2,
      height: `${height}px`,
      width: `${width}%`,
    },
    borderRadius: '5px',
    width: '100%',
  }),

  fileUploadContainerStyles: (): StyledProps => ({
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  }),

  iconLabelContainer: (height: number): StyledProps => ({
    position: 'absolute',
    paddingTop: `${height / 5}px`,
    width: '100%',
    fontSize: '12px',
    justifyContent: 'center',
  }),

  inputLabelStyles: (disabled: boolean): StyledProps => ({
    color: disabled ? 'lightgrey' : '',
    marginTop: '6px',
  }),

  inputLabelStyles11: (): StyledProps => ({
    fontSize: '.85rem',
    margin: '8px 0px 0px 0px',
    fontWeight: 'bold',
  }),

  cloudUploadIconStyles: (disabled: boolean): StyledProps => ({
    marginTop: '5px',
    color: disabled ? 'lightgrey' : '',
  }),

  BoxStyles: (): StyledProps => ({
    fontSize: '0.8rem',
    textAlign: 'left',
    color: 'darkgray',
    fontWeight: 'bold',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(242, 246, 248, 0.75)',
  }),

  UploadIconStyles: (): StyledProps => ({
    marginTop: '5px',
  }),

  DeleteIconStyles: (): StyledProps => ({
    marginTop: '5px',
    alignSelf: 'right',
    color: '#B00020',
  }),

  LoadingTextBoxStyles: (): StyledProps => ({
    fontSize: '0.8rem',
    color: 'darkgray',
    fontWeight: 'bold',
    textAlign: 'left',
  }),
};
