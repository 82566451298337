import { Grid as MuiGrid, Stack } from '@suid/material';
import HelpOutlineOutlinedIcon from '@suid/icons-material/HelpOutlineOutlined';
import { Typography } from '@components';

import logo from '../../assets/logo.svg';
import cls from './classes';
import AccountMenu from './components/AccountMenu';
import Notifications from './components/Notifications';
import NavBar from './components/NavBar';
import { SearchField } from './search/SearchField';

export const Header = () => {
  return (
    <header class={cls.headerContainer}>
      <MuiGrid class={`${cls.innerContainer} justify-between`}>
        <Stack direction="row" spacing={2} alignItems="center" width={'50%'}>
          <img src={logo} alt="logo" />
          <Typography component="h1" sxProps={cls.logoTitle} variant="h1">
            ATGFr8
          </Typography>
          <SearchField />
        </Stack>
      </MuiGrid>
      <MuiGrid
        class={`${cls.innerContainer} flex-1 lg:flex-[.70] xl:flex-[.60] w-[100%] overflow-x-auto`}
      >
        <NavBar />
      </MuiGrid>
      <MuiGrid class={`${cls.innerContainer} gap-12`}>
        <Notifications />
        <HelpOutlineOutlinedIcon class="cursor-pointer" />
        <AccountMenu />
      </MuiGrid>
    </header>
  );
};
