export default {
  totalLabel:
    '!text-[#171b1A] !font-bold  !rounded-sm !overflow-hidden text-end',
  totalAmount:
    '!bg-[#0000000F]  !font-bold !text-[#171b1A] px-3 rounded-sm py-2',
  addIcon: 'text-[#0070A2] cursor-pointer',
  deleteIcon: 'text-[#EF5350] cursor-pointer',
  tableBg: 'bg-[#BFDFD8]',
  emptyTable: 'w-full h-64 bg-[#BFDFD8]',
  tableRow:
    ' border-t-2 border-[#a8c5bf] !bg-[#BFDFD8] [&:nth-child(1)]:!border-none ',
  tableFooter:
    'text-base font-normal font-weight-700 leading-20.02 tracking-0.17 text-black',
  tableFooterRow: 'border-t-2    border-[#a8c5bf] !bg-[#BFDFD8]',
  dollarIcon:
    'bg-[#026EA1] text-white !text-4xl p-[2px] !rounded-md cursor-pointer',
  errorRow: '!pt-0',
  successColor:
    'bg-[#AC6E1A] text-white !text-4xl p-[2px] !rounded-md cursor-pointer',
};
