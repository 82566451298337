const classes = {
  container: 'h-screen flex flex-col',
  // header: 'flex-none',
  main: 'flex-1',
  mainHeight: { height: 'calc(100vh - 53.98px)' },
  content: 'w-full h-full flex flex-row overflow-hidden',
  sidebar:
    'flex-none relative bg-gradient-to-b from-[#006C9D] from-[68%] to-[#0B2432]',
  childrenContainer: 'flex-1 h-full',
  // mainContent:
  //   'flex-1  bg-gray-100 h-[calc(100vh-71px)] flex flex-col w-[calc(100%-64px)] ',
  mainContent:
    'flex-1  bg-gray-100 h-[calc(100vh-71px)] flex flex-col w-[100%] relative',
};

export default classes;
