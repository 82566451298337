import { Button, Menu, ToastType } from '@components';
import {
  TrackingData,
  disableTracking,
  enableTracking,
  orderStore,
} from '@store/orders';
import ArrowDropDownIcon from '@suid/icons-material/ArrowDropDown';
import { Box } from '@suid/material';
import { timeZoneAbbreviation } from '@utils/dateFormat';
import { handleToast } from '@utils/utils';
import { trackingOptions } from '@views/order/constants';
import { Component } from 'solid-js';

import classes from './classes';

const StopsTracking: Component = () => {
  const serviceId =
    orderStore.order.loads[orderStore.activeTab.index]?.trackingService;

  const initialTrackingValues = {
    driverPhone:
      orderStore.order.loads[orderStore.activeTab.index]?.driverPhoneNumber,
    loadId: orderStore.order.loads[orderStore.activeTab.index]?.id,
    trackingStartDate: new Date().toISOString(),
    settings: {
      truckNumber: '',
      trailerNumber: '',
      timezone: timeZoneAbbreviation(),
      resend: true,
    },
    trackingStopWindows:
      orderStore.order.loads[orderStore.activeTab.index]?.stops?.map((val) => {
        return { Id: val.id, EDT: '', ETA: '' };
      }) || [],
    serviceId: serviceId === null ? 0 : serviceId,
  };

  const handleTracking = async (trackingOption: string) => {
    try {
      if (trackingOption === 'Disable') {
        await disableTracking(
          orderStore.order.loads[orderStore.activeTab.index].id,
        );
      } else if (trackingOption === 'Resend') {
        const resp = await enableTracking(
          initialTrackingValues as unknown as TrackingData,
        );

        if (resp) {
          handleToast(ToastType.Success, resp.message);
        }
      }
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  return (
    <Box class="flex justify-center">
      <Menu
        menuItems={trackingOptions}
        disabled={orderStore.isReadOnly}
        menuButtonLabel={
          <Button
            label="Tracking Options"
            class={classes.stopsTrackingButton}
            endIcon={<ArrowDropDownIcon fontSize="small" />}
            disabled={orderStore.isReadOnly}
            size="small"
            sx={{ lineHeight: '22px' }}
          />
        }
        onMenuItemClick={(item) => {
          void handleTracking(item);
        }}
        downArrowShowHide={false}
      />
    </Box>
  );
};

export default StopsTracking;
