import {
  BasicModal,
  Button,
  Card,
  DatePicker,
  EmptyData,
  Typography,
} from '@components';
import { DialogBox } from '@components/DialogBox';
import { TextInput } from '@components/forms';
import { openDialogBox } from '@store/dialogBox';
import { openModal } from '@store/modals';
import { PaymentViewModel, orderStore, updateOrderState } from '@store/orders';
import {
  AddCircleOutlineOutlined,
  CreditCard,
  Delete,
} from '@suid/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@suid/material';
import { formatAmount } from '@utils/utils';
import { DateTime } from 'luxon';
import { Accessor, Index, Show, createSignal } from 'solid-js';

import { removeTableRow } from '../carrier/stops/stopsEditor/utilFunctions';
import { PayWithCreditCardModal } from './CustomerInformation/index';
import classes from './classes';

export const CustomerPayments = () => {
  const [deleteRow, setDeleteRow] = createSignal<number | null>(null);

  const addRow = () => {
    if (orderStore.isReadOnly) {
      return;
    }

    const payments = [...orderStore.order.payments];
    payments.push({
      operationType: 'Insert',
      id: 0,
      type: 'Payment',
      description: '',
      amount: 0,
      status: 'PendingSync',
      paidDate: new Date().toISOString(),
      referenceNum: '',
      checkNumber: null,
      // orderId: orderStore.order.id,
    });
    updateOrderState({ payments: payments });
  };

  const headers = ['', 'Date', 'Reference', 'Description', 'Amount'];

  const cellStyles = {
    padding: '1px',
  };

  const computeTotalPayments = () => {
    return orderStore.order.payments.length > 0
      ? orderStore.order.payments
          .filter((v) => v.operationType !== 'Delete')
          .reduce((acc, item) => acc + item.amount, 0)
      : 0;
  };

  const computeTotalLineItems = () => {
    return orderStore.order.lineItems.length > 0
      ? orderStore.order.lineItems
          .filter((v) => v.operationType !== 'Delete')
          .reduce(
            (acc, item) => acc + Number(item.rate) * Number(item.quantity),
            0,
          )
      : 0;
  };

  const handleCustomerPaymentChange = (
    key: string,
    value: string | number,
    index: number,
  ) => {
    const payments = [...orderStore.order.payments];
    if (payments[index].operationType === 'None') {
      payments[index] = {
        ...payments[index],
        [key]: value,
        operationType: 'Update',
      };
    } else {
      payments[index] = { ...payments[index], [key]: value };
    }
    updateOrderState({
      payments: payments,
    });
  };

  return (
    <Card
      startTitle="Customer Payments"
      loading={orderStore.loading}
      endTitle={
        <Button
          label="Pay With Card"
          startIcon={<CreditCard />}
          onClick={() => openModal('payWithCreditCardModal')}
          sx={{
            backgroundColor: '#468DB5',
            color: '#FFF',
            lineHeight: '22px',
            marginTop: '-1px',
            marginBottom: '-1px',
          }}
          disabled={orderStore.isReadOnly}
          size="small"
        />
      }
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((item) => (
                <TableCell sx={cellStyles} class="!text-xs">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <Show
            when={
              orderStore.order.payments.filter(
                (item) => item.operationType !== 'Delete',
              ).length === 0
            }
          >
            <TableBody class={classes.customerPayments.emptyTable}>
              <TableRow>
                <TableCell colspan={headers.length} style={{ border: 0 }}>
                  <EmptyData />
                </TableCell>
              </TableRow>
            </TableBody>
          </Show>
          <Show when={orderStore.order.payments.length > 0}>
            <TableBody class={classes.customerPayments.tableBg}>
              <Index each={orderStore.order.payments}>
                {(item: Accessor<PaymentViewModel>, index) => (
                  <Show when={item().operationType !== 'Delete'}>
                    <TableRow class={classes.customerPayments.tableRow}>
                      <TableCell sx={{ padding: '0px' }}>
                        <IconButton
                          onClick={() => {
                            setDeleteRow(index);
                            openDialogBox('deleteCustomerPaymentRow');
                          }}
                          disabled={orderStore.isReadOnly}
                        >
                          <Delete
                            class={classes.customerPayments.deleteIcon}
                            fontSize="small"
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                        }}
                      >
                        <div class="tablet:max-w-[100px] 1080p:max-w-[500px]">
                          <DatePicker
                            size="small"
                            value={DateTime.fromJSDate(
                              new Date(item().paidDate),
                            ).toISO()}
                            handleChange={(value) => {
                              handleCustomerPaymentChange(
                                'paidDate',
                                value,
                                index,
                              );
                            }}
                            placeholder="Select Date"
                            disabled={orderStore.isReadOnly}
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        sx={{
                          ...cellStyles,
                        }}
                      >
                        <div class="tablet:max-w-[90px] 1080p:max-w-[500px]">
                          <TextInput
                            size="small"
                            value={item().referenceNum as string}
                            onChange={(value: string) => {
                              handleCustomerPaymentChange(
                                'referenceNum',
                                value,
                                index,
                              );
                            }}
                            classes={'bg-white rounded-lg'}
                            label=""
                            placeholder="Reference #"
                            disabled={orderStore.isReadOnly}
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={{ ...cellStyles }}>
                        <div class="tablet:max-w-[100px] 1080p:max-w-[500px]">
                          <TextInput
                            size="small"
                            value={item().description}
                            onChange={(value: string) => {
                              handleCustomerPaymentChange(
                                'description',
                                value,
                                index,
                              );
                            }}
                            classes={'bg-white rounded-lg'}
                            label=""
                            placeholder="Description"
                            disabled={orderStore.isReadOnly}
                          />
                        </div>
                      </TableCell>
                      <TableCell sx={{ ...cellStyles }}>
                        <div class="tablet:max-w-[60px] 1080p:max-w-[500px]">
                          <TextInput
                            size="small"
                            label=""
                            placeholder="Amount"
                            type="number"
                            value={item().amount}
                            onChange={(value) => {
                              handleCustomerPaymentChange(
                                'amount',
                                value as unknown as number,
                                index,
                              );
                            }}
                            classes={'bg-white rounded-lg'}
                            disabled={orderStore.isReadOnly}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </Show>
                )}
              </Index>
            </TableBody>
          </Show>
          <TableFooter class={classes.customerPayments.tableFooter}>
            <TableRow class={classes.customerPayments.tableFooterRow}>
              <TableCell sx={cellStyles}>
                <AddCircleOutlineOutlined
                  class={`${classes.customerPayments.addIcon} ${
                    orderStore.isReadOnly ? '' : 'cursor-pointer'
                  }`}
                  onClick={addRow}
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell sx={cellStyles}>
                <Typography
                  variant="body1"
                  class={classes.customerPayments.totalLabel}
                >
                  Total
                </Typography>
              </TableCell>
              <TableCell sx={cellStyles}>
                <Typography
                  variant="body1"
                  class={classes.customerPayments.totalAmount}
                >
                  {formatAmount(computeTotalPayments())}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow class={classes.customerPayments.tableFooterRow}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell sx={cellStyles}>
                <Typography
                  variant="body1"
                  class={classes.customerPayments.totalLabel}
                >
                  Outstanding
                </Typography>
              </TableCell>
              <TableCell sx={cellStyles}>
                <Typography
                  variant="body1"
                  class={classes.customerPayments.totalAmount}
                >
                  {formatAmount(
                    computeTotalLineItems() - computeTotalPayments(),
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <BasicModal
        id="payWithCreditCardModal"
        title="Pay With Credit Card"
        width={900}
        footer={false}
        showClose={false}
      >
        <PayWithCreditCardModal />
      </BasicModal>
      <DialogBox
        id="deleteCustomerPaymentRow"
        title={'Are you sure you want to delete this payment?'}
        onSubmit={() => {
          removeTableRow(deleteRow()!, orderStore.order, 'order', 'payments');
          setDeleteRow(null);
        }}
        onSubmitText="Delete"
      />
    </Card>
  );
};
