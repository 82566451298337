import { Accessor, Index, Show, createSignal, onMount } from 'solid-js';
import {
  Button,
  Typography,
  BasicModal,
  WarningPlaceholder,
  Placeholder,
} from '@components';
import { Grid } from '@suid/material';
import { EDI_LOGS_ID } from '@views/order/constants';
import {
  loadCustomerEdiLogs,
  ediLogsStore,
  customerStore,
  ediLog,
  resendEDI,
  orderStore,
} from '@store/orders';
import { formatDate } from '@utils/dateFormat';
import { closeModal, openModal } from '@store/modals';
import { get, isEmpty } from 'lodash';
import { printLog } from '@utils/utils';

import CompareChange from './CompareChanges';
import cls from './classes';

const EDI_LOGS_COMPARE_CHANGES = 'ediLogsCompareChanges';
interface EdiLogEntryProps {
  buttonLabel: string;
  buttonSx?: React.CSSProperties;
  customClass?: string;
  titleClass?: string;
  type: string | undefined;
  timeStamp?: string;
  item: Accessor<ediLog>;
  onClick?: () => void;
  index: number;
}
const [isLoading, setIsLoading] = createSignal<number>();
const [changeEDITransactionId, setChangeEDITransactionId] = createSignal<
  string | undefined
>('');

const EdiLogTitle = (type: string | undefined, item: Accessor<ediLog>) => {
  if (type === '204') {
    return (
      <div>
        Customer Sent <strong>{item().tenderType}</strong> Tender
      </div>
    );
  }
  if (type === '990') {
    return (
      <div>
        Tender Response:{' '}
        <strong class="text-[#388E3C]">{item().response}</strong>
      </div>
    );
  }
  if (type === '214') {
    return (
      <>
        <span>
          Shipment Update Sent
          <p>Stop Number: {item().stopNumber}</p>
          <strong>
            {`(${get(item(), 'event.code', '')})`}{' '}
            {get(item(), 'event.description', '')}
          </strong>{' '}
          <div>
            {get(item(), 'status.description', '')} at{' '}
            {convertDate(get(item(), 'eventTime', ''))}
          </div>
          <p>{get(item(), 'note', '')}</p>
        </span>
      </>
    );
  }
  if (type === '210') {
    return <strong> EDI Invoice Sent</strong>;
  }
};

const convertDate = (timeStamp: string) => {
  return formatDate({
    date: new Date(timeStamp),
    formatString: 'LLL dd,yyyy h:mm a',
  });
};

const EdiLogEntry = (props: EdiLogEntryProps) => (
  <div class={`${cls.ediLogParent} ${props.customClass} `}>
    <Typography variant="inherit" class={cls.ediTimeStamp}>
      {props.type} |{' '}
      {formatDate({
        date: new Date(props.timeStamp ?? new Date()),
        formatString: 'LLL dd,yyyy h:mm a',
      })}
    </Typography>
    <div class={props.titleClass}>
      {EdiLogTitle(get(props, 'type'), props.item)}
    </div>
    <Button
      label={props.buttonLabel}
      type="submit"
      variant="contained"
      sx={{ marginRight: 2, ...props.buttonSx }}
      onClick={props.onClick}
      isLoading={isLoading() === props.index}
      disabled={isLoading() === props.index}
    />
  </div>
);

type ediLogProps = {
  hideX6: Accessor<boolean>;
};

export const EdiLog = (props: ediLogProps) => {
  onMount(() => {
    void loadCustomerEdiLogs(
      get(customerStore.customer, 'id'),
      orderStore.order.referenceNumber,
    );
  });

  const ResendEDIData = async (id: string | undefined, index: number) => {
    setIsLoading(index);
    try {
      await resendEDI(id);
      setIsLoading();
    } catch (error: unknown) {
      printLog(error, 'errors');
      setIsLoading();
    }
  };

  const filteredLogs = () => {
    return ediLogsStore.ediLogs.filter((log) => {
      if (props.hideX6()) {
        return !(log.event && log.event.code === 'X6');
      }
      return true;
    });
  };

  return (
    <>
      <Show when={isEmpty(ediLogsStore.ediLogs) && !ediLogsStore.loading}>
        <WarningPlaceholder
          message={'This order does not have any history associated with it.'}
        />
      </Show>
      <Show when={ediLogsStore.loading}>
        <Placeholder variant="rectangular" height={40} />
      </Show>
      <Show when={!isEmpty(filteredLogs()) && !ediLogsStore.loading}>
        <div class={cls.ediContainer}>
          <Index each={filteredLogs()}>
            {(item: Accessor<ediLog>, index: number) => (
              <EdiLogEntry
                item={item}
                buttonLabel={
                  item().type === '204' ? 'COMPARE CHANGE' : 'Resend'
                }
                buttonSx={{
                  backgroundColor:
                    item().type === '204' ? '#388E3C' : '#1B4960',
                  maxHeight: '40px',
                  alignSelf: 'center',
                }}
                index={index}
                type={item().type}
                customClass="border-l-[#AEA3FF]"
                timeStamp={convertDate(item().timestamp ?? '')}
                onClick={
                  item().type === '204'
                    ? () => {
                        setChangeEDITransactionId(item().transactionId);
                        openModal(EDI_LOGS_COMPARE_CHANGES);
                      }
                    : () => ResendEDIData(item().transactionId, index)
                }
              />
            )}
          </Index>
        </div>
      </Show>

      <Grid class={cls.buttonsContainer}>
        <Button
          variant="text"
          label="CANCEL"
          onClick={() => closeModal(EDI_LOGS_ID)}
        />
      </Grid>
      <BasicModal
        id={EDI_LOGS_COMPARE_CHANGES}
        title="EDI Transaction History"
        width={'1350px'}
        onClose={() => closeModal(EDI_LOGS_COMPARE_CHANGES)}
        footer={false}
      >
        <CompareChange changeEDITransactionId={changeEDITransactionId} />
      </BasicModal>
    </>
  );
};
