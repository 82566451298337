import { Tab, TabsComponent } from '@components';
import { Box } from '@suid/material';
import { Component, createSignal, Match, Switch } from 'solid-js';
import { FactoringCompanyEditHistory } from '@views/factoringCompany/factoringCompanyEditHistory';

import { FactoringCompanyDetailsTab } from './FactoringCompanyDetailsTab';
import { FactoringCompanyDetailsCarriersTab } from './FactoringCompanyDetailsCarriersTab';
import { FactoringCompanyDetailsLoadTab } from './FactoringCompanyDetailsLoadTab';

export const FactoringCompanyDetailsTabPanel: Component = () => {
  const [activeTab, setActiveTab] = createSignal(0);

  const tabs = ['DETAILS', 'CARRIERS', 'LOAD', 'EDIT HISTORY'];

  return (
    <>
      <TabsComponent
        tabs={tabs.map((item) => (
          <Tab>{item}</Tab>
        ))}
        activeTab={activeTab()}
        onTabClick={setActiveTab}
      />
      <Box p={2}>
        <Switch>
          <Match when={activeTab() === 0}>
            <FactoringCompanyDetailsTab />
          </Match>
          <Match when={activeTab() === 1}>
            <FactoringCompanyDetailsCarriersTab />
          </Match>
          <Match when={activeTab() === 2}>
            <FactoringCompanyDetailsLoadTab />
          </Match>
          <Match when={activeTab() === 3}>
            <FactoringCompanyEditHistory />
          </Match>
        </Switch>
      </Box>
    </>
  );
};
