import { DateTime } from 'luxon';

interface formatDateProps {
  date: Date;
  formatString: string;
}

export const formatDate = ({ date, formatString }: formatDateProps) => {
  return DateTime.fromJSDate(date).toFormat(formatString);
};

export const formatDateString = (
  date1?: string | Date | null,
  format?: string,
): string => {
  if (date1 === undefined || date1 === null) return '';
  const date =
    date1 instanceof Date
      ? DateTime.fromJSDate(date1)
      : DateTime.fromISO(date1);

  return date.toFormat(format ?? 'MM/dd/yyyy');
};

export const timeZoneAbbreviation = () => {
  // Create a DateTime object with the current date
  const now = DateTime.now();
  // Get the time zone abbreviation
  return now.toFormat('z');
};

export const convertUtcToLocalAndFormat = (
  date: string,
  format: string,
): string => {
  return DateTime.fromISO(date, {
    zone: 'utc',
  })
    .setZone('local')
    .toFormat(format);
};

export const formatDateOrNull = (date: string | null): string | null => {
  if (date === null) return null;
  if (typeof date !== 'string' || date.trim() === '') return null;
  const dateFormatRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  if (dateFormatRegex.test(date.trim())) {
    return date;
  }
  return formatDateString(date);
};
