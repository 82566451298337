﻿import { DateTime } from 'luxon';
import * as yup from 'yup';

import { checkValidZipCode } from './helper';
import { LTLQuotingState } from './types';

type yupThisObject = {
  createError: (error: { message: string }) => void;
  parent: LTLQuotingState;
};

const LoadItemSchema = yup.object().shape({
  quantity: yup
    .number()
    .required('Quantity is required')
    .test('is-decimal', 'Quantity cannot be a decimal', (value) => {
      return Boolean(value) && !value.toString().includes('.');
    })
    .moreThan(0, 'Quantity must be greater than 0'),
  item: yup.string().required('Unit type is required'),
  description: yup
    .string()
    .nullable()
    .required('Description is required')
    .max(150, 'Description must be at most 150 characters'),
  pieces: yup.number().nullable(),
  weight: yup
    .mixed()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .test('is-number-or-empty', 'weight must be a number', (value) => {
      return value === '' || !isNaN(Number(value)) || value === undefined;
    })
    .test('is-positive', 'Weight must be greater than 0', (value) => {
      return Number(value) > 0;
    })
    .nullable()
    .test(
      'must-be-at-least-999',
      'Weight must be at most 6 digits',
      (value) => {
        return Number(value) <= 999999;
      },
    )
    .required('Weight is required'),
  lengthInch: yup.number().nullable().moreThan(-1, 'Length cannot be negative'),
  widthInch: yup.number().nullable().moreThan(-1, 'Width cannot be negative'),
  heightInch: yup.number().nullable().moreThan(-1, 'Height cannot be negative'),
  nmfc: yup.string().nullable(),
  class: yup.string().required('Class is required'),
  hazmat: yup.boolean().nullable(),
  uom: yup.string().nullable(),
});

export const LTLQuotingSchema = yup.object().shape({
  originZip: yup
    .string()
    .required()
    .test({
      name: 'originZip',
      test: function (value) {
        const { createError, parent }: yupThisObject = this;

        if (!value)
          return createError({
            message: 'Origin Zip Code is required',
          });

        if (!checkValidZipCode(value)) {
          return createError({
            message: 'Invalid Origin Zip Code',
          });
        }

        if (!Boolean(parent.originCity)) {
          return createError({
            message: 'Please make a selection',
          });
        }
        return true;
      },
    }),
  destinationZip: yup
    .string()
    .required()
    .test({
      name: 'destinationZip',
      test: function (value) {
        const { createError, parent }: yupThisObject = this;
        if (!value)
          return createError({
            message: 'Destination Zip Code is required',
          });
        if (!checkValidZipCode(value)) {
          return createError({
            message: 'Invalid Destination Zip Code',
          });
        }
        if (!Boolean(parent.destinationCity)) {
          return createError({
            message: 'Please make a selection',
          });
        }
        return true;
      },
    }),
  pickupDate: yup
    .date()
    .test(
      'is-current-or-future-date',
      'Invalid Pickup Date. Date cannot be before today.',
      (value) => {
        if (!value) return false;
        const today = DateTime.now().startOf('day');
        const inputDate = DateTime.fromJSDate(value).startOf('day');
        return inputDate >= today;
      },
    )
    .required(),
  declaredValue: yup
    .number()
    .max(999999, 'Must be less than 999999')
    .nullable(),
  loadItems: yup.array().of(LoadItemSchema),
});

export const hazmatDetailsSchema = yup.object().shape({
  contactName: yup.string().required('Contact Name is Required'),
  contactNumber: yup
    .string()
    .min(10, 'Phone number must be at least 10 digits'),
});
