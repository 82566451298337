import { SelectField, TextAreaField } from '@components/forms';
import {
  applyCollectionActions,
  collectionDetailsStore,
  sendToCollections,
} from '@store/collectionsDashboard';
import { Box, Chip, Grid, Typography } from '@suid/material';
import CloseIcon from '@suid/icons-material/Close';
import { Accessor, createSignal, onMount } from 'solid-js';
import { Button, DialogBox, ToastType } from '@components';
import { handleToast } from '@utils/utils';
import { closeDialogBox, openDialogBox } from '@store/dialogBox';

import { SEND_TO_COLLECTIONS } from '../constants';

type CollectionNotesProps = {
  selectedRows: Accessor<number[]>;
};

const CollectionNotes = (props: CollectionNotesProps) => {
  const [actionNotes, setActionNotes] = createSignal<string[]>([]);
  const [selectedNotes, setSelectedNotes] = createSignal<string[]>([]);
  const [commentVal, setCommentVal] = createSignal<string>('');

  onMount(() => {
    setActionNotes(collectionDetailsStore.collectionDetails.actions);
  });

  const handleDelete = (value: string) => {
    const updatedActions = selectedNotes().filter((item) => item !== value);
    setSelectedNotes(updatedActions);
    setActionNotes((prev) => [...prev, value].sort());
  };

  const handleBulkCollectionAction = async () => {
    if (commentVal().length > 4000) {
      handleToast(ToastType.Error, 'Comment should not exceed 4000 characters');
      return;
    }
    const notes = selectedNotes();
    if (notes.length === 0) {
      handleToast(
        ToastType.Error,
        'You have not selected any actions to perform',
      );
      return;
    }

    const payload = props.selectedRows().map((orderId) => ({
      actions: notes,
      orderId: Number(orderId),
      ...(commentVal() && { comment: commentVal() }),
    }));

    await applyCollectionActions(payload, () => {
      setSelectedNotes([]);
      setCommentVal('');
    });
  };

  const handleSendToCollections = async () => {
    await sendToCollections(props.selectedRows());
    closeDialogBox(SEND_TO_COLLECTIONS);
  };

  return (
    <Box class="bg-[#f5f5f5] border p-[10px]">
      <Typography class="!font-medium !text-sm !py-[5px]">
        Collections Notes:
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectField
            sxProps={{
              height: 'auto',
              maxWidth: '100%',
            }}
            menuItems={actionNotes().map((item) => ({
              label: item,
              value: item,
            }))}
            label=""
            size="small"
            value={selectedNotes()}
            renderValue={(selected: string | string[]) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                }}
              >
                {(selected as string[]).map((value) => (
                  <Chip
                    label={value}
                    deleteIcon={
                      <CloseIcon
                        sx={{
                          fontSize: '15px !important',
                          color: 'white !important',
                        }}
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    sx={{
                      color: 'white',
                      backgroundColor: '#026EA1',
                      borderRadius: 1,
                    }}
                    onDelete={() => handleDelete(value)}
                  />
                ))}
              </Box>
            )}
            onChange={(e) => {
              const updateSelectedNotes = actionNotes().find(
                (item) => item === e.target.value,
              );
              if (updateSelectedNotes === undefined) return;
              setSelectedNotes([...selectedNotes(), updateSelectedNotes]);
              setActionNotes([
                ...actionNotes().filter((item) => item !== e.target.value),
              ]);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextAreaField
            label=""
            rows={3}
            value={commentVal()}
            onChange={(value) => setCommentVal(value)}
            class="bg-[#ffffff]"
          />
        </Grid>
        <Grid container item gap={1}>
          <Grid item lg={5} xs={12}>
            <Button
              label="Apply To All"
              variant="contained"
              size="small"
              onClick={handleBulkCollectionAction}
            />
          </Grid>
          <Grid item lg={6.5} xs={12}>
            <Button
              label="Send to Collections"
              variant="contained"
              size="small"
              onClick={() => openDialogBox(SEND_TO_COLLECTIONS)}
              class=" !bg-[#d9534f]"
            />
          </Grid>
        </Grid>
      </Grid>
      <DialogBox
        title="Are you sure you want to send these Invoices to Collections?"
        id={SEND_TO_COLLECTIONS}
        onSubmit={handleSendToCollections}
      />
    </Box>
  );
};

export default CollectionNotes;
