import { Badge, Box, Divider, Typography } from '@suid/material';
import { For, Show, createSignal, createMemo } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { Info } from '@suid/icons-material';
import { DateTime } from 'luxon';
import { routeOrderDetails } from '@utils/routeOrderDetails';
import { TextInput } from '@components/forms';
import { debounce } from 'lodash';

import { approvalDetailsStore, tabsStyle } from '../Approvals';
import cls from '../classes';

type Props = {
  name: string;
};

const ApprovalCard = (props: Props) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = createSignal('');

  const handleSearchInput = debounce((value: string) => {
    setSearchQuery(value);
  }, 300);

  const filteredApprovals = createMemo(() => {
    const groupings =
      props.name === 'AllApprovals'
        ? approvalDetailsStore.approvalGrouping
        : approvalDetailsStore.disputedGrouping;

    if (!searchQuery()) return groupings;

    const searchLower = searchQuery().toLowerCase();
    return Object.fromEntries(
      Object.entries(groupings).filter(([agent]) =>
        agent.toLowerCase().includes(searchLower),
      ),
    );
  });

  const handleApprovalRowClick = (agentId: number) => {
    const isNative = routeOrderDetails(agentId);
    if (isNative) {
      navigate(`/Order/Details/${agentId}`);
    }
  };

  return (
    <Box>
      <TextInput
        label="Filter Person"
        variant="outlined"
        onInput={(e: Event) => {
          const target = e.target as HTMLInputElement | null;
          if (target) {
            handleSearchInput(target.value);
          }
        }}
        sxProps={{ marginY: '16px' }}
      />

      <For each={Object.entries(filteredApprovals())}>
        {([agent, approvals]) => (
          <Box mb={2}>
            <Show when={!searchQuery()}>
              <Typography variant="h6">
                {agent} <Badge badgeContent={approvals.length} sx={tabsStyle} />
              </Typography>
            </Show>
            <For each={approvals}>
              {(approval) => (
                <Box
                  class={`${cls.approvalCard} ${
                    approval.needsAttention
                      ? cls.withNeedAttentionBg
                      : cls.withoutNeedAttentionBg
                  } ${approval.isDisputed && cls.disputedBorderStyle}`}
                  onClick={() => handleApprovalRowClick(approval.order)}
                >
                  <Info
                    class={`${cls.infoStyle} ${
                      approval.needsAttention
                        ? cls.needAttention
                        : cls.withoutNeedAttention
                    }`}
                  />
                  <Box class="w-full">
                    <Box class={cls.boxStyle}>
                      <Box class={cls.boxStyle}>
                        <Typography class={cls.typoFont}>
                          {approval.order}
                        </Typography>
                        <Show when={approval.quickpay}> - QuickPay</Show>
                        <Show when={approval.isDisputed}>
                          <Typography class={cls.disputedStyle}>
                            Disputed
                          </Typography>
                        </Show>
                      </Box>
                      <Box class={cls.boxStyle}>
                        <Typography class={cls.typoFont}>
                          {approval.agent}
                        </Typography>
                        <Box class="mx-[4px]">|</Box>
                        <Typography class={cls.typoFont}>
                          {DateTime.fromISO(approval.setDate).toFormat(
                            'MM/dd/yyyy',
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider class="!my-[5px]" />
                    <Box
                      class={`${
                        approval.needsAttention
                          ? cls.needAttention
                          : cls.withoutNeedAttention
                      }`}
                    >
                      <span class={cls.typoFont}>{approval.setBy} - </span>
                      <span>{approval.description}</span>
                    </Box>
                    <Show when={approval.lastComment}>
                      <Divider class="!my-[5px]" />
                      <Box
                        class={`${
                          approval.needsAttention
                            ? cls.needAttention
                            : cls.withoutNeedAttention
                        }`}
                      >
                        <Typography class={cls.boxStyle}>
                          <span class={cls.typoFont}>Last Comment: </span>
                          <span>
                            {approval.lastCommentBy} -{' '}
                            {Boolean(approval.lastCommentDate)
                              ? DateTime.fromISO(
                                  approval.lastCommentDate!,
                                ).toFormat('MM/dd/yyyy')
                              : ''}
                          </span>
                        </Typography>
                        <Typography>{approval.lastComment}</Typography>
                      </Box>
                    </Show>
                  </Box>
                </Box>
              )}
            </For>
          </Box>
        )}
      </For>
    </Box>
  );
};

export default ApprovalCard;
