import { Button, CardPlaceholder, Notification } from '@components';
import { closeModal } from '@store/modals';
import { IOrderViewModel, fetchEdiChangeLogs, orderStore } from '@store/orders';
import { ChevronLeft } from '@suid/icons-material';
import { Accessor, Show, createSignal, onMount } from 'solid-js';
import { printLog } from '@utils/utils';

import cls from './classes';
import EDIChangeComparison from './components/EDIChangeComparison';

type Props = {
  changeEDITransactionId: Accessor<string | undefined>;
};

const EDI_LOGS_COMPARE_CHANGES = 'ediLogsCompareChanges';

const CompareChange = (props: Props) => {
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [changeEDIData, setChangeEDIData] = createSignal<IOrderViewModel>();

  const NotificationText = () => {
    return (
      <>
        <span class={cls.notificationText}>
          {' '}
          Nothing is committed until you save the order. If the system does not
          perform the merge correctly, you can refresh and merge your changes
          manually.
        </span>
      </>
    );
  };

  onMount(async () => {
    try {
      setIsLoading(true);
      const response = await fetchEdiChangeLogs(props.changeEDITransactionId());
      setChangeEDIData(response);
    } catch (error: unknown) {
      printLog(error, 'errors');
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <>
      <Show when={!isLoading()} fallback={<CardPlaceholder />}>
        <div class={cls.compareTopContainer}>
          <Button
            startIcon={<ChevronLeft />}
            variant="contained"
            label="Back"
            class={cls.backBtn}
            onClick={() => closeModal(EDI_LOGS_COMPARE_CHANGES)}
          />
          <Notification type="info" text={NotificationText()} />
        </div>
        <div class="flex">
          <div class="w-[50%] pr-[5px]">
            <EDIChangeComparison order={orderStore.order} noUpdate={true} />
          </div>
          <div class="w-[50%] pl-[5px]">
            {changeEDIData() && (
              <EDIChangeComparison
                order={changeEDIData() as IOrderViewModel}
                noUpdate={false}
              />
            )}
          </div>
        </div>
      </Show>
    </>
  );
};
export default CompareChange;
