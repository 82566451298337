export default {
  headerContainer: '!shadow-lg h-[100%] px-4 py-2 z-10 md:!h-fit lg:h-24',
  orderSubText: 'divide-x-[1px] divide-black',
  headerBtn: '!bg-[#E0E0E0] !text-black !h-fit',
  deleteBtn: '!bg-[#B00020] !text-white h-fit',
  customerNameText: 'inline-flex items-center gap-2 justify-start',
  customerActionBtns: 'flex items-center gap-3 justify-end flex-wrap ',
  blacklistCommentTitle: '!text-[#1B4960] !text-base !font-medium',
  deleteBtnContainer: 'inline-flex h-full !pt-[9px]',
};
