import { SavedQuoteInfo } from '@store/ltl';
import { ltlQuoteState, savedQuoteStore } from '@store/ltl/store';
import { IsFeatureFlagEnabledByName } from '@store/Global/service';

import { ConfigManager } from './ConfigManager';
import { printLog } from './utils';

export const copyQuoteEvent = (groupId: string) => {
  const selectedQuotes: SavedQuoteInfo[] = [];
  savedQuoteStore.items.forEach((item) => {
    if (item.quoteStatus === 'Active' && item.groupId === groupId) {
      selectedQuotes.push(item);
    }
  });
  if (!Boolean(selectedQuotes) || !Boolean(groupId)) {
    return;
  }
  if (!Boolean(selectedQuotes) || selectedQuotes.length <= 0) {
    return;
  }
  const singleQuote = selectedQuotes[0];

  const lineItems = singleQuote.lineItems.map((i) => {
    const newI = { ...i, dimensions: '' };
    if (
      Boolean(i.heightInches) &&
      Boolean(i.lengthInches) &&
      Boolean(i.widthInches)
    ) {
      newI.dimensions = `${i.lengthInches ?? ''}\" x ${
        i.widthInches ?? ''
      }\" x ${i.heightInches ?? ''}\"`;
    }
    return newI;
  });
  const accessorials = singleQuote.pickupAccessorials
    .concat(singleQuote.deliveryAccessorials, singleQuote.shipmentAccessorials)
    .filter((val, i, array) => array.indexOf(val) === i);

  accessorials.sort();

  const lineItemEls = lineItems
    .map((i) => {
      return `<tr>
        <td>${i.quantity}</td>
        <td>${i.item}</td>
        <td>${i.weight} lbs</td>
        <td>${i.dimensions}</td>
        <td>${i.class}</td>
        </tr>`;
    })
    .join('');

  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  selectedQuotes.sort((a, b) => {
    if (
      a.responseInfo.customerRateQuote?.totalCost !== undefined &&
      b.responseInfo.customerRateQuote?.totalCost !== undefined
    ) {
      if (
        a.responseInfo.customerRateQuote.totalCost <
        b.responseInfo.customerRateQuote.totalCost
      ) {
        return -1;
      }
      if (
        a.responseInfo.customerRateQuote.totalCost >
        b.responseInfo.customerRateQuote.totalCost
      ) {
        return 1;
      }
    }
    return 0;
  });
  const carrierRatesEls = selectedQuotes
    .map((q) => {
      return `<tr>
        <td>${q.carrierName}</td>
        <td>${USDollar.format(
          Boolean(q.responseInfo) && q.responseInfo.customerRateQuote
            ? q.responseInfo.customerRateQuote.totalCost
            : 0,
        )}</td>
        ${
          ltlQuoteState.isFalveyInsuranceFlow &&
          Boolean(singleQuote.declaredValue)
            ? `<td>${USDollar.format(
                Boolean(q.responseInfo) && q.responseInfo.customerRateQuote
                  ? q.responseInfo.customerRateQuote.totalCost +
                      (q.quotePremium ?? 0) +
                      (q.quoteServiceFee ?? 0)
                  : 0,
              )}</td>`
            : ''
        }
        <td>${USDollar.format(
          Boolean(q.responseInfo) && q.responseInfo.maxNewLiability != null
            ? q.responseInfo.maxNewLiability
            : 0,
        )}/${USDollar.format(
          Boolean(q.responseInfo) && q.responseInfo.maxUsedLiability != null
            ? q.responseInfo.maxUsedLiability
            : 0,
        )}</td>
        <td>${q.serviceType}</td>
        <td>${q.transitDays}</td>
        </tr>`;
    })
    .join('');
  const copyQuoteTemplate = `<style>
    th,
    td {
      text-align: left;
      padding-left: 0;
    }

    table {
        width: 600px;
    }

    b {
        font-size: larger;
    }
    </style>
    <div>
        <p><b>Quote Group #</b></p>
        <p>${
          Boolean(IsFeatureFlagEnabledByName('UseShortQuoteGroupLocator'))
            ? singleQuote.quoteRequestLocator ?? singleQuote.groupId
            : singleQuote.groupId
        }</p>
        <table>
            <thead>
                <tr>
                    <th style="width: 50%;"><p><b>Origin</b></p></th>
                    <th style="width: 50%;"><p><b>Destination</b></p></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>${singleQuote.originCity}, ${
                      singleQuote.originState
                    }, ${singleQuote.originZip}</td>
                    <td>${singleQuote.destinationCity}, ${
                      singleQuote.destinationState
                    }, ${singleQuote.destinationZip}</td>
                </tr>
            </tbody>
        </table>
        <p><b>Accessorials</b></p>
        <p>${accessorials.join(', ')}</p>
        <p><b>Items</b></p>
        <table>
            <thead>
                <tr>    
                    <th># of Units</th>
                    <th>Unit Type</th>
                    <th>Weight</th>
                    <th>Dimensions</th>
                    <th>Class</th>
                </tr>
            </thead>
            <tbody>
                ${lineItemEls}
            </tbody>
        </table>
        <p><b>LTL Carrier Rates</b></p>
         ${
           ltlQuoteState.isFalveyInsuranceFlow &&
           Boolean(singleQuote.declaredValue)
             ? `<th style="width: 50%;"><p><b>Declared Value: $${singleQuote.declaredValue}</b></p></th>`
             : ''
         }

        <table>
            <thead>
                <tr>
                    <th>Carrier</th>
                    <th>Rate</th>
                    ${
                      ltlQuoteState.isFalveyInsuranceFlow &&
                      Boolean(singleQuote.declaredValue)
                        ? `<th>Rate w/ Insurance ${
                            Boolean(singleQuote.quoteServiceFee) && '+ Fees'
                          }</th>`
                        : ''
                    }
                    <th>New/Used Liability</th>
                    <th>Service Type</th>
                    <th>Transit Days</th>
                </tr>
            </thead>
            <tbody>
                ${carrierRatesEls}
            </tbody>
        </table>
    </div>`;

  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        try {
          parent.postMessage(
            {
              message: 'copyQuote',
              quote: copyQuoteTemplate,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'copyQuote: There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};
