export const saveQuoteClasses = {
  heading: '!text-2xl !font-normal !text-[#123B50]',
  tabContainer: '!rounded-md !overflow-y-auto',
};

export const LTlFormClasses = {
  mandatory: 'text-rose-600',
  quoteNotification: '!w-full !gap-2',
  selectedValueBox: '!bg-[#dff0d8] flex gap-1 p-1 items-center text-xs',
  selectedValueIcon: '!text-base',
  subTitle: '!text-[#123B50]',
  subTitle2: '!pb-2 !text-base !font-medium !text-[#000000DE]',
  fabInputContainer: 'flex flex-wrap gap-2',
  shipmentSectionHeader: 'flex gap-2 items-center !pb-1',
  shipmentTitle: '!text-base !font-medium !text-[#000000DE]',
  shipmentClearLink: 'cursor-pointer underline text-[#026EA1] !text-sm',
  getRatesBtnContainer: 'flex justify-end items-center',
  tableContainer: 'overflow-y-auto ',
  hazmatPopOverContainer: 'flex items-center gap-1',
  hazmatPopOver:
    'text-[#1B4960] font-roboto text-base font-normal flex underline border-0 hover:bg-white absolute',
  deleteIcon: 'text-[#B00020] cursor-pointer',
};
