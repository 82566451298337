export const CommentCount = (count: number | undefined) => {
  return (
    <div
      class={
        'w-[30px] h-[30px] rounded-3xl bg-[#1B4960] text-white text-center border-[#fff] border-2'
      }
    >
      {count}
    </div>
  );
};
