import HeaderInfo from './HeaderInfo';
import { HeaderControl } from './HeaderControl';

export function OrderPageHeader() {
  return (
    <div class="flex justify-between items-center h-12 bg-white sticky shadow-lg">
      <HeaderControl />
      <HeaderInfo />
    </div>
  );
}
