const classes = {
  dateContainer: 'text-[14px] flex gap-[6px] date-container',
  timeContainer: 'text-[14px] text-[#000000DE] time-container',
  locationContainer: 'text-[14px] text-[#000000DE] location-container',
  weightContainer: 'text-[14px] text-[#000000DE] weight-container',
  descriptionContainer: 'text-[14px] text-[#000000DE] description-container',
  flexContainer: 'flex gap-[24px]',
  leftContainer: 'w-[60%]',
  rightContainer: 'w-[40%] bg-white',
  cancelButton:
    'text-right border-t-[1px] py-[22px] mt-[30px] text-[14px] font-medium cancel-button',
  payContainer: 'text-[#388E3C] text-[24px] mb-[8px] ',
  headingContainer: 'text-[34px] mb-[8px]',
  equipmentContainer: 'text-[16px]  mb-[8px]',
  transportationMethod: 'text-[16px]  mb-[8px]',
  boldFont: 'font-bold',
  marginBottom24: 'mb-[24px]',
  tableContainer: 'overflow-y-scroll h-80 mt-5',
  itemsContainer: 'items-container',
};

export default classes;
