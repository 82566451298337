import { FactoringCompany } from '@store/carriers';

export const FACTORING_COMPANY_INTIAL_OBJECT: FactoringCompany = {
  id: 0,
  createdDate: '',
  modifiedDate: '',
  name: '',
  taxId: null,
  needs1099: false,
  vendorHold: false,
  physicalAddress1: null,
  physicalAddress2: null,
  physicalCity: null,
  physicalState: null,
  physicalZip: null,
  remitTo: '',
  mailingAddress1: null,
  mailingAddress2: null,
  mailingCity: null,
  mailingState: null,
  mailingZip: null,
  billingTerms: '',
  status: 'Pending',
  userName: null,
  password: null,
  accountingSysId: null,
  aCHAccount: null,
  aCHRouting: null,
  primaryContact: {
    verificationStatus: 0,
    emailVerified: false,
    phoneVerified: false,
    id: 0,
    objectId: 0,
    objectType: '',
    name: '',
    phone1: '',
    extension1: null,
    phone2: null,
    extension2: null,
    fax: null,
    email: '',
    type: '',
    status: '',
    createdDate: '',
    modifiedDate: null,
    mailerEmails: false,
    password: null,
    userName: null,
    createdBy: null,
    modifiedBy: null,
    phone: '',
    verifiedDisabled: null,
  },
  carriers: [],
  comments: [],
  contacts: [],
  numberOfCarriers: 0,
};

export const FactoringCompanyStatuses = [
  'Active',
  'Inactive',
  'Pending',
  'Out of Business',
];

export const FactoringCompanyBillingTerms = [
  'Due Upon Receipt',
  'Net 30',
  'Net 45',
  'Net 60',
];
