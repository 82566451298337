export default {
  headerContainer:
    '!shadow-lg h-[100%] !h-fit sm:!h-fit  px-4 py-6 z-10 md:!h-fit lg:h-24',
  orderSubText: 'divide-x-[1px] divide-black',
  headerBtn: '!bg-[#E0E0E0] !text-black',
  customerNameText:
    'flex items-center gap-2 justify-center md:justify-start lg:justify-start xl:justify-start 2xl:justify-start',
  customerActionBtns:
    'flex items-center gap-3 justify-center flex-wrap  md:justify-end lg:justify-end xl:justify-end 2xl:justify-end',
  blacklistCommentTitle: '!text-[#1B4960] !text-base !font-medium',
};
