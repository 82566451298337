import { announcementsMessages } from '@common/commonLists';
import { Button, CardPlaceholder, Typography } from '@components';
import { SelectField, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import {
  addAnnouncement,
  announcementMarkAsRead,
  fetchAllAnnouncement,
} from '@store/Global/service';
import { AnnouncementMessagesType } from '@store/Global/types';
import { Grid, Box, Divider } from '@suid/material';
import { createSignal, For, onMount, Show } from 'solid-js';
import PersonIcon from '@suid/icons-material/Person';
import { DateTime } from 'luxon';
import {
  AnnouncementIcon,
  ImportantIcon,
  FixIcon,
  AlertIcon,
  NewFeatureIcon,
} from '@assets';
import { size } from 'lodash';
import { isAdmin, isManager } from '@utils/utils';

const Announcement = () => {
  const [announcementData, setAnnouncementData] = createSignal<
    AnnouncementMessagesType[]
  >([]);
  const [isLoading, setIsLoading] = createSignal(false);

  const { form, setFields, data } = createForm({
    initialValues: {
      type: 'Announcement',
      message: '',
    },
    onSubmit: async (values) => {
      await addAnnouncement(values);
      await getAllAnnouncementData();
    },
  });

  const remainingCount = () => {
    return 500 - size(data().message);
  };

  const getAnnouncementsBackgroundColor = (type: string) => {
    const colorMap: { [key: string]: string } = {
      Announcement: '#E8F0F6',
      Alert: '#FFD8DF',
      Important: '#FFD8DF',
      Fix: '#BFDFD8',
      'New Feature': '#BFDFD8',
      Warning: '#FFE3BC',
      Reminder: '#D9EDF7',
    };

    return colorMap[type];
  };

  const getAnnouncementsIcon = (type: string) => {
    const colorMap: { [key: string]: string } = {
      Announcement: AnnouncementIcon,
      Alert: AlertIcon,
      Important: ImportantIcon,
      Fix: FixIcon,
      'New Feature': NewFeatureIcon,
      Warning: AnnouncementIcon,
      Reminder: AnnouncementIcon,
    };

    return colorMap[type] || '#E1ECF2';
  };

  const getAnnouncementsCreatedByHeadingColor = (type: string) => {
    const colorMap: { [key: string]: string } = {
      Announcement: '#026EA1',
      Alert: '#B00020',
      Important: '#B00020',
      Fix: '#1C6857',
      Warning: '#7B4F13',
      Reminder: '#026EA1',
      'New Feature': '#1C6857',
    };

    return colorMap[type] || '#E1ECF2';
  };

  const Announcement = (props: { data: AnnouncementMessagesType }) => {
    return (
      <Box
        class="mb-4 rounded p-3 shadow-xl"
        sx={{
          backgroundColor: getAnnouncementsBackgroundColor(
            props.data.messageType,
          ),
        }}
      >
        <Box class="flex justify-between">
          <Box>
            <Typography
              variant="h6"
              class={`!text-[${getAnnouncementsCreatedByHeadingColor(
                props.data.messageType,
              )}] !text-base !font-medium`}
            >
              {props.data.createdBy}
            </Typography>
          </Box>
          <Box class="flex gap-2">
            <Typography
              variant="h6"
              class={`!text-[${getAnnouncementsCreatedByHeadingColor(
                props.data.messageType,
              )}] !text-sm !font-medium !contents`}
            >
              {DateTime.fromISO(props.data.createdDate, {
                zone: 'local',
              })
                .setZone('local')
                .toRelative()}
            </Typography>

            <img src={getAnnouncementsIcon(props.data.messageType)} alt="" />
          </Box>
        </Box>
        <Divider class="!mt-2" />
        <Box class="flex gap-5 mt-4">
          {props.data.thumbnail ? (
            <img
              src={props.data.thumbnail}
              alt=""
              class="!h-24 !w-24 rounded-xl border"
            />
          ) : (
            <Box class="!h-24 !w-24 rounded-xl border bg-[#EDEDED] flex justify-center items-center">
              <PersonIcon class="!text-5xl !text-[#026EA1]" />
            </Box>
          )}

          <Box class="w-full">
            <Typography
              variant="h6"
              class="!text-[13px] font-normal !text-[#808080]"
            >
              {props.data.message}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const getAllAnnouncementData = async () => {
    try {
      setIsLoading(true);
      const announcementDataList = await fetchAllAnnouncement();
      if (announcementDataList) {
        setAnnouncementData(announcementDataList);
        const unReadAnnouncements = announcementDataList.filter(
          (data) => !data.read,
        );
        if (size(unReadAnnouncements) > 0) {
          const payload = unReadAnnouncements.map((data) => data.id);
          await announcementMarkAsRead(payload);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  onMount(async () => {
    await getAllAnnouncementData();
  });

  return (
    <form ref={form} class="w-[990px] max-w-full h-[600px] overflow-auto">
      <Show when={!isLoading()} fallback={<CardPlaceholder />}>
        <Grid container gap={2}>
          {(isAdmin() || isManager()) && (
            <Grid
              item
              container
              lg={4}
              sm={12}
              flexDirection="column"
              gap={4}
              class="p-4 !bg-white"
            >
              <Grid item>
                <SelectField
                  label="Message Type"
                  menuItems={announcementsMessages}
                  value={data().type}
                  onChange={(e) => {
                    setFields('type', e.target.name);
                  }}
                  size="medium"
                />
              </Grid>
              <Grid item>
                <TextInput
                  label="Message"
                  maxLength={2000}
                  value={data().message}
                  onChange={(e) => {
                    setFields('message', e as string);
                  }}
                  multiline
                  placeholder="Write a short message to send the Company"
                  rows={10}
                  error={remainingCount() <= 0 ? 'Message is too long' : null}
                />
                {remainingCount() >= 1 && (
                  <span class="text-xs font-normal">{`${remainingCount()} of 500 characters remaining`}</span>
                )}
              </Grid>

              <Grid item>
                <Button
                  type="submit"
                  label={'Announce'}
                  class="!w-full"
                  disabled={remainingCount() <= 0 || !data().message}
                />
              </Grid>
            </Grid>
          )}
          <Grid item lg={isAdmin() || isManager() ? 7.8 : 12} sm={12}>
            <Box class="h-[580px] overflow-y-scroll p-4 bg-[#FFFFFF]">
              <For each={announcementData()}>
                {(data) => <Announcement data={data} />}
              </For>
            </Box>
          </Grid>
        </Grid>
      </Show>
    </form>
  );
};

export default Announcement;
