import { orderStore } from '@store/orders';
import * as yup from 'yup';

import {
  refundAdjustmentError,
  retractionAdjustmentError,
} from './helperFunctions';

export const adjustmentsSchema = yup.object().shape({
  operationType: yup.string().nullable(),
  id: yup.number().nullable(),
  orderId: yup.number().nullable(),
  amount: yup
    .number()
    .nullable()
    .when('retraction', (retraction, schema) => {
      return schema
        .test({
          name: 'retraction',
          test: function (amount) {
            const { createError } = this;
            const value = retraction[0] !== null ? Number(retraction[0]) : null;
            if (
              value === null ||
              amount === null ||
              amount === undefined ||
              value === 4
            ) {
              return true;
            }
            if (amount > 0 && (value === 1 || value === 2)) {
              return createError({
                message: 'Retractions must be negative',
              });
            }
            if (amount < 0 && (value === 3 || value === 5)) {
              return createError({
                message: 'Refunds must be positive',
              });
            }
            return true;
          },
        })
        .meta({ section: 'customerInformation' });
    }),
  description: yup
    .string()
    .required('Description is required')
    .meta({ section: 'customerInformation' }),
  settledDate: yup.string().nullable(),
  retraction: yup
    .number()
    .nullable()
    .test({
      name: 'retraction',
      test: function () {
        const { createError } = this;
        const adjustments =
          orderStore.order.adjustments.length > 0
            ? [
                ...orderStore.order.adjustments.filter(
                  (x) => x.operationType !== 'Delete',
                ),
              ]
            : [];

        if (adjustments.length === 0) return true;
        const retraction90Count = adjustments.filter(
          (x) => x.retraction === 1,
        ).length;
        const refund90Count = adjustments.filter(
          (x) => x.retraction === 3,
        ).length;
        const retraction120Count = adjustments.filter(
          (x) => x.retraction === 2,
        ).length;
        const refund120Count = adjustments.filter(
          (x) => x.retraction === 5,
        ).length;

        const adjustmentErrors = [
          {
            condition: retractionAdjustmentError(
              retraction90Count,
              refund90Count,
            ),
            message:
              '90 Days retraction can not be performed as action already exists and no corresponding refund is created for it.',
          },
          {
            condition: refundAdjustmentError(retraction90Count, refund90Count),
            message:
              '90 Days refund can not be performed as corresponding retraction is not present.',
          },
          {
            condition: retractionAdjustmentError(
              retraction120Count,
              refund120Count,
            ),
            message:
              '120 Days retraction can not be performed as action already exists and no corresponding refund is created for it.',
          },
          {
            condition: refundAdjustmentError(
              retraction120Count,
              refund120Count,
            ),
            message:
              '120 Days refund can not be performed as corresponding retraction is not present.',
          },
        ];

        for (const error of adjustmentErrors) {
          if (error.condition) {
            return createError({ message: error.message });
          }
        }
        return true;
      },
    })
    .meta({ section: 'customerInformation' }),
  order: yup.object().nullable(),
});
