import { createStore, SetStoreFunction } from 'solid-js/store';
import { HubConnection } from '@microsoft/signalr';

interface GlobalState {
  count: number;
  message: string;
  socketv: HubConnection | undefined;
}

type GlobalStoreType = [GlobalState, SetStoreFunction<GlobalState>];

// Create a global store
const [globalState, setGlobalState]: GlobalStoreType = createStore<GlobalState>(
  {
    count: 0,
    message: 'Hello, Solid!',
    socketv: undefined,
  },
);

// Functions to update global state
export const incrementCount = () => {
  setGlobalState((prev) => ({ count: prev.count + 1 }));
};

export const setSocketConnection = (socketv: HubConnection) => {
  setGlobalState((prev) => {
    return {
      ...prev,
      socketv,
    };
  });
};

export const updateMessage = (newMessage: string) => {
  setGlobalState(() => ({ message: newMessage }));
};

export { globalState };
