import { CardPlaceholder, Typography } from '@components';
import {
  fetchAllNotification,
  notificationMarkAsRead,
} from '@store/Global/service';
import { NotificationTypes } from '@store/Global/types';
import { Box, Divider } from '@suid/material';
import { createSignal, For, onMount, Setter, Show } from 'solid-js';
import { NotificationIcon } from '@assets';
import { DateTime } from 'luxon';
import { isEmpty, size } from 'lodash';
import { useNavigate } from '@solidjs/router';
import { SidebarMenuLabelTypes } from '@components/Sidebar/Sidebar';

const Alerts = (props: { setChecked: Setter<SidebarMenuLabelTypes> }) => {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = createSignal<
    NotificationTypes[]
  >([]);
  const [isLoading, setIsLoading] = createSignal(false);

  const NotificationAlerts = (props: { data: NotificationTypes }) => {
    return (
      <Box
        class="mb-3 rounded-md p-3 shadow-xl odd:bg-[#E8F0F6]"
        onClick={() => handleClick(props.data)}
      >
        <Box>
          <Typography variant="h6" class={'!text-base !font-medium'}>
            {props.data.createdBy}
          </Typography>
          <Divider class="!mt-2" />
        </Box>
        <Box class="flex gap-5 mt-4">
          <img src={NotificationIcon} alt="" class="" />
          <Box class="w-full">
            <Typography
              variant="h6"
              class="!text-[13px] font-normal !text-[#808080]"
            >
              {props.data.message}
            </Typography>
            <Typography
              variant="h6"
              class="!text-[13px] !font-bold !text-[#808080]"
            >
              {DateTime.fromISO(props.data.createdDate).toFormat(
                'LLL dd, yyyy h:mm a',
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const handleClick = (update: NotificationTypes) => {
    if (!isEmpty(update.context) && update.context !== 'Load') {
      navigate('/' + update.context + '/Details/' + update.objectId);
      props.setChecked('');
    }
  };

  onMount(async () => {
    setIsLoading(true);
    const notificationDataList = await fetchAllNotification();
    if (notificationDataList) {
      setNotificationData(notificationDataList);
      const unReadNotification = notificationDataList.filter(
        (data) => !data.read,
      );
      if (size(unReadNotification) > 0) {
        const payload = unReadNotification.map((data) => data.id);
        await notificationMarkAsRead(payload);
      }
    }
    setIsLoading(false);
  });
  return (
    <Box class="w-[650px] max-w-full h-[600px] overflow-auto">
      <Show when={!isLoading()} fallback={<CardPlaceholder />}>
        <Box class="h-[580px] overflow-y-auto p-4 m-2 bg-[#FFFFFF]">
          <For each={notificationData()}>
            {(data) => <NotificationAlerts data={data} />}
          </For>
        </Box>
      </Show>
    </Box>
  );
};

export default Alerts;
