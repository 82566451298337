import { Typography } from '@components';
import { Box, Grid, Divider } from '@suid/material';

import cls from './classes';

export const LineItemsLockedModel = () => {
  return (
    <Box>
      <Grid container spacing={2} px={1} pb={2} alignItems="center">
        <Grid item xs={12} textAlign="center">
          <Typography class={cls.postLoadText} variant="h6" component="h2">
            Why Can’t I Edit My Line Items?
          </Typography>
        </Grid>
      </Grid>
      <Divider class={cls.divider} />
      <Grid container rowSpacing={2}>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <span class={cls.lockedText}>
            This is not a glitch. Your Line Items have been locked because one
            or more of your Loads have been Processed and written to our
            accounting books. At this time, change to existing line items can no
            longer be made.
          </span>
        </Grid>
        <Grid item xs={12}>
          <span class={cls.lockedText}>
            You can add new Line Items to show any adjustment to the rate, but
            you cannot change existing Line Items.
          </span>
        </Grid>
      </Grid>
    </Box>
  );
};
