import { NewAutoComplete } from '@components/Autocomplete';
import { createSignal, Show } from 'solid-js';
import { SelectChangeEvent } from '@suid/material/Select';
import { Grid } from '@suid/material';
import { useNavigate } from '@solidjs/router';
import { TextInput } from '@components/forms';
import { ValidationError } from 'yup';
import { findOrder, findTracking } from '@store/Global/service';
import { routeOrderDetails } from '@utils/routeOrderDetails';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components/Toast';

import { SelectSearchCategory } from './components/SelectSearchCategory';
import { autoCompleteMap } from './constants';
import { AutoCompleteItem } from './types';
import { loadSchema } from './validation';

export const SearchField = () => {
  const [selectedCategory, setSelectedCategory] = createSignal<string>('Load');
  const [text, setText] = createSignal<number | undefined>();
  const [clearSearch, setClearSearch] = createSignal<boolean>(false);

  const navigate = useNavigate();

  const handleChange = (e: SelectChangeEvent<string>) => {
    setSelectedCategory(e.target.value);
    setText(undefined);
    setClearSearch(true);
  };

  const handleKeyPress = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;

    const currentLoadText = text();

    if (currentLoadText === undefined) {
      return;
    }
    const navigateToOrderDetails = (orderId: number) => {
      const isNative = routeOrderDetails(orderId);
      if (isNative) {
        navigate(`/Order/Details/${orderId}`);
      }
    };

    try {
      const category = selectedCategory();
      if (category === 'Tracking#') {
        const data = await findTracking(currentLoadText);
        if (data) {
          navigateToOrderDetails(data.orderId);
        }
      } else {
        loadSchema.validateSync(currentLoadText, { abortEarly: false });
        const data = await findOrder(currentLoadText);
        if (data) {
          navigateToOrderDetails(currentLoadText);
        }
      }
    } catch (validationError) {
      const errors: string[] = [];
      if (validationError instanceof ValidationError) {
        errors.push(...validationError.errors);
      }
      handleToast(ToastType.Error, errors.join(', '));
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={4}>
        <SelectSearchCategory
          category={selectedCategory}
          setCategory={setSelectedCategory}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={8}>
        <Show
          when={
            selectedCategory() === 'Load' || selectedCategory() === 'Tracking#'
          }
        >
          <TextInput
            label=""
            sxProps={{
              background: 'white !important',
              borderRadius: '5px',
              marginLeft: '2px',
              '& .MuiInputBase-input': {
                padding: '10px 14px !important',
              },
            }}
            value={text() !== undefined ? text() : ''}
            onChange={(e: unknown) => setText(e as number)}
            onKeyPress={(e) => handleKeyPress(e)}
          />
        </Show>
        <Show
          when={
            selectedCategory() !== 'Load' && selectedCategory() !== 'Tracking#'
          }
        >
          <NewAutoComplete
            label=""
            version="v3"
            endpoint={autoCompleteMap[selectedCategory()].endpoint}
            id={autoCompleteMap[selectedCategory()].id}
            clearSearch={clearSearch()}
            onChange={() => clearSearch() && setClearSearch(false)}
            renderItem={(item) => {
              const category = selectedCategory();
              return autoCompleteMap[category].display(
                item as AutoCompleteItem<typeof category>,
              );
            }}
            phoneInput={selectedCategory() === 'Phone #'}
            minCharacters={autoCompleteMap[selectedCategory()].minCharacters}
            onItemSelect={(item) => {
              const category = selectedCategory();
              const redirectLink = autoCompleteMap[category].redirect(
                item as AutoCompleteItem<typeof category>,
              );
              if (redirectLink !== '') {
                navigate(redirectLink);
              }
            }}
            class="!bg-white rounded-[5px]"
          />
        </Show>
      </Grid>
    </Grid>
  );
};
