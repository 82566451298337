import { isEmpty } from 'lodash';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email/Username is required'),

  firstName: Yup.string()
    .min(1, 'First name is required')
    .max(50, 'First name is too long')
    .required('First name is required'),

  lastName: Yup.string()
    .min(1, 'Last name is required')
    .max(50, 'Last name is too long')
    .required('Last name is required'),

  phoneNumber: Yup.string().when((value) => {
    return isEmpty(value[0])
      ? Yup.string().nullable().notRequired()
      : Yup.string().min(10, 'Phone number must be at least 10 digits');
  }),

  phone1Ext: Yup.string()
    .matches(/^\d*$/, 'Extension must be a number')
    .optional(),

  mobileNumber: Yup.string().when((value) => {
    return isEmpty(value[0])
      ? Yup.string().nullable().notRequired()
      : Yup.string().min(10, 'Phone number must be at least 10 digits');
  }),

  phone2Ext: Yup.string()
    .matches(/^\d*$/, 'Extension must be a number')
    .optional(),

  fax: Yup.string().when((value) => {
    return isEmpty(value[0])
      ? Yup.string().nullable().notRequired()
      : Yup.string().min(10, 'Phone number must be at least 10 digits');
  }),

  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .required('Password is required'),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),

  userRoles: Yup.string(),

  hideDashboard: Yup.boolean(),
  requestEFSChecks: Yup.boolean(),
  viewSettlements: Yup.boolean(),
  editPermissions: Yup.boolean(),
  editCarrierContacts: Yup.boolean(),
});

export default validationSchema;
