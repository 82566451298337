export default {
  paymentHistory: '!h-[100%]',
  paymentHistoryInstructions: 'border-2 border-[#B00020] rounded-md',
  closeIcon: 'cursor-pointer !text-[#B00020] absolute right-4 top-3',
  paymentHistoryInstructionsParagraph: '!text-[#B00020] !text-base',
  paymentHistoryInstructionsList:
    '!ml-2 !list-disc	!list-inside !p-0	!text-[#B00020]',
  paymentHistoryInstructionsListItem: '!text-base',
  paymentHistoryInstructionsListItemText: '!list-item',
};
