import { Settings } from '@suid/icons-material';
import { For, JSXElement } from 'solid-js';

import MainMenuItem from './MainMenuItem';

export type NavLink = {
  id: string;
  label: string;
  path: string;
  children?: Array<{
    path: string;
    label: string;
    icon?: JSXElement | undefined;
    seperator?: boolean;
  }>;
};

export default function NavBar() {
  const navlinks: Array<NavLink> = [
    {
      id: 'dashboard',
      label: 'Reports',
      path: '',
      children: [
        {
          path: '/',
          label: 'Positing',
          icon: <Settings fontSize="small" />,
          seperator: true,
        },
        {
          path: '/claims',
          label: 'Claims',
        },
      ],
    },
    {
      id: 'loads',
      label: 'Loads',
      path: '',
      children: [
        {
          path: '/loadboard',
          label: 'Loadboard',
        },
        {
          path: '/loads',
          label: 'Load Search',
        },
        // ...(userHasRole('Capacity')
        //   ? []
        //   : [
        //       {
        //         path: '/load/tenders',
        //         label: 'EDI Load Tenders',
        //       },
        //     ]),
        {
          path: '/load/holds',
          label: 'Holds',
        },
      ],
    },
    {
      id: 'carriers',
      label: 'Carriers',
      path: '',
      children: [
        {
          path: '/carriers',
          label: 'Carriers',
        },
      ],
    },
    {
      id: 'customers',
      label: 'Customers',
      path: '',
      children: [
        {
          path: '/customers',
          label: 'Customers',
        },
        {
          path: '/customers',
          label: 'Prospects',
        },
        {
          path: '/customer/dormant-accounts',
          label: 'My Dormants Acounts',
        },
      ],
    },
    {
      id: 'admin',
      label: 'Admin',
      path: '/admin',
    },
    {
      id: 'accounting',
      label: 'Accounting',
      path: '/accounting',
    },
  ];
  return (
    <For each={navlinks}>{(navlink) => <MainMenuItem navlink={navlink} />}</For>
  );
}
