import { Box } from '@suid/material';
import { JSX } from 'solid-js';

type PropsT = {
  label: JSX.Element | string;
  startIcon: JSX.Element;
  endIcon?: JSX.Element;
};

export const FieldDisplay = (props: PropsT) => {
  return (
    <Box class="flex flex-row item-center text-armstrong-sm gap-[3px] text-armstrong-blue font-medium">
      <Box>{props.startIcon}</Box>
      <Box>{props.label}</Box>
      <Box>{props.endIcon}</Box>
    </Box>
  );
};
