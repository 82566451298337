import { Card, CardPlaceholder } from '@components';
import { Show } from 'solid-js';
import { orderStore } from '@store/orders';

export const CarrierLoadingCards = () => {
  return (
    <>
      <Card startTitle="Load">
        <Show when={!orderStore.loading} fallback={<CardPlaceholder />}>
          ''
        </Show>
      </Card>
      <Card startTitle="Carrier Billing Info">
        <Show when={!orderStore.loading} fallback={<CardPlaceholder />}>
          ''
        </Show>
      </Card>
      <Card startTitle="Stops">
        <Show when={!orderStore.loading} fallback={<CardPlaceholder />}>
          ''
        </Show>
      </Card>
      <Card startTitle="Tracking">
        <Show when={!orderStore.loading} fallback={<CardPlaceholder />}>
          ''
        </Show>
      </Card>
    </>
  );
};
