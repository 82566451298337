export function addressFormatter(
  address1: string | undefined | null,
  city: string | undefined | null,
  stateOrProvince: string | undefined | null,
  zip: string | undefined | null,
) {
  let address: string = '';
  if (address1 == undefined) {
    return '';
  }
  address = address1 + ' ' + city + ', ' + stateOrProvince + ' ' + zip;

  return address;
}

export const calculateMargin = (revenue: number, cost: number) => {
  if (revenue > 0) return parseFloat((100 - (cost / revenue) * 100).toFixed(2));
  return 0;
};

export const getRoundedRate = (rate: number, quantity: number) => {
  const lineItemTotal = rate * quantity;
  const decimalIndex = lineItemTotal.toString().indexOf('.');

  if (decimalIndex === -1) {
    return lineItemTotal;
  }

  const rateDecimals = countDecimals(rate);
  const quantityDecimals = countDecimals(quantity);
  const actualTotal = +lineItemTotal.toPrecision(
    decimalIndex + rateDecimals + quantityDecimals,
  );
  return roundDecimalValue(actualTotal, 2);
};

export const countDecimals = (value: number) => {
  if (Math.floor(value) == value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export const roundDecimalValue = (number: number, decimalPlaces: number) => {
  let lineItemValue = number;
  for (let i = decimalPlaces; i > 1; i--) {
    lineItemValue = fixedTo(lineItemValue, i);
  }
  return lineItemValue;
};

export const fixedTo = (lineItemValue: number, decimalPlaces: number) => {
  const roundingPlace = Math.pow(10, decimalPlaces);
  return Math.round(lineItemValue * roundingPlace) / roundingPlace;
};
