import { ErrorDto } from '@store/orders/v3Types';

export type DocumentUploadModel = {
  entityId: number;
  documentEntityType: DocumentEntityType;
  file: FileList;
};

export enum DocumentEntityType {
  'Global' = 1,
  'Load' = 2,
}

export type DocumentUploadResponse = {
  uploadErrors: ErrorDto[];
  results: DocumentModel[];
};

export type DocumentModel = {
  url: string;
  id?: number;
  name: string;
};
