import { SxProps } from '@suid/system';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { Component, JSXElement, mergeProps } from 'solid-js';

import { TextInput } from './TextInput';

type PhoneInputProps = {
  name?: string;
  label: string | JSXElement;
  error?: string | string[] | null;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  autoComplete?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  size?: 'small' | 'medium';
  onBlur?: (e: FocusEvent) => void;
  onChange?: (val: string) => void;
  sxProps?: SxProps;
  classes?: string;
  value?: string;
  InputAdornmentPosition?: 'start' | 'end';
  InputAdornmentText?: string;
  startAdornmentFlag?: boolean;
};

export const PhoneInput: Component<PhoneInputProps> = (props) => {
  const defaultProps: Partial<PhoneInputProps> = {
    error: null,
    placeholder: '',
    disabled: false,
    required: false,
    helperText: '',
  };

  const handleInputChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const cursorPosition = target.selectionStart;
    const oldValue = target.value;
    const oldLength = oldValue.length;

    const newUnformattedValue = oldValue.replace(/[^0-9]/g, '');

    if (newUnformattedValue.length <= 10) {
      const newValue = formatPhoneNumber(newUnformattedValue);

      const newLength = newValue.length;

      props.onChange && props.onChange(newUnformattedValue);

      if (cursorPosition !== null) {
        const diff = newLength - oldLength;
        target.setSelectionRange(cursorPosition + diff, cursorPosition + diff);
      }
    }
  };

  props = mergeProps(defaultProps, props);

  const formattedValue = () => {
    if (props.value !== undefined) {
      return formatPhoneNumber(props.value);
    }
    return '';
  };

  return (
    <TextInput
      name={props.name}
      value={formattedValue()}
      onChange={(e: string | number | Event | null) =>
        handleInputChange(e as Event)
      }
      onBlur={props.onBlur}
      type="event"
      size={props.size}
      required={props.required}
      label={props.label}
      variant="outlined"
      placeholder={props.placeholder}
      disabled={props.disabled}
      error={
        props.error !== null &&
        props.error !== undefined &&
        props.error.length > 0
          ? props.error
          : ''
      }
      noErrorMessage={props.error === null}
      sxProps={props.sxProps}
      classes={props.classes}
    />
  );
};
