import { ToastType } from '@components';
import {
  orderStore,
  updateOrderFormErrorPerSectionState,
  updateOrderFormErrorState,
  updateOrderFormMappedErrorSection,
} from '@store/orders';
import { handleToast, printLog } from '@utils/utils';
import { orderFormSchema } from '@views/order/validations/orderSchema';
import { ValidationError } from 'yup';

export function validateOrder() {
  if (orderStore.order.loads.length === 0) {
    handleToast(ToastType.Error, 'Please add at least one Carrier');
    return;
  }
  try {
    orderFormSchema.validateSync(orderStore.order, { abortEarly: false });
    updateOrderFormErrorState({});
    updateOrderFormErrorPerSectionState({
      customerInformation: 0,
      loads: 0,
      instructionsAndCosts: 0,
    });

    const loads = orderStore.order.loads;
    if (
      orderStore.order.operationType === 'None' &&
      loads.every((load) => load.operationType === 'None') &&
      (orderStore.order.vendors?.every(
        (vendor) => vendor.operationType === 'None',
      ) ??
        true) &&
      orderStore.order.lineItems.every(
        (lineItem) => lineItem.operationType === 'None',
      ) &&
      loads.every(
        (load) =>
          load.lineItems?.every(
            (lineItem) => lineItem.operationType === 'None',
          ),
      ) &&
      loads.every(
        (load) => load.stops?.every((stop) => stop.operationType === 'None'),
      ) &&
      loads.every(
        (load) =>
          load.payments?.every((payment) => payment.operationType === 'None'),
      ) &&
      loads.every(
        (load) => load.items?.every((item) => item.operationType === 'None'),
      ) &&
      loads.every((load) => load.ltlShipmentData?.operationType === 'None')
    ) {
      handleToast(
        ToastType.Error,
        'No changes were detected. Please make changes to save the order.',
      );
      return false;
    }
    return true;
  } catch (validationError: unknown) {
    // eslint-disable-next-line no-console
    console.log('validate error encountered', validationError);
    const errors: Record<string, string[]> = {};
    const mappedErrorSection: Record<string, string> = {};

    if (validationError instanceof ValidationError) {
      const errorsPerSection = {
        customerInformation: 0,
        loads: 0,
        vendor: 0,
        instructionsAndCosts: 0,
        carrier: 0,
        carrierDriverDetails: 0,
      };

      validationError.inner.forEach((error: ValidationError) => {
        if (error.path !== undefined) {
          errors[error.path] = error.errors;

          // @ts-expect-error `meta` is present on `spec`
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const section = error.params?.spec?.meta?.section as unknown as
            | keyof typeof errorsPerSection
            | undefined;

          if (section !== undefined) {
            errorsPerSection[section] += 1;
            mappedErrorSection[error.path] = section;
          }
        }
      });

      printLog('validationError', errors);
      // it will store mapped error state for each section to track which section has how many errors
      // if error in particular section is corrected then it will remove that section from error state
      updateOrderFormMappedErrorSection(mappedErrorSection);
      updateOrderFormErrorState(errors);
      updateOrderFormErrorPerSectionState(errorsPerSection);
      return false;
    }
  }
}

export function flatValidationErrors(errors: Record<string, string[]>) {
  let newobj = {};

  function createObjectFromArray(
    inputArray: { path: (string | number)[]; value: string }[],
  ) {
    const obj: Record<string, unknown> = {};
    inputArray.forEach((item) => {
      let currentObj: Record<string, unknown> = obj;
      item.path.forEach((key, index, array) => {
        if (currentObj[key] === undefined) {
          if (index === array.length - 1) {
            currentObj[key] = item.value;
          } else {
            currentObj[key] = typeof array[index] === 'number' ? {} : [];
          }
        }
        currentObj = currentObj[key] as Record<string, unknown>;
      });
    });

    return obj;
  }

  function transformObject(obj: Record<string, unknown>) {
    let dummyObj: { path: (string | number)[]; value: string }[] = [];
    for (const k in obj) {
      const path = [];
      let value = '';
      const keys = k.split('.');
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].includes('[')) {
          const [arrayKey, index] = keys[i].split('[');
          const atIndex = parseInt(index.slice(0, -1));
          path.push(arrayKey, atIndex);
        } else {
          path.push(keys[i]);
          value = obj[k];
        }
      }
      dummyObj = [
        ...dummyObj,
        {
          path,
          value,
        },
      ];
    }
    newobj = createObjectFromArray(dummyObj);
    return newobj;
  }
  return transformObject(errors);
}

// /*eslint-disable*/
// export const flattenValidationErrors = (errors: any): any => {
//   const flattenedErrors: any = {};

//   const flattenErrorsRecursive = (obj: any, currentPath = ""): void => {
//     Object.keys(obj).forEach((key) => {
//       const path = currentPath ? `${currentPath}.${key}` : key;
//       const value = obj[key];
//       if (Array.isArray(value)) {
//         if (value[0] && typeof value[0] === "object") {
//           value.forEach((item, index) =>{
//               printLog("item", item);
//            return flattenErrorsRecursive(item, `${path}[${index}]`);
//           }
//           );
//         } else {
//           flattenedErrors[path] = [value.join(", ")];
//         }
//       } else if (value && typeof value === "object") {
//         flattenErrorsRecursive(value, path);
//       } else {
//         flattenedErrors[path] = [value];
//       }
//     });
//   };

//   flattenErrorsRecursive(errors);
//   return transformArrayErrors(flattenedErrors);
// };

// export const transformArrayErrors = (errors: ErrorObject): ErrorObject => {
//   const transformedErrors: ErrorObject = {};

//   Object.entries(errors).forEach(([path, error]) => {
//     const parts = path.split(/\.|\[|\]\.?/).filter(Boolean);
//     let currentPart: ErrorObject | ErrorObject[] = transformedErrors;

//     for (let i = 0; i < parts.length; i++) {
//       const part = parts[i];
//       const isLastPart = i === parts.length - 1;
//       const isArrayIndex = /^\d+$/.test(parts[i + 1]);

//       if (isArrayIndex && !isLastPart) {
//         if (!currentPart[part]) {
//           currentPart[part] = [];
//         }
//         if (!((currentPart[part] as ErrorObject[])[parseInt(parts[i + 1], 10)])) {
//           (currentPart[part] as ErrorObject[])[parseInt(parts[i + 1], 10)] = {};
//         }
//         currentPart = (currentPart[part] as ErrorObject[])[parseInt(parts[i + 1], 10)];
//         i++;
//       } else if (isArrayIndex && isLastPart) {
//         if (!currentPart[part]) {
//           currentPart[part] = [];
//         }
//         (currentPart[part] as string[]).push(error as string);
//       } else if (!isArrayIndex && isLastPart) {
//         currentPart[part] = error;
//       } else {
//         if (!currentPart[part]) {
//           currentPart[part] = {};
//         }
//         currentPart = currentPart[part] as ErrorObject;
//       }
//     }
//   });

//   return transformedErrors;
// };

// export const transformArrayErrors = (errors: any) => {
//   const transformedErrors: any = {};

//   Object.entries(errors).forEach(([path, error]) => {
//     const arrayMatch = path.match(/(\w+)\[(\d+)\]/);
//     const fieldMatch = path.match(/\.(\w+)$/);

//     if (arrayMatch) {
//       const arrayName = arrayMatch[1];
//       const index = arrayMatch[2];
//       const fieldName = fieldMatch ? fieldMatch[1] : "";

//       if (!transformedErrors[arrayName]) {
//         transformedErrors[arrayName] = [];
//       }

//       if (fieldName) {
//         if (!transformedErrors[arrayName][index]) {
//           transformedErrors[arrayName][index] = {};
//         }
//         transformedErrors[arrayName][index][fieldName] = error;
//       } else {
//         transformedErrors[arrayName][index] = error;
//       }
//     } else {
//       transformedErrors[path] = error;
//     }
//   });

//   return transformedErrors;
// };
