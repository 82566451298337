export enum userRoles {
  MANAGER = 'Manager',
  ADMIN = 'Admin',
  RECRUITER = 'Recruiter',
  CARRIER_RELATIONS = 'Carrier Relations',
  ACCOUNTING = 'Accounting',
  SUPERVISOR = 'Supervisor',
  COLLECTIONS = 'Collections',
  SUPER_ADMIN = 'Super Admin',
  ADMIN_LITE = 'Admin-Lite',
  CAPACITY = 'Capacity',
}
