import { TextInput } from '@components/forms';

function DisabledTextField(props: { value: string }) {
  return (
    <TextInput
      value={props.value}
      disabled
      classes={'bg-slate-100 rounded-md'}
      variant="outlined"
      label=""
    />
  );
}

export default DisabledTextField;
