//  Component Usage
//  By default, the tooltip will be displayed at the top of the element.
// required props: text, children
import { JSXElement, mergeProps } from 'solid-js';

import cls from './classes';

interface TooltipProps {
  text: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children: JSXElement;
  textWidth?: string;
  tootlTipClasses?: string;
}

const Tooltip = (props: TooltipProps) => {
  const mp = mergeProps(
    {
      placement: 'top',
      textWidth: 'max-content',
      tootlTipClasses: 'text-base',
    },
    props,
  );

  const getPlacement = () => {
    switch (mp.placement) {
      case 'bottom':
        return 'top-[105%] !left-2/4 transform -translate-x-1/2 after:top-[-2%] after:left-1/2 after:-translate-x-1/2';
      case 'top':
        return 'bottom-[105%] left-2/4 transform -translate-x-1/2 after:bottom-[-2%] after:left-1/2 after:-translate-x-1/2';
      case 'right':
        return 'left-[105%] top-2/4 transform -translate-y-1/2 after:left-[-2px] after:top-1/2 after:-translate-y-1/2';
      case 'left':
        return 'right-[105%] top-2/4 transform -translate-y-1/2 after:right-[-2px] after:top-1/2 after:-translate-y-1/2';
      default:
        return 'bottom-[105%] left-2/4 transform -translate-x-1/2 after:top-[-2%] after:left-1/2 after:-translate-x-1/2';
    }
  };

  return (
    <div class={cls.tooltipContainer}>
      {mp.children}
      <span
        class={`${cls.tooltip}
          ${getPlacement()} ${mp.tootlTipClasses}`}
        style={{
          width: mp.textWidth || 'max-content',
        }}
      >
        {mp.text}
      </span>
    </div>
  );
};

export default Tooltip;
