/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  AddressCard,
  ContactDropdown,
  Popover,
  ReassignContactCard,
} from '@components';
import {
  ICustomerContact,
  UserInfoModel,
  customerStore,
  fetchUserInfo,
  fetchUserInfoUserModel,
  orderStore,
  updateOrderState,
} from '@store/orders';
import { CustomerContactViewModel } from '@store/orders/v3Types';
import { AccountCircle, Email, Phone } from '@suid/icons-material';
import { Box, Grid, Stack } from '@suid/material';
import { formatContactPhone } from '@utils/phoneNumberFormat';
import { canReassign, formatAmount, printLog } from '@utils/utils';
import { get, isEmpty } from 'lodash';
import {
  Accessor,
  Component,
  Setter,
  Show,
  createSignal,
  onMount,
} from 'solid-js';

import ReassignCard from '../reassign/ReassignCard';
import classes from './classes';

export type CustomerContactInformationDetailsProps = {
  orderRepInformation: Accessor<UserInfoModel | undefined>;
  setOrderRepInformation: Setter<UserInfoModel | undefined>;
};

const CustomerContactInformationDetails: Component<
  CustomerContactInformationDetailsProps
> = (props: CustomerContactInformationDetailsProps) => {
  const [isLoading, setIsLoading] = createSignal(false);
  const [accountManagerInformation, setAccountManagerInformation] =
    createSignal<CustomerContactViewModel>();
  const [salesManagerInformation, setSalesManagerInformation] =
    createSignal<CustomerContactViewModel>();

  const cls = classes.customerInfo;

  const isCustomerPresent = () => orderStore.order.customerId !== null;

  const getContact = () => {
    return [
      ...customerStore.customer.billingContacts,
      ...customerStore.customer.freightContacts,
    ].find((item) => item.id === Number(orderStore.order.customerContactId));
  };

  const getCustomerInformation = async (id: number, type: string) => {
    if (!isCustomerPresent()) return;

    try {
      setIsLoading(true);

      let response;
      switch (type) {
        case 'SALE':
          if (isEmpty(salesManagerInformation())) {
            response = await fetchUserInfo(id);
            setSalesManagerInformation(response);
          }
          break;

        case 'ACCOUNT':
          if (isEmpty(accountManagerInformation())) {
            response = await fetchUserInfo(id);
            setAccountManagerInformation(response);
          }
          break;

        case 'ORDER REP':
          response = await fetchUserInfoUserModel(id);
          props.setOrderRepInformation(response);
          updateOrderState({
            owner: response.name,
            assignedId: response.id,
          });

          break;

        default:
          break;
      }
    } catch (err) {
      printLog(err, 'ERROR');
    } finally {
      setIsLoading(false);
    }
  };

  const emailLink = () =>
    [
      ...customerStore.customer.billingContacts,
      ...customerStore.customer.freightContacts,
    ].find((item) => item.id === Number(orderStore.order.customerContactId))
      ?.email;

  const iconStyle = () =>
    !isCustomerPresent() ? cls.disabledPhoneIconStyle : cls.phoneIconStyle;

  const getCustomerContactId = () => {
    const { customerContactId } = orderStore.order;
    const tempContact: ICustomerContact[] = [
      ...customerStore.customer.freightContacts,
      ...customerStore.customer.billingContacts,
    ];

    updateOrderState({
      customerContactId:
        Number(customerContactId) ||
        (tempContact.length > 0 ? tempContact[0].id : null),
    });
  };

  onMount(() => getCustomerContactId());

  return (
    <>
      <Box>
        <ContactDropdown
          contacts={[
            ...customerStore.customer.freightContacts,
            ...customerStore.customer.billingContacts,
          ]}
          onChange={(item) => {
            updateOrderState({ customerContactId: item });
          }}
          contactId={Number(orderStore.order.customerContactId)}
          disabled={!isCustomerPresent() || orderStore.isReadOnly}
          respectVerification={false}
          small
        />
      </Box>
      <Show when={!orderStore.isCreate}>
        <Box class={`${cls.rowContainer} text-[#026EA1]`}>
          <Box class="font-semibold text-sm">
            <Phone
              style={iconStyle()}
              // @ts-expect-error Adding a pixel size works
              fontSize="16px"
              sx={{ marginTop: '-1px' }}
            />{' '}
            {formatContactPhone(getContact(), 'display')}
          </Box>
          <Box class="font-semibold text-sm">
            <Email
              style={iconStyle()}
              // @ts-expect-error Adding a pixel size works
              fontSize="16px"
              sx={{ marginTop: '-1px' }}
            />{' '}
            <a href={`mailto:${emailLink() ?? '-'}`}>{emailLink() ?? '-'}</a>
          </Box>
        </Box>
      </Show>
      <Box></Box>
      <Box class={orderStore.isCreate ? 'mt-[16px]' : ''}>
        <Stack spacing={1}>
          <Grid container>
            <Grid item xs={3}>
              <Box class="!text-[#666666] inline-flex text-sm items-center">
                Credit
              </Box>
            </Grid>
            <Grid item xs class="flex items-center">
              <Box class="text-sm">
                {get(customerStore.customer, 'available') && isCustomerPresent()
                  ? formatAmount(get(customerStore.customer, 'available', 0))
                  : '-'}{' '}
                /{' '}
                {get(customerStore.customer, 'creditLimit') &&
                isCustomerPresent()
                  ? formatAmount(get(customerStore.customer, 'creditLimit', 0))
                  : '-'}
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            onClick={() =>
              getCustomerInformation(
                get(customerStore, 'customer.accountManagerId'),
                'ACCOUNT',
              )
            }
          >
            <Grid item xs={3}>
              <Box class="!text-[#666666] inline-flex text-sm items-center">
                Account Manager
              </Box>
            </Grid>
            <Grid item xs>
              <Popover
                eleName={
                  <Box class="inline-flex">
                    <AccountCircle style={iconStyle()} fontSize="small" />
                    <Show when={isCustomerPresent()}>
                      <Box class="ml-2 text-sm">
                        {get(customerStore.customer, 'accountManager', '')}
                      </Box>
                    </Show>
                  </Box>
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sxProps={{
                  textTransform: 'none',
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'none',
                  },
                  pointerEvents: isCustomerPresent() ? 'auto' : 'none',
                }}
              >
                <AddressCard
                  contact={accountManagerInformation()}
                  mode="regular"
                  showIcon
                  isLoading={isLoading()}
                />
              </Popover>
            </Grid>
          </Grid>
          <Grid
            container
            onClick={() =>
              getCustomerInformation(
                get(customerStore, 'customer.salesManagerId'),
                'SALE',
              )
            }
          >
            <Grid item xs={3}>
              <Box class="!text-[#666666] inline-flex text-sm items-center">
                Sales Manager
              </Box>
            </Grid>
            <Grid item xs>
              <Popover
                eleName={
                  <Box class="inline-flex">
                    <AccountCircle style={iconStyle()} fontSize="small" />
                    <Show when={isCustomerPresent()}>
                      <Box class="ml-2 text-sm">
                        {get(customerStore.customer, 'salesManager', '')}
                      </Box>
                    </Show>
                  </Box>
                }
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sxProps={{
                  textTransform: 'none',
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'none',
                  },
                  pointerEvents: isCustomerPresent() ? 'auto' : 'none',
                }}
              >
                <AddressCard
                  contact={salesManagerInformation()}
                  mode="regular"
                  showIcon
                  isLoading={isLoading()}
                />
              </Popover>
            </Grid>
          </Grid>
          <Grid
            container
            class="flex text-sm items-center"
            onClick={() => {
              void getCustomerInformation(
                get(orderStore, 'order.assignedId'),
                'ORDER REP',
              );
            }}
          >
            <Grid item xs={3}>
              <Box class="!text-[#666666] inline-flex">Order Rep</Box>
            </Grid>
            <Grid item xs class="inline-flex ml-auto items-center">
              <Popover
                eleName={
                  <Box class="inline-flex ml-auto">
                    <AccountCircle style={iconStyle()} fontSize="small" />
                    <Show when={isCustomerPresent()}>
                      <Box class="flex ml-2 text-small">
                        {get(orderStore.order, 'owner', '')}
                      </Box>
                    </Show>
                  </Box>
                }
                sxProps={{
                  textTransform: 'none',
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'none',
                  },
                  pointerEvents: isCustomerPresent() ? 'auto' : 'none',
                }}
              >
                <ReassignContactCard
                  contact={props.orderRepInformation()}
                  showIcon
                  isLoading={isLoading()}
                />
              </Popover>
              <Show
                when={
                  isCustomerPresent() && canReassign() && !orderStore.isReadOnly
                }
              >
                <Popover
                  eleName={
                    <div
                      onClick={() => {
                        void getCustomerInformation(
                          get(orderStore, 'order.assignedId'),
                          'ORDER REP',
                        );
                      }}
                      class="text-[#026EA1] text-sm"
                    >
                      REASSIGN
                    </div>
                  }
                >
                  <ReassignCard
                    type={'ORDER REP'}
                    contactInfo={props.orderRepInformation()}
                    isLoading={isLoading}
                    onButtonClick={getCustomerInformation}
                    disabled={orderStore.isReadOnly}
                  />
                </Popover>
              </Show>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
};

export default CustomerContactInformationDetails;
