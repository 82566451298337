export const mockContactData = {
  Id: 123,
  ObjectId: 456,
  ObjectType: 'ExampleType',
  Name: 'John Doe',
  Phone1: '123-456-7890',
  Extension1: '123',
  Phone2: '987-654-3210',
  Extension2: '456',
  Fax: '987-654-3210',
  Email: 'john.doe@example.com',
  Type: 'Billing',
  Status: 'Active',
  CreatedDate: '2022-01-01T00:00:00',
  ModifiedDate: '2022-01-02T12:34:56',
  MailerEmails: true,
  Password: 'securePassword123',
  UserName: 'john_doe',
  Phone: '123-456-7890',
};

export const mockContactData1 = {
  Id: 1231234,
  ObjectId: 456,
  ObjectType: 'ExampleType',
  Name: 'parth Doe',
  Phone1: '321-456-7890',
  Extension1: '123',
  Phone2: '987-654-3210',
  Extension2: '456',
  Fax: '987-654-3210',
  Email: 'parth.doe@example.com',
  Type: 'Billing',
  Status: 'Active',
  CreatedDate: '2022-01-01T00:00:00',
  ModifiedDate: '2022-01-02T12:34:56',
  MailerEmails: true,
  Password: 'securePassword123',
  UserName: 'john_doe',
  Phone: '123-456-7890',
};

const sampleAddress = {
  Line1: '123 Main St',
  Line2: null,
  City: 'Example City',
  State: 'ES',
  Zip: '12345',
  Name: null,
  Phone: null,
  PhoneAreaCode: null,
  PhoneSevenDigit: null,
  Code: null,
  Country: null,
  Contact: null,
  ContactLastName: null,
  ContactFirstName: null,
};

export const mockCarrierData = {
  Id: 123,
  Name: 'XPO Logistics',
  Contacts: [mockContactData],
  DOT: '460019',
  MC: '219694',
  Address: sampleAddress,
  Rating: 2.33,
  NumRatings: 3,
  Status: 'Active',
  CarrierActionPlan: false,
  OfficeRating: 0.0,
  IsActive: true,
  HasExpiredCargoInsurance: false,
  HasExpiredLiabilityInsurance: false,
  CargoExpiration: '2024-12-01T00:00:00',
  LiabilityExpiration: '2024-11-01T00:00:00',
  Warnings: [],
  AdvancesAllowed: true,
  Mode: 'LTL',
  Blacklisted: false,
  FirstLoad: false,
  CorporatePhoneNumber: '',
  Factors: false,
  Fallouts: 0,
  TotalLoads: 779,
  LTLEnabled: true,
  TransfloEnabled: true,
  BrokerAuthority: false,
  CurrencyOptionEnabled: false,
};

export const mockCarrierData1 = {
  Id: 123,
  Name: 'XPO1 Logistics',
  Contacts: [mockContactData1],
  DOT: '460019',
  MC: '219694',
  Address: sampleAddress,
  Rating: 2.33,
  NumRatings: 3,
  Status: 'Active',
  CarrierActionPlan: false,
  OfficeRating: 0.0,
  IsActive: true,
  HasExpiredCargoInsurance: false,
  HasExpiredLiabilityInsurance: false,
  CargoExpiration: '2024-12-01T00:00:00',
  LiabilityExpiration: '2024-11-01T00:00:00',
  Warnings: [],
  AdvancesAllowed: true,
  Mode: 'LTL',
  Blacklisted: false,
  FirstLoad: false,
  CorporatePhoneNumber: '',
  Factors: false,
  Fallouts: 0,
  TotalLoads: 779,
  LTLEnabled: true,
  TransfloEnabled: true,
  BrokerAuthority: false,
  CurrencyOptionEnabled: false,
};

export const mockOrderData = {
  Id: 1954786,
  CustomerId: 800184,
  CustomerContact: {
    Id: 66200,
    ObjectId: 800184,
    ObjectType: 'Customer',
    Name: 'Freight test',
    Phone1: '7045555555',
    Extension1: null,
    Phone2: null,
    Extension2: null,
    Fax: null,
    Email: null,
    Type: 'Freight',
    Status: 'Active',
    CreatedDate: '2023-07-28T13:12:03.1',
    ModifiedDate: '2023-07-28T13:46:15.723',
    MailerEmails: false,
    Password: null,
    UserName: 'freighttest',
    Phone: '7045555555',
  },
  UsersViewing: [],
  ChargeCreditCardFee: false,
  CustomerName: 'Ryan Poynter Customer',
  CustomerCredit: 0.0,
  CustomerContactId: 66200,
  AvailableCredit: null,
  ReferenceNumber: null,
  PaymentTerms: 'Net 45',
  Status: 'Open',
  AssignedId: 784301,
  Owner: 'Allen Pugh',
  BillingHold: false,
  AgingOut: false,
  LineItems: [
    {
      Id: 178703067,
      Type: 'Hazmat',
      Description: 'HAZ',
      SettledDate: null,
      CreatedDate: '1/3/2024',
      Status: 'Unsettled',
      Quantity: 1.0,
      Rate: 10.0,
      Estimate: false,
      ReadOnly: false,
      Synched: false,
      Adjustment: false,
      ReferenceNumber: '',
      StopId: null,
      DescriptionWithParanthesis: '(HAZ)',
      RateDetail: '$10.00',
    },
    {
      Id: 178703068,
      Type: 'Fuel',
      Description: 'Fuel Surcharge',
      SettledDate: null,
      CreatedDate: '1/3/2024',
      Status: 'Unsettled',
      Quantity: 1.0,
      Rate: 20.81,
      Estimate: false,
      ReadOnly: false,
      Synched: false,
      Adjustment: false,
      ReferenceNumber: '',
      StopId: null,
      DescriptionWithParanthesis: '(Fuel Surcharge)',
      RateDetail: '$20.81',
    },
    {
      Id: 178703069,
      Type: 'Line Haul',
      Description: 'Line Haul',
      SettledDate: null,
      CreatedDate: '1/3/2024',
      Status: 'Unsettled',
      Quantity: 1.0,
      Rate: 95.0,
      Estimate: false,
      ReadOnly: false,
      Synched: false,
      Adjustment: false,
      ReferenceNumber: '',
      StopId: null,
      DescriptionWithParanthesis: '(Line Haul)',
      RateDetail: '$95.00',
    },
  ],
  Payments: [],
  Adjustments: [],
  Loads: [
    {
      Id: 3075012,
      Editable: true,
      HasBeenSharedWithCapacity: false,
      HideCustomerName: false,
      Carrier: null,
      OrderId: 1954786,
      LoadNum: '1954786-1',
      Status: 'Assigned',
      Sequence: 1,
      Type: null,
      CustomerReferenceNumber: null,
      CarrierContact: null,
      CustomerContact: {
        Id: 66200,
        ObjectId: 800184,
        ObjectType: 'Customer',
        Name: 'Freight test',
        Phone1: '7045555555',
        Extension1: null,
        Phone2: null,
        Extension2: null,
        Fax: null,
        Email: null,
        Type: 'Freight',
        Status: 'Active',
        CreatedDate: '2023-07-28T13:12:03.1',
        ModifiedDate: '2023-07-28T13:46:15.723',
        MailerEmails: false,
        Password: null,
        UserName: 'freighttest',
        Phone: '7045555555',
      },
      CoveredById: 784301,
      CoveredBy: 'Allen Pugh',
      CarrierId: 654093,
      CarrierName: 'Southeastern Freightlines',
      CarrierActionPlan: false,
      CarrierStatus: 'Active',
      CarrierContactId: 976599,
      Equipment: 'Beam Trailer',
      Mode: 'LTL',
      TeamRequired: false,
      HazmatRequired: false,
      TarpRequired: false,
      CarrierInstructions: null,
      Terminal: null,
      PracticalMileage: 237.59,
      ShortestMileage: 235.31,
      Driver: null,
      DriverPhoneNumber: null,
      TruckNumber: null,
      TrailerNumber: null,
      TrackingNumber: null,
      Product: null,
      TempInstructions: null,
      BillingHold: false,
      BillingHoldReason: null,
      BillingHoldDescription: null,
      BilledDate: null,
      PaidDate: null,
      TrackingEnabled: false,
      EnableTracking: false,
      AssignedToUserId: 784301,
      AssignedToGroupId: null,
      AssignedToUser: 'Allen Pugh',
      AssignedToGroup: null,
      AssignedToOffice: null,
      LegalText: null,
      ProfitSplit: null,
      SharedWithOfficeId: null,
      OD: false,
      FreightValue: null,
      LoadTracking: [],
      NMFC: null,
      Weight: 0.0,
      MarketRate: null,
      Class: null,
      MinPrice: null,
      MaxPrice: null,
      LineItems: [
        {
          Id: 163947283,
          Type: 'Hazmat',
          Description: 'HAZ',
          SettledDate: null,
          CreatedDate: '1/3/2024',
          Status: 'Unsettled',
          Quantity: 1.0,
          Rate: 10.0,
          Estimate: false,
          ReadOnly: false,
          Synched: false,
          Adjustment: false,
          ReferenceNumber: '',
          StopId: null,
          DescriptionWithParanthesis: '(HAZ)',
          RateDetail: '$10.00',
        },
        {
          Id: 163947284,
          Type: 'Fuel',
          Description: 'Fuel Surcharge',
          SettledDate: null,
          CreatedDate: '1/3/2024',
          Status: 'Unsettled',
          Quantity: 1.0,
          Rate: 20.81,
          Estimate: false,
          ReadOnly: false,
          Synched: false,
          Adjustment: false,
          ReferenceNumber: '',
          StopId: null,
          DescriptionWithParanthesis: '(Fuel Surcharge)',
          RateDetail: '$20.81',
        },
        {
          Id: 163947285,
          Type: 'Line Haul',
          Description: 'Line Haul',
          SettledDate: null,
          CreatedDate: '1/3/2024',
          Status: 'Unsettled',
          Quantity: 1.0,
          Rate: 95.0,
          Estimate: false,
          ReadOnly: false,
          Synched: false,
          Adjustment: false,
          ReferenceNumber: '',
          StopId: null,
          DescriptionWithParanthesis: '(Line Haul)',
          RateDetail: '$95.00',
        },
      ],
      Payments: [],
      Stops: [
        {
          Id: 7764311,
          CustomerId: 800184,
          LoadId: 3075012,
          StopDateTime: '2024-01-03T00:00:00',
          Description: null,
          Address1: ' test',
          Address2: '',
          City: 'Charlotte',
          State: 'NC',
          Zip: '28202',
          LocationName: 'Charlotte',
          Contact: 'test',
          Phone: '5555555555',
          PickUp: true,
          DropOff: false,
          StopOrder: 0,
          Origin: true,
          Destination: false,
          CreatedDate: '2024-01-03T19:56:29.9266667',
          ModifiedDate: '2024-01-03T19:56:29.8933333',
          ReferenceNum: null,
          Time: null,
          Instructions: null,
          Directions: null,
          NeedsAppointment: false,
          AppointmentDate: null,
          DriverInTime: '2024-01-03T08:00:00',
          DriverOutTime: '2024-01-03T18:00:00',
          AppointmentNum: null,
          Email: null,
          StopCode: null,
          Arrived: false,
          Lat: null,
          Long: null,
          CountryCode: 'USA',
          PracticalMileageToNextStop: 237.59,
          ShortestMileageToNextStop: 235.31,
          Load: null,
          LoadItems: [],
          OrderLineItems: [],
          TimeZoneOffset: 0,
          StopType: 'Pickup',
        },
        {
          Id: 7764310,
          CustomerId: 800184,
          LoadId: 3075012,
          StopDateTime: '2024-01-04T00:00:00',
          Description: null,
          Address1: ' test',
          Address2: '',
          City: 'Atlanta',
          State: 'GA',
          Zip: '30333',
          LocationName: 'Atlanta',
          Contact: 'test',
          Phone: '5555555555',
          PickUp: false,
          DropOff: true,
          StopOrder: 1,
          Origin: false,
          Destination: true,
          CreatedDate: '2024-01-03T19:56:29.9266667',
          ModifiedDate: '2024-01-03T19:56:29.8933333',
          ReferenceNum: null,
          Time: null,
          Instructions: null,
          Directions: null,
          NeedsAppointment: false,
          AppointmentDate: null,
          DriverInTime: '2024-01-04T08:00:00',
          DriverOutTime: '2024-01-04T18:00:00',
          AppointmentNum: null,
          Email: null,
          StopCode: null,
          Arrived: false,
          Lat: null,
          Long: null,
          CountryCode: 'USA',
          PracticalMileageToNextStop: null,
          ShortestMileageToNextStop: null,
          Load: null,
          LoadItems: [],
          OrderLineItems: [],
          TimeZoneOffset: 0,
          StopType: 'Dropoff',
        },
      ],
      Comments: [],
      LastInternalNote:
        'Load Quoted as LTL\nQuote ID: CXACE35B8\nCarrier: ATG - Southeastern Freightlines\nOrigin Zip: 28202\nDestination Zip: 30333\nCost: $125.81\nPick Date: 01/03/2024\nService Type: Normal\n \nPickup Accessorials: \n\n \nDropoff Accessorials: \n\n \nShipment Accessorials: \n\n \nItems:\n# of Units | # of Pieces | Weight | NMFC | Class | Dimensions | Hazmat | Unit Type\n1 | 1 | 1000 lbs |  | 50 | 48"x48"x48" | Yes | Pallets',
      Warnings: null,
      Flags: [],
      Claim: null,
      CanRate: true,
      Synched: false,
      EDI: false,
      EquipmentOptions: null,
      Items: [
        {
          Id: 1446744,
          LoadId: 3075012,
          NMFC: '',
          Class: '50',
          Item: 'Pallets',
          Description: 'test',
          Quantity: 1,
          Charge: null,
          PickUpId: 7764311,
          DropOffId: 7764310,
          Weight: 1000.0,
          UOM: 'lbs',
          Volume: null,
          Hazmat: true,
          HeightInch: 48,
          LengthInch: 48,
          WidthInch: 48,
          Height: null,
          Length: null,
          Width: null,
          Pieces: 1,
          DescriptionWithParanthesis: '(test)',
          DisplayDimensions: '48  x 48 x 48',
          LoadItemHazmatDetail: {
            Id: 0,
            UNNumber: '6565',
            PackingGroup: 'No Packing Group Assigned',
            HazardClass: '1.1 Massive Explosive Hazard',
            Piece: 'A-Frame',
            ContactNumber: '5555555555',
            LoadItemId: 1446744,
          },
        },
      ],
      PostingId: null,
      SignedRateCon: false,
      SignedConfirmationDate: null,
      CustomerId: 800184,
      Ramps: false,
      MacropointInterval: null,
      QuoteID: 'CXACE35B8',
      QuoteItems: null,
      QuoteStops: null,
      LtlQuote: {
        Id: '94a64928-b6e2-4234-ae1d-57a37cdea59d',
        QuoteId: 'CXACE35B8',
        QuoteDate: '2024-01-03T19:52:42.71',
        CarrierName: 'Southeastern Freightlines',
        CustomerName: '',
        CarrierTotal: 125.81,
        CustomerTotal: null,
        PickupAccessorials: [],
        DeliveryAccessorials: [],
        ShipmentAccessorials: ['Hazmat'],
        PickupAccessorialDescription: '',
        DeliveryAccessorialDescription: '',
        ShipmentAccessorialDescription: 'Hazmat',
        MaxNewLiability: 6.0,
        MaxUsedLiability: 3.0,
        TransitDays: '1',
        ServiceType: 'Normal',
        LogoImageSource: '/images/logos/SEFLlogo.jpg',
        LtlQuoteLoadItems: [
          {
            Id: 'ff323a65-cb48-422f-ae89-8ef834a868da',
            Quantity: 1,
            Pieces: 1,
            Weight: 1000.0,
            Class: '50',
            Nmfc: '',
            LengthInches: 48,
            WidthInches: 48,
            HeightInches: 48,
            Hazmat: true,
            Dimensions: '48" x 48" x 48"',
            SuggestedClass: null,
            Item: 'Pallets',
            LTLQuoteHazmatDetails: {
              UNNumber: '6565',
              PackingGroup: null,
              HazardClass: '1.1 Massive Explosive Hazard',
              Piece: 'A-Frame',
              ContactNumber: '5555555555',
              LTLQuoteLoadItemId: 'ff323a65-cb48-422f-ae89-8ef834a868da',
            },
          },
        ],
        CPCreatedBy: null,
        CreatedByDisplayName: '',
        TerminalInfo: {
          PickupTerminal: {
            TerminalName: 'CHARLOTTE',
            Address1: '4524 REAGAN DR',
            Address2: null,
            City: 'CHARLOTTE',
            PostalCode: '28206',
            StateOrProvince: 'NC',
            CountryCode: null,
            ContactName: 'KYLE DONAHUE',
            ContactPhone: '704-597-9828',
            ContactTollFree: '800-438-6007',
            ContactFax: '704-598-0636',
            ContactEmail: 'KYLE.DONAHUE@SEFL.COM',
            CarrierName: 'SOUTHEASTERN FREIGHT LINES',
            SCAC: 'SEFL',
          },
          DeliveryTerminal: {
            TerminalName: 'ATLANTA',
            Address1: '4061 MORELAND AVENUE',
            Address2: null,
            City: 'CONLEY',
            PostalCode: '30288',
            StateOrProvince: 'GA',
            CountryCode: null,
            ContactName: 'CHRIS RICH',
            ContactPhone: '404-363-7800',
            ContactTollFree: '800-282-3274',
            ContactFax: '404-363-1873',
            ContactEmail: 'chris.rich@sefl.com',
            CarrierName: 'SOUTHEASTERN FREIGHT LINES',
            SCAC: 'SEFL',
          },
        },
      },
      TrackingService: null,
      LTLSubCarrier: 'SMC3',
      TenderedToTransfloDate: null,
      CurrencyType: 'USD',
      CanVoidLoad: true,
      CargoValueIsHigh: null,
      LTLShipmentData: {
        LoadId: 0,
        ShipmentId: null,
        ProNumber: null,
        PickupNumber: null,
        TrackingURL: null,
        ReferenceNumbers: null,
      },
      LTLQuoteId: '94a64928-b6e2-4234-ae1d-57a37cdea59d',
      EnableLoadCarrierRestrictionOverride: false,
      EnableLoadCarrierRestrictionOverrideOffice: null,
      LoadCarrierRestrictionOverride: null,
      UserId: 784354,
    },
  ],
  Vendors: [],
  Comments: [
    {
      Id: 4707477,
      ObjectId: 1954786,
      UserId: 784301,
      Name: 'Allen Pugh',
      Type: 'System',
      Comment:
        'Load Quoted as LTL\nQuote ID: CXACE35B8\nCarrier: ATG - Southeastern Freightlines\nOrigin Zip: 28202\nDestination Zip: 30333\nCost: $125.81\nPick Date: 01/03/2024\nService Type: Normal\n \nPickup Accessorials: \n\n \nDropoff Accessorials: \n\n \nShipment Accessorials: \n\n \nItems:\n# of Units | # of Pieces | Weight | NMFC | Class | Dimensions | Hazmat | Unit Type\n1 | 1 | 1000 lbs |  | 50 | 48"x48"x48" | Yes | Pallets',
      Timestamp: '2024-01-03T19:56:29.8266667',
      Thumbnail: null,
    },
  ],
  Metadata: [],
  CustomerPrePayments: [],
  CollectionActions: null,
  Warnings: [],
  Flags: [],
  InvoicedDate: null,
  PaidDate: null,
  Editable: true,
  Role: null,
  Synched: false,
  Private: false,
  HideCustomerName: false,
  Partnerships: {},
  EDI: false,
  TrackLoads: false,
  RequiredTrackingService: null,
  TrackingVisibilityProvider: null,
  TrackingServiceName: null,
  AccountingSysId: null,
  LoadTenderId: null,
  BidType: 'Spot Market',
  SpotId: null,
  SpotQuote: null,
  Show214: false,
  CurrencyType: 'USD',
  DueDate: null,
  DaysPastDue: 0,
  CanVoidOrder: true,
  NeedsApprovalFlag: null,
  HoldRetraction: false,
  RevisitRetractionDate: null,
  ExceptionReason: null,
  UserAssigned: null,
};

export const falveyVendorIntialState = {
  operationType: 'Insert',
  id: 0,
  orderId: 0,
  description: 'Insured Value:',
  invoicedDate: null,
  paidDate: null,
  name: 'Falvey Shippers Insurance',
  mailingAddress1: '66 Whitecap Dr',
  mailingAddress2: '',
  mailingCity: 'North Kingstown',
  mailingState: 'RI',
  mailingZip: '02852',
  countryCode: 'USA',
  serviceType: 'LTL Insurance',
  serviceTypeText: null,
  currencyType: 'USD',
  contactName: 'Falvey',
  contactPhone: '4017920144',
  status: null,
  synched: false,
  lineItems: [],
  payments: [],
  vendorPayDisplay: 0,
  otherServiceType: false,
};
