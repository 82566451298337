import { createStore, produce } from 'solid-js/store';

import { ModalState } from './types';

export const [modalStore, setModalStore] = createStore<ModalState>({
  modalIds: [],
});

export const openModal = (modalId: string) => {
  setModalStore(
    produce((draft: ModalState) => {
      draft.modalIds.push(modalId);
    }),
  );
};

export const closeModal = (modalId: string) => {
  setModalStore(
    produce((draft: ModalState) => {
      draft.modalIds = draft.modalIds.filter((id) => id !== modalId);
    }),
  );
};

export const isModalOpen = (modalId: string) => {
  return modalStore.modalIds.includes(modalId);
};

export default { modalStore, openModal, closeModal };
