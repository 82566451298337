import { AddressBookModel } from '@store/customers/customerDetails/addressBookTab';
import { PlaylistAddCheck } from '@suid/icons-material';
import { Box, Stack } from '@suid/material';
import { useParams } from '@solidjs/router';

import { addressBookTabStyle as classes } from './classes';
import AddressBookGrid from './AddressBookGrid';

export const agGridCellStyle = {
  padding: '10px',
  'word-break': 'break-word',
  'white-space': 'normal',
  'line-height': '1.6 ',
  'align-items': 'center !important',
};

export const DefaultOrigin = (data: AddressBookModel) => {
  const statusIcon = data.isDefaultAddress && (
    <PlaylistAddCheck class="text-[#15668f]" />
  );
  return (
    <Box class="flex gap-3">
      <span class={`${data.isDefaultAddress && 'font-semibold'}`}>
        {data.name} {statusIcon}
      </span>
    </Box>
  );
};

export const AddressColumn = (props: {
  val1: string | null;
  val2: string | null;
}) => {
  return (
    <Stack direction="column">
      <span>{props.val1}</span>
      <span>{props.val2}</span>
    </Stack>
  );
};

const AddressBookTab = () => {
  const params = useParams();

  return (
    <Box class={classes.container} bgcolor="#E1ECF2">
      <AddressBookGrid customerId={Number(params.id)} />
    </Box>
  );
};

export default AddressBookTab;
