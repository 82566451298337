export default {
  label:
    'pb-2 border-b-2 border-dashed border-black text-gray-500 mb-2 text-sm',
  section: 'mb-2 text-black font-inter text-sm font-normal',
  heading: 'text-black font-inter font-bold text-base',
  divider: 'border border-black my-4',
  payWithCreditCardModal: {
    chipStyle: { background: '#ebebebff', marginRight: '10px' },
    marginBottom16: { marginBottom: '16px' },
    subheading: '!text-[#315467] !text-lg !font-medium',
    radioOptionStyles: {
      display: 'flex',
      width: '100%',
      padding: '10px 60px 10px 17px',
      background: '#E8F0F6',
      margin: '0px',
      'border-radius': '5px',
      'align-items': 'baseline',
    },
    radioLabelHelperText: 'text-lg',
    radioButtonStyles: { position: 'relative', top: '6px' },
    priceSectionContainer:
      ' w-[100%] bg-[#eeeeeeff] rounded-lg flex flex-col justify-between font-bold text-lg h-[100%]',
    amount:
      'text-[#2E7D32] text-center font-roboto font-bold text-lg leading-6 tracking-tighter',
    radioLabelHeading: 'font-semibold text-base md:text-lg',
  },
  totalLabel: '!text-[#171b1A] !font-bold  !rounded-sm !overflow-hidden',
  totalAmount:
    '!bg-[#0000000F]  !font-bold !text-[#171b1A] px-1 rounded-sm py-1',
  addIcon: 'text-[#0070A2] cursor-pointer',
  deleteIcon: 'text-[#B00020] cursor-pointer',
  tableBg: 'bg-[#BFDFD8]',
  tableRow:
    ' !border-t-2 !border-[#a8c5bf] !bg-[#BFDFD8] [&:nth-child(1)]:!border-none ',
  emptyTable: 'w-full h-64 bg-[#BFDFD8]',
  tableFooter:
    'text-base font-normal font-weight-700 leading-20.02 tracking-0.17 text-black !bg-[#BFDFD8]',
  tableFooterRow: 'border-t-2    border-[#a8c5bf] ',
  editLineItems:
    'text-[#026EA1] font-medium text-xs underline leading-5 cursor-pointer',
};
