export default {
  PCMiler: {
    PCMilerAPIKey: 'C04D8BAE0A636940BCB1878A7AA51DF7',
    PCMilerBaseUrl: 'https://pcmiler.alk.com/APIs/REST/v1.0/Service.svc',
    PCMilerMapSourceUrl: 'https://maps.alk.com/api/1.2/alkmaps.js',
  },
  GoogleGeoCode: {
    GoogleGeoCodeAPIKey: 'AIzaSyBb7Zi_xh5RiFjzFttLAOTyN58YEB7eJoo',
  },
};
