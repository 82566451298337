import { createStore } from 'solid-js/store';

import { OfficeState } from './types';

const [officeStore, setOfficeStore] = createStore<OfficeState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  offices: [],
  overlay: false,
});

export { officeStore, setOfficeStore };
