import {
  equipmentTypes,
  intermodalEquipmentTypes,
  loadModesTypes,
} from '@common/commonLists';
import { Typography } from '@components';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import {
  ILoadEditViewModel,
  orderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { Box, Stack } from '@suid/material';
import { Show } from 'solid-js';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const LoadDetails = (props: PropsT) => {
  return (
    <Box>
      <Box mb={1}>
        <Typography
          variant="body1"
          class="text-base font-bold text-armstrong-primary"
        >
          Load Details
        </Typography>
      </Box>
      <Stack direction="column" gap={1}>
        <Box>
          <SelectField
            label="Mode"
            menuItems={loadModesTypes.map((mode) => {
              return {
                label: mode,
                value: mode,
              };
            })}
            onChange={(e) =>
              updateLoadPropertyAtIndex({
                // @ts-expect-error This is the same error in the old code.
                mode: e.target.value,
                cargoValueIsHigh: undefined,
                freightValue: undefined,
                teamRequired:
                  e.target.value === 'LTL' ? false : props.load.teamRequired,
                secondDriver:
                  e.target.value === 'LTL'
                    ? undefined
                    : props.load.secondDriver,
                secondDriverPhoneNumber:
                  e.target.value === 'LTL'
                    ? undefined
                    : props.load.secondDriverPhoneNumber,
              })
            }
            value={props.load.mode ?? ''}
            size="small"
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Box>
          <SelectField
            label="Equipment"
            menuItemGrow
            menuItems={
              props.load.mode === 'Intermodal'
                ? intermodalEquipmentTypes
                : equipmentTypes
            }
            onChange={(e) =>
              updateLoadPropertyAtIndex({
                equipment: e.target.value,
              })
            }
            size="small"
            value={props.load.equipment ?? ''}
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Box>
          <TextInput
            label="Product"
            value={props.load.product ?? ''}
            onChange={(val) =>
              updateLoadPropertyAtIndex({
                product: val as string,
              })
            }
            size="small"
            maxLength={30}
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Box>
          <TextInput
            label="Temperature Instructions"
            value={props.load.tempInstructions ?? ''}
            onChange={(val) =>
              updateLoadPropertyAtIndex({
                tempInstructions: val as string,
              })
            }
            size="small"
            classes="h-full"
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[
                `loads[${props.tabIndex}].tempInstructions`
              ]
            }
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Box>
          <TextInput
            label="Tracking Number"
            value={props.load.trackingNumber ?? ''}
            onChange={(val) =>
              updateLoadPropertyAtIndex({
                trackingNumber: val as string,
              })
            }
            maxLength={60}
            size="small"
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Stack direction="column" gap={0}>
          {props.load.mode !== 'Intermodal' ? (
            <>
              <Show when={props.load.mode !== 'LTL'}>
                <CheckboxInput
                  label="Team"
                  checked={props.load.teamRequired}
                  onChange={(checked) => {
                    updateLoadPropertyAtIndex({
                      teamRequired: checked,
                      secondDriver: checked
                        ? props.load.secondDriver
                        : undefined,
                      secondDriverPhoneNumber: checked
                        ? props.load.secondDriverPhoneNumber
                        : undefined,
                    });
                  }}
                  disabled={orderStore.isReadOnly}
                />
              </Show>
              <CheckboxInput
                label="Over Dimensional"
                checked={props.load.od}
                onChange={(checked) => {
                  updateLoadPropertyAtIndex({
                    od: checked,
                  });
                }}
                disabled={orderStore.isReadOnly}
              />

              <CheckboxInput
                label="Tarps Required"
                checked={props.load.tarpRequired}
                onChange={(checked) => {
                  updateLoadPropertyAtIndex({
                    tarpRequired: checked,
                  });
                }}
                disabled={orderStore.isReadOnly}
              />
            </>
          ) : null}

          <CheckboxInput
            label="Hazmat"
            checked={props.load.hazmatRequired}
            onChange={(checked) => {
              updateLoadPropertyAtIndex({
                hazmatRequired: checked,
              });
            }}
            disabled={orderStore.isReadOnly}
          />
          <CheckboxInput
            label="Ramps Required"
            checked={props.load.ramps}
            onChange={(checked) => {
              updateLoadPropertyAtIndex({
                ramps: checked,
              });
            }}
            disabled={orderStore.isReadOnly}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
