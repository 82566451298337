import { Checkbox, FormControlLabel, FormGroup } from '@suid/material';
import { Component, createSignal, For } from 'solid-js';

import FormErrorComponent from './FormErrorComponent';

type CheckboxOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type CheckboxGroupFieldProps = {
  name: string;
  label: string;
  value?: string[];
  options: CheckboxOption[];
  onChange: (selectedValues: string[]) => void;
  error?: string | string[] | null;
};

export const CheckboxGroup: Component<CheckboxGroupFieldProps> = (props) => {
  const [selectedValues, setSelectedValues] = createSignal<string[]>(
    props.value || [],
  );

  const handleChange = (value: string) => {
    const newSelectedValues = selectedValues().includes(value)
      ? selectedValues().filter((v) => v !== value)
      : [...selectedValues(), value];
    setSelectedValues(newSelectedValues);
    props.onChange(newSelectedValues);
  };

  return (
    <>
      <FormGroup>
        <For each={props.options}>
          {(option) => (
            <FormControlLabel
              value={option.value}
              control={
                <Checkbox
                  checked={selectedValues().includes(option.value)}
                  onChange={() => handleChange(option.value)}
                  disabled={option.disabled ?? false}
                  //commented this line because for group selection of checkboxes we dont need individual checkboxes with name when we submit
                  // name={`${props.name}-${option.value}`}
                />
              }
              label={option.label}
            />
          )}
        </For>
      </FormGroup>
      <FormErrorComponent error={props.error} />
    </>
  );
};
