import { Box } from '@suid/material';
import { JSXElement, Show } from 'solid-js';

type PropsT = {
  title: string;
  content: string | JSXElement | undefined;
  icon?: JSX.Element;
  titleFont?: string;
  contentFont?: string;
  contentWeight?: string;
};

export const StackCard = (props: PropsT) => {
  return (
    <Box flex={1}>
      <Box
        fontSize={props.titleFont ?? '11px'}
        color="#000000ba"
        paddingBottom="4px"
      >
        {props.title}
      </Box>
      <Box
        fontSize={props.contentFont ?? '0.875rem'}
        fontWeight={props.contentWeight ?? ''}
        lineHeight={1}
        sx={{ wordBreak: 'break-word' }}
      >
        {/* @ts-expect-error - Type is fine */}
        <Show when={props.icon !== undefined}>{props.icon}</Show>
        {props.content}
      </Box>
    </Box>
  );
};
