import { FlagViewModel, UserInfoModel } from '@store/orders';
import { createStore } from 'solid-js/store';

type LoadInfoStoreT = {
  assignedTo: UserInfoModel | undefined;
  coveredBy: UserInfoModel | undefined;
  isLoading: boolean;
  assignedToButton: string;
  flag: FlagViewModel | undefined;
  flagIndex: number | null;
};

export const [loadInfoStore, setLoadInfoStore] = createStore<LoadInfoStoreT>({
  assignedTo: undefined,
  coveredBy: undefined,
  isLoading: false,
  assignedToButton: 'Reassign',
  flag: undefined,
  flagIndex: null,
});
