import {
  ShoppingCartCheckoutSharp,
  Description,
  Search,
  PersonSharp,
  MapsHomeWorkSharp,
  LocalPhoneSharp,
  AccountBalanceSharp,
  FileOpenSharp,
  LocalShippingSharp,
} from '@suid/icons-material';
import { isAdmin, isManager } from '@utils/utils';
import { routeCarrierDetails } from '@utils/routeCarrierDetails';
import { routeOrderDetails } from '@utils/routeOrderDetails';

import {
  AutoCompleteRenderType,
  CarrierType,
  CustomerType,
  FactoringCompanyType,
  OfficeType,
  PhoneType,
  ProspectType,
  ProType,
  ReferenceType,
  SearchItemsType,
  ShipmentMetadataType,
  UserType,
} from './types';
import {
  CarrierItemsRender,
  CustomerItemsRender,
  FactoringCompanyItemsRender,
  OfficeItemsRender,
  PhoneItemsRender,
  ProItemsRender,
  ProspectItemsRender,
  ReferenceItemsRender,
  ShipmentMetaDataItemsRender,
  UserItemsRender,
} from './components/ItemsRender';

export const searchItems: SearchItemsType[] = [
  {
    label: 'Load',
    disclaimer: 'Search by a Load #',
    icon: ShoppingCartCheckoutSharp,
  },
  {
    label: 'Reference',
    disclaimer: 'Find a Load By Reference#',
    icon: Description,
  },
  {
    label: 'Shipment Metadata',
    disclaimer: 'Search custom fields on shipments',
    icon: Search,
  },
  {
    label: 'Carrier',
    disclaimer: 'Search for a carrier',
    icon: LocalShippingSharp,
  },
  {
    label: 'Customer',
    disclaimer: 'Search for a Customer',
    icon: PersonSharp,
  },
  {
    label: 'Prospect',
    disclaimer: 'Search for a Prospect',
    icon: PersonSharp,
  },
  {
    label: 'Office',
    disclaimer: 'Search for an Office',
    icon: MapsHomeWorkSharp,
    hide: !isAdmin(),
  },
  {
    label: 'User',
    disclaimer: 'Search for a User',
    icon: PersonSharp,
    hide: !isAdmin() && !isManager(),
  },
  {
    label: 'Tracking#',
    disclaimer: 'Find a load by Tracking #',
    icon: FileOpenSharp,
  },
  {
    label: 'Factoring Company',
    disclaimer: 'Search for a Factoring Company',
    icon: AccountBalanceSharp,
  },
  {
    label: 'Phone #',
    disclaimer: 'Search by Phone #',
    icon: LocalPhoneSharp,
  },
  {
    label: 'Pro #',
    disclaimer: 'Search for an LTL load by pro #',
    icon: Description,
  },
];

export const autoCompleteMap: Record<string, AutoCompleteRenderType> = {
  Reference: {
    endpoint: 'Order/Search/ReferenceNumber/?query=',
    id: 'autocomplete_reference_search',
    redirect: (item: ReferenceType) => {
      const isNative = routeCarrierDetails(item.orderId);
      if (isNative) {
        return `/Order/Details/${item.orderId}`;
      }
      return '';
    },
    display: (item: ReferenceType) => <ReferenceItemsRender {...item} />,
  },

  'Shipment Metadata': {
    endpoint: 'Order/Search/CustomerMetadata/?query=',
    id: 'autocomplete_shipment_metadata_search',
    redirect: (item: ShipmentMetadataType) => {
      const isNative = routeOrderDetails(item.orderId);
      if (isNative) {
        return `/Order/Details/${item.orderId}`;
      }
      return '';
    },
    display: (item: ShipmentMetadataType) => (
      <ShipmentMetaDataItemsRender {...item} />
    ),
  },
  Carrier: {
    endpoint: 'carrier/autocomplete/?term=',
    id: 'autocomplete_carrier_search',
    redirect: (item: CarrierType) => {
      const isNative = routeCarrierDetails(item.id);
      if (isNative) {
        return `/Carrier/Details/${item.id}`;
      }
      return '';
    },
    display: (item: CarrierType) => <CarrierItemsRender {...item} />,
  },
  Customer: {
    endpoint: 'customer/autocomplete/?term=',
    id: 'autocomplete_customer_search',
    redirect: (item: CustomerType) => {
      const isNative = routeCarrierDetails(item.id);
      if (isNative) {
        return `/Customer/Details/${item.id}`;
      }
      return '';
    },
    display: (item: CustomerType) => <CustomerItemsRender {...item} />,
  },
  Prospect: {
    endpoint: 'prospect/autocomplete/?term=',
    id: 'autocomplete_prospect_search',
    redirect: (item: ProspectType) => {
      const isNative = routeCarrierDetails(item.id);
      if (isNative) {
        return `/Prospect/Details/${item.id}`;
      }
      return '';
    },
    display: (item: ProspectType) => <ProspectItemsRender {...item} />,
  },
  Office: {
    endpoint: 'office/autocomplete/?term=',
    id: 'autocomplete_office_search',
    redirect: (item: OfficeType) => {
      const isNative = routeCarrierDetails(item.id);
      if (isNative) {
        return `/Office/Details/${item.id}`;
      }
      return '';
    },
    display: (item: OfficeType) => <OfficeItemsRender {...item} />,
  },
  User: {
    endpoint: 'user/autocomplete/?term=',
    id: 'autocomplete_user_search',
    redirect: (item: UserType) => {
      const isNative = routeCarrierDetails(item.id);
      if (isNative) {
        return `/User/Details/${item.id}`;
      }
      return '';
    },
    display: (item: UserType) => <UserItemsRender {...item} />,
  },
  'Factoring Company': {
    endpoint: 'factoringcompany/autocomplete/?term=',
    id: 'autocomplete_factoringcompany_search',
    redirect: (item: FactoringCompanyType) => {
      const isNative = routeCarrierDetails(item.id);
      if (isNative) {
        return `/FactoringCompany/Details/${item.id}`;
      }
      return '';
    },
    display: (item: FactoringCompanyType) => (
      <FactoringCompanyItemsRender {...item} />
    ),
  },
  'Phone #': {
    endpoint: '/GlobalSearch/PhoneNumber?phone=',
    id: 'autocomplete_phone_search',
    redirect: (item: PhoneType) => {
      const isNative = routeCarrierDetails(item.objectId);
      if (isNative) {
        return `/${item.contactType}/Details/${item.objectId}`;
      }
      return '';
    },
    display: (item: PhoneType) => <PhoneItemsRender {...item} />,
    minCharacters: 9,
  },
  'Pro #': {
    endpoint: '/Order/Search/ProNumber/?query=',
    id: 'autocomplete_pro_search',
    redirect: (item: ProType) => {
      const isNative = routeOrderDetails(item.orderId);
      if (isNative) {
        return `/Order/Details/${item.orderId}`;
      }
      return '';
    },
    display: (item: ProType) => <ProItemsRender {...item} />,
  },
};
