import { StarRating } from '@components/Rating/StarRating';
import { openModal } from '@store/modals';
import { carrierStore, ILoadEditViewModel } from '@store/orders';
import { Stack } from '@suid/material';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
  isCarrierSelected: boolean;
  rateCarrierModalId: string;
};

export const Rating = (props: PropsT) => {
  return (
    <Stack direction="row" alignItems="center">
      <div
        class={`underline text-armstrong-sm text-armstrong-blue font-semibold cursor-pointer ${
          props.isCarrierSelected ? '' : 'opacity-40 !cursor-default'
        }`}
        onClick={() =>
          props.isCarrierSelected ? openModal(props.rateCarrierModalId) : null
        }
      >
        RATE CARRIER
      </div>
      <div class="ml-4">
        <StarRating
          currentRating={carrierStore.carrier[props.tabIndex]?.rating || 0}
          starCount={5}
          numberOfRatings={carrierStore.carrier[props.tabIndex]?.numRatings}
          showCount={props.isCarrierSelected}
          small
        />
      </div>
    </Stack>
  );
};
