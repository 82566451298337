import { createStore } from 'solid-js/store';

import { CustomerContractState } from './types';

const [customerContractStore, setCustomerContract] =
  createStore<CustomerContractState>({
    customerContract: null,
    isLoading: false,
    error: '',
  });

export { customerContractStore, setCustomerContract };
