import { Grid, Stack } from '@suid/material';

import { OfficeDetails } from './OfficeDetails';
import { Contract } from './Contract';
import { ManageUsers } from './manageUserDetails/ManageUsers';
import { GroupDetails } from './groupDetails';

export const OfficeDetailsContent = () => {
  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12} md={3}>
        <OfficeDetails />
      </Grid>
      <Grid item xs={12} md={6}>
        <ManageUsers />
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack direction="column" spacing={2}>
          <GroupDetails />
          <Contract />
        </Stack>
      </Grid>
    </Grid>
  );
};
