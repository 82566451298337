import { JSXElement, onCleanup, onMount } from 'solid-js';
import { Loader } from '@googlemaps/js-api-loader';
import { TextInput } from '@components/forms';
import mapConfig from '@components/Map/mapConfig';

export type AddressDetails = {
  address1?: string;
  city?: string;
  county?: string;
  state?: string;
  zipCode?: string;
};

interface AutocompleteAddressProps {
  label: string | JSXElement;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  onItemSelect?: (item: AddressDetails) => void;
  error?: string | string[] | null;
  classes?: string;
  zIndex?: string;
  disabled?: boolean;
  maxLength?: number;
}

const AutocompleteAddress = (props: AutocompleteAddressProps) => {
  let inputRef: HTMLInputElement | undefined;

  const adjustZIndex = () => {
    if (Boolean(props.zIndex)) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (
              node.nodeType === 1 &&
              (node as Element).classList.contains('pac-container')
            ) {
              (node as HTMLElement).style.zIndex = props.zIndex!;
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
      onCleanup(() => {
        observer.disconnect();
      });
    }
  };

  onMount(async () => {
    const loader = new Loader({
      apiKey: mapConfig.GoogleGeoCode.GoogleGeoCodeAPIKey,
      version: 'weekly',
      libraries: ['places'],
    });

    await loader.load();
    adjustZIndex();
    if (inputRef) {
      const autocomplete = new google.maps.places.Autocomplete(inputRef, {
        types: ['address'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.address_components) return;

        const details: AddressDetails = place.address_components.reduce(
          (acc: AddressDetails, component) => {
            const comp = component.types[0];
            switch (comp) {
              case 'street_number':
              case 'route':
                acc.address1 =
                  (Boolean(acc['address1']) ? acc['address1'] + ' ' : '') +
                  component.short_name;
                break;
              case 'locality':
                acc.city = component.long_name;
                break;
              case 'administrative_area_level_2':
              case 'country':
                acc.county = component.short_name;
                break;
              case 'administrative_area_level_1':
                acc.state = component.short_name;
                break;
              case 'postal_code':
                acc.zipCode = component.long_name;
                break;
            }
            return acc;
          },
          {},
        );

        props.onItemSelect && props.onItemSelect(details);
      });
    }
  });

  onCleanup(() => {
    if (inputRef !== undefined) {
      Boolean(window.google) &&
        window.google.maps.event.clearInstanceListeners(inputRef);
    }
  });

  return (
    <TextInput
      id="address"
      label={props.label}
      inputRef={(input: HTMLInputElement) => (inputRef = input)}
      value={props.value}
      onChange={(value: string) => props.onChange && props.onChange(value)}
      placeholder={props.placeholder}
      variant="outlined"
      disabled={props.disabled}
      error={props.error}
      maxLength={props.maxLength}
    />
  );
};

export default AutocompleteAddress;
