import {
  columnsToolPanel,
  filtersToolPanel,
} from '@components/Grid/components/constants';
import { useParams } from '@solidjs/router';
import {
  FactoringCompanyDetailsLoadHistory,
  fetchFactoringCompanyDetailsLoadHistory,
} from '@store/factoringCompany/factoringCompanyDetails';
import { dateFormatter } from '@store/loadboard';
import { dateComparator } from '@store/loadboard/utils';
import { Box, Stack } from '@suid/material';
import { atgLogo } from '@views/ltl';
import AgGridSolid, { AgGridSolidRef } from '@ag-grid-community/solid';
import { Component, createSignal } from 'solid-js';
import { formatAmount } from '@utils/utils';
import { ColDef } from '@ag-grid-community/core';
import { agGridCellStyle } from '@components/Grid/SavedQuotesGrid';
import { ExcelSheetIcon } from '@assets';
import { Button } from '@components';

import cls from './classes';

const filterParams = {
  buttons: ['apply', 'reset'],
  suppressAndOrCondition: true,
};

const commonColumnDefs: ColDef = {
  cellStyle: agGridCellStyle,
  filter: 'agTextColumnFilter',
  enableRowGroup: true,
  filterParams,
  autoHeight: true,
};

export const FactoringCompanyDetailsLoadTab: Component = () => {
  const [factoringCompanyLoadHistoryData, setFactoringCompanyLoadHistoryData] =
    createSignal<FactoringCompanyDetailsLoadHistory[]>([]);

  const params = useParams();
  let gridRef: AgGridSolidRef;

  const columnDefs: ColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 210,
      rowGroup: true,
      sortable: true,
      sort: 'desc',
      ...commonColumnDefs,
    },
    {
      field: 'carrierName',
      headerName: 'Carrier Name',
      minWidth: 300,
      rowGroup: true,
      ...commonColumnDefs,
    },
    {
      field: 'mC',
      headerName: 'MC',
      minWidth: 150,
      ...commonColumnDefs,
    },

    {
      field: 'dOT',
      headerName: 'DOT',
      minWidth: 150,
      ...commonColumnDefs,
    },
    {
      field: 'loadNumber',
      headerName: 'Load #',
      minWidth: 150,
      ...commonColumnDefs,
    },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice #',
      minWidth: 150,
      ...commonColumnDefs,
    },
    {
      field: 'charges',
      headerName: 'Charges',
      minWidth: 150,
      valueFormatter: (params: { value: number }) => {
        return formatAmount(params.value);
      },
      ...commonColumnDefs,
    },
    {
      field: 'advances',
      headerName: 'Advances',
      minWidth: 150,
      valueFormatter: (params: { value: number }) => {
        return formatAmount(params.value);
      },
      ...commonColumnDefs,
    },
    {
      field: 'payments',
      headerName: 'Payments',
      minWidth: 150,
      valueFormatter: (params: { value: number }) => {
        return formatAmount(params.value);
      },
      ...commonColumnDefs,
    },
    {
      field: 'checkNumber',
      headerName: 'Check',
      minWidth: 150,
      ...commonColumnDefs,
    },
    {
      field: 'checkDate',
      headerName: 'Check Date',
      minWidth: 150,
      valueFormatter: dateFormatter,
      filter: 'agDateColumnFilter',
      enableRowGroup: true,
      filterParams: {
        ...filterParams,
        comparator: dateComparator,
      },
      cellStyle: agGridCellStyle,
    },
  ];

  return (
    <Stack class={cls.carrierTabGridContainer} spacing={2}>
      <Box displayRaw="flex" alignItems="center" justifyContent="end">
        <Button
          startIcon={<img src={ExcelSheetIcon} alt="Excel Sheet" />}
          variant="contained"
          label="Export to Excel"
          onClick={() =>
            gridRef.api.exportDataAsExcel({
              fileName: 'FactoringCompanyLoadHistory.xlsx',
            })
          }
        />
      </Box>
      <Box class="ag-theme-alpine flex-1" position="relative">
        <AgGridSolid
          ref={(ref) => (gridRef = ref)}
          overlayNoRowsTemplate="No data available"
          overlayLoadingTemplate={atgLogo}
          columnDefs={columnDefs}
          rowData={factoringCompanyLoadHistoryData()}
          gridOptions={{
            groupDisplayType: 'groupRows',
            defaultColDef: {
              flex: 1,
              filter: true,
            },
            pagination: true,
            suppressPaginationPanel: false,
            rowGroupPanelShow: 'always',
            sideBar: {
              toolPanels: [columnsToolPanel, filtersToolPanel],
            },
            groupRowRendererParams: {
              innerRenderer: (params: {
                node: {
                  allLeafChildren: {
                    data: FactoringCompanyDetailsLoadHistory;
                  }[];
                  level: number;
                  key: string;
                };
              }) => {
                const groupLevel = params.node.level;
                let customText;

                const calculateSum = (
                  key: 'charges' | 'advances' | 'payments',
                ) =>
                  params.node.allLeafChildren.reduce(
                    (acc, curr) => acc + curr.data[key],
                    0,
                  );

                switch (groupLevel) {
                  case 0:
                    customText = `${params.node.key} - Charges: ${formatAmount(
                      calculateSum('charges'),
                    )} - Advances: ${formatAmount(
                      calculateSum('advances'),
                    )} - Payments: ${formatAmount(calculateSum('payments'))}`;
                    break;
                  case 1:
                    customText = `${params.node.key}`;
                    break;
                  default:
                    customText = `Grouped by: ${params.node.key}`;
                }

                return `${customText}`;
              },
            },
            onGridReady: async (gridParams) => {
              if (!params.id) return;
              gridParams.api.showLoadingOverlay();
              const res = await fetchFactoringCompanyDetailsLoadHistory(
                params.id,
              );
              if (res) setFactoringCompanyLoadHistoryData(res);
              gridParams.api.hideOverlay();
            },
          }}
        />
      </Box>
    </Stack>
  );
};
