import {
  equipmentTypes,
  intermodalEquipmentTypes,
  loadModesTypes,
} from '@common/commonLists';
import { BasicModal } from '@components';
import { RadioInput, SelectField } from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { addNewLoadToOrder, orderStore, updateOrderState } from '@store/orders';
import { defaultLoadEditViewModel } from '@store/orders/defaultVals';
import { Box } from '@suid/material';
import { printLog } from '@utils/utils';
import { createEffect } from 'solid-js';
import * as yup from 'yup';

type TabActionFormProps = {
  onClose: () => void;
  onSubmit: (formData: AddNewCarrier) => void;
};

export const AddCarrierModalId = 'AddCarrierModal';

export type AddNewCarrier = {
  modeType: string;
  equipmentType: string;
  bidType: string;
};

const AddNewCarrier = (props: TabActionFormProps) => {
  const { form, data, setFields, errors } = createForm<AddNewCarrier>({
    initialValues: {
      modeType: '',
      equipmentType: '',
      bidType: '',
    },
    extend: validator({
      schema: yup.object().shape({
        modeType: yup.string().required('Mode Type is required'),
        equipmentType: yup.string().required('Equipment Type is required'),
        bidType: yup.string().required('Bid Type is required'),
      }),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = (values: AddNewCarrier) => {
    printLog(values, 'values');
    props.onClose();
    props.onSubmit(values);
    addNewLoadToOrder({
      ...defaultLoadEditViewModel,
      cargoValueIsHigh: undefined,
      mode: values.modeType,
      equipment: values.equipmentType,
      customerId: orderStore.order.customerId,
      // fix this when api is fixed
      // bidType: values.bidType,
    });
    updateOrderState({ bidType: values.bidType });
    setFields({
      modeType: '',
      equipmentType: '',
      bidType: '',
    });
  };
  createEffect(() => {
    if (data().modeType && data().equipmentType && data().bidType) {
      handleSubmit(data() as AddNewCarrier);
    }
  });
  const style = {
    header: true,
    footer: true,
    width: '300px',
    onCloseText: 'Cancel',
    onSubmitText: 'Submit',
    modalStyles: {
      backgroundColor: 'white',
    },
    footerContainerClass: 'flex justify-end items-center gap-2 flex-wrap',
    closeButtonVariant: 'outlined',
    showClose: true,
  };

  return (
    <BasicModal
      id={AddCarrierModalId}
      title="Select a Mode"
      onClose={props.onClose}
      showClose={false}
      footer={false}
      modalStyles={style}
    >
      <form ref={form}>
        <Box class="flex flex-col gap-y-4">
          <SelectField
            label="Mode Types"
            menuItems={loadModesTypes.map((mode) => {
              return {
                label: mode,
                value: mode,
              };
            })}
            value={data().modeType}
            onChange={(event) => setFields('modeType', event.target.value)}
            error={errors().modeType}
          />
          <SelectField
            label="Equipment Types"
            menuItems={
              data().modeType === 'Intermodal'
                ? intermodalEquipmentTypes
                : equipmentTypes
            }
            value={data().equipmentType}
            onChange={(event) => setFields('equipmentType', event.target.value)}
            error={errors().equipmentType}
          />
          <RadioInput
            name="bidType"
            label="Bid Type"
            direction="horizontal"
            value={data().bidType}
            options={[
              { label: 'Spot Market', value: 'Spot Market' },
              { label: 'Contract', value: 'Contract' },
            ]}
            onChange={(e) => {
              setFields('bidType', e.target.value);
            }}
            error={errors().bidType}
          />
        </Box>
      </form>
    </BasicModal>
  );
};

export default AddNewCarrier;
