import { orderStore } from '@store/orders';
import { Stack } from '@suid/material';
import { Component } from 'solid-js';

import { StopsList } from './StopsList';
import { StopListContainerProps } from './types';

export const StopListContainer: Component<StopListContainerProps> = (
  props: StopListContainerProps,
) => {
  const { setAddressFromStopList } = props;
  return (
    <Stack spacing={1}>
      <StopsList
        heading="Top 10 Stops"
        stops={orderStore.topStops}
        setAddressFromStopList={setAddressFromStopList}
        customerId={orderStore.order.customerId}
      />
      <StopsList
        heading="Last 10 Stops"
        stops={orderStore.lastStops}
        setAddressFromStopList={setAddressFromStopList}
        customerId={orderStore.order.customerId}
      />
    </Stack>
  );
};
