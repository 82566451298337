export const zipCodePatternA = new RegExp('^[0-9]{5}$');
export const zipCodePatternB = new RegExp('^[a-zA-Z][a-zA-Z0-9]{5}$');

export const checkValidZipCode = (zipCode: string) => {
  const trimmedZipCode = zipCode.trim();
  return (
    zipCodePatternA.test(trimmedZipCode) ||
    zipCodePatternB.test(trimmedZipCode.replace(/\s/g, ''))
  );
};
