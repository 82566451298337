import { fetchCustomerContracts, updateCustomerContractCall } from './services';
import { customerContractStore, setCustomerContract } from './store';

const getCustomerContracts = async (customerId: string) => {
  const resp = await fetchCustomerContracts(customerId, () =>
    setCustomerContract('isLoading', false),
  );
  resp && setCustomerContract('customerContract', resp);
};

const updateCustomerContract = async () => {
  customerContractStore.customerContract &&
    (await updateCustomerContractCall(customerContractStore.customerContract));
};

export { getCustomerContracts, updateCustomerContract };
