import { TextField } from '@suid/material';
interface NumericInputProps {
  value: unknown;
  label: string;
  name?: string;
  error: string[] | null | undefined;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent) => void;
  disabled?: boolean;
}
export const NumericInput = (props: NumericInputProps) => {
  return (
    <TextField
      fullWidth
      disabled={props.disabled}
      type="number"
      size={'small'}
      name={props.name}
      label={props.label}
      error={Boolean(props.error)}
      required={props.required}
      helperText={props.error}
      onBlur={(e) => {
        props.onBlur?.(e);
      }}
      //@ts-expect-error onChange is the worst type
      onChange={(e) => props.onChange?.(e)}
      sx={{
        '& .MuiInputLabel-root.Mui-focused': {
          fontWeight: 600, // Font weight when focused
        },
        '& .MuiInputLabel-root.MuiFormLabel-filled:not(.Mui-disabled):not(.Mui-error)':
          {
            fontWeight: 600, // Font weight when there is a value
            color: '#123b50',
          },
        '& label': {
          fontSize: '14px',
        },
        '& input': {
          fontSize: '14px',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '1px',
            borderColor: '#80b6cf',
            fontSize: '14px',
            color: '#123b50',
            fontWeight: 600,
          },
        },
      }}
      value={props.value}
    />
  );
};
