import { createStore, produce } from 'solid-js/store';

import { DialogState } from './types';

export const [dialogBoxStore, setDialogBoxStore] = createStore<DialogState>({
  dialogIds: [],
});

export const openDialogBox = (dialogId: string) => {
  setDialogBoxStore(
    produce((draft) => {
      draft.dialogIds.push(dialogId);
    }),
  );
};

export const closeDialogBox = (dialogId: string) => {
  setDialogBoxStore(
    produce((draft) => {
      draft.dialogIds = draft.dialogIds.filter((id) => id !== dialogId);
    }),
  );
};

export default { dialogBoxStore, openDialogBox, closeDialogBox };
