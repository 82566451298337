import { Accessor, Component, Show } from 'solid-js';
import { Box, TableCell, TableRow } from '@suid/material';
import { Button, Notification, Typography } from '@components';
import { recommendClassForLoadItem } from '@utils/ltlUtils';
import { PickUpDropOffItem } from '@store/orders';

import { LTLQuotingState } from '../types';

export type SuggestLineItemClassProps = {
  customerFeatureFlagInfo: () => {
    suggestedClass: boolean;
  };
  suggestedClassSettingEnabled: () => boolean;
  data: Accessor<LTLQuotingState>;
  setFields: (field: string, value: unknown) => void;
  index: number;
};

const SuggestLineItemClass: Component<SuggestLineItemClassProps> = (props) => {
  const recommendClass = (item: PickUpDropOffItem) => {
    const suggestedClass = recommendClassForLoadItem(item);
    if (
      suggestedClass !== undefined &&
      (props.customerFeatureFlagInfo().suggestedClass ||
        props.suggestedClassSettingEnabled())
    ) {
      item.suggestedClass = suggestedClass.toString();
    }
    return suggestedClass;
  };

  return (
    <Show
      when={
        (props.customerFeatureFlagInfo().suggestedClass ||
          props.suggestedClassSettingEnabled()) &&
        recommendClass(props.data().loadItems[props.index])
      }
    >
      <TableRow>
        <TableCell colspan={11}>
          <Notification
            text={
              <Box class="flex flex-wrap items-center gap-2">
                <Typography variant="body1" component="p">
                  Carrier ships at actual class based on NMFC Rules, which may
                  differ from the suggested class for density. Based on the
                  dimensions you've entered, the suggested class is Class
                  <strong>
                    {' '}
                    {recommendClass(props.data().loadItems[props.index])}
                  </strong>
                  .
                </Typography>
                <Button
                  label="Use suggested class"
                  onClick={() => {
                    props.setFields(
                      `loadItems.${props.index}.class`,
                      recommendClass(props.data().loadItems[props.index]),
                    );
                  }}
                />
              </Box>
            }
            type="info"
          />
        </TableCell>
      </TableRow>
    </Show>
  );
};

export default SuggestLineItemClass;
