export const mapToComponent: Record<string, string> = {
  customerInformation: '#instructions-and-costs',
  loads: '#order-load-tabs',
  vendor: '#order-load-tabs',
  instructionsAndCosts: '#instructions-and-expected-costs',
  carrierDriverDetails: '#carrier-and-driver-details',
  carrier: '#order-load-tabs',
};

export const mapToTitle: Record<string, string> = {
  customerInformation: 'Customer Information',
  loads: 'Loads Tab',
  vendor: 'Vendor Tab',
  instructionsAndCosts: 'Instructions and Expected Costs',
  carrierDriverDetails: 'Carrier and Driver Details',
  carrier: 'Carrier Tab',
};

export const mapToMessage: Record<string, string> = {
  customerInformation: 'There are errors in the customer information section',
  loads: 'There are errors in one or more loads',
  vendor: 'There are errors in the vendor tab of the load',
  instructionsAndCosts:
    'There are errors in the instruction and expected costs section',
  carrierDriverDetails: 'There is an error with the carrier and driver details',
  carrier: 'There is an error with the carrier tab of the load',
};
