import { Button } from '@components';
import { orderStore } from '@store/orders';
import { Skeleton } from '@suid/material';
import { OpenInNew } from '@suid/icons-material';
import { Show } from 'solid-js';
import { customerPay, totalOrderExpenses } from '@utils/calculateLineItems';
import { formatAmount } from '@utils/utils';
import { orderUrls } from '@constants/urls';

const HeaderInfo = () => {
  const adjustmentsTotal = () => {
    return orderStore.order.adjustments.reduce(
      (acc, curr) => acc + curr.amount,
      0,
    );
  };

  const profit = () => {
    return customerPay() - totalOrderExpenses() + adjustmentsTotal();
  };

  const margin = () => {
    const calcMargin = (profit() / customerPay()) * 100;

    return !isNaN(calcMargin) && calcMargin != Infinity
      ? calcMargin.toFixed(2)
      : 0;
  };

  const revenue = () => {
    return customerPay() + adjustmentsTotal();
  };

  return (
    <Show
      when={!orderStore.loading}
      fallback={
        <div class=" flex justify-end pr-4">
          <Skeleton variant="rectangular" height={20} width={200} />
        </div>
      }
    >
      <div class="hidden small:flex space-x-2 justify-end font-semibold px-4 text-nowrap items-center overflow-hidden">
        <div class="hidden tablet:flex text-xs space-x-2  small-netbook:text-base netbook:text-xl ">
          <div>
            Revenue: {formatAmount(revenue(), { minimumFractionDigits: 2 })}
          </div>
          <div>|</div>
          <div>Margin: {margin()}%</div>
          <div>|</div>
          <div>
            Profit: {formatAmount(profit(), { minimumFractionDigits: 2 })}
          </div>
        </div>
        <div class="hidden tablet:block">
          <Show when={orderStore.order.status === 'Void'}>
            <Button
              disableRipple={true}
              disableElevation={true}
              label="VOID"
              sx={{
                cursor: 'default',
                width: '40px',
                backgroundColor: '#8D001A',
                borderRadius: '0px',
                '&:hover': {
                  backgroundColor: '#8D001A',
                },
                height: '30px',
              }}
            />
          </Show>
        </div>
        <Button
          variant="text"
          disabled={orderStore.isCreate}
          label="View Edit History"
          startIcon={<OpenInNew />}
          sx={{ width: '175px' }}
          color="secondary"
          onClick={() => {
            if (!orderStore.isCreate) {
              window.open(orderUrls.editHistory(orderStore.order.id), '_blank');
            }
          }}
        />
      </div>
    </Show>
  );
};

export default HeaderInfo;
