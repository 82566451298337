import { apiClient, v3Client, v3ODataClient } from '@api/apiClient';
import { get } from 'lodash';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import {
  AddressBook,
  AddressBookModel,
  AddressBookResponse,
  FilteredAddressBook,
  NewAddressResponse,
} from './types';
import { setAddressBookStore } from './store';

export async function fetchAddressBookData(customerId: string) {
  try {
    const response: AddressBookResponse = await v3ODataClient.get(
      `CustomerAddresses?%24format=json&%24top=500&%24orderby=Name&%24filter=CustomerId+eq+${customerId}&%24count=true`,
    );
    if ('value' in response) {
      return get(response, 'value');
    }
    return [];
  } catch (error: unknown) {
    throw new Error(`Failed to fetch pricing Data: ${error}`);
  }
}

export const getFilteredAddressBookData = async (
  payload: Partial<FilteredAddressBook>,
) => {
  try {
    const response: {
      addresses: AddressBookModel[];
      totalCount: number;
    } = await apiClient.get('CustomerAddress/getcustomeraddress', {
      params: payload,
    });
    return response;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch Address Book Data');
  }
};

export async function postAddress(payload: AddressBook) {
  try {
    const { cb, ...restPayload } = payload;
    const response: NewAddressResponse = await v3Client.post(
      'CustomerAddressBook',
      restPayload,
    );
    if (response.success) {
      cb && cb('Address Successfully Saved!');
      return response;
    }
    setAddressBookStore('addOrEditErrorMessage', response.message.join(', '));
    handleToast(ToastType.Error, 'Address Failed to save!');

    return response;
  } catch (error: unknown) {
    throw new Error(`Failed to add address: ${error}`);
  }
}

export async function editAddress(payload: AddressBook) {
  try {
    const { cb, ...restPayload } = payload;
    const response: NewAddressResponse = await v3Client.put(
      `CustomerAddressBook/${restPayload.id}`,
      restPayload,
    );
    if (response.success) {
      cb && cb('Address Successfully Updated!');
      return response;
    }
    setAddressBookStore('addOrEditErrorMessage', response.message.join(', '));
    handleToast(ToastType.Error, 'Address Failed to save!');

    return response;
  } catch (error: unknown) {
    throw new Error(`Failed to add address: ${error}`);
  }
}

export async function deleteAddressBook(addressId: string) {
  try {
    const res: { success: boolean } = await v3Client.delete(
      `CustomerAddressBook/${addressId}`,
    );
    if (res.success) {
      handleToast(ToastType.Success, 'Address Successfully Deleted!');
      return res.success;
    }
  } catch (error: unknown) {
    throw new Error(`Failed to delete address: ${error}`);
  }
}
export async function fetchAddressBookById(addressId: string) {
  try {
    const res: AddressBookModel = await v3Client.get(
      `CustomerAddressBook/${addressId}`,
    );
    return res;
  } catch (error: unknown) {
    throw new Error(`Failed to delete address: ${error}`);
  }
}
