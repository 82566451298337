import { NoProfileImage } from '@assets';
import { TextAreaField } from '@components/forms';
import { useParams } from '@solidjs/router';
import {
  addCollectionComment,
  collectionDetailsStore,
  deleteCollectionComment,
  editCollectionComment,
  IComment,
} from '@store/collectionsDashboard';
import { Cancel } from '@suid/icons-material';
import { Box, Grid } from '@suid/material';
import { ConfigManager } from '@utils/ConfigManager';
import { DateTime } from 'luxon';
import { createSignal, For } from 'solid-js';
import { createStore } from 'solid-js/store';

const CustomerCollectionComments = () => {
  const params = useParams();
  const [commentVal, setCommentVal] = createStore<{
    comment: string;
    editingCommentId: number | null;
    editingCommentValue: string;
  }>({
    comment: '',
    editingCommentId: null,
    editingCommentValue: '',
  });
  const [isProcessing, setIsProcessing] = createSignal(false);

  const handleKeyPress = async (e: KeyboardEvent) => {
    const payload = {
      id: Number(params.id),
      comment: commentVal.comment,
      commentType: 'Collections',
      type: 'Customer',
    };

    if (e.key === 'Enter') {
      e.preventDefault();
      setIsProcessing(true);
      await addCollectionComment(payload, () => {
        setIsProcessing(false);
        setCommentVal('comment', '');
      });
    }
  };

  const handleEditComment = (id: number, comment: string) => {
    setCommentVal('editingCommentId', id);
    setCommentVal('editingCommentValue', comment);
  };

  const handleEditKeyDown = async (e: KeyboardEvent, id: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const updatePayload = {
        id,
        comment: commentVal.editingCommentValue,
        objectId: Number(params.id),
        type: 'Customer',
      };
      await editCollectionComment(updatePayload, () => {
        setCommentVal('editingCommentId', null);
        setCommentVal('editingCommentValue', '');
      });
    }
  };

  const CommentCard = (props: { commentData: IComment }) => {
    const { id, comment } = props.commentData;
    return (
      <Box>
        <Box class="flex bg-[#006fa0] text-white p-3 rounded-t-md">
          {props.commentData.name}
          <Box class="ml-auto">
            <Cancel
              class="cursor-pointer"
              onClick={() => deleteCollectionComment(props.commentData.id)}
            />
          </Box>
        </Box>
        <Box class="p-4 border-2 border-t-0 rounded-b-md flex overflow-auto">
          <img
            src={
              Boolean(props.commentData.thumbnail)
                ? `${ConfigManager.domainUrl}${props.commentData.thumbnail}`
                : NoProfileImage
            }
            alt="Profile Image"
            class={`${
              Boolean(props.commentData.thumbnail)
                ? 'h-[50px] w-[50px] rounded-full'
                : 'w-[50px] h-[50px]'
            } `}
          />
          <Box class="flex flex-col ml-4 w-full">
            <span class="inline-flex ml-auto font-semibold">
              {DateTime.fromISO(props.commentData.timestamp).toFormat(
                'MMMM d, yyyy h:mm a',
              )}
            </span>
            <Grid container>
              <Grid item xs={12} sx={{ marginTop: '5px', marginLeft: '5px' }}>
                {commentVal.editingCommentId === id ? (
                  <TextAreaField
                    rows={3}
                    value={commentVal.editingCommentValue}
                    onChange={(value: string) =>
                      setCommentVal('editingCommentValue', value)
                    }
                    onKeyPress={(e) => handleEditKeyDown(e, id)}
                    onBlur={() => setCommentVal('editingCommentId', null)}
                  />
                ) : (
                  <div onClick={() => handleEditComment(id, comment)}>
                    {comment}
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box class="m-[10px] p-[10px] border bg-[#ffffff] max-h-[500px] overflow-auto">
      <TextAreaField
        label=""
        rows={2}
        value={commentVal.comment}
        placeholder="Press Enter to Save"
        onChange={(value) => setCommentVal('comment', value)}
        onKeyPress={handleKeyPress}
        class="!border-[#a5a5a5]"
        disabled={isProcessing()}
      />
      <Grid container spacing={2} class="!mt-2">
        <For each={collectionDetailsStore.collectionComments}>
          {(comment) => (
            <Grid item xs={4}>
              <CommentCard commentData={comment} />
            </Grid>
          )}
        </For>
      </Grid>
    </Box>
  );
};

export default CustomerCollectionComments;
