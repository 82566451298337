import { apiClient } from '@api/apiClient';
import { createError } from '@utils/errorUtils';

import { EditHistory } from './types';

export async function getEditHistory(id: string): Promise<EditHistory[]> {
  try {
    const response = await apiClient.get(`/order/history/${id}`);
    if ('value' in response) {
      return response.value as EditHistory[];
    }
    throw new Error('Failed to fetch edit history');
  } catch (error: unknown) {
    throw createError(error, 'Failed to fetch edit history');
  }
}
