import { LTLTerminal } from '@store/ltl';
import { Box, Grid } from '@suid/material';
import { StackCard } from '@views/order/components/header/StackCard';
export const TerminalDestinationCard = (props: LTLTerminal) => {
  return (
    <Box class="bg-[#0288D114] p-4 overflow-hidden">
      <StackCard title="Name" content={props.terminalName ?? '-'} />
      <StackCard title="Address 1" content={props.address1 ?? '-'} />
      <StackCard title="Address 2" content={props.address2 ?? '-'} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StackCard title="City" content={props.city ?? '-'} />
        </Grid>
        <Grid item xs={3}>
          <StackCard title="State" content={props.stateOrProvince ?? '-'} />
        </Grid>
        <Grid item xs={3}>
          <StackCard title="Zip" content={props.postalCode ?? '-'} />
        </Grid>
      </Grid>
      <StackCard title="Contact Name" content={props.contactName ?? '-'} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StackCard title="Phone Number" content={props.contactPhone ?? '-'} />
        </Grid>
        <Grid item xs={6}>
          <StackCard title="Toll Free" content={props.contactTollFree ?? '-'} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <StackCard title="Fax" content={props.contactFax ?? '-'} />
        </Grid>
        <Grid item xs={6}>
          <StackCard title="Email" content={props.contactEmail ?? '-'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TerminalDestinationCard;
