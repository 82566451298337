﻿import { SavedQuoteInfo } from '@store/ltl';
import { LTLLoadItem, LTLQuote } from '@store/ltl/types';
import { printLog } from '@utils/utils';

export const MapSavedQuoteToLTLQuote = (quote: SavedQuoteInfo): LTLQuote => {
  return {
    customerName: quote.customerName,
    customerId: quote.customerId ?? 0,
    originZip: quote.originZip,
    originCity: quote.originCity,
    originState: quote.originState,
    originCountry: 'USA',
    destinationCity: quote.destinationCity,
    destinationState: quote.destinationState,
    destinationZip: quote.destinationZip,
    destinationCountry: 'USA',
    ltlQuote: {
      ...quote,
      rateRequest: quote.requestInfo,
      //NOTE: populating patially ltlInsurance object so that reuqoting vals work.
      ltlInsurance: {
        shippingSumInsured: quote.declaredValue ?? 0,
        quotePremium: quote.quotePremium ?? 0,
        quoteServiceFee: quote.quoteServiceFee ?? 0,
      },
    },
    loadItems: quote.lineItems.map((item) => {
      printLog('MapSavedQuoteToLTLQuote', quote.lineItems);
      return {
        nmfc: item.nMFC,
        class: Number(item.class),
        item: item.type ?? (item.item || 'Pallets'),
        type: item.type ?? (item.item || 'Pallets'),
        description: item.description,
        quantity: item.quantity,
        charge: item.charge,
        weight: item.weight,
        height: item.height,
        length: item.length,
        width: item.width,
        volume: item.volume,
        uom: 'lbs',
        hazmat: item.hazmat,
        heightInch: item.heightInch ?? item.height,
        lengthInch: item.lengthInch ?? item.length,
        widthInch: item.widthInch ?? item.width,
        pieces: item.pieces,
        loadItemHazmatDetail: item.loadItemHazmatDetail || null,
        suggestedClass: item.suggestedClass ?? null,
        hazmatItems: item.hazmatItems ?? null,
        hazmatDescription: item.hazmatDescription ?? null,
        density: item.density ?? '',
      } as LTLLoadItem;
    }),
  };
};
