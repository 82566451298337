import { LTL } from './LTL';
import { SavedQuotes } from './SavedQuotes';
export const LTLRoute = {
  path: '/ltl',
  component: LTL,
};

export const SavedQuoteRoute = {
  path: '/savedQuotes',
  component: SavedQuotes,
};

export * from './constants';
