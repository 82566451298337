import { Buckets, Invoices } from '@store/collectionsDashboard';
import { Box, Grid, Typography } from '@suid/material';
import { DateTime } from 'luxon';
import { For, Show } from 'solid-js';

type HistoryInvoiceProps = {
  invoice: Invoices;
  bucket: Buckets;
};

const HistoryInvoice = (props: HistoryInvoiceProps) => {
  const { bucket, invoice } = props;

  const hasBucketActions = (bucket: Buckets, invoice: Invoices) => {
    return bucket.actions.filter((x) => x.orderId == invoice.id).length > 0;
  };

  const matchingActions = (bucket: Buckets, invoice: Invoices) => {
    const actions = bucket.actions.filter((x) => x.orderId === invoice.id);
    const actionDates = [...new Set(actions.map((x) => x.date))].reverse();

    const matchingActions: {
      dateActions: string[];
      comment: string;
      user: string;
      date: string;
    }[] = [];

    actionDates.forEach((element) => {
      const matches = actions.filter((x) => x.date === element);
      let lastComment = '';
      matches.forEach((match) => {
        const currentComment = match.comment;
        if (currentComment !== lastComment) {
          matchingActions.push({
            dateActions: matches
              .filter((x) => x.action && x.comment === currentComment)
              .map((x) => x.action),
            comment: match.comment,
            user: match.user,
            date: match.date,
          });
          lastComment = currentComment;
        }
      });
    });
    return matchingActions;
  };

  return (
    <Box
      sx={{ margin: 1 }}
      class={
        Boolean(hasBucketActions(bucket, invoice))
          ? 'border m-[10px] p-[10px]'
          : undefined
      }
    >
      <Show when={Boolean(hasBucketActions(bucket, invoice))}>
        <For each={matchingActions(bucket, invoice)}>
          {(rowItem: {
            dateActions: string[];
            comment: string;
            user: string;
            date: string;
          }) => (
            <Grid container gap={2} width={'100%'}>
              <Grid item xs={1}>
                <Typography class="!font-medium">{bucket.bucket}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography class="!font-medium">
                  {DateTime.fromISO(rowItem.date).toFormat('MM/dd/yyyy')}
                </Typography>
                <Typography>
                  <For each={rowItem.dateActions}>
                    {(action) => (
                      <span class="text-sm">
                        {action}
                        <br />
                      </span>
                    )}
                  </For>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <p>{rowItem.comment}</p>
                <Typography class="!font-medium !text-sm">
                  {rowItem.user} -{' '}
                  {DateTime.fromISO(rowItem.date).toFormat(
                    'MMM d, yyyy, h:mm a',
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
        </For>
      </Show>
    </Box>
  );
};

export default HistoryInvoice;
