import { closeModal, modalStore } from '@store/modals';

import Modal, { type BasicModalProps } from './Modal';

type ModalProps = Omit<BasicModalProps, 'showModal'>;

export const BasicModal = (props: ModalProps) => {
  const onModalClose = () => {
    if (props.onClose) {
      props.onClose();
    }

    closeModal(props.id);
  };

  return (
    <Modal
      showModal={modalStore.modalIds.includes(props.id)}
      onClose={onModalClose}
      {...props}
    />
  );
};
