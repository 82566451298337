import { FactoringCompany } from './FactoringCompany';
import { FactoringCompanyDetails } from './factoringCompanyDetails';

const FactoringCompanyRoute = {
  path: '/FactoringCompany',
  component: FactoringCompany,
};

const FactoringCompanyDetailsRoute = {
  path: '/FactoringCompany/details/:id',
  component: FactoringCompanyDetails,
};

const FactoringCompanyDetailsCreationRoute = {
  path: '/FactoringCompany/details/',
  component: FactoringCompanyDetails,
};

export {
  FactoringCompanyRoute,
  FactoringCompanyDetailsRoute,
  FactoringCompanyDetailsCreationRoute,
};
