import { Layout } from '@layouts/index';
import { Box, Stack } from '@suid/material';
import { createEffect, Show } from 'solid-js';
import { useParams } from '@solidjs/router';
import { CarrierDetailsHeaderSkeleton } from '@views/carriers/carrierDetails/components';
import {
  fetchDocumentAction,
  fetchOfficeDetailDataAction,
  fetchRecruitersListAction,
} from '@store/office/officeDetails/actions';
import {
  officeDetailStore,
  resetOfficeDetail,
  setOfficeDetailStore,
} from '@store/office/officeDetails';

import { OfficeDetailHeader, OfficeDetailsContent } from './components';

export const OfficeDetailContainer = () => {
  const params = useParams();

  createEffect(() => {
    const fetchData = async () => {
      await resetOfficeDetail();
      if (params.id) {
        await Promise.all([
          fetchOfficeDetailDataAction(params.id),
          fetchDocumentAction(params.id),
          fetchRecruitersListAction(),
        ]);
        setOfficeDetailStore('isLoading', false);
      } else {
        await fetchRecruitersListAction();
      }
    };

    void fetchData();
  });

  return (
    <Layout>
      <Stack class="bg-[#E1ECF2] h-[100vh] overflow-scroll">
        <Box class="bg-white" id="customer-detail-page-header">
          <Show
            when={!officeDetailStore.isLoading}
            fallback={<CarrierDetailsHeaderSkeleton />}
          >
            <OfficeDetailHeader id={params.id} />
          </Show>
        </Box>
        <Box class="m-4 p-2 bg-white mb-[70px] max-md:mb-[80px]">
          <OfficeDetailsContent />
        </Box>
      </Stack>
    </Layout>
  );
};
