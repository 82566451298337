import { apiClient } from '@api/apiClient';
import { Button, DatePicker, Notification } from '@components';
import { TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { useNavigate } from '@solidjs/router';
import { closeModal } from '@store/modals';
import { IOrderViewModel, copyOrder } from '@store/orders';
import AddIcon from '@suid/icons-material/Add';
import DeleteIcon from '@suid/icons-material/Delete';
import {
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@suid/material';
import { routeOrderDetails } from '@utils/routeOrderDetails';
import { DateTime } from 'luxon';
import { Index, Show, createSignal } from 'solid-js';

type formItems = {
  referenceNumber: string;
  pickUpDate: string;
  pickUpTime: string;
  pickUpNumber: string;
  dropDate: string;
  dropTime: string;
  dropNumber: string;
  orderId: number;
};
type formState = { lineItems: formItems[] };
const defaultVals = {
  referenceNumber: '',
  pickUpDate: '',
  pickUpTime: '',
  pickUpNumber: '',
  dropDate: '',
  dropTime: '',
  dropNumber: '',
  orderId: 0,
};

export const copyMultipleModalId = 'CopyMultipleModal';

export const CopyMultipleModal = () => {
  const navigate = useNavigate();
  const [apiResponseError, setApiResponseError] = createSignal<string | null>(
    null,
  );
  const { form, setFields, data } = createForm<formState>({
    onSubmit: () => {
      data().lineItems.forEach(async (lineItem, ind) => {
        const order = await copyOrder(true);
        if (order.loads[0].stops) {
          const destIndex = order.loads[0].stops.length - 1;
          order.referenceNumber = lineItem.referenceNumber;
          order.loads[0].stops[0].stopDateTime = lineItem.pickUpDate;
          order.loads[0].stops[0].referenceNum = lineItem.pickUpNumber;
          order.loads[0].stops[0].time = lineItem.pickUpTime;
          order.loads[0].stops[destIndex].stopDateTime = lineItem.dropDate;
          order.loads[0].stops[destIndex].referenceNum = lineItem.dropNumber;
          order.loads[0].stops[destIndex].time = lineItem.dropTime;
          try {
            const response: IOrderViewModel = await apiClient.post(
              'order',
              order,
            );
            setFields(`lineItems.${ind}.orderId`, response.id);
          } catch (error) {
            const errorResponse = error as {
              response: { data: { errors: [{ errorMessage: string }] } };
            };
            const errorMessage = errorResponse.response.data.errors
              .map((errorObj) => errorObj.errorMessage)
              .join(' , ');
            setApiResponseError(errorMessage);
          }
        }
      });
    },
    initialValues: {
      lineItems: [defaultVals],
    },
  });

  return (
    <form ref={form}>
      <div class="flex justify-center">
        <div class="font-semibold text-xl">Copy Order</div>
      </div>
      <Notification
        type="error"
        text={
          'Do not use this tool to copy loads with multiple stops or if there is more than one carrier'
        }
      />
      <Divider />
      <div>
        <div class="text-xl font-medium">Errors</div>
        <Show when={apiResponseError()}>
          <Notification type="error" text={apiResponseError()} />
        </Show>
      </div>
      <TableContainer>
        <Table sx={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Edit</TableCell>
              <TableCell>Reference #</TableCell>
              <TableCell>Pick Up Date *</TableCell>
              <TableCell>Pick Up Time </TableCell>
              <TableCell>Pick Up #</TableCell>
              <TableCell>Drop Date *</TableCell>
              <TableCell>Drop Time</TableCell>
              <TableCell>Drop #</TableCell>
              <TableCell>Order #</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Index each={data().lineItems}>
              {(_, index) => {
                return (
                  <TableRow>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          setFields('lineItems', [
                            ...data().lineItems.slice(0, index),
                            ...data().lineItems.slice(index + 1),
                          ]);
                        }}
                      >
                        <DeleteIcon class="text-[#B00020] cursor-pointer" />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <TextInput
                        label=""
                        onChange={(e) => {
                          setFields(
                            `lineItems.${index}.referenceNumber`,
                            e as string,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePicker
                        required
                        value={data().lineItems[index].pickUpDate}
                        handleChange={(value: string) => {
                          setFields(
                            `lineItems.${index}.pickUpDate`,
                            DateTime.fromISO(value).toFormat('yyyy-MM-dd'),
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextInput
                        label=""
                        onChange={(e) => {
                          setFields(
                            `lineItems.${index}.pickUpTime`,
                            e as string,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextInput
                        label=""
                        onChange={(e) => {
                          setFields(
                            `lineItems.${index}.pickUpNumber`,
                            e as string,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <DatePicker
                        required
                        value={data().lineItems[index].dropDate}
                        handleChange={(value: string) => {
                          setFields(
                            `lineItems.${index}.dropDate`,
                            DateTime.fromISO(value).toFormat('yyyy-MM-dd'),
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextInput
                        label=""
                        onChange={(e) => {
                          setFields(`lineItems.${index}.dropTime`, e as string);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextInput
                        label=""
                        onChange={(e) => {
                          setFields(
                            `lineItems.${index}.dropNumber`,
                            e as string,
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        label={
                          data().lineItems[index].orderId
                            ? data().lineItems[index].orderId.toString()
                            : ''
                        }
                        disabled={data().lineItems[index].orderId === 0}
                        sx={{ minHeight: '50px', minWidth: '100px' }}
                        onClick={() => {
                          const isNative = routeOrderDetails(
                            data().lineItems[index].orderId,
                          );
                          if (isNative) {
                            navigate(
                              `/order/details/${
                                data().lineItems[index].orderId
                              }`,
                            );
                            closeModal(copyMultipleModalId);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              }}
            </Index>
            <TableRow>
              <TableCell>
                <IconButton
                  size="small"
                  onClick={() => {
                    setFields('lineItems', [...data().lineItems, defaultVals]);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        sx={{
          width: '100%',
          marginTop: '15px',
        }}
        type="submit"
        variant="contained"
        label="Create"
      />
    </form>
  );
};
