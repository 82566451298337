import { createStore } from 'solid-js/store';

import { OrderHistoryState } from './types';

const [orderHistoryStore, setOrderHistoryStore] =
  createStore<OrderHistoryState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    items: [],
  });

export { orderHistoryStore, setOrderHistoryStore };
