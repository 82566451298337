import {
  factoringCompanyDetailsStore,
  setFactoringCompanyDetailsStore,
} from '@store/factoringCompany/factoringCompanyDetails';
import { ValidationError } from 'yup';
import { unwrap } from 'solid-js/store';

import { factoringCompanyDetailsSchema } from './validation';

export const handleFactoringCompanyDetailsValidation = () => {
  try {
    setFactoringCompanyDetailsStore('factoringCompanyDetailsErrors', null);
    factoringCompanyDetailsSchema.validateSync(
      factoringCompanyDetailsStore.factoringCompanyDetails,
      {
        abortEarly: false,
      },
    );
    return true;
  } catch (validationError: unknown) {
    const errors: Record<string, string[]> = {};
    if (validationError instanceof ValidationError) {
      validationError.inner.forEach((error: ValidationError) => {
        if (error.path !== undefined) {
          errors[error.path] = error.errors;
        }
      });
      setFactoringCompanyDetailsStore('factoringCompanyDetailsErrors', errors);
      return false;
    }
  }
};

export const validateFieldForFactoring = async (
  validatePath: string,
  errorPath: string,
) => {
  const updateErrors = (error: unknown) => {
    const updatedErrors = {
      ...(unwrap(factoringCompanyDetailsStore.factoringCompanyDetailsErrors) ||
        {}),
      [errorPath]: error,
    };
    if (error === undefined || error === null) delete updatedErrors[errorPath];
    setFactoringCompanyDetailsStore(() => ({
      factoringCompanyDetailsErrors: updatedErrors as Record<string, string[]>,
    }));
  };
  try {
    await factoringCompanyDetailsSchema.validateAt(
      validatePath,
      factoringCompanyDetailsStore.factoringCompanyDetails,
    );
    updateErrors(undefined);
  } catch (validationError: unknown) {
    if (validationError instanceof ValidationError) {
      updateErrors(validationError.errors);
    }
  }
};
