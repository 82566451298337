import { itemsClass, LoadItemTypes, UOMList } from '@common/commonLists';
import {
  BasicTable,
  MenuItemType,
  Notification,
  Popover,
  ToastType,
} from '@components';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import {
  HazmatDetails,
  orderStore,
  PickUpDropOffItem,
  saveToLTLCatalog,
} from '@store/orders';
import { intialHazmatDetails } from '@store/orders/defaultVals';
import {
  AddCircleOutlineOutlined,
  Delete,
  Edit,
  Save,
} from '@suid/icons-material';
import { Grid, IconButton, TableCell, TableRow } from '@suid/material';
import { calculateDensity } from '@utils/ltlUtils';
import { handleToast } from '@utils/utils';
import { Accessor, createSignal, Index, JSXElement } from 'solid-js';

import cls from './classes';
import { HazardousMaterial } from './HazardousMaterial';

type LoadItemValidationErrors = {
  quantity?: string[] | null;
  description?: string[] | null;
  weight?: string[] | null;
  length?: string[] | null;
  height?: string[] | null;
  width?: string[] | null;
  lengthInch?: string[] | null;
  heightInch?: string[] | null;
  widthInch?: string[] | null;
  nmfc?: string[] | null;
  pieces?: string[] | null;
};
interface StopsItemsProps {
  arrayName: string;
  mode?: string;
  stopType: string;
  loadItems: PickUpDropOffItem[];
  setFields: (
    arg: string,
    value: string | boolean | number | HazmatDetails | PickUpDropOffItem[],
  ) => void;
  addNewListItem: () => void;
  options: MenuItemType[];
  stopIds: { id: number; locationName: string }[];
  stopId: number;
  errors: Record<number, LoadItemValidationErrors> | null;
}
export const StopItems = (props: StopsItemsProps) => {
  const [isSaving, setIsSaving] = createSignal<boolean>(false);
  const getColumns = () => {
    const prevCommonColumns = [
      { name: '# of Units', headerClasses: 'w-[5%]' },
      { name: 'Unit Type', headerClasses: 'w-[7%]' },
      {
        name: 'Item Description',
        headerClasses: 'w-[15%]',
      },
    ];
    const ltlColumns = [
      ...prevCommonColumns,
      { name: '# of Pieces', headerClasses: 'w-[8%]' },
      {
        name: 'Weight',
        headerClasses: props.mode === 'LTL' ? 'w-[7%]' : 'w-[15%]',
      },
      { name: 'Dimensions (in)', headerClasses: 'w-[16%]' },
      { name: 'NMFC', headerClasses: 'w-[8%]' },
      { name: 'Class', headerClasses: 'w-[5%]' },
      { name: 'Hazmat', headerClasses: 'w-[5%]' },
      {
        name: (
          <span>
            PCF (lb/ft<sup>3</sup>)
          </span>
        ),
        headerClasses: 'w-[5%]',
      },
      {
        name: props.stopType == 'Pick Up' ? 'Going To' : 'Coming From',
        headerClasses: 'w-[15%]',
      },
      { name: 'Action', headerClasses: 'w-[4%]' },
    ];

    const otherModeColumns = [
      ...prevCommonColumns,
      { name: 'Weight', headerClasses: 'w-[10%]' },
      { name: 'Dimensions (in)', headerClasses: 'w-[30%]' },
      { name: 'Hazmat', headerClasses: 'w-[5%]' },
      {
        name: props.stopType === 'Pick Up' ? 'Going To' : 'Coming From',
        headerClasses: 'w-[15%]',
      },
      { name: 'Action', headerClasses: 'w-[5%]' },
    ];

    if (props.mode === 'LTL') {
      return ltlColumns;
    }
    return otherModeColumns;
  };

  const footerRow = [
    {
      renderCell: () => (
        <AddCircleOutlineOutlined
          sx={{ color: '#0070A2', cursor: 'pointer' }}
          onClick={props.addNewListItem}
        />
      ),
      key: 'ideaTitle',
    },
  ];

  const getKeyByValue = (value: number) => {
    return props.stopIds.find((item) => item.id === value)?.id.toString() ?? '';
  };

  const onDeleteClick = (index: number) => {
    const loadItems = [...props.loadItems];
    if (loadItems[index].operationType !== 'Insert') {
      loadItems[index].operationType = 'Delete';
    } else {
      loadItems.splice(index, 1);
    }
    props.setFields(props.arrayName, loadItems);
  };

  const onSaveClick = async (index: number) => {
    setIsSaving(true);
    const item = props.loadItems[index];
    const customerId = orderStore.order.customerId;

    if (!Boolean(customerId)) {
      return;
    }

    try {
      await saveToLTLCatalog(item, customerId ?? 0);
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleItemChanges = (
    path: string,
    value: string | boolean | number | HazmatDetails | null,
    idx: number,
  ) => {
    props.setFields(path, value);
    if (props.loadItems[idx].operationType === 'None') {
      props.setFields(`${props.arrayName}.${idx}.operationType`, 'Update');
    }
  };

  const notificationsErrors = (index: number) => {
    const errors: Partial<LoadItemValidationErrors | null> =
      props.errors && props.errors[index];
    const showNotification: boolean | null =
      errors && Object.keys(errors).some((item) => errors[item] !== null);
    if (!Boolean(showNotification)) return;
    const elements: JSXElement[] = [];
    Object.entries(errors ?? {}).forEach(([_, value]) => {
      if (value !== null) {
        elements.push(<li>{value}</li>);
      }
    });
    return (
      <td colSpan={getColumns().length}>
        <Notification
          type="error"
          text={<ul class="list-disc my-[8px] pl-[24px]">{elements}</ul>}
        />
      </td>
    );
  };

  const dontShowTableRow = (rowData: PickUpDropOffItem) => {
    let dontShow = false;
    if (
      rowData.operationType === 'Delete' ||
      (rowData.pickUpId !== props.stopId && props.stopType === 'Pick Up') ||
      (rowData.dropOffId !== props.stopId && props.stopType === 'Drop Off')
    ) {
      dontShow = true;
    }
    return dontShow;
  };

  const hazmatCheckbox = (
    rowData: Accessor<PickUpDropOffItem>,
    index: number,
  ) => {
    return (
      <CheckboxInput
        label=""
        checked={Boolean(rowData().hazmat)}
        onChange={(val) => {
          handleItemChanges(`${props.arrayName}.${index}.hazmat`, val, index);
          if (!val) {
            handleItemChanges(
              `${props.arrayName}.${index}.loadItemHazmatDetail`,
              null,
              index,
            );
          }
        }}
        sxProps={{
          'MuiFormControlLabel-root': {
            marginRight: '4px',
          },
        }}
      />
    );
  };
  return (
    <BasicTable
      columns={getColumns()}
      footerRow={footerRow}
      sxTable={{
        '& .MuiTableCell-root': {
          padding: '4px',
        },
      }}
    >
      <Index each={props.loadItems}>
        {/*eslint-disable*/}
        {(rowData, index) => (
          <>
            <TableRow
              sx={{
                verticalAlign: "middle",
                ...{
                  display: dontShowTableRow(rowData()) ? "none" : "table-row",
                },
              }}
            >
              <TableCell>
                <TextInput
                  type="number"
                  name={`${props.arrayName}.${index}.quantity`}
                  label=""
                  placeholder=""
                  sxProps={cls.wFull}
                  value={rowData().quantity}
                  onChange={(e) => {
                    handleItemChanges(
                      `${props.arrayName}.${index}.quantity`,
                      e,
                      index,
                    );
                  }}
                  error={props.errors && props.errors[index]?.quantity}
                  noErrorMessage
                  size="small"
                />
              </TableCell>
              <TableCell>
                <SelectField
                  name={`${props.arrayName}.${index}.item`}
                  label=""
                  menuItems={LoadItemTypes}
                  setValueOnKeyPressFeatureOn
                  value={rowData().item}
                  onChange={(e) => {
                    handleItemChanges(
                      `${props.arrayName}.${index}.item`,
                      e.target.value,
                      index,
                    );
                  }}
                  setFitContent
                  size="small"
                />
              </TableCell>
              <TableCell>
                <TextInput
                  name={`${props.arrayName}.${index}.description`}
                  label=""
                  placeholder=""
                  sxProps={cls.wFull}
                  value={rowData().description ?? ""}
                  onChange={(e) => {
                    handleItemChanges(
                      `${props.arrayName}.${index}.description`,
                      e,
                      index,
                    );
                  }}
                  error={props.errors && props.errors[index]?.description}
                  noErrorMessage
                  maxLength={150}
                />
              </TableCell>
              {props.mode === "LTL" ? (
                <TableCell>
                  <TextInput
                    type="number"
                    name={`${props.arrayName}.${index}.pieces`}
                    label=""
                    placeholder=""
                    sxProps={cls.wFull}
                    value={rowData().pieces ?? ""}
                    onChange={(e) => {
                      handleItemChanges(
                        `${props.arrayName}.${index}.pieces`,
                        e,
                        index,
                      );
                    }}
                    error={props.errors && props.errors[index]?.pieces}
                    noErrorMessage
                    maxLength={7}
                  />
                </TableCell>
              ) : null}
              <TableCell>
                <Grid container class="flex" spacing={1}>
                  <Grid item xs={props.mode !== "LTL" ? 6 : 12}>
                    <TextInput
                      type="number"
                      name={`${props.arrayName}.${index}.weight`}
                      label=""
                      placeholder=""
                      sxProps={cls.wFull}
                      value={rowData().weight ?? ""}
                      onChange={(e) => {
                        handleItemChanges(
                          `${props.arrayName}.${index}.weight`,
                          e,
                          index,
                        );
                      }}
                      maxLength={6}
                      error={props.errors && props.errors[index]?.weight}
                      noErrorMessage
                      size="small"
                    />
                  </Grid>
                  {props.mode !== "LTL" ? (
                    <Grid item xs={6}>
                      <SelectField
                        label=""
                        menuItems={UOMList}
                        setValueOnKeyPressFeatureOn
                        value={rowData().uom}
                        onChange={(e) => {
                          handleItemChanges(
                            `${props.arrayName}.${index}.uom`,
                            e.target.value,
                            index,
                          );
                        }}
                        size="small"
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid class="flex">
                  {props.mode !== "LTL" ? (
                    <TextInput
                      name={`${props.arrayName}.${index}.length`}
                      type="number"
                      label=""
                      placeholder="ft"
                      sxProps={cls.wFull}
                      value={rowData().length ?? ""}
                      onChange={(e) => {
                        handleItemChanges(
                          `${props.arrayName}.${index}.length`,
                          e,
                          index,
                        );
                      }}
                      error={props.errors && props.errors[index]?.length}
                      noErrorMessage
                      size="small"
                    />
                  ) : null}
                  <TextInput
                    name={`${props.arrayName}.${index}.lengthInch`}
                    type="number"
                    label=""
                    placeholder={props.mode == "LTL" ? "L" : "in"}
                    sxProps={{
                      ...cls.wFull,
                      ...(props.mode !== "LTL" && { marginLeft: "5px" }),
                    }}
                    value={rowData().lengthInch ?? ""}
                    onChange={(e) => {
                      handleItemChanges(
                        `${props.arrayName}.${index}.lengthInch`,
                        e,
                        index,
                      );
                    }}
                    error={props.errors && props.errors[index]?.lengthInch}
                    noErrorMessage
                    size="small"
                  />

                  <span class={cls.xIcon}>X</span>
                  {props.mode !== "LTL" ? (
                    <TextInput
                      name={`${props.arrayName}.${index}.width`}
                      type="number"
                      label=""
                      placeholder="ft"
                      sxProps={cls.wFull}
                      value={rowData().width ?? ""}
                      onChange={(e) => {
                        handleItemChanges(
                          `${props.arrayName}.${index}.width`,
                          e,
                          index,
                        );
                      }}
                      error={props.errors && props.errors[index]?.width}
                      noErrorMessage
                      size="small"
                    />
                  ) : null}

                  <TextInput
                    name={`${props.arrayName}.${index}.widthInch`}
                    type="number"
                    label=""
                    placeholder={props.mode == "LTL" ? "W" : "in"}
                    sxProps={{
                      ...cls.wFull,
                      ...(props.mode !== "LTL" && { marginLeft: "5px" }),
                    }}
                    value={rowData().widthInch ?? ""}
                    onChange={(e) => {
                      handleItemChanges(
                        `${props.arrayName}.${index}.widthInch`,
                        e,
                        index,
                      );
                    }}
                    error={props.errors && props.errors[index]?.widthInch}
                    noErrorMessage
                    size="small"
                  />
                  <span class={cls.xIcon}>X</span>
                  {props.mode !== "LTL" ? (
                    <TextInput
                      name={`${props.arrayName}.${index}.height`}
                      type="number"
                      label=""
                      placeholder="ft"
                      sxProps={cls.wFull}
                      value={rowData().height ?? ""}
                      onChange={(e) => {
                        handleItemChanges(
                          `${props.arrayName}.${index}.height`,
                          e,
                          index,
                        );
                      }}
                      error={props.errors && props.errors[index]?.height}
                      noErrorMessage
                      size="small"
                    />
                  ) : null}

                  <TextInput
                    name={`${props.arrayName}.${index}.heightInch`}
                    type="number"
                    label=""
                    placeholder={props.mode == "LTL" ? "H" : "in"}
                    sxProps={{
                      ...cls.wFull,
                      ...(props.mode !== "LTL" && { marginLeft: "5px" }),
                    }}
                    value={rowData().heightInch ?? ""}
                    onChange={(e) => {
                      handleItemChanges(
                        `${props.arrayName}.${index}.heightInch`,
                        e,
                        index,
                      );
                    }}
                    error={props.errors && props.errors[index]?.heightInch}
                    noErrorMessage
                    size="small"
                  />
                </Grid>
              </TableCell>
              {props.mode === "LTL" ? (
                <>
                  <TableCell>
                    <TextInput
                      name={`${props.arrayName}.${index}.nmfc`}
                      label=""
                      placeholder=""
                      sxProps={cls.wFull}
                      value={rowData().nmfc ?? ""}
                      onChange={(e) => {
                        handleItemChanges(
                          `${props.arrayName}.${index}.nmfc`,
                          e,
                          index,
                        );
                      }}
                      error={props.errors && props.errors[index]?.nmfc}
                      noErrorMessage
                      maxLength={10}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <SelectField
                      name={`${props.arrayName}.${index}.class`}
                      label=""
                      value={rowData().class?.toString()!}
                      menuItems={itemsClass}
                      onChange={(e) => {
                        handleItemChanges(
                          `${props.arrayName}.${index}.class`,
                          e.target.value,
                          index,
                        );
                      }}
                      size="small"
                    />
                  </TableCell>
                </>
              ) : null}
              <TableCell>
                {
                  <span class={`${props.mode !== "LTL" ? "block" : "hidden"}`}>
                    {hazmatCheckbox(rowData, index)}
                  </span>
                }
                {
                  <span class={`${props.mode === "LTL" ? "block" : "hidden"}`}>
                    <Popover
                      forceClosePopover={!rowData().hazmat}
                      eleName={
                        <div class="flex items-center">
                          {hazmatCheckbox(rowData, index)}
                          <Edit
                            sx={{
                              color: Boolean(rowData().hazmat)
                                ? "black"
                                : "gray",
                            }}
                          />
                        </div>
                      }
                      classes={cls.reminderTxt}
                      sxProps={{
                        backgroundColor: "white",
                        borderRadius: "0",
                        textDecoration: "underline !important",
                      }}
                    >
                      {(closePopover) => (
                        <HazardousMaterial
                          fromSidebar={false}
                          loadItemHazmatDetail={
                            rowData().loadItemHazmatDetail ??
                            intialHazmatDetails
                          }
                          onSave={(hazmatDetail: HazmatDetails) => {
                            handleItemChanges(
                              `${props.arrayName}.${index}.loadItemHazmatDetail`,
                              hazmatDetail,
                              index,
                            );
                            closePopover();
                          }}
                          onClose={closePopover}
                        />
                      )}
                    </Popover>
                  </span>
                }
              </TableCell>
              {props.mode === "LTL" ? (
                <TableCell>
                  <span>{calculateDensity(rowData())}</span>
                </TableCell>
              ) : null}
              <TableCell>
                <SelectField
                  label=""
                  value={
                    getKeyByValue(
                      (props.stopType && props.stopType === "Pick Up"
                        ? rowData().dropOffId
                        : rowData().pickUpId) ?? 0,
                    ) ?? ""
                  }
                  menuItems={props.options}
                  onChange={(e) => {
                    handleItemChanges(
                      `${props.arrayName}.${index}.${
                        props.stopType === "Pick Up" ? "dropOffId" : "pickUpId"
                      }`,
                      props.stopIds.find(
                        (stop) => stop.id === Number(e.target.value),
                      )?.id ?? 0,
                      index,
                    );
                  }}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Grid container gap={0.5} class="flex !flex-nowrap mt-[10px]">
                  {props.mode === "LTL" ? (
                    <IconButton disabled={isSaving()}>
                      <Save
                        sx={{ color: "#1B4960" }}
                        onClick={async () => {
                          await onSaveClick(index);
                        }}
                      />
                    </IconButton>
                  ) : null}
                  <IconButton disabled={isSaving()}>
                    <Delete
                      sx={{ color: "#B00020" }}
                      onClick={() => {
                        onDeleteClick(index);
                      }}
                    />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
            {<TableRow>{notificationsErrors(index)}</TableRow>}
          </>
        )}
      </Index>
    </BasicTable>
  );
};
