import {
  BasicModal,
  BasicTable,
  Button,
  CardPlaceholder,
  ToastType,
  Typography,
} from '@components';
import {
  approveCheckRequest,
  fetchAllPendingChecks,
  getAllCheckRequest,
} from '@store/Global/service';
import {
  CheckRequestType,
  pendingChecksTypes,
  PreviousCheckTypes,
} from '@store/Global/types';
import { closeModal, openModal } from '@store/modals';
import { Box, Stack } from '@suid/material';
import { formatAmount, handleToast } from '@utils/utils';
import { DateTime } from 'luxon';
import { createSignal, onMount, Show } from 'solid-js';

import DenyCheckRequest from './DenycheckRequest';

const Advances = () => {
  const [advancesData, setAdvancesData] = createSignal<pendingChecksTypes[]>(
    [],
  );
  const [requestCheckData, setRequestData] = createSignal<CheckRequestType>();
  const [isLoading, setIsLoading] = createSignal(false);
  const [requestCheckLoading, setRequestCheckLoading] = createSignal(false);

  const advancesColumns = [
    {
      name: 'Load #',
      key: 'loadNum',
    },
    {
      name: 'Agent',
      key: 'requestedBy',
    },
    {
      name: 'Amount',
      key: 'requestedBy',
      renderCell: (row: pendingChecksTypes) => (
        <>{`${formatAmount(row.for)}`}</>
      ),
    },
    {
      name: 'Details',
      key: 'details',
    },
    {
      name: 'Date',
      key: 'requestDate',
      renderCell: (row: pendingChecksTypes) => (
        <>{`${DateTime.fromISO(row.requestDate).toFormat('MM/dd/yyyy')}`}</>
      ),
    },
  ];

  const pendingChecksColumn = [
    {
      name: 'Load',
      key: 'loadNum',
    },
    {
      name: 'Customer',
      key: 'customer',
    },
    {
      name: 'Agent',
      key: 'agent',
    },
    {
      name: 'Loads Completed',
      key: 'loadsCompleted',
    },
  ];

  const previousIssueCheck = [
    {
      name: 'Amount',
      key: 'amount',
      renderCell: (row: PreviousCheckTypes) => (
        <>{`${formatAmount(row.amount)}`}</>
      ),
    },
    {
      name: 'Description',
      key: 'description',
    },
    {
      name: 'Date',
      key: 'paymentDate',
      renderCell: (row: PreviousCheckTypes) => (
        <>{`${
          row.paymentDate
            ? DateTime.fromISO(row.paymentDate).toFormat('MM/dd/yyyy')
            : ''
        }`}</>
      ),
    },
  ];

  const footerRow = [
    {
      renderCell: () => (
        <Typography variant="body1" class="!text-sm !font-bold !text-black">
          <span>Total: </span>
          {`${formatAmount(
            requestCheckData()?.previousChecks.reduce((acc, cur) => {
              return acc + cur.amount;
            }, 0),
          )}/${formatAmount(requestCheckData()?.carrierPay)}`}
        </Typography>
      ),
      key: '#',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
  ];

  const onRowClick = async (row: pendingChecksTypes) => {
    setRequestCheckLoading(true);
    openModal('pending-checks');
    const res = await getAllCheckRequest(row.id);
    if (res) {
      setRequestData(res);
    }
    setRequestCheckLoading(false);
  };

  onMount(async () => {
    setIsLoading(true);
    const res = await fetchAllPendingChecks();
    if (res) {
      setAdvancesData(res);
    }
    setIsLoading(false);
  });

  return (
    <Box class="w-[700px] max-w-full h-[580px] overflow-auto bg-white p-2">
      <Show when={!isLoading()} fallback={<CardPlaceholder />}>
        <Box
          sx={{
            height: 'auto',
            maxHeight: '580px',
            overflowY: 'auto',
            '& .MuiTableHead-root': {
              background: '#F2F2F2',
            },
            '& .MuiTableBody-root': {
              '& .MuiTableRow-root': {
                cursor: 'pointer',
              },
            },
          }}
        >
          <BasicTable<pendingChecksTypes>
            columns={advancesColumns}
            rows={advancesData()}
            onRowClick={onRowClick}
          />
        </Box>
      </Show>
      <BasicModal
        title="Pending Checks"
        id={'pending-checks'}
        onClose={() => {
          closeModal('pending-checks');
        }}
        showClose={false}
        width={'900px'}
        footer={false}
      >
        <Show when={!requestCheckLoading()} fallback={<CardPlaceholder />}>
          <Box
            sx={{
              height: 'auto',
              maxHeight: '580px',
              overflowY: 'auto',
              '& .MuiTableHead-root': {
                background: '#F2F2F2',
              },
            }}
          >
            <Stack spacing={2}>
              <BasicTable<CheckRequestType>
                columns={pendingChecksColumn}
                rows={[requestCheckData() as CheckRequestType]}
              />
              <Typography variant="h6" class="!text-xl !font-bold">
                For: {requestCheckData()?.carrier}
              </Typography>
              <Typography
                variant="h6"
                class="!text-xs !font-normal text-[#808080]"
              >
                Amount Requested:{' '}
                <span class="!text-base !font-bold !text-black">
                  {formatAmount(requestCheckData()?.amount)}
                </span>
              </Typography>
              <Typography variant="h6" class="!text-xl !font-medium">
                Previous Issued Checks
              </Typography>
              <BasicTable<PreviousCheckTypes>
                columns={previousIssueCheck}
                rows={requestCheckData()?.previousChecks}
                footerRow={footerRow}
              />
              <Box class="flex justify-end gap-2">
                <>
                  <Button
                    class="!bg-[#248670] !rounded-sm"
                    variant="contained"
                    onClick={async () => {
                      const response = await approveCheckRequest(
                        requestCheckData()?.requestId as number,
                      );
                      if (response && response.success) {
                        handleToast(
                          ToastType.Success,
                          'Approved check request successfully',
                        );
                        const filteredData = advancesData().filter(
                          (item) => item.id !== requestCheckData()?.requestId,
                        );
                        setAdvancesData(filteredData);
                        setRequestData();
                      } else {
                        handleToast(
                          ToastType.Error,
                          response && response.message
                            ? response.message
                            : 'Failed to approve check request',
                        );
                      }
                    }}
                    type="button"
                    label="Approve"
                  />
                  <Button
                    class="!bg-[#B00020] !rounded-sm"
                    variant="contained"
                    onClick={() => openModal('deny-pending-checks')}
                    type="button"
                    label="Deny"
                  />
                </>
              </Box>
            </Stack>
          </Box>
        </Show>
      </BasicModal>
      <DenyCheckRequest
        requestCheckData={requestCheckData}
        advancesData={advancesData}
        setAdvancesData={setAdvancesData}
      />
    </Box>
  );
};

export default Advances;
