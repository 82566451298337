import { useParams } from '@solidjs/router';
import { orderStore } from '@store/orders';
import { Grid } from '@suid/material';
import {
  CustomerFileUpload,
  CustomerInformation,
  CustomerPayments,
  CustomerUploadFileProps,
  NewATGOnlyNote,
} from '@views/order/components';
import { Show } from 'solid-js';

export type Props = {
  customerFileUploadProps: CustomerUploadFileProps;
  lgBreakpoint: number;
};

export function Customer(props: Props) {
  const params = useParams();

  const isCreate = !params.id;

  return (
    <Grid item md={12} lg={props.lgBreakpoint} width={'100%'}>
      <CustomerInformation />
      <Show when={!isCreate}>
        <CustomerFileUpload {...props.customerFileUploadProps} />
      </Show>
      {!isCreate && (
        <NewATGOnlyNote
          cardTitle="Internal Notes"
          type="Order"
          comments={orderStore.order.comments ?? []}
          loading={orderStore.loading}
        />
      )}
      <CustomerPayments />
    </Grid>
  );
}
