import { Component } from 'solid-js';
import { Grid, Skeleton } from '@suid/material';

import cls from './classes';

export const CarrierDetailsHeaderSkeleton: Component = () => {
  return (
    <Grid
      container
      alignItems="center"
      backgroundColor="white"
      class={cls.headerContainer}
    >
      <Grid item xs={12} md={3.5}>
        <Skeleton variant="rectangular" height={50} width={300} />
      </Grid>
      <Grid item xs={12} md={8.5}>
        <Skeleton variant="rectangular" height={50} width={'100%'} />
      </Grid>
    </Grid>
  );
};
