import { Box, Grid, Stack, Skeleton } from '@suid/material';

export const ProductFormSkeleton = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Skeleton variant="text" width="80%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={118} />
            <Skeleton variant="rectangular" width="60%" height={52} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Skeleton variant="rectangular" width="60%" height={52} />
            <Skeleton variant="text" width="80%" height={60} />
            <Skeleton variant="rectangular" width="100%" height={52} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Skeleton variant="rectangular" width="100%" height={200} />{' '}
            <Skeleton variant="rectangular" width="100%" height={118} />
          </Stack>
        </Grid>
      </Grid>
      <Box displayRaw="flex" justifyContent="flex-end" mt={2} gap={2}>
        <Skeleton variant="rectangular" width={90} height={40} />
        <Skeleton variant="rectangular" width={90} height={40} />
      </Box>
    </Box>
  );
};
