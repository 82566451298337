import { Placeholder } from '@components/Skeleton';
import Person from '@suid/icons-material/Person';
import EmailIcon from '@suid/icons-material/Email';
import FaxIcon from '@suid/icons-material/Fax';
import PhoneIcon from '@suid/icons-material/Phone';
import LockIcon from '@suid/icons-material/Lock';
import BusinessIcon from '@suid/icons-material/Business';
import { Box, Grid, Typography } from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { JSX, Show, mergeProps } from 'solid-js';
import { ContactProps } from '@common/types';

import PointyCheck from '../../assets/PointyCheck.svg';
import UserPointyCheck from '../../assets/UserPointyCheck.svg';
import PointyX from '../../assets/PointyX.svg';
import classes from './classes';

type RowProps = {
  icon: JSX.Element;
  text: string;
  hideIcon?: boolean;
  verificationIcon?: string;
};
const typographyStyle = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
  color: 'rgba(0, 0, 0, 0.6)',
  overflowWrap: 'anywhere',
};

const Row = (props: RowProps) => {
  return (
    <Box class={classes.rowStyle}>
      <Show when={!(props.hideIcon ?? false)}>
        <Box sx={{ '& .MuiSvgIcon-root': { width: 16, height: 'auto' } }}>
          {props.icon}
        </Box>
      </Show>
      <Box
        component={'div'}
        style={{
          'margin-left': props.hideIcon ?? false ? '0px' : '8px',
          ...typographyStyle,
        }}
      >
        {props.text}
      </Box>
      <Box>
        <img src={props.verificationIcon} width={24} />
      </Box>
    </Box>
  );
};
export type AddressCardVerificationProps = {
  verificationStatus: number;
  phoneVerified: boolean;
  emailVerified: boolean;
};

export type AddressCardProps<T> = {
  isCustomerContact?: boolean;
  contactType?: string;
  contact: T | undefined;
  showIcon?: boolean;
  showType?: boolean;
  type?: string;
  mode: 'warning' | 'regular' | 'info';
  iconColor?: string;
  personIconSize?: string;
  isLoading?: boolean;
  isActive?: boolean;
  verificationInfo?: AddressCardVerificationProps;
};

const modeColorMap = {
  regular: {
    normal: 'white',
    hover: '#D2E2ED',
    active: '#EDF3F7',
  },
  warning: {
    normal: '#FFE3BC',
    hover: '#FFD190',
    active: '#FFECD2',
  },
  info: {
    normal: '#E2EDF8',
    hover: '#FFE3BC',
    active: '#FFF3E4',
  },
};

const typePhoneNumber = ['string', 'number'];

export const AddressCard = <T extends ContactProps>(
  props: AddressCardProps<T>,
) => {
  props = mergeProps(
    {
      showIcon: false,
      showType: false,
      type: 'Type',
      mode: 'regular',
      iconColor: '#026EA1',
      isLoading: false,
    },
    props,
  );

  const GetPhoneIcon = () => {
    if (
      props.verificationInfo == undefined ||
      props.contact?.phone1 == undefined ||
      props.contact.phone1.trim() === ''
    )
      return '';
    if (props.verificationInfo.verificationStatus === 2) {
      return PointyCheck;
    } else if (props.verificationInfo.phoneVerified) {
      return UserPointyCheck;
    }
    return PointyX;
  };

  const GetEmailIcon = () => {
    if (
      props.verificationInfo == undefined ||
      props.contact?.email == undefined ||
      props.contact.email.trim() === ''
    )
      return '';
    if (props.verificationInfo.verificationStatus === 2) {
      return PointyCheck;
    } else if (props.verificationInfo.emailVerified) {
      return UserPointyCheck;
    }
    return PointyX;
  };

  const CardPlaceholder = () => {
    return (
      <>
        <Grid container spacing={2} width={200}>
          <Grid item xs={12}>
            <Placeholder variant="text" height={60} />
            <Placeholder variant="rectangular" height={40} />
          </Grid>
        </Grid>
      </>
    );
  };

  const getFormattedPhoneNumber = () => {
    const contact = props.contact;
    if (typePhoneNumber.includes(typeof contact?.phone1)) {
      const phoneNumber = formatPhoneNumber(contact?.phone1);
      const extension = Boolean(contact?.extension1)
        ? ` Ext. ${contact?.extension1}`
        : '';
      return `${phoneNumber} ${extension}`;
    }
    return '-';
  };

  return (
    <Grid
      class={`!w-[100%] p-3 hover:${modeColorMap[props.mode].hover}`}
      sx={{
        ...(Boolean(!(props.isCustomerContact ?? false)) && {
          backgroundColor:
            props.isActive ?? false
              ? modeColorMap[props.mode].active
              : modeColorMap[props.mode].normal,
          '&:hover': {
            backgroundColor: modeColorMap[props.mode].hover,
          },
        }),
      }}
    >
      <Show when={props.isLoading === false} fallback={<CardPlaceholder />}>
        <Grid container class="!m-0 !p-0 !w-[100%]">
          <Show when={props.showIcon}>
            <Grid
              item
              class={`${
                props.personIconSize != null ? 'pr-5' : 'pr-3'
              } flex !pl-0 !pt-0`}
            >
              <Person
                class={classes.personIconStyle}
                sx={{
                  width: props.personIconSize ?? '24px',
                  height: props.personIconSize ?? '24px',
                }}
              />
            </Grid>
          </Show>
          <Grid item class="!p-0">
            <Grid container justifyItems="center" alignItems="center" gap={1}>
              <span>{props.contact?.name ?? '-'}</span>
              {Boolean(props.contactType) && <span>({props.contactType})</span>}
              {Boolean(props.contact?.userName) && (
                <LockIcon sx={{ width: '14px', height: '14px' }} />
              )}
            </Grid>
            <Show
              when={![undefined, null, '', '-'].includes(props.contact?.phone1)}
            >
              <Row
                icon={<PhoneIcon sx={{ color: props.iconColor }} />}
                text={getFormattedPhoneNumber()}
                verificationIcon={GetPhoneIcon()}
              />
            </Show>
            <Show
              when={![undefined, null, '', '-'].includes(props.contact?.email)}
            >
              <Row
                icon={<EmailIcon sx={{ color: props.iconColor }} />}
                text={props.contact?.email ?? '-'}
                verificationIcon={GetEmailIcon()}
              />
            </Show>
            <Show
              when={![undefined, null, '', '-'].includes(props.contact?.fax)}
            >
              <Row
                icon={<FaxIcon sx={{ color: props.iconColor }} />}
                text={
                  typePhoneNumber.includes(
                    typeof (props.contact && props.contact.fax),
                  )
                    ? formatPhoneNumber(props.contact?.fax ?? '')
                    : '-'
                }
              />
            </Show>
            <Show
              when={![undefined, null, '', '-'].includes(props.contact?.office)}
            >
              <Row
                icon={<BusinessIcon sx={{ color: props.iconColor }} />}
                text={props.contact?.office ?? '-'}
              />
            </Show>
          </Grid>
          <Grid xs={3} class="!ml-auto">
            <Show when={props.showType}>
              <Box class={classes.typeBoxStyle}>
                <Typography>{props.type}</Typography>
              </Box>
            </Show>
          </Grid>
        </Grid>
      </Show>
    </Grid>
  );
};
