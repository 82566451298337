import { roundDecimalValue } from '@common/utils';
import { customerStore, orderStore } from '@store/orders';
import { Grid } from '@suid/material';
import { customerPay, totalOrderExpenses } from '@utils/calculateLineItems';
import { Accessor, Component, Index, Setter, createEffect } from 'solid-js';
import { Notification } from '@components';

export type CustomerWarningsProps = {
  customerWarnings: Accessor<string[]>;
  setCustomerWarnings: Setter<string[]>;
};

const CustomerWarnings: Component<CustomerWarningsProps> = (
  props: CustomerWarningsProps,
) => {
  createEffect(() => {
    const { id, status, available, paymentTerms } = customerStore.customer;
    const { order } = orderStore;
    const warnings = [];

    if (id) {
      if (status === 'Inactive') {
        warnings.push('Customer is Inactive');
      }
      if (status === 'Duplicate') {
        warnings.push('Customer is Duplicate');
      }
      if (status === 'Dormant') {
        warnings.push('Customer is Dormant');
      }
      if (['Credit Hold', 'Collection Hold'].includes(status)) {
        warnings.push(
          'Customer is on hold due to non payment, please reach out to A/R.  New Loads cannot be created.',
        );
      }
      if (status === 'In Collections' && !order.id) {
        warnings.push(
          'Customer is in Collections, new loads cannot be created',
        );
      }
      if (available < totalOrderExpenses() && order.id === 0) {
        warnings.push('Customer has insufficient Credit for this Order');
      }
      const prePaymentsTotal = order.customerPrePayments
        ? getPrePaymentsTotal()
        : 0;
      if (
        paymentTerms === 'Prepay' &&
        outstandingBalance() - prePaymentsTotal > 0
      ) {
        warnings.push(
          'Customer is marked as prepay.  You Must obtain payment before proceeding with any loads.',
        );
      }
      props.setCustomerWarnings(warnings);
    }
  });

  const outstandingBalance = () => {
    return customerPay() - paymentsTotal();
  };

  const getPrePaymentsTotal = () => {
    const unprocessedPayments = (
      orderStore.order.customerPrePayments || []
    ).filter((p) => p.processed == false);
    const total = unprocessedPayments.reduce((accumulator, payment) => {
      return accumulator + payment.paidAmount;
    }, 0);
    return total;
  };

  const paymentsTotal = () => {
    return orderStore.order.payments.length > 0
      ? orderStore.order.payments.reduce(
          (acc, item) => acc + roundDecimalValue(item.amount, 2),
          0,
        )
      : 0;
  };

  return (
    <>
      {props.customerWarnings().length && (
        <Grid item xs={12}>
          <Index each={props.customerWarnings()}>
            {(item: Accessor<string>) => (
              <Notification type="error" text={item()} />
            )}
          </Index>
        </Grid>
      )}
    </>
  );
};

export default CustomerWarnings;
