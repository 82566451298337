import { v3Client } from '@api/apiClient';
import { logError } from '@utils/errorLogging';
import { createError } from '@utils/errorUtils';

import { UserViewModel } from './types';

export async function fetchUser(): Promise<UserViewModel> {
  try {
    const userData: UserViewModel = await v3Client.get('/user');
    return userData;
  } catch (error: unknown) {
    logError('fetchUser', error as Error);
    throw createError(error, 'Failed to fetch offices');
  }
}
