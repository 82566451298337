const classes = {
  customerInfo: {
    invoiceDate: 'flex-1 flex-col gap-1 p-1 bg-[#B4D3CE] rounded-sm',
    datesContainer: 'flex gap-1 my-[16px] bg-[#BFDFD8] p-1 rounded-sm',
    invoiceDateTitle: '!text-xs !font-normal !text-[#000000d9]',
    invoiceDateValue: ' !text-[#000000d9]',
    rowContainer:
      'flex 1080p:inline-flex 1080p:[flex:3] gap-4 my-[10px] items-center text-[#026EA1]',
    textFieldContainer: 'flex-1',
    profileContainer: 'bg-[#FFECB3] p-[8px]',
    profileName: 'font-roboto text-lg font-medium',
    anchorLink:
      'font-roboto text-sm font-small mr-[10px] text-[#026EA1] font-semibold',
    disabledAnchorLink:
      'font-roboto text-sm font-medium mr-[20px] text-[#000000] opacity-40 pointer-events-none',
    customerContainer: 'flex flex-col flex-[1.7] mr-3',
    customerFieldInfo: 'text-xs text-[#666666ff] mt-[4px] flex align-center',
    infoIconStyle: {
      'font-size': '16px',
      color: '#468DB5',
      'margin-right': '4px',
    },
    phoneIconStyle: { color: '#016fa1ff' },
    disabledPhoneIconStyle: {
      color: '#000000',
      opacity: 0.4,
      cursor: 'default',
    },
    reassignButton:
      'inline-block pl-1 pt-2 pr-2 pb-2 text-[#026EA1] font-roboto font-medium text-[15px] leading-6 tracking-tighter cursor-pointer mt-2',
    assignLoadButton: {
      height: '100%',
      color: '#026EA1',
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '-0.025em',
      cursor: 'pointer',
    },
    divider: 'border-t border-gray-300',
    profileStyle: { maxHeight: '40px' },
    lockBtn: 'h-[42px]',
    ediLogs: 'flex flex-1 h-[38px]',
    unlinkText: 'flex text-lg text-center font-bold',
  },
  customerPayments: {
    totalLabel: '!text-[#171b1A] !font-bold  !rounded-sm !overflow-hidden',
    totalAmount:
      '!bg-[#0000000F]  !font-bold !text-[#171b1A] px-3 rounded-sm py-2',
    addIcon: 'text-[#0070A2]',
    deleteIcon: 'text-[#B00020] cursor-pointer',
    tableBg: 'bg-[#BFDFD8] ',
    emptyTable: 'w-full h-64 bg-[#BFDFD8]',
    tableRow:
      ' border-t-2 border-[#a8c5bf] !bg-[#BFDFD8] [&:nth-child(1)]:!border-none ',
    tableFooter:
      'text-base font-normal font-weight-700 leading-20.02 tracking-0.17 text-black',
    tableFooterRow: 'border-t-2    border-[#a8c5bf] !bg-[#BFDFD8]',
  },
  saveNewNote: '!mb-3 !float-right	',
};

export default classes;
