//TODO : ADD USAGE
//USAGE EXAMPLE
//FOR REFERENCE CHECK AddEditDocument.tsx File
//FOR REFERENCE CHECK ImportCatalogItem.tsx File

import { FileUploadErrorIcon } from '@assets';
import { DialogBox } from '@components/DialogBox';
import { LinkComponent } from '@components/Link';
import { domainUrl } from '@constants/urls';
import { openDialogBox } from '@store/dialogBox';
import { DocumentModel } from '@store/documents';
import { Delete } from '@suid/icons-material';
import InfoIcon from '@suid/icons-material/Info';
import {
  Box,
  CircularProgress,
  TextField as FileUploadInput,
  FormControl,
  FormLabel,
  Grid,
} from '@suid/material';
import { HoverPop } from '@views/carrierMatching/HoverPop';
import { isAdmin } from '@views/order/components/carrier/stops/stopsEditor/utilFunctions';
import {
  Component,
  createSignal,
  For,
  mergeProps,
  onCleanup,
  onMount,
  Show,
} from 'solid-js';

import { classes } from './FileUpload.style';

export type CommonFileUploadFiles = {
  name: string;
  dateAdded?: string;
  id?: number;
  url?: string;
  size?: number;
};

export type FileTypes =
  | 'pdf'
  | 'jpeg'
  | 'jpg'
  | 'png'
  | 'doc'
  | 'docx'
  | 'csv'
  | 'xls'
  | 'xlsx'
  | 'ppt'
  | 'pptx'
  | 'txt'
  | 'tif'
  | 'xks'
  | 'html'
  | 'msg'
  | 'odt';
// Add more file types as needed

export type Props = {
  removeFile?: (
    index: number,
    item: CommonFileUploadFiles,
  ) => Promise<void> | void;
  maxFileSize?: number;
  acceptedFileTypes?: FileTypes[];
  isRemoveFile?: boolean;
  shouldUploadOnSelect?: boolean;
  uploadedFiles?: CommonFileUploadFiles[];
  apiCallBackFn?: (file: File) => Promise<void>;
  error?: string | string[] | null;
  onSelectedFile?: (file: File) => void;
  onSelectedFileRemove?: () => void;
  customValidation?: (file: File) => boolean;
  isMultipleUpload?: boolean;
  height?: number;
  width?: number;
  label?: string;
  disabled?: boolean;
  alreadyUploadedDocuments?: DocumentModel[];
  required?: boolean;
  showAttachFiles?: boolean;
};

const DEFAULT_LABEL = 'Click or Drag a File To Upload';
const DEFAULT_HEIGHT = 150;
const DEFAULT_WIDTH = 100;
const DEFAULT_MAX_SIZE = 5242880;

const CommonFileUpload: Component<Props> = (props) => {
  props = mergeProps({ maxFileSize: 5242880 }, props);

  const [loading, setLoading] = createSignal(false);
  const [invalidFile, setInvalidFile] = createSignal<
    'fileType' | 'fileSize' | 'none'
  >('none');
  const [fileToDelete, setFileToDelete] = createSignal<{
    index: number;
    file: CommonFileUploadFiles;
  } | null>(null);
  const [inputRef, setInputRef] = createSignal<HTMLInputElement>();
  const [dropZoneRef, setDropZoneRef] = createSignal<HTMLDivElement>();
  const [buttonRef, setButtonRef] = createSignal<HTMLDivElement>();

  const handleFileValidation = (file: File | null): boolean => {
    if (!file) return false;

    if (props.customValidation && !props.customValidation(file)) {
      return false;
    }

    if (!(checkFileType(file) ?? false)) {
      setInvalidFile('fileType');
      return false;
    }
    const size = props.maxFileSize ?? DEFAULT_MAX_SIZE; // Default File size is 5MB
    if (file.size > size) {
      setInvalidFile('fileSize');
      return false;
    }
    return true;
  };

  const handleFileChangeOrDrop = async (
    file: File | null,
    e: DragEvent | Event,
  ) => {
    if (!handleFileValidation(file)) {
      if (e.target) {
        (e.target as HTMLInputElement).value = '';
      }
      return;
    }

    const shouldUpload =
      Boolean(props.shouldUploadOnSelect) || Boolean(props.isMultipleUpload);

    if (!shouldUpload) {
      props.onSelectedFile?.(file!);
    } else {
      props.apiCallBackFn && (await props.apiCallBackFn(file!));
    }
  };

  const handleChange = async (e: Event) => {
    setLoading(true);
    setInvalidFile('none');
    const target = e.target as HTMLInputElement;
    if (Boolean(props.isMultipleUpload)) {
      const filePromises = [];
      for (let i = 0; i < (target.files?.length ?? 0); i++) {
        filePromises.push(handleFileChangeOrDrop(target.files?.[i] ?? null, e));
      }
      await Promise.all(filePromises);
    } else {
      const file = target.files?.[0];
      await handleFileChangeOrDrop(file!, e);
    }
    setLoading(false);
  };

  const handleDrop = async (e: DragEvent) => {
    e.preventDefault();
    setInvalidFile('none');
    setLoading(true);
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = 'rgb(242, 246, 248)';
      dropZone.style.border = '3px solid transparent';
    }
    if (Boolean(props.isMultipleUpload)) {
      const filePromises = [];
      for (let i = 0; i < (e.dataTransfer?.files.length ?? 0); i++) {
        filePromises.push(
          handleFileChangeOrDrop(e.dataTransfer?.files[i] ?? null, e),
        );
      }
      await Promise.all(filePromises);
    } else {
      const file = e.dataTransfer?.files[0];
      await handleFileChangeOrDrop(file!, e);
    }
    setLoading(false);
  };

  const checkFileType = (file: File) => {
    const fileExtension = file.name.split('.').pop();
    if (
      !Boolean(props.acceptedFileTypes) ||
      props.acceptedFileTypes?.length === 0
    )
      return true;
    return props.acceptedFileTypes?.includes(fileExtension as FileTypes);
  };

  const handleDelete = async () => {
    if (fileToDelete()) {
      const index = fileToDelete()?.index;
      const file = fileToDelete()?.file;
      if (index !== undefined && file !== undefined) {
        if (props.removeFile) {
          await props.removeFile(Number(index), file);
        }
      }
      setFileToDelete(null);
    }
  };

  const removeUploadedFile = (
    e: Event,
    index: () => number,
    file: CommonFileUploadFiles,
  ) => {
    setFileToDelete({
      index: index(),
      file,
    });
    openDialogBox('customFileUploadDeleteDialog');
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = '#d3ebf0';
      dropZone.style.border = '3px solid #026ea1';
    }
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = 'rgb(242, 246, 248)';
      dropZone.style.border = '3px solid transparent';
    }
  };

  onMount(() => {
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('dragenter', handleDragOver);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('drop', handleDrop);
      dropZone.style.transition = '0.2s ease-in-out';
      dropZone.style.border = '3px solid transparent';
      dropZone.style.borderRadius = '6px';
    }
    buttonRef()?.addEventListener('click', () => {
      inputRef()?.click();
    });
  });

  onCleanup(() => {
    dropZoneRef()?.removeEventListener('dragover', handleDragOver);
    dropZoneRef()?.removeEventListener('dragenter', handleDragOver);
    dropZoneRef()?.removeEventListener('dragleave', handleDragLeave);
    dropZoneRef()?.removeEventListener('drop', handleDrop);
  });

  return (
    <>
      <FormControl
        variant="standard"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        ref={setDropZoneRef}
        class="w-full h-full"
      >
        <Grid
          container
          sx={classes.iconLabelContainer(props.height ?? DEFAULT_HEIGHT)}
          ref={setButtonRef}
        >
          <FormLabel sx={classes.inputLabelStyles(props.disabled ?? false)}>
            {loading() ? (
              <>Files are uploading please wait....</>
            ) : (
              props.label ?? (
                <div class="flex items-center">
                  <div>{DEFAULT_LABEL}</div>
                  <div class="relative z-10 ml-2">
                    <HoverPop anchor={<InfoIcon />} render={true}>
                      <div class="p-2">
                        SVG, PNG, JPG, GIF, CSV, TIFF, BMP, or, PDF (max. 5mb)
                      </div>
                    </HoverPop>
                  </div>
                </div>
              )
            )}
          </FormLabel>
        </Grid>
        <FileUploadInput
          inputProps={{
            multiple: props.isMultipleUpload,
          }}
          inputRef={setInputRef}
          type="file"
          sx={classes.fileUploadInputStyles(
            props.height ?? DEFAULT_HEIGHT,
            props.width ?? DEFAULT_WIDTH,
          )}
          disabled={(props.disabled ?? false) || loading()}
          onChange={handleChange}
          required={props.required}
        />
        {invalidFile() !== 'none' && (
          <Box
            backgroundColor="#FFD8DF"
            displayRaw="flex"
            alignItems="center"
            justifyContent="center"
            p={1}
            gap={1}
          >
            <span class="text-[#952828] font-bold">Status:</span>
            <span>
              {invalidFile() === 'fileType'
                ? 'Invalid File Type'
                : 'File Size Exceeded'}
            </span>
            <span>
              <img src={FileUploadErrorIcon} alt="File Upload Error" />
            </span>
          </Box>
        )}
        <Box class="max-h-[400px] overflow-scroll py-2">
          <For each={props.uploadedFiles}>
            {(file, index) => {
              return (
                <Box displayRaw="flex" alignItems="center" p={1} gap={2}>
                  <Box displayRaw="flex" gap={2} alignItems="center">
                    {Boolean(file.url) ? (
                      <Box class="text-left break-all">
                        <LinkComponent
                          url={`${domainUrl}${file.url}`}
                          target={'_blank'}
                          title={file.name}
                        />
                      </Box>
                    ) : (
                      <span>{file.name}</span>
                    )}
                  </Box>
                  <Show when={Boolean(props.isRemoveFile) && isAdmin()}>
                    <Delete
                      class="text-[#B00020] cursor-pointer"
                      onclick={(e) => removeUploadedFile(e, index, file)}
                    />
                  </Show>
                </Box>
              );
            }}
          </For>
        </Box>
        <Show when={loading()}>
          <Box sx={classes.BoxStyles()}>
            <Box>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Show>
      </FormControl>
      <DialogBox
        id="customFileUploadDeleteDialog"
        title="Are you sure you want to delete this document?"
        onSubmit={handleDelete}
        onSubmitText="Delete"
        onCancel={() => setFileToDelete(null)}
      />
    </>
  );
};

export default CommonFileUpload;
