import { createStore } from 'solid-js/store';

import { CustomerLTLSettingState, ILTLCustomerData } from './types';

const [customerLTLSettings, setCustomerLTLSettings] =
  createStore<CustomerLTLSettingState>({
    isLoading: true,
    isError: false,
    errorMessage: '',
    settings: {} as ILTLCustomerData,
  });

export { customerLTLSettings, setCustomerLTLSettings };
