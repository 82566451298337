export const factoringCompanyGridClasses = {
  text: '!text-xs !font-normal ',
  textBold: '!text-xs !font-extrabold',
  textLarge: '!text-lg !font-normal',
};

export const factoringCompanyHeaderClasses = {
  headerContainer:
    '!shadow-lg h-[100%] !h-fit sm:!h-fit  px-4 py-6 z-10 md:!h-fit lg:h-24',
  factoringNameText:
    'flex items-center md:justify-start lg:justify-start xl:justify-start 2xl:justify-start',
};
