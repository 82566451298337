import { EditHistory } from './EditHistory';
import Order from './Order';

export const OrderRoute = {
  path: '/order/details',
  component: Order,
};

export const OrderDetailsRoute = {
  path: '/order/details/:id',
  component: Order,
};

export const EditHistoryRoute = {
  path: 'order/editHistory/:id',
  component: EditHistory,
};
