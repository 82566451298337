import { lazy } from 'solid-js';

const CollectionsDashboard = lazy(() => import('./CollectionsDashboard'));
import { CollectionDetails } from './components';

export const CollectionsDashboardRoute = {
  path: '/dashboard/collections',
  component: CollectionsDashboard,
};

export const CollectionDetailsRoute = {
  path: '/accounting/collectionsByCustomer/:id',
  component: CollectionDetails,
};
