import { FalveyInsuranceQuoteInLoad, TempQuoteModel } from '@store/ltl/types';
import { Address } from '@store/orders/v3Types';
import { userStore } from '@store/user';
import { BuildOrderFromQuote } from '@utils/ltl';
import { formatAmount } from '@utils/utils';
import { size } from 'lodash';
import { DateTime } from 'luxon';

import {
  addNewVendorToOrder,
  updateOrderState,
  updateVendorPropertyAtIndex,
} from './actions';
import { falveyVendorIntialState } from './constant';
import { orderStore, setOrderStore } from './store';
import {
  ILoadEditViewModel,
  IOrderViewModel,
  OperationTypeV4,
  VendorContractViewModel,
  VendorLineItem,
} from './types';

/**
 * This function copies a load.
 * @param load The load to copy
 * @param perserveCarrier Whether to preserve the carrier
 * @param perserveStopDateAndTime If this is false, then the stop date will be empty
 * @param advanceOneDay If this is true, then the stop date will advance by one day
 * @returns The copied load
 */
// eslint-disable-next-line complexity
export const copyLoad = (
  load: ILoadEditViewModel,
  perserveCarrier?: boolean,
  perserveStopDateAndTime?: boolean,
  advanceOneDay = false,
) => {
  let newStopId = 0;
  let newItemId = 0;
  const isLTL = load.mode === 'LTL';
  const keepCarrier = perserveCarrier !== undefined ? perserveCarrier : false;
  const keepStopDateAndTime =
    perserveStopDateAndTime !== undefined ? perserveStopDateAndTime : false;
  return {
    loadInformation: load.loadInformation,
    warnings: load.warnings,
    sequence: load.sequence,
    type: load.type,
    customerReferenceNumber: load.customerReferenceNumber,
    carrierName: isLTL && !keepCarrier ? undefined : load.carrierName,
    carrierId: isLTL && !keepCarrier ? undefined : load.carrierId,
    carrierActionPlan:
      isLTL && !keepCarrier ? undefined : load.carrierActionPlan,
    carrierStatus: isLTL && !keepCarrier ? 'Unassigned' : load.carrierStatus,
    carrierContactId: isLTL && !keepCarrier ? undefined : load.carrierContactId,
    equipment: load.equipment,
    mode: load.mode,
    teamRequired: load.teamRequired,
    hazmatRequired: load.hazmatRequired,
    tarpRequired: load.tarpRequired,
    hideCustomerName: load.hideCustomerName,
    hasBeenSharedWithCapacity: false,
    freightValue: undefined,
    carrierInstructions:
      isLTL && !keepCarrier ? undefined : load.carrierInstructions,
    terminal: isLTL ? undefined : load.terminal,
    practicalMileage: load.practicalMileage,
    shortestMileage: load.shortestMileage,
    driver: undefined,
    driverPhoneNumber: undefined,
    truckNumber: undefined,
    trailerNumber: undefined,
    trackingNumber: undefined,
    product: load.product,
    tempInstructions: load.tempInstructions,
    enableLoadCarrierRestrictionOverride: false,
    enableLoadCarrierRestrictionOverrideOffice: undefined,
    billingHold: false,
    billingHoldReason: undefined,
    billingHoldDescription: undefined,
    billedDate: undefined,
    paidDate: undefined,
    trackingEnabled: false,
    assignedToUserId: load.assignedToUserId,
    assignedToGroupId: load.assignedToGroupId,
    assignedToUser: load.assignedToUser,
    assignedToGroup: load.assignedToGroup,
    assignedToOffice: load.assignedToOffice,
    legalText: load.legalText,
    profitSplit: load.profitSplit,
    sharedWithOfficeId: undefined,
    od: load.od,
    nmfc: load.nmfc,
    weight: load.weight,
    marketRate: load.marketRate,
    class: load.class,
    canRate: load.canRate,
    synched: false,
    edi: load.edi,
    minPrice: load.minPrice,
    maxPrice: load.maxPrice,
    lastInternalNote: undefined,
    postingId: null,
    signedRateCon: false,
    signedConfirmationDate: undefined,
    customerId: load.customerId,
    ramps: load.ramps,
    macropointInterval: undefined,
    ltlSubCarrier: null,
    tenderedToTransfloDate: undefined,
    currencyType: load.currencyType,
    canVoidLoad: true,
    assignedId: 0,
    owner: load.owner,
    carrierContact: isLTL && !keepCarrier ? undefined : load.carrierContact,
    claim: null,
    trackingService: undefined,
    ltlShipmentData: {
      operationType: 'Insert',
      loadId: load.id + 1,
      shipmentId: '',
      proNumber: '',
      pickupNumber: '',
      trackingURL: '',
      referenceNumbers: [],
    },
    operationType: 'Insert',
    id: 0,
    orderId: 0,
    loadNum: '',
    quoteID: null,
    isCopied: true,
    ltlQuoteId: null,
    ltlQuote: undefined,
    quoteItems: [],
    quoteStops: [],
    enableTracking: false,
    editable: true,
    status: Boolean(load.carrierId) ? 'Assigned' : 'Unassigned',
    coveredBy: userStore.user.name,
    coveredById: userStore.user.id,
    payments: [],
    comments: [],
    flags: [],
    lineItems: isLTL
      ? []
      : load.lineItems
          ?.filter((lineItem) => lineItem.operationType !== 'Delete')
          ?.map((lineItem) => ({
            operationType: 'Insert' as OperationTypeV4,
            id: 0,
            orderId: 0,
            settledDate: null,
            status: 'Unsettled',
            readOnly: false,
            synched: false,
            pickUpId: undefined,
            dropOffId: undefined,
            type: lineItem.type,
            description: lineItem.description,
            createdDate: new Date(),
            quantity: lineItem.quantity,
            rate: lineItem.rate,
            estimate: lineItem.estimate,
            adjustment: lineItem.adjustment,
            referenceNumber: lineItem.referenceNumber,
            stopId: undefined,
            descriptionWithParanthesis: lineItem.descriptionWithParanthesis,
            rateDetail: lineItem.rateDetail,
          }))
          .filter((item) => item.type !== 'Adjustment'),
    stops: load.stops
      ?.filter((stop) => stop.operationType !== 'Delete')
      ?.map((stop) => {
        newStopId--;

        let newStopTime: DateTime | undefined = undefined;

        if (keepStopDateAndTime) {
          newStopTime = DateTime.fromISO(stop.stopDateTime ?? '');
        } else if (Boolean(stop.pickUp)) {
          newStopTime = DateTime.fromISO(stop.stopDateTime ?? '');
        }

        if (advanceOneDay) {
          newStopTime = newStopTime?.plus({ day: 1 });
        }

        return {
          operationType: 'Insert' as OperationTypeV4,
          id: newStopId,
          loadId: 0,
          timeZoneOffset: stop.timeZoneOffset,
          stopType: stop.stopType,
          customerId: stop.customerId,
          stopDate: stop.stopDate,
          stopDateFormatted: stop.stopDateFormatted,
          stopDateTimeFormatted: stop.stopDateTimeFormatted,
          description: stop.description,
          address1: stop.address1,
          address2: stop.address2,
          city: stop.city,
          state: stop.state,
          zip: stop.zip,
          locationName: stop.locationName,
          contact: stop.contact,
          phone: stop.phone,
          pickUp: stop.pickUp,
          dropOff: stop.dropOff,
          stopOrder: stop.stopOrder,
          origin: stop.origin,
          destination: stop.destination,
          createdDate: new Date(),
          modifiedDate: undefined,
          referenceNum: stop.referenceNum,
          time: stop.time,
          instructions: stop.instructions,
          directions: stop.directions,
          needsAppointment: stop.needsAppointment,
          appointmentDate: stop.appointmentDate,
          driverInTime:
            load.mode === 'LTL'
              ? keepStopDateAndTime
                ? stop.driverInTime
                : stop.pickUp
                  ? stop.driverInTime
                  : undefined
              : undefined,
          driverOutTime:
            load.mode === 'LTL'
              ? keepStopDateAndTime
                ? stop.driverOutTime
                : stop.pickUp
                  ? stop.driverOutTime
                  : undefined
              : undefined,
          appointmentNum: stop.appointmentNum,
          email: stop.email,
          stopCode: stop.stopCode,
          countryCode: stop.countryCode,
          arrived: undefined,
          lat: stop.lat,
          long: stop.long,
          practicalMileageToNextStop: stop.practicalMileageToNextStop,
          shortestMileageToNextStop: stop.shortestMileageToNextStop,
          stopDateTime: newStopTime?.toISO() ?? undefined,
          appointment: stop.appointment,
          driverIn: undefined,
          driverOut: undefined,
        };
      }),
    items: load.items
      ?.filter((item) => item.operationType !== 'Delete')
      ?.map((item) => {
        newItemId--;
        const pickUpId =
          -1 * (load.stops!.findIndex((stop) => stop.id === item.pickUpId) + 1);
        const dropOffId =
          -1 *
          (load.stops!.findIndex((stop) => stop.id === item.dropOffId) + 1);
        return {
          operationType: 'Insert' as OperationTypeV4,
          id: newItemId,
          orderId: 0,
          pickUpId: pickUpId,
          dropOffId: dropOffId,
          loadId: 0,
          nmfc: item.nmfc,
          class: item.class,
          item: item.item,
          description: item.description,
          quantity: item.quantity,
          charge: item.charge,
          weight: item.weight,
          uom: item.uom,
          volume: item.volume,
          hazmat: item.hazmat,
          heightInch: item.heightInch,
          lengthInch: item.lengthInch,
          widthInch: item.widthInch,
          height: item.height,
          length: item.length,
          width: item.width,
          pieces: item.pieces,
          descriptionWithParanthesis: item.descriptionWithParanthesis,
          displayDimensions: item.displayDimensions,
          loadItemHazmatDetail: item.loadItemHazmatDetail,
        };
      }),
  };
};

export const buildLoadFromQuote = (
  quote: TempQuoteModel,
  address?: Address,
): IOrderViewModel => {
  const load = BuildOrderFromQuote(quote, address);
  return load;
};

export const addCustomerPayLineItem = (
  pricingQuote: FalveyInsuranceQuoteInLoad,
) => {
  //remove lineItem if already present
  const lineItems = [...orderStore.order.lineItems].filter(
    (lineItem) => lineItem.type !== 'LTL Insurance',
  );
  const insuranceLineItem = [...orderStore.order.lineItems].filter(
    (lineItem) => lineItem.type === 'LTL Insurance',
  );
  if (insuranceLineItem.length > 0) {
    const updatedLineItem = {
      ...insuranceLineItem[0],
      operationType: 'Update' as OperationTypeV4,
      rate: pricingQuote.quotePremium + pricingQuote.quoteServiceFee,
    };
    lineItems.unshift(updatedLineItem);
  } else {
    lineItems.unshift({
      operationType: 'Insert',
      quantity: 1,
      type: 'LTL Insurance',
      description: 'Shipment Insurance',
      rate: pricingQuote.quotePremium + pricingQuote.quoteServiceFee,
      id: 0,
      estimate: false,
      readOnly: false,
      synched: false,
      adjustment: false,
      stopId: null,
    });
  }
  updateOrderState({ lineItems });
};

export const addVendorPayLineItem = (
  pricingQuote: FalveyInsuranceQuoteInLoad,
  declaredValue: number,
) => {
  const falveyVendorIndex = (orderStore.order.vendors ?? []).findIndex(
    (item) => item.name === 'Falvey Shippers Insurance',
  );
  const vendors = [...(orderStore.order.vendors || [])];
  if (falveyVendorIndex !== -1) {
    vendors.splice(falveyVendorIndex, 1);
    setOrderStore('order', 'vendors', vendors);
  }
  addNewVendorToOrder({
    ...(falveyVendorIntialState as VendorContractViewModel),
    description: `Insured Value: ${formatAmount(declaredValue)}`,
  });
  const vendorLineItems = orderStore.order.vendors
    ? [
        ...(orderStore.order.vendors[orderStore.activeTab.index]
          .lineItems as VendorLineItem[]),
      ]
    : [];

  const insertVendorObj: VendorLineItem = {
    operationType: 'Insert',
    type: 'Accessorial',
    quantity: 1,
    description: 'Certificate #',
    rate: pricingQuote.quotePremium,
    id: 0,
    estimate: false,
    readOnly: false,
    synched: false,
    adjustment: false,
    stopId: 0,
  };
  vendorLineItems.push(insertVendorObj);
  updateVendorPropertyAtIndex({ lineItems: vendorLineItems });
};

const updateCustomerPayLineItem = (
  pricingQuote: FalveyInsuranceQuoteInLoad,
) => {
  const lineItems = [...orderStore.order.lineItems];
  const falveyCPIndex = lineItems.findIndex(
    (lineItem) =>
      lineItem.description === 'Shipment Insurance' &&
      lineItem.operationType !== 'Delete',
  );

  if (falveyCPIndex !== -1) {
    const { quotePremium, quoteServiceFee } = pricingQuote;
    const updatedLineItem = {
      ...lineItems[falveyCPIndex],
      rate: quotePremium + quoteServiceFee,
      operationType: (lineItems[falveyCPIndex].id > 0
        ? 'Update'
        : 'Insert') as OperationTypeV4,
    };

    lineItems[falveyCPIndex] = updatedLineItem;
    updateOrderState({ lineItems });
  }
};

export const updateVendorPayLineItem = (
  pricingQuote: FalveyInsuranceQuoteInLoad,
  declaredValue: number,
) => {
  if (
    orderStore.order.vendors !== undefined &&
    size(orderStore.order.vendors) > 0
  ) {
    const falveyVendorIndex = orderStore.order.vendors.findIndex(
      (v) => v.name === 'Falvey Shippers Insurance',
    );
    const vendorLineItems = [
      ...(orderStore.order.vendors[falveyVendorIndex]
        .lineItems as VendorLineItem[]),
    ];

    const updatedVendorLineItems = vendorLineItems.reduce(
      (acc, lineItem) => {
        if (lineItem.description === 'Certificate #') {
          acc.push({
            ...lineItem,
            rate: pricingQuote.quotePremium,
            operationType: lineItem.id > 0 ? 'Update' : 'Insert',
          });
        } else {
          acc.push(lineItem);
        }
        return acc;
      },
      [] as typeof vendorLineItems,
    );
    updateVendorPropertyAtIndex(
      {
        lineItems: updatedVendorLineItems,
        description: `Insured Value: ${formatAmount(declaredValue)}`,
      },
      falveyVendorIndex,
    );
  }
};

export const updateCustomerAndVendorPay = (
  pricingQuote: FalveyInsuranceQuoteInLoad,
  type: string,
  declaredValue: number,
) => {
  if (type === 'insert') {
    addCustomerPayLineItem(pricingQuote);
    addVendorPayLineItem(pricingQuote, declaredValue);
  } else {
    updateCustomerPayLineItem(pricingQuote);
    updateVendorPayLineItem(pricingQuote, declaredValue);
  }
};

export const isFalveyInsuranceCommitted = () => {
  return (
    orderStore.order.comments !== undefined &&
    orderStore.order.comments.some(
      (comment) => comment.comment?.includes('Falvey COI Link:'),
    )
  );
};
