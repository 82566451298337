import { statusOptions } from '@common/commonLists';
import { Card, Notification } from '@components';
import { RadioInput, SelectField, TextInput } from '@components/forms';
import {
  UserInfoModel,
  customerStore,
  fetchUserInfoUserModel,
  orderStore,
  setOrderStore,
  updateOrderState,
} from '@store/orders';
import { userStore } from '@store/user';
import { Box, Grid } from '@suid/material';
import { CustomerInfoHeader } from '@views/order/components/customer/CustomerInfoHeader';
import { For, Show, createEffect, createSignal } from 'solid-js';
import { CreditCard } from '@suid/icons-material';
import { formatAmount, isAdmin } from '@utils/utils';

import classes from './classes';
import CustomerContactInformationDetails from './CustomerContactInformationDetails';
import CustomerEDI from './CustomerEDI';
import { BillingHoldFlag } from './CustomerInformation/components';
import CustomerInformationTabs from './CustomerInformationTabs';
import { CustomerInvoice } from './CustomerInvoice';
import CustomerSelection from './CustomerSelection';
import CustomerWarnings from './CustomerWarnings';

export const CustomerInformation = () => {
  const adjustmentsErrorKeys = [
    'revisitRetractionDate',
    'badAdjustments',
    'exceptionReason',
  ];
  const [customerWarnings, setCustomerWarnings] = createSignal<Array<string>>(
    [],
  );
  const [orderRepInformation, setOrderRepInformation] =
    createSignal<UserInfoModel>();

  const cls = classes.customerInfo;

  createEffect(async () => {
    if (orderStore.isCreate && userStore.user.id) {
      const response: UserInfoModel = await fetchUserInfoUserModel(
        userStore.user.id,
      );
      setOrderRepInformation(response);
      updateOrderState({
        owner: response.name,
        assignedId: response.id,
      });
    }
  });

  const handleMetadataChange = (value: string, idx: number) => {
    let tempMetadata = [...(orderStore.order.metadata ?? [])][idx];
    tempMetadata = { ...tempMetadata, value: value, operationType: 'Update' };
    setOrderStore('order', 'metadata', idx, tempMetadata);
  };

  const hasPrepaymentsToDisplay = () =>
    orderStore.order.customerPrePayments != null &&
    orderStore.order.customerPrePayments.filter((cpp) => cpp.processed == false)
      .length > 0;

  const prepaymentsTotal = () =>
    orderStore.order.customerPrePayments == null
      ? 0
      : orderStore.order.customerPrePayments
          .filter((cpp) => cpp.processed == false)
          .reduce((total, cpp) => {
            return cpp.paidAmount + total;
          }, 0);

  const totalPrepayments = () =>
    orderStore.order.customerPrePayments == null
      ? 0
      : orderStore.order.customerPrePayments.filter(
          (cpp) => cpp.processed == false,
        ).length;
  const isCustomerPresent = () => orderStore.order.customerId !== null;
  return (
    <Card
      startTitle="Customer Information"
      loading={orderStore.loading || customerStore.loading}
      cardWarning={customerWarnings().length > 0 && !orderStore.loading}
    >
      <Show
        when={
          orderStore.orderFormError &&
          adjustmentsErrorKeys.some((key) => key in orderStore.orderFormError!)
        }
      >
        <Box gap={1} flexDirection="column" displayRaw="flex" mb={1}>
          {adjustmentsErrorKeys.map(
            (key) =>
              orderStore.orderFormError &&
              orderStore.orderFormError[key] && (
                <Notification
                  type="error"
                  text={orderStore.orderFormError[key]}
                  tableRowNotification={true}
                />
              ),
          )}
        </Box>
      </Show>
      <Show when={!orderStore.isCreate && !orderStore.order.hideCustomerName}>
        <CustomerInfoHeader />
      </Show>
      <CustomerSelection setCustomerWarnings={setCustomerWarnings} />
      <CustomerWarnings
        customerWarnings={customerWarnings}
        setCustomerWarnings={setCustomerWarnings}
      />
      <Show when={orderStore.orderFormError?.referenceNumber}>
        <div class="mb-2">
          <Notification
            type="tableError"
            tableRowNotification
            text={<Box>Reference Number Must Be Less Than 120 Characters.</Box>}
          />
        </div>
      </Show>
      <Show when={isCustomerPresent()}>
        <CustomerInvoice />
      </Show>
      <CustomerContactInformationDetails
        orderRepInformation={orderRepInformation}
        setOrderRepInformation={setOrderRepInformation}
      />
      <CustomerEDI />
      <Box class="flex flex-col flex-1 1080p:flex-row tablet:gap-1 1080p:gap-3 1080p:items-center">
        <div class={cls.rowContainer}>
          <SelectField
            label="Status"
            menuItems={statusOptions}
            value={orderStore.order.status}
            onChange={(event) => {
              updateOrderState({ status: event.target.value });
            }}
            size="small"
            disabled={orderStore.isReadOnly}
          />
          <TextInput
            variant="outlined"
            label="Reference"
            placeholder="Reference #"
            value={orderStore.order.referenceNumber ?? ''}
            onChange={(value) => {
              updateOrderState({ referenceNumber: value as string });
            }}
            size="small"
            disabled={
              orderStore.isReadOnly ||
              (!isAdmin() && orderStore.order.loadTenderId != null)
            }
          />
        </div>
        <Box class="high-res:[flex:2]">
          <RadioInput
            label="Bid Type"
            value={orderStore.order.bidType ?? undefined}
            options={[
              { label: 'Spot Market', value: 'Spot Market' },
              { label: 'Contract', value: 'Contract' },
            ]}
            onChange={(e) => {
              updateOrderState({ bidType: e.target.value });
            }}
            labelClass="mb-[-6px] text-xs"
            gap={0}
            radioSize="small"
            disabled={orderStore.isReadOnly}
            error={
              orderStore.orderFormError && orderStore.orderFormError['bidType']
            }
            optionStyles={{
              '&:not(:last-child)': {
                marginBottom: '-10px',
              },
              '& > span:last-child': {
                fontSize: '14px',
              },
            }}
            direction="vertical"
          />
        </Box>
      </Box>
      <Show
        when={
          orderStore.order.bidType !== 'Contract' &&
          Boolean(orderStore.order.bidType)
        }
      >
        <Box class="flex gap-4 mb-[16px]">
          <TextInput
            variant="outlined"
            label="Spot ID"
            placeholder="Spot Id"
            value={orderStore.order.spotId ?? ''}
            onChange={(value) => {
              updateOrderState({ spotId: value as string });
            }}
            disabled={orderStore.isReadOnly}
            size="small"
            error={
              orderStore.orderFormError && orderStore.orderFormError['spotId']
            }
          />
          <TextInput
            variant="outlined"
            label="Spot Quote"
            placeholder="Spot Quote"
            type="number"
            value={orderStore.order.spotQuote ?? ''} //setting this to empty string to avoid warnings
            onChange={(value) => {
              updateOrderState({ spotQuote: value as number });
            }}
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError['spotQuote']
            }
            disabled={orderStore.isReadOnly}
            size="small"
          />
        </Box>
      </Show>
      <Show when={hasPrepaymentsToDisplay()}>
        <div class="rounded mb-4 p-2 bg-[#026EA114]">
          <CreditCard class="text-armstrong-primary" />
          <span class="pl-2 text-sm !text-[#000000DE]">
            {`${totalPrepayments()} payment totaling:`}
          </span>
          <span class="pl-2 text-sm text-armstrong-primary font-semibold">
            {formatAmount(prepaymentsTotal())}
          </span>
        </div>
      </Show>
      <Show when={(orderStore.order.metadata ?? []).length > 0}>
        <div class="mb-4">
          <strong
            class={!isCustomerPresent() ? 'text-[#000000] opacity-40' : ''}
          >
            Shipment Metadata
          </strong>
        </div>
        <Grid container spacing={2}>
          <For each={orderStore.order.metadata}>
            {(item, idx) => (
              <Grid item xs={6}>
                <TextInput
                  variant="outlined"
                  label={item.propertyName}
                  placeholder={item.propertyName}
                  value={item.value}
                  onChange={(value) => {
                    handleMetadataChange(value as string, idx());
                  }}
                  size="small"
                />
              </Grid>
            )}
          </For>
        </Grid>
      </Show>
      <BillingHoldFlag />
      <div class={cls.divider} />
      <CustomerInformationTabs />
    </Card>
  );
};
