import { Card, DatePicker, Notification, Typography } from '@components';
import {
  CheckboxInput,
  SelectField,
  TextAreaField,
  TextInput,
} from '@components/forms';
import {
  Box,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Stack,
  Switch,
  Radio,
} from '@suid/material';
import { For, onMount, Show } from 'solid-js';
import { useParams } from '@solidjs/router';
import {
  customerContractStore,
  setCustomerContract,
  getCustomerContracts,
  ContractTermOption,
  CustomerContract,
  InsuranceOption,
} from '@store/customers/customerDetails/contracts';
import { isReadonly } from '@utils/utils';

import {
  accessorialOptions,
  amountOptions,
  brokerInsuranceOptions,
  brokerResponsibilityOptions,
  cargoOptions,
  carrierBrokerLiabilityOptions,
  carrierInsuranceOptions,
  claimAndConcealedDamageOptions,
  claimAndConcealedDamageRadioOptions,
  claimValueBasisOptions,
  indemnificationOptions,
  otherCarrierRequirementsOptions,
  pollutionOptions,
  temperatureControlledOptions,
  termAndPaymentTextAreaOptions,
  timeFrameOptions,
  workerCompensationOptions,
} from './constant';
import cls from './classes';

const DisclaimerText = (
  <span>
    <b>DISCLAIMER:</b> THE CUSTOMER'S REQUIREMENTS ARE OUTLINED BELOW. IT IS THE
    AGENT'S RESPONSIBILITY TO ENSURE ALL CARRIERS MEET THE COVERAGE LIMITS
    REQUIRED AND THAT THE CUSTOMER’S DEMANDS ARE OTHERWISE MET. THIS DOCUMENT IS
    MEANT TO ASSIST, BUT IS NOT A SUPPLEMENT FOR REVIEWING THE ENTIRE AGREEMENT.
  </span>
);

interface CheckBoxComponentProps {
  label: string;
  key: string;
  optionType: string;
  value: InsuranceOption;
  handleCheckBoxComponentChange: (
    key: keyof CustomerContract,
    value: string | boolean,
    intermediatryVal: keyof InsuranceOption,
  ) => void;
}

const CheckBoxComponent = (props: CheckBoxComponentProps) => {
  if (customerContractStore.customerContract === null) return null;
  let menuItems = amountOptions;
  switch (props.optionType) {
    case 'amount':
      menuItems = amountOptions;
      break;
    case 'pollution':
      menuItems = pollutionOptions;
      break;
    case 'cargo':
      menuItems = cargoOptions;
      break;
    case 'worker':
      menuItems = workerCompensationOptions;
      break;
  }

  return (
    <Grid container class="mb-4">
      <Grid sm={6}>
        <CheckboxInput
          label={props.label}
          checked={props.value.enabled}
          onChange={(val) => {
            props.handleCheckBoxComponentChange(
              props.key as keyof CustomerContract,
              val,
              'enabled',
            );
          }}
          disabled={isReadonly()}
        />
      </Grid>
      <Grid sm={6}>
        <Stack>
          <SelectField
            label=""
            placeholder="Select Amount"
            value={String(props.value.optionsValue) || ''}
            onChange={(e) => {
              props.handleCheckBoxComponentChange(
                props.key as keyof CustomerContract,
                e.target.value,
                'optionsValue',
              );
            }}
            menuItems={menuItems}
            size="small"
            disabled={!Boolean(props.value.enabled) || isReadonly()}
          />
          <Show
            when={
              props.value.optionsValue == '-1' ||
              props.value.optionsValue == '_other'
            }
          >
            <TextInput
              classes="!mt-2"
              showInCurrencyFormat
              size="small"
              label=""
              value={props.value.otherValue}
              onChange={(val) => {
                props.handleCheckBoxComponentChange(
                  props.key as keyof CustomerContract,
                  val as string,
                  'otherValue',
                );
              }}
              disabled={isReadonly()}
            />
          </Show>
        </Stack>
      </Grid>
    </Grid>
  );
};

interface RadioComponentProps {
  label: string;
  key: string;
  value: boolean | null;
  onRadioChange: (val: string, key: keyof CustomerContract) => void;
}

const RadioComponent = (props: RadioComponentProps) => {
  return (
    <Grid container class="mb-2 items-center">
      <Grid item xs={5}>
        <Box class={cls.labelClass}>{props.label}</Box>
      </Grid>
      <Grid item xs={7} class="!flex">
        <RadioGroup
          name="radio-buttons-group"
          class="!flex-row !ml-auto"
          value={props.value}
          onChange={(e) => {
            if (!isReadonly()) {
              props.onRadioChange(
                e.target.value,
                props.key as keyof CustomerContract,
              );
            }
          }}
          defaultValue={null}
          aria-disabled={isReadonly()}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
          <FormControlLabel
            value={null}
            control={<Radio />}
            label="Not Specified"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

interface SelectComponentProps {
  label: string;
  key: string;
  value: ContractTermOption | string;
  onlySelect?: boolean;
  handleChange: (
    value: ContractTermOption | string,
    key: keyof CustomerContract,
  ) => void;
}

const SelectComponetWithLabel = (props: SelectComponentProps) => {
  const menuItems = Boolean(props.onlySelect)
    ? claimValueBasisOptions
    : timeFrameOptions;

  const handleOnChange = (value: string) => {
    if (Boolean(props.onlySelect)) {
      props.handleChange(value, props.key as keyof CustomerContract);
    } else {
      props.handleChange(
        { optionsValue: value, otherValue: null },
        props.key as keyof CustomerContract,
      );
    }
  };
  return (
    <FormLabel>
      <Box class={`${cls.labelClass} mb-2`}>{props.label}</Box>
      <Stack>
        <SelectField
          value={
            Boolean(props.onlySelect)
              ? (props.value as string)
              : (props.value as ContractTermOption).optionsValue ?? ''
          }
          onChange={(e) => {
            handleOnChange(e.target.value);
          }}
          menuItems={menuItems}
          size="small"
          sxProps={{ width: '60%' }}
          placeholder="Select an option"
          disabled={isReadonly()}
        />
        <Show
          when={
            Boolean(props.value) &&
            ((props.value as ContractTermOption).optionsValue ?? '') ===
              '_other'
          }
        >
          <TextInput
            value={(props.value as ContractTermOption).otherValue ?? ''}
            onChange={(val) => {
              props.handleChange(
                { optionsValue: '_other', otherValue: val as string },
                props.key as keyof CustomerContract,
              );
            }}
            size="small"
            sxProps={{ width: '60%' }}
            disabled={isReadonly()}
          />
        </Show>
      </Stack>
    </FormLabel>
  );
};

interface TextOnlyComponentProps {
  label: string;
  key1: keyof CustomerContract;
  key2: keyof CustomerContract;
  value1: boolean;
  value2: string;
  handleTextOnlyComponentChange: (
    key: keyof CustomerContract,
    value: boolean | string,
  ) => void;
}

const TextOnlyComponent = (props: TextOnlyComponentProps) => {
  return (
    <Grid container class="mb-4">
      <Grid sm={6}>
        <CheckboxInput
          label={props.label}
          checked={props.value1 || false}
          onChange={(val) => {
            props.handleTextOnlyComponentChange(
              props.key1 as keyof CustomerContract,
              val,
            );
          }}
          disabled={isReadonly()}
        />
      </Grid>
      <Grid sm={6}>
        <TextInput
          showInCurrencyFormat={props.label === 'Other'}
          size="small"
          label=""
          value={props.value2 || ''}
          onChange={(val) => {
            props.handleTextOnlyComponentChange(
              props.key2 as keyof CustomerContract,
              val as string,
            );
          }}
          disabled={!props.value1 || isReadonly()}
        />
      </Grid>
    </Grid>
  );
};

export const Contracts = () => {
  onMount(async () => {
    const params = useParams();
    if (params.id) {
      setCustomerContract('isLoading', true);
      await getCustomerContracts(params.id);
    }
  });

  const getContractsValuesFromKey = (key: keyof CustomerContract) => {
    return customerContractStore.customerContract
      ? customerContractStore.customerContract[key]
      : null;
  };

  const handleSelectComponentChange = (
    value: ContractTermOption | string,
    key: keyof CustomerContract,
  ) => {
    setCustomerContract('customerContract', key, value);
  };

  const renderConcealedDamageOptions = () => {
    if (!customerContractStore.customerContract) return null;
    const leftSide = claimAndConcealedDamageOptions
      .slice(0, 3)
      .map((itm) => (
        <SelectComponetWithLabel
          {...itm}
          value={
            getContractsValuesFromKey(
              itm.key as keyof CustomerContract,
            ) as ContractTermOption
          }
          handleChange={handleSelectComponentChange}
        />
      ));
    const rightSide = claimAndConcealedDamageOptions
      .slice(3, 6)
      .map((itm) => (
        <SelectComponetWithLabel
          {...itm}
          value={
            getContractsValuesFromKey(
              itm.key as keyof CustomerContract,
            ) as ContractTermOption
          }
          handleChange={handleSelectComponentChange}
        />
      ));

    return (
      <Grid container spacing={2} class="!mb-3">
        <Grid item sm={6}>
          <Stack spacing={2}>{leftSide}</Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack spacing={2}>{rightSide}</Stack>
        </Grid>
      </Grid>
    );
  };

  const handleRadioChange = (val: string, key: keyof CustomerContract) => {
    const value = val === 'true' ? true : val === 'false' ? false : null;
    setCustomerContract('customerContract', key, value);
  };

  const onCheckBoxComponentChange = (
    key: keyof CustomerContract,
    value: string | boolean,
    intermediatryVal: keyof InsuranceOption,
  ) => {
    setCustomerContract('customerContract', key, intermediatryVal, value);
  };

  return (
    <Show
      when={
        !customerContractStore.isLoading &&
        customerContractStore.customerContract
      }
    >
      <Box class="w-full !mb-4">
        <Notification
          type="info"
          text={DisclaimerText}
          sxProps="bg-[#d9edf7] text-[#31708f]"
        />
        <Box class="p-2 border-[#31708f] border w-full mt-4 rounded-sm flex ">
          <FormLabel class="!pr-[50px] !border-r-2 mr-[50px]">
            <Box class={cls.labelClass}>Agreement Effective Date</Box>
            <DatePicker
              value={
                (getContractsValuesFromKey('agreementDate') as string) || ''
              }
              handleChange={(val) => {
                setCustomerContract('customerContract', 'agreementDate', val);
              }}
              size="small"
              disabled={isReadonly()}
            />
          </FormLabel>
          <FormLabel class="!pr-[50px] !border-r-2 mr-[50px]">
            <Box class={cls.labelClass}>Customer Entity Type</Box>
            <Stack>
              <SelectField
                value={
                  customerContractStore.customerContract?.customerEntityType
                    .optionsValue ?? ''
                }
                menuItems={[
                  { label: 'Shipper', value: 'Shipper' },
                  { label: 'Co-Broker', value: 'Co-Broker' },
                  { label: 'Frieght Forwarder', value: 'Frieght Forwarder' },
                  { label: 'Other (please specify)', value: '_other' },
                ]}
                onChange={(e) => {
                  setCustomerContract(
                    'customerContract',
                    'customerEntityType',
                    'optionsValue',
                    e.target.value,
                  );
                }}
                disabled={isReadonly()}
                size="small"
              />
              <Show
                when={
                  customerContractStore.customerContract?.customerEntityType
                    .optionsValue === '_other'
                }
              >
                <TextInput
                  size="small"
                  label=""
                  value={
                    customerContractStore.customerContract?.customerEntityType
                      .otherValue ?? ''
                  }
                  onChange={(val) => {
                    setCustomerContract(
                      'customerContract',
                      'customerEntityType',
                      'otherValue',
                      val as string,
                    );
                  }}
                  disabled={isReadonly()}
                />
              </Show>
            </Stack>
          </FormLabel>
          <FormLabel class="!flex !flex-col !pr-[24px]">
            <Box class={cls.labelClass}>Is customer a US entity ?</Box>
            <span>
              No
              <Switch
                checked={customerContractStore.customerContract?.isUsaEntity}
                onChange={(_, val) => {
                  setCustomerContract('customerContract', 'isUsaEntity', val);
                }}
                disabled={isReadonly()}
              />
              Yes
            </span>
          </FormLabel>
          <FormLabel>
            <Box class={cls.labelClass}>Country of Incorporation</Box>
            <SelectField
              value={getContractsValuesFromKey('countryOption') as string}
              menuItems={[
                { label: 'US', value: 'US' },
                { label: 'Canada', value: 'Canada' },
                { label: 'Mexico', value: 'Mexico' },
              ]}
              size="small"
              placeholder="Select Country"
              onChange={(e) => {
                setCustomerContract(
                  'customerContract',
                  'countryOption',
                  e.target.value,
                );
              }}
              disabled={isReadonly()}
            />
          </FormLabel>
        </Box>
        <Grid container spacing={2} class="!mt-2">
          <Grid item sm={12} md={7} class="px-2">
            <Card startTitle="Contract Details" accordion={false}>
              <Box>
                <FormLabel>
                  <Stack spacing={1}>
                    <Box class={cls.labelClass}>Is shipper self insured?</Box>
                    <span>
                      No
                      <Switch
                        checked={
                          customerContractStore.customerContract
                            ?.isShipperSelfInsured
                        }
                        onChange={(_, val) => {
                          setCustomerContract(
                            'customerContract',
                            'isShipperSelfInsured',
                            val,
                          );
                        }}
                        disabled={isReadonly()}
                      />
                      Yes
                    </span>
                  </Stack>
                </FormLabel>
              </Box>
              <Grid container spacing={2}>
                <Grid item sm={6} class="my-3">
                  <Stack spacing={2}>
                    <Box>
                      <Box class={cls.labelClass}>Carrier Insurance</Box>
                      <Grid container class="items-center">
                        <Grid item sm={6}>
                          <Box class={cls.heading}>All-Risk (Required)</Box>
                        </Grid>
                        <Grid item sm={6}>
                          <span>
                            No
                            <Switch
                              checked={
                                customerContractStore.customerContract
                                  ?.carrierAllRiskEnabled
                              }
                              onChange={(_, val) => {
                                setCustomerContract(
                                  'customerContract',
                                  'carrierAllRiskEnabled',
                                  val,
                                );
                              }}
                              disabled={isReadonly()}
                            />
                            Yes
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box displayRaw="flex">
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Insurance Type
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Amount
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item sm={6} class="my-3">
                  <Stack spacing={2}>
                    <Box>
                      <Box class={cls.labelClass}>Broker Insurance</Box>
                      <Grid container class="items-center">
                        <Grid item sm={6}>
                          <Box class={cls.heading}>All-Risk (Required)</Box>
                        </Grid>
                        <Grid item sm={6}>
                          <span>
                            No
                            <Switch
                              checked={
                                customerContractStore.customerContract
                                  ?.brokerAllRiskEnabled
                              }
                              onChange={(_, val) => {
                                setCustomerContract(
                                  'customerContract',
                                  'brokerAllRiskEnabled',
                                  val,
                                );
                              }}
                              disabled={isReadonly()}
                            />
                            Yes
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box displayRaw="flex">
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Insurance Type
                      </Typography>
                      <Typography
                        variant="body1"
                        component="p"
                        class={`${cls.labelClass} !flex-1`}
                      >
                        Amount
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item sm={6}>
                  <For
                    each={
                      customerContractStore.customerContract &&
                      carrierInsuranceOptions
                    }
                  >
                    {(item) => (
                      <CheckBoxComponent
                        {...item}
                        value={
                          getContractsValuesFromKey(
                            item.key as keyof CustomerContract,
                          ) as InsuranceOption
                        }
                        handleCheckBoxComponentChange={
                          onCheckBoxComponentChange
                        }
                      />
                    )}
                  </For>
                  <TextOnlyComponent
                    key1="carrierOtherInsuranceEnabled"
                    key2="carrierOtherInsurance"
                    label="Other"
                    value1={
                      customerContractStore.customerContract
                        ?.carrierOtherInsuranceEnabled as boolean
                    }
                    value2={
                      customerContractStore.customerContract
                        ?.carrierOtherInsurance as string
                    }
                    handleTextOnlyComponentChange={(
                      key: keyof CustomerContract,
                      value: string | boolean,
                    ) => {
                      setCustomerContract('customerContract', key, value);
                    }}
                  />
                </Grid>
                <Grid item sm={6}>
                  <For
                    each={
                      customerContractStore.customerContract &&
                      brokerInsuranceOptions
                    }
                  >
                    {(item) => (
                      <CheckBoxComponent
                        {...item}
                        value={
                          getContractsValuesFromKey(
                            item.key as keyof CustomerContract,
                          ) as InsuranceOption
                        }
                        handleCheckBoxComponentChange={
                          onCheckBoxComponentChange
                        }
                      />
                    )}
                  </For>
                  <TextOnlyComponent
                    key1="generalOtherInsuranceEnabled"
                    key2="generalOtherInsurance"
                    label="Other"
                    value1={
                      customerContractStore.customerContract
                        ?.generalOtherInsuranceEnabled as boolean
                    }
                    value2={
                      customerContractStore.customerContract
                        ?.generalOtherInsurance as string
                    }
                    handleTextOnlyComponentChange={(
                      key: keyof CustomerContract,
                      value: string | boolean,
                    ) => {
                      setCustomerContract('customerContract', key, value);
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
            <Card startTitle="Term and Payment Terms" accordion={false}>
              <Box>
                <Box class={cls.heading}>Claims and Concealed Damages</Box>
                <Divider class="!mb-3" />
                {renderConcealedDamageOptions()}
                <For each={claimAndConcealedDamageRadioOptions}>
                  {(item) => (
                    <RadioComponent
                      label={item.label}
                      value={
                        getContractsValuesFromKey(
                          item.key as keyof CustomerContract,
                        ) as boolean | null
                      }
                      key={item.key}
                      onRadioChange={handleRadioChange}
                    />
                  )}
                </For>
              </Box>
            </Card>
            <Card startTitle="Term and Payment Terms" accordion={false}>
              <For each={termAndPaymentTextAreaOptions}>
                {(item) => (
                  <Box class="!mb-3">
                    <FormLabel>
                      <span class={cls.heading}>{item.label}</span>
                      <Divider class="!mb-1" />
                      <TextAreaField
                        value={
                          getContractsValuesFromKey(
                            item.key as keyof CustomerContract,
                          ) as string
                        }
                        onChange={(val) =>
                          setCustomerContract(
                            'customerContract',
                            item.key as keyof CustomerContract,
                            val,
                          )
                        }
                        rows={5}
                        placeholder="Add Details"
                        class="!border-[#a5a5a5]"
                        disabled={isReadonly()}
                      />
                    </FormLabel>
                  </Box>
                )}
              </For>
            </Card>
          </Grid>
          <Grid item sm={12} md={5}>
            <Card
              startTitle="Temperature Controlled Commodities"
              accordion={false}
            >
              <Box>
                <Box class={cls.heading}>
                  Temperature Controlled Commodities
                </Box>
                <Divider class="!mb-2" />
                <For each={temperatureControlledOptions}>
                  {(item) => (
                    <RadioComponent
                      label={item.label}
                      value={
                        getContractsValuesFromKey(
                          item.key as keyof CustomerContract,
                        ) as boolean | null
                      }
                      key={item.key}
                      onRadioChange={handleRadioChange}
                    />
                  )}
                </For>
              </Box>
              <Box>
                <Box class={cls.heading}>Other Carrier Requirements</Box>
                <Divider class="!mb-2" />
                <For each={otherCarrierRequirementsOptions}>
                  {(item) => (
                    <RadioComponent
                      label={item.label}
                      value={
                        getContractsValuesFromKey(
                          item.key as keyof CustomerContract,
                        ) as boolean | null
                      }
                      key={item.key}
                      onRadioChange={handleRadioChange}
                    />
                  )}
                </For>
              </Box>
            </Card>
            <Card startTitle="Carrier/Broker Liability" accordion={false}>
              <Box>
                <Box class={cls.heading}>Carrier/Broker Liability</Box>
                <Divider class="!mb-2" />
                <For each={carrierBrokerLiabilityOptions}>
                  {(item) => (
                    <RadioComponent
                      label={item.label}
                      value={
                        getContractsValuesFromKey(
                          item.key as keyof CustomerContract,
                        ) as boolean | null
                      }
                      key={item.key}
                      onRadioChange={handleRadioChange}
                    />
                  )}
                </For>
              </Box>
              <Box>
                <Box class={cls.heading}>Broker Responsibilities</Box>
                <Divider class="!mb-2" />
                <For each={brokerResponsibilityOptions}>
                  {(item) => (
                    <RadioComponent
                      label={item.label}
                      value={
                        getContractsValuesFromKey(
                          item.key as keyof CustomerContract,
                        ) as boolean | null
                      }
                      key={item.key}
                      onRadioChange={handleRadioChange}
                    />
                  )}
                </For>
              </Box>
            </Card>
            <Card startTitle="Accessorial/Billing Information">
              <Box>
                <Box class={cls.heading}>Accessorial/Billing Information</Box>
                <Divider class="!mb-2" />
                <For each={accessorialOptions}>
                  {(item) => (
                    <RadioComponent
                      label={item.label}
                      value={
                        getContractsValuesFromKey(
                          item.key as keyof CustomerContract,
                        ) as boolean | null
                      }
                      key={item.key}
                      onRadioChange={handleRadioChange}
                    />
                  )}
                </For>
              </Box>
              <Box>
                <Box class={cls.heading}>Indemnification</Box>
                <Divider class="!mb-2" />
                <For each={indemnificationOptions}>
                  {(item) => (
                    <RadioComponent
                      label={item.label}
                      value={
                        getContractsValuesFromKey(
                          item.key as keyof CustomerContract,
                        ) as boolean | null
                      }
                      key={item.key}
                      onRadioChange={handleRadioChange}
                    />
                  )}
                </For>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Show>
  );
};
