import { Typography } from '@components';
import { ContainerItem } from '@store/customers/customerDetails';
import { openModal } from '@store/modals';
import { DragIndicator } from '@suid/icons-material';
import { Box, Grid } from '@suid/material';
import {
  DragDropProvider,
  DragDropSensors,
  DragEvent,
  DragOverlay,
  Draggable,
  Droppable,
  Id,
  SortableProvider,
  closestCenter,
  createDroppable,
  createSortable,
} from '@thisbeyond/solid-dnd';
import { For, batch } from 'solid-js';
import { Part, createStore } from 'solid-js/store';
import { effect } from 'solid-js/web';

type DragAndDropMultipleListProps = {
  initialData: Record<string, ContainerItem[]>;
  onDragEnd?: (containers: Record<string, ContainerItem[]>) => void;
  originContainer?: Record<number, string>;
  class?: string;
  setSelectedItem: ({
    containerId,
    item,
  }: {
    containerId: string;
    item: ContainerItem;
  }) => void;
};

const Sortable = (props: { item: Id; label: string; containerId: Id }) => {
  const sortable = createSortable(props.item);
  return (
    <div
      ref={sortable}
      class={`sortable cursor-pointer w-full px-1 py-2 !flex items-start !text-left rounded-lg shadow-sm  lg:!truncate  ${
        props.containerId === 'A'
          ? 'bg-[#468DB5] text-white'
          : 'bg-[#E0E0E0] text-black'
      }`}
      classList={{
        'opacity-50': sortable.isActiveDraggable,
      }}
    >
      <DragIndicator class="w-[10px] !mx-1 " />
      <Typography
        variant="body1"
        class="truncate font-medium !mx-2 text-[15px]"
      >
        {props.label}
      </Typography>
    </div>
  );
};

const Column = (props: {
  id: Id;
  items: ContainerItem[];
  setSelectedItem: ({
    containerId,
    item,
  }: {
    containerId: string;
    item: ContainerItem;
  }) => void;
}) => {
  const droppable = createDroppable(String(props.id));

  const openDialog = (item: ContainerItem) => {
    props.setSelectedItem({
      containerId: props.id as string,
      item:
        props.id === 'A'
          ? {
              ...item,
              isAdded: true,
              isDirty: true,
              isDeleted: false,
            }
          : item,
    });
    openModal('addOrEditParameter');
  };

  return (
    <Grid item xs={12} lg={6} ref={droppable}>
      <Box class="border border-[#999999] p-3 flex flex-col flex-1 gap-3 rounded-lg">
        <SortableProvider ids={props.items.map((item) => item.id)}>
          <For each={props.items}>
            {(item) => (
              <div onClick={() => openDialog(item)}>
                <Sortable
                  item={item.id}
                  label={item.name}
                  containerId={props.id}
                />
              </div>
            )}
          </For>
        </SortableProvider>
      </Box>
    </Grid>
  );
};

const EDIRulesDragAndDropMultipleList = (
  props: DragAndDropMultipleListProps,
) => {
  const [containers, setContainers] =
    createStore<Record<string, ContainerItem[]>>();

  effect(() => {
    setContainers(props.initialData);
  });

  const containerIds = () => Object.keys(containers);

  const isContainer = (id: Id) => containerIds().includes(id as string);

  const getContainer = (id: Id) => {
    for (const [key, items] of Object.entries(containers)) {
      if (items.some((item) => item.id === id)) {
        return key;
      }
    }
  };

  const closestContainerOrItem = (
    draggable: Draggable,
    droppables: Droppable[],
    context: { activeDroppableId: Id | null },
  ) => {
    const closestContainer = closestCenter(
      draggable,
      droppables.filter((droppable: Droppable) =>
        isContainer(droppable.id as string),
      ),
      context,
    );

    if (closestContainer) {
      const containerItemIds = containers[closestContainer.id as string].map(
        (item) => item.id,
      );
      const closestItem = closestCenter(
        draggable,
        droppables.filter((droppable: Droppable) =>
          containerItemIds.includes(droppable.id as number),
        ),
        context,
      );
      if (!closestItem) {
        return closestContainer;
      }

      if (getContainer(Number(draggable.id)) !== closestContainer.id) {
        const isLastItem =
          containerItemIds.indexOf(closestItem.id as number) ===
          containerItemIds.length - 1;

        if (isLastItem) {
          const belowLastItem =
            draggable.transformed.center.y > closestItem.transformed.center.y;

          if (belowLastItem) {
            return closestContainer;
          }
        }
      }
      return closestItem;
    }
  };

  const move = (
    draggable: Draggable,
    droppable: Droppable,
    onlyWhenChangingContainer = true,
  ) => {
    const draggableContainer = getContainer(draggable.id);
    const droppableContainer = isContainer(String(droppable.id))
      ? droppable.id
      : (getContainer(droppable.id as unknown as number) as string);

    if (
      draggableContainer != droppableContainer ||
      !onlyWhenChangingContainer
    ) {
      const containerItemIds = containers[droppableContainer]!.map(
        (item) => item.id,
      );
      let index = containerItemIds.indexOf(droppable.id as unknown as number);
      if (index === -1) index = containerItemIds.length;
      let draggableItem: ContainerItem | undefined;

      if (typeof draggableContainer === 'string') {
        const containerItems = containers[draggableContainer];
        if (Array.isArray(containerItems)) {
          draggableItem = containerItems.find(
            (item) => item.id === draggable.id,
          );
        }
      }
      batch(() => {
        if (typeof draggableContainer === 'string') {
          setContainers(
            draggableContainer as Part<Record<string, ContainerItem[]>, string>,
            (items) => items.filter((item) => item.id !== draggable.id),
          );
        }
        setContainers(String(droppableContainer), (items: ContainerItem[]) => {
          return [
            ...items.slice(0, index),
            String(droppableContainer) === 'A'
              ? {
                  ...(draggableItem as ContainerItem),
                  // TODO - Check RunOrder and set it Package is not letting me set it
                }
              : {
                  ...(draggableItem as ContainerItem),
                  isAdded: false,
                  isDirty: false,
                  isDeleted: true,
                },
            ...items.slice(index),
          ];
        });
      });

      props.onDragEnd && props.onDragEnd(containers);
    }
  };

  const onDragOver = ({ draggable, droppable }: DragEvent) => {
    if (droppable) move(draggable, droppable);
  };

  const onDragEnd = ({ draggable, droppable }: DragEvent) => {
    if (droppable) move(draggable, droppable, false);
  };

  const getDisplayValue = (id: string | number) => {
    const dataItems = [];
    for (const [, items] of Object.entries(containers)) {
      for (const item of items) {
        if (item.id === id) {
          dataItems.push(item);
        }
      }
    }

    return dataItems.find((item) => item.id === id)?.name;
  };

  return (
    <DragDropProvider
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      collisionDetector={closestContainerOrItem}
    >
      <DragDropSensors />
      <Grid container spacing={1}>
        <For each={containerIds()}>
          {(key) => (
            <Column
              setSelectedItem={props.setSelectedItem}
              id={key}
              items={containers[key]}
            />
          )}
        </For>
      </Grid>
      <DragOverlay>
        {(draggable) => (
          <>
            <div class="sortable">
              {draggable ? getDisplayValue(draggable.id) : null}
            </div>
          </>
        )}
      </DragOverlay>
    </DragDropProvider>
  );
};

export default EDIRulesDragAndDropMultipleList;
