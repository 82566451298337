import { CarrierContactT } from '@store/orders';

export const formatPhoneNumber = (
  value: string | number | undefined,
  orElse = '',
) => {
  if (value === undefined) {
    return orElse;
  }

  const strValue = String(value);
  const x = strValue.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  if (x) {
    return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  }

  return orElse;
};

export const formatContactPhone = (
  contact: CarrierContactT | undefined,
  format: 'link' | 'display',
) => {
  if (!contact) {
    return '-';
  }

  const link = contact.phone1;

  if (
    contact.extension1 !== '' &&
    contact.extension1 !== undefined &&
    contact.extension1 !== null
  ) {
    if (format === 'link') {
      return `${link},${contact.extension1}`;
    }

    if (formatPhoneNumber(link, '-') !== '') {
      return `${formatPhoneNumber(link, '-')} Ext. ${contact.extension1}`;
    }

    return '-';
  }

  return format === 'link' ? link : formatPhoneNumber(link, '-');
};
