import { For, Setter, Show, createSignal } from 'solid-js';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Skeleton,
} from '@suid/material';
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from '@suid/icons-material';
import {
  AlertsSidebarIcon,
  ContainerSidebarIcon,
  CustomersSidebarIcon,
  SidebarLTLIcon,
  MessagesSidebarIcon,
  ApprovalsSidebarIcon,
  AdvancesSidebarIcon,
  PricingToolSidebarIcon,
  PaperworkSidebarIcon,
} from '@assets';
import { userStore } from '@store/user';
import { isAdmin, isCarrierRelation } from '@utils/utils';

import cls from './classes';

export const drawerStyles = {
  drawerPaper: {
    height: '400px', // Drawer height is 90% of the viewport height
    marginTop: '10%', // Margin top is 10% of the viewport height
    boxShadow: 'none', // No shadow
    overflowX: 'hidden', // Hides the horizontal scrollbar
  },
};

export type SidebarMenuLabelTypes =
  | 'Announcements'
  | 'Approvals'
  | 'Advances'
  | 'Customers'
  | 'Pending Carriers'
  | 'Alerts'
  | 'Paperwork'
  | 'LTL'
  | 'Pricing Tool'
  | '';

type Props = {
  setChecked: Setter<SidebarMenuLabelTypes>;
  checked: () => SidebarMenuLabelTypes;
};

export default function Sidebar(props: Props) {
  const [isCollapsed, setIsCollapsed] = createSignal(true);

  const menuItems = [
    {
      label: 'Announcements',
      icon: MessagesSidebarIcon,
      hide: () => false,
    },
    {
      label: 'Approvals',
      icon: ApprovalsSidebarIcon,
      hide: () => isAdmin(),
    },
    {
      label: 'Advances',
      icon: AdvancesSidebarIcon,
      hide: () => false,
    },
    {
      label: 'Customers',
      icon: CustomersSidebarIcon,
      hide: () => !isAdmin(),
    },
    {
      label: 'Pending Carriers',
      icon: ContainerSidebarIcon,
      hide: () => !isCarrierRelation(),
    },
    {
      label: 'Alerts',
      icon: AlertsSidebarIcon,
      hide: () => false,
    },
    {
      label: 'Paperwork',
      icon: PaperworkSidebarIcon,
      hide: () => isAdmin(),
    },
    {
      label: 'LTL',
      icon: SidebarLTLIcon,
      hide: () => false,
    },
    {
      label: 'Pricing Tool',
      icon: PricingToolSidebarIcon,
      hide: () => false,
    },
  ];

  const SidebarMenuSkeleton = () => (
    <For each={[1, 2, 3, 4, 5, 6]}>
      {() => (
        <MenuList class="h-12 w-full border-transparent flex items-center  mb-4">
          <MenuItem class="!py-0">
            <ListItemText class="!text-[#ffffff]">
              <Skeleton variant="text" width={40} height={40} />
            </ListItemText>
          </MenuItem>
        </MenuList>
      )}
    </For>
  );

  const drawerList = () => (
    <Box class="mt-4">
      <Show when={!userStore.isLoading} fallback={<SidebarMenuSkeleton />}>
        <For each={menuItems}>
          {(item) =>
            !Boolean(item.hide()) && (
              <MenuList
                class={`border-l-4 h-12 w-full border-transparent flex items-center  mb-4 hover:border-white hover:bg-[#01618D] ${
                  props.checked() === item.label
                    ? 'border-white bg-[#01618D]'
                    : ''
                }`}
                onClick={() =>
                  props.setChecked(item.label as SidebarMenuLabelTypes)
                }
              >
                <MenuItem class="!py-0">
                  <ListItemIcon class="!text-[#A5C6DA]">
                    <img src={item.icon} alt={item.label} />
                  </ListItemIcon>
                  {!isCollapsed() && (
                    <ListItemText class="!text-[#ffffff]">
                      {item.label}
                    </ListItemText>
                  )}
                </MenuItem>
              </MenuList>
            )
          }
        </For>
      </Show>
    </Box>
  );

  return (
    <div class={cls.container}>
      <button
        class={cls.toggleButton}
        onClick={() => setIsCollapsed(!isCollapsed())}
      >
        {isCollapsed() ? (
          <KeyboardArrowRightOutlined class="bg-[#468DB6] rounded-full " />
        ) : (
          <KeyboardArrowLeftOutlined class="bg-[#468DB6] rounded-full " />
        )}
      </button>
      <div class={cls.content(isCollapsed())}>{drawerList()}</div>
    </div>
  );
}
