import { ReassignTypeEnum } from '@common/types';
import { Popover, ReassignContactCard, Typography } from '@components';
import { ILoadEditViewModel, orderStore } from '@store/orders';
import { Box, Grid } from '@suid/material';
import ReassignCard from '@views/order/components/reassign/ReassignCard';
import { Show } from 'solid-js';

import { coveredByStyle, subTextStyle } from '../styles';
import { loadInfoStore } from './store';
import { handleReassignedUser } from './utils';

export type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

export const CoveredBy = (props: PropsT) => {
  return (
    <div class=" flex bg-[#dfecf2] p-2 rounded max-h-12 min-w-52">
      <Box
        onClick={() => {
          if (props.load.coveredBy != null) {
            void handleReassignedUser(
              props.load.coveredById as number,
              ReassignTypeEnum.CoveredBy,
            );
          }
        }}
      >
        <Show when={props.load.coveredBy != null}>
          <Popover
            eleName={
              <div class="text-left">
                <div class="text-[#123b50] font-semibold text-xs">
                  Covered By
                </div>
                <Show
                  when={props.load.coveredBy}
                  fallback={
                    <Typography
                      variant="body1"
                      component="div"
                      sxProps={{ textAlign: 'left' }}
                    >
                      -
                    </Typography>
                  }
                >
                  <div class="font-medium">{props.load.coveredBy}</div>
                </Show>
              </div>
            }
            sxProps={coveredByStyle}
          >
            <Box sx={{ padding: '8px' }}>
              <ReassignContactCard
                contact={loadInfoStore.coveredBy}
                showIcon
                isLoading={loadInfoStore.isLoading}
              />
            </Box>
          </Popover>
        </Show>
        <Show when={props.load.coveredBy == null}>
          <Grid item>
            <Typography
              variant="body1"
              component="div"
              sxProps={{ ...subTextStyle, textAlign: 'left' }}
            >
              Covered By
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" component="div" class="text-left">
              <Typography
                variant="body1"
                component="div"
                sxProps={{ textAlign: 'left' }}
              >
                -
              </Typography>
            </Typography>
          </Grid>
        </Show>
      </Box>
      <div class="mt-3.5 ml-auto">
        <Popover
          classes="h-[20px]"
          eleName={
            <Box
              class=" text-[#026EA1] font-medium text-sm leading-6 tracking-tighter cursor-pointer "
              onClick={() => {
                if (orderStore.isReadOnly) {
                  return;
                }

                if (props.load.coveredBy != null) {
                  void handleReassignedUser(
                    props.load.coveredById as number,
                    ReassignTypeEnum.CoveredBy,
                  );
                }
              }}
            >
              REASSIGN
            </Box>
          }
        >
          <ReassignCard
            type={'COVERED BY'}
            contactInfo={
              props.load.coveredById != null
                ? loadInfoStore.coveredBy
                : undefined
            }
            isLoading={() => loadInfoStore.isLoading}
            onButtonClick={handleReassignedUser}
          />
        </Popover>
      </div>
    </div>
  );
};
