import { BasicModal } from '@components';
import GoogleMapsStreetView from '@components/Map/GoogleMapsStreetView';

interface StopStreetViewMapProps {
  lat: number | undefined;
  lng: number | undefined;
  modalId: string;
}
const StopStreetViewMap = (props: StopStreetViewMapProps) => {
  return (
    <BasicModal
      id={props.modalId}
      header={true}
      footer={false}
      showClose={true}
      title="StreetView"
    >
      <GoogleMapsStreetView lat={props.lat} lng={props.lng} />
    </BasicModal>
  );
};
export default StopStreetViewMap;
