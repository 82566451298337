export const ContactData = [
  {
    Id: 1074579,
    ObjectId: 317725,
    ObjectType: null,
    Name: 'parth',
    Phone1: '4352345234',
    Extension1: null,
    Phone2: '4353245234',
    Extension2: null,
    Fax: '3245324523',
    Email: 'test@ga.ds',
    Type: 'Billing',
    Status: null,
    CreatedDate: '0001-01-01T00:00:00',
    ModifiedDate: null,
    MailerEmails: false,
    Password: null,
    UserName: null,
    Phone: '4352345234',
  },
];

export const CustomerTypes = ['Billing', 'Freight', 'EDI', 'Collections'];

export const CarrierTypes = ['Billing', 'Driver', 'Dispatch'];

export const factoringCompanyDetailsTypes = [
  'Billing',
  'Payments',
  'Remittance',
  'Other',
];
