import { BasicModal, Button, Menu } from '@components';
import { useNavigate, useParams } from '@solidjs/router';
import { openModal } from '@store/modals';
import { copyOrder, updateOrderFormErrorState } from '@store/orders';
import { orderStore } from '@store/orders/store';
import { ArrowDropDown } from '@suid/icons-material';
import { routeOrderDetailsCopyOrder } from '@utils/routeOrderDetails';
import { ADD_BILLING_HOLD_NOTE } from '@views/order/constants';
import { Component, createEffect, createSignal } from 'solid-js';

import BillingHoldModal from '../models/BillingHoldModal';
import { CopyMultipleModal, copyMultipleModalId } from './CopyMultipleModal';
import {
  SaveAsTemplateModal,
  saveAsTemplateModalId,
} from './SaveAsTemplateModal';
import { VoidModal, voidModalId } from './VoidModal';
import { getMenuItems } from './helpers';

const MoreOptions: Component = () => {
  const params = useParams();
  const isCreate = !params.id;
  const navigate = useNavigate();

  const [moreOptions, setMoreOptions] = createSignal(
    getMenuItems(orderStore.isReadOnly, orderStore.isSmallScreen),
  );

  const handleOnClick = async (value: string) => {
    switch (value) {
      case 'Add Billing Hold/Note':
        openModal(ADD_BILLING_HOLD_NOTE);
        break;
      case 'Void Order':
        openModal(voidModalId);
        break;
      case 'Copy Multiple':
        openModal(copyMultipleModalId);
        break;
      case 'Save As Template':
        openModal(saveAsTemplateModalId);
        break;
      case 'Copy Order':
        await copyOrder();
        if (orderStore.copiedOrderId !== undefined) {
          const isNative = routeOrderDetailsCopyOrder(orderStore.copiedOrderId);
          if (isNative) {
            navigate(`/order/details/?copyOrderId=${orderStore.copiedOrderId}`);
          }
          orderStore.copiedOrderId;
        }
        updateOrderFormErrorState({});
        break;
      default:
        // Handle default case if necessary
        break;
    }
  };

  const newOrderMoreOptions = () => [
    { label: 'Copy Order', value: 'Copy Order' },
    { label: 'Copy Multiple', value: 'Copy Multiple' },
    { label: 'Save as Template', value: 'Save as Template' },
  ];

  createEffect(() => {
    if (isCreate) {
      setMoreOptions(newOrderMoreOptions);
      return;
    }
    setMoreOptions(() => {
      let items = getMenuItems(orderStore.isReadOnly, orderStore.isSmallScreen);

      if (orderStore.order.status === 'Void') {
        items = items.filter((val) => val.label !== 'Void Order');
      }

      return items;
    });
  });

  return (
    <>
      <Menu
        menuItems={moreOptions()}
        menuButtonLabel={
          <Button
            endIcon={<ArrowDropDown />}
            label="More Options"
            size="small"
            variant="outlined"
            class="h-fill-available whitespace-nowrap"
          />
        }
        onMenuItemClick={(item) => {
          void handleOnClick(item);
        }}
        downArrowShowHide={false}
        width={200}
      />

      <SaveAsTemplateModal />
      <BasicModal
        id={copyMultipleModalId}
        width="90vw"
        header={false}
        footer={false}
      >
        <CopyMultipleModal />
      </BasicModal>
      <BasicModal
        id={voidModalId}
        width="340px"
        header={false}
        footer={false}
        hideClose={true}
      >
        <VoidModal />
      </BasicModal>
      <BasicModal
        id={ADD_BILLING_HOLD_NOTE}
        width="900px"
        header={false}
        footer={false}
      >
        <BillingHoldModal modalId={ADD_BILLING_HOLD_NOTE} />
      </BasicModal>
    </>
  );
};

export default MoreOptions;
