import { atgLogo } from '@assets';
import {
  BasicModal,
  Button,
  LTLProductCatalog,
  Popover,
  TableRowErrors,
  Typography,
} from '@components';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import Tooltip from '@components/Tooltip';
import { LoadLineItemDimensions, TotalLinearFeet } from '@store/ltl';
import { GetCalculationsFromLoadItems } from '@store/ltl/services';
import { ltlQuoteState, setLtlQuoteState } from '@store/ltl/store';
import { LTLLoadItem } from '@store/ltl/types';
import { closeModal, openModal } from '@store/modals';
import { FeatureFlagCustomer, LTLCatalogs } from '@store/orders';
import { intialHazmatDetails } from '@store/orders/defaultVals';
import {
  Add,
  AddCircleOutlineOutlined,
  Delete,
  Edit,
  Info,
} from '@suid/icons-material';
import {
  Box,
  IconButton,
  Stack,
  StyledProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@suid/material';
import {
  calculateDensity,
  getTotalShipmentPCF,
  getTotalWeight,
  singleWeightTooMuch,
} from '@utils/ltlUtils';
import { HazardousMaterial } from '@views/order/components/carrier/stops/stopsEditor/HazardousMaterial';
import { compact } from 'lodash';
import {
  Accessor,
  Component,
  Index,
  Show,
  createSignal,
  onMount,
} from 'solid-js';

import { LTlFormClasses as cls } from '../classes';
import {
  DEFAULT_LOAD_ITEM,
  LTLWeightLimit,
  freightClasses,
  unitTypes,
} from '../constants';
import { LTlFormStyles } from '../styles';
import { LTLQuotingState } from '../types';
import { ExclusionList } from './ExclusionList';
import SuggestLineItemClass from './SuggestLineItemClass';
const cellStyles = {
  padding: '10px !important',
  borderBottom: '0px !important',
};

export type LTLLoadItemsProps = {
  setFields: (field: string, value: unknown) => void;
  customerFeatureFlagInfo: Accessor<FeatureFlagCustomer>;
  data: Accessor<LTLQuotingState>;
  errors: (field: string) => string | undefined;
  suggestedClassSettingEnabled: () => boolean;
};

export const LTLLoadItems: Component<LTLLoadItemsProps> = (props) => {
  const { setFields } = props;

  const [loadItemDimChange, setLoadItemDimChange] = createSignal(false);
  const [linearFeetLoading, setLinearFeetLoading] = createSignal(false);

  const tableHeaders = [
    {
      title: '# of Units',
      required: true,
      minWidth: '105px',
      maxWidth: '105px',
    },
    {
      title: 'Unit Type',
      required: true,
      minWidth: '120px',
      maxWidth: '120px',
    },
    {
      title: 'Item Description',
      required: true,
      minWidth: '140px',
      maxWidth: '140px',
    },
    {
      title: '# of Pcs',
      minWidth: '90px',
      maxWidth: '90px',
    },
    {
      title: 'Weight',
      required: true,
      minWidth: '110px',
      maxWidth: '110px',
    },
    {
      title: 'Dimensions (in)',
      minWidth: '210px',
      maxWidth: '210px',
    },
    {
      title: 'NMFC',
      minWidth: '120px',
      maxWidth: '120px',
    },
    {
      title: 'Class',
      required: true,
      minWidth: '100px',
      maxWidth: '100px',
    },
    {
      title: 'Hazmat',
      minWidth: '80px',
      maxWidth: '180px',
    },
    {
      title: 'PCF (lb/ft3)',
      minWidth: '120px',
      maxWidth: '120px',
    },
    {
      title: 'Actions',
    },
  ];

  const createLoadItem = (item: LTLCatalogs) => ({
    quantity: 1,
    item: item.unitType || 'Pallets',
    class: item.class?.toString(),
    description: item.description,
    lengthInch: item.lengthInches,
    widthInch: item.widthInches,
    heightInch: item.heightInches,
    pieces: item.pieces,
    nmfc: item.nMFC,
    hazmat: item.hazmat,
    weight: item.weight ?? 0,
    loadItemHazmatDetail: item.hazmat
      ? {
          hazardClass: item.hazardClass,
          packingGroup: item.packingGroup,
          unNumber: item.uNNumber,
          contactName: item.contactName,
          contactNumber: item.contactNumber,
          piece: item.piece,
        }
      : null,
  });

  const addListItemFromLTLCatalog = (item: LTLCatalogs) => {
    const loadItems = props.data().loadItems;
    if (
      loadItems.length === 1 &&
      'key' in loadItems[0] &&
      delete loadItems[0]?.key &&
      JSON.stringify(loadItems[0]) === JSON.stringify(DEFAULT_LOAD_ITEM)
    ) {
      const newLoadItems = [createLoadItem(item)];
      setFields('loadItems', newLoadItems);
    } else {
      setFields('loadItems', [...props.data().loadItems, createLoadItem(item)]);
    }
    closeModal('sidebar-ltl-product-catalog');
  };

  const createErrors = (index: number) => {
    const fields = [
      'quantity',
      'description',
      'weight',
      'lengthInch',
      'widthInch',
      'heightInch',
      'class',
      //ADD MORE FIELDS HERE IF NEEDED
    ];
    return compact(
      fields.map((field) => props.errors(`loadItems[${index}].${field}`)),
    );
  };

  const handleHazmatChange = (
    rowData: Accessor<LTLLoadItem>,
    index: number,
  ) => {
    setFields(`loadItems.${index}.hazmat`, !rowData().hazmat);
    if (!rowData().hazmat) {
      setFields(`loadItems.${index}.loadItemHazmatDetail`, {});
    }
  };

  const getCalculationsFromLoadItems = async () => {
    if (!loadItemDimChange()) {
      setLoadItemDimChange(false);
      return;
    }
    if (props.data().isLinearFeetOverridden) {
      setFields('linearFeet', props.data().linearFeet);
      return;
    }
    const totalLinearFeet: TotalLinearFeet = {
      isStackable: props.data().isStackable,
      loadItems: props.data().loadItems.map((item) => ({
        heightInch: item.heightInch,
        lengthInch: item.lengthInch,
        widthInch: item.widthInch,
        quantity: item.quantity,
        weight: Number(item.weight),
      })) as LoadLineItemDimensions[],
    };
    setLinearFeetLoading(true);
    const response = await GetCalculationsFromLoadItems(totalLinearFeet);
    setFields('linearFeet', response);
    setLoadItemDimChange(false);
    setLinearFeetLoading(false);
    try {
      const response = await GetCalculationsFromLoadItems(totalLinearFeet);
      setFields('linearFeet', response);
      setLoadItemDimChange(false);
    } catch (error) {
      return;
    } finally {
      setLinearFeetLoading(false);
    }
  };

  const handleOnChangeForLinearfeet = async (index: number) => {
    if (
      props.data().loadItems[index].lengthInch !== null &&
      props.data().loadItems[index].widthInch !== null &&
      props.data().loadItems[index].heightInch !== null
    ) {
      await getCalculationsFromLoadItems();
    }
  };

  const handleDeclaredValueChange = (value: number) => {
    if (!Boolean(value)) {
      //resetting so that the store is reset and the old values dont show up
      setLtlQuoteState('falveyPricingQuote', undefined);
    }
    setFields('declaredValue', value);
  };

  onMount(() => {
    // TODO : Refactor flow for LTL
    if (props.data().loadItems.length > 0) {
      setLoadItemDimChange(true);
      void getCalculationsFromLoadItems();
    }
  });

  return (
    <>
      <Stack spacing={1} pt={1} position="relative">
        <Show when={linearFeetLoading()}>
          <img
            src={atgLogo}
            alt="Armstrong Transport Group"
            class="size-16 absolute left-1/2 top-1/2 z-10"
          />
        </Show>
        <Box
          displayRaw="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Box displayRaw="flex" alignItems="center" gap={2}>
            <Typography variant="h6" sxProps={{ color: '#123B50' }}>
              Items Picking Up
            </Typography>
            <Button
              disabled={!Boolean(props.data().customerId)}
              variant="contained"
              startIcon={<Add />}
              label="Open Product Catalog"
              title="Select a specific customer to enable the product catalog"
              onClick={() => openModal('sidebar-ltl-product-catalog')}
            />
          </Box>
          <Show when={ltlQuoteState.isFalveyInsuranceFlow}>
            <Box displayRaw="flex" alignItems="center" gap={2}>
              <TextInput
                label="Declared Value"
                value={String(props.data().declaredValue ?? '')}
                type="number"
                onChange={(val) => {
                  handleDeclaredValueChange(Number(val));
                }}
                size="small"
                fullWidth={false}
                placeholder="Add Cargo Value"
                disabled={!Boolean(props.data().customerId)}
                error={props.errors('declaredValue')}
                dataTestId="declared_value"
              />
              <Typography
                variant="h6"
                sxProps={{
                  color: '#123B50',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '12px',
                  maxWidth: '330px',
                }}
              >
                Please review this{' '}
                <Popover
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  classes="!p-0 !font-medium !text-[#026EA1] !leading-3"
                  parenDivStyle="contents"
                  eleName={<>EXCLUSION LIST</>}
                >
                  <ExclusionList />
                </Popover>{' '}
                to check for items not covered by insurance added to this quote.
                <span class="underline">
                  ITEMS IN THIS LIST WILL NOT BE COVERED
                </span>{' '}
                in the event of a claim.
              </Typography>
            </Box>
          </Show>
        </Box>
        <Box
          class={`${cls.tableContainer}
          ${linearFeetLoading() ? 'cursor-not-allowed' : 'cursor-pointer'}
          `}
        >
          <Table
            class={`${
              linearFeetLoading()
                ? 'pointer-events-none'
                : 'pointer-events-auto'
            }`}
          >
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell
                    sx={{
                      minWidth: header.minWidth,
                      maxWidth: header.maxWidth,
                    }}
                  >
                    {header.title}
                    {Boolean(header.required) && (
                      <span class={cls.mandatory}> *</span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <Index each={props.data().loadItems}>
                {(rowData, index) => (
                  <>
                    <TableRow>
                      <TableCell sx={cellStyles}>
                        <TextInput
                          label=""
                          value={rowData().quantity}
                          maxLength={2}
                          type="number"
                          onChange={(e) => {
                            if (e !== props.data().loadItems[index].quantity) {
                              setLoadItemDimChange(true);
                            }
                            setFields(`loadItems.${index}.quantity`, Number(e));
                            void handleOnChangeForLinearfeet(index);
                          }}
                          size="small"
                          placeholder="Qty"
                          onBlur={(e: Event) => {
                            const target = e.target as HTMLInputElement;
                            if (target.value === '') {
                              setFields(`loadItems.${index}.quantity`, 0);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <SelectField
                          variant="outlined"
                          size="small"
                          label=""
                          onChange={(e) => {
                            setFields(
                              `loadItems.${index}.type`,
                              e.target.value,
                            );
                            setFields(
                              `loadItems.${index}.item`,
                              e.target.value,
                            );
                          }}
                          value={
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            rowData().type !== null &&
                            rowData().type !== undefined &&
                            rowData().type !== ''
                              ? rowData().type
                              : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                rowData().item !== null &&
                                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                  rowData().item !== undefined &&
                                  rowData().item !== ''
                                ? rowData().item
                                : 'Pallets'
                          }
                          error={props.errors(`loadItems.${index}.type`)}
                          menuItems={unitTypes}
                          data-testid="unit_type_dropdown_bags"
                        ></SelectField>
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <TextInput
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Description"
                          value={rowData().description}
                          onChange={(e) =>
                            setFields(`loadItems.${index}.description`, e)
                          }
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <TextInput
                          label=""
                          type="number"
                          placeholder="#"
                          size="small"
                          maxLength={8}
                          value={rowData().pieces ?? ''}
                          onChange={(e) =>
                            setFields(`loadItems.${index}.pieces`, Number(e))
                          }
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <TextInput
                          label=""
                          size="small"
                          type="number"
                          maxLength={6}
                          value={rowData().weight}
                          onChange={(e) => {
                            if (e !== props.data().loadItems[index].weight) {
                              setLoadItemDimChange(true);
                            }

                            setFields(`loadItems.${index}.weight`, e);
                            void handleOnChangeForLinearfeet(index);
                          }}
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <Stack direction="row" spacing="5px">
                          <TextInput
                            label=""
                            placeholder="L"
                            maxLength={3}
                            type="number"
                            variant="outlined"
                            size="small"
                            value={rowData().lengthInch ?? ''}
                            onChange={(e) => {
                              if (
                                e !== props.data().loadItems[index].lengthInch
                              ) {
                                setLoadItemDimChange(true);
                              }
                              setFields(
                                `loadItems.${index}.lengthInch`,
                                Number(e),
                              );
                              void handleOnChangeForLinearfeet(index);
                            }}
                          />
                          <TextInput
                            label=""
                            placeholder="W"
                            variant="outlined"
                            maxLength={3}
                            type="number"
                            size="small"
                            value={rowData().widthInch ?? ''}
                            onChange={(e) => {
                              if (
                                e !== props.data().loadItems[index].widthInch
                              ) {
                                setLoadItemDimChange(true);
                              }
                              setFields(
                                `loadItems.${index}.widthInch`,
                                Number(e),
                              );
                              void handleOnChangeForLinearfeet(index);
                            }}
                          />
                          <TextInput
                            label=""
                            placeholder="H"
                            type="number"
                            maxLength={3}
                            variant="outlined"
                            size="small"
                            value={rowData().heightInch ?? ''}
                            onChange={(e) => {
                              if (
                                e !== props.data().loadItems[index].heightInch
                              ) {
                                setLoadItemDimChange(true);
                              }
                              setFields(
                                `loadItems.${index}.heightInch`,
                                Number(e),
                              );
                              void handleOnChangeForLinearfeet(index);
                            }}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <TextInput
                          label=""
                          maxLength={10}
                          variant="outlined"
                          size="small"
                          placeholder="NMFC"
                          value={rowData().nmfc}
                          onChange={(e) =>
                            setFields(`loadItems.${index}.nmfc`, e)
                          }
                          dataTestId="dataTestId"
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <SelectField
                          width="100%"
                          label=""
                          size="small"
                          value={rowData().class?.toString()}
                          onChange={(e) => {
                            setFields(
                              `loadItems.${index}.class`,
                              e.target.value,
                            );
                          }}
                          menuItems={freightClasses}
                          dataTestId="dataTestId"
                        ></SelectField>
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <Popover
                          forceClosePopover={!rowData().hazmat}
                          eleName={
                            <div class={cls.hazmatPopOverContainer}>
                              <CheckboxInput
                                label=""
                                sxProps={LTlFormStyles.hazmatCheckBox}
                                checked={Boolean(rowData().hazmat)}
                                onChange={() =>
                                  handleHazmatChange(rowData, index)
                                }
                              />
                              <Edit
                                sx={{
                                  color: Boolean(rowData().hazmat)
                                    ? 'text-[#026EA1]'
                                    : 'gray',
                                }}
                              />
                            </div>
                          }
                          classes={cls.hazmatPopOver}
                          sxProps={LTlFormStyles.hazmatPopOver}
                        >
                          {(closePopover) => (
                            <HazardousMaterial
                              fromSidebar={true}
                              loadItemHazmatDetail={
                                props.data().loadItems[index]
                                  ?.loadItemHazmatDetail ?? intialHazmatDetails
                              }
                              onSave={(hazmatDetail) => {
                                setFields(
                                  `loadItems.${index}.loadItemHazmatDetail`,
                                  hazmatDetail,
                                );
                                setFields(`loadItems.${index}.hazmat`, true);
                                closePopover();
                              }}
                              onClose={closePopover}
                            />
                          )}
                        </Popover>
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <TextInput
                          label="PCF"
                          InputProps={{
                            disableUnderline: true,
                            readOnly: true,
                          }}
                          value={calculateDensity(
                            props.data().loadItems[index],
                          )}
                          variant="filled"
                          size="small"
                        />
                      </TableCell>
                      <TableCell sx={cellStyles}>
                        <IconButton
                          onClick={() => {
                            const filteredLoadItems = props
                              .data()
                              .loadItems.filter((item, i) => i !== index);
                            setFields('loadItems', filteredLoadItems);
                            if (filteredLoadItems.length === 0) {
                              setFields('linearFeet', '');
                            }
                            if (filteredLoadItems.length !== 0) {
                              setLoadItemDimChange(true);
                              void getCalculationsFromLoadItems();
                            }
                          }}
                        >
                          <Delete class={cls.deleteIcon} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <SuggestLineItemClass
                      customerFeatureFlagInfo={props.customerFeatureFlagInfo}
                      data={props.data}
                      index={index}
                      setFields={setFields}
                      suggestedClassSettingEnabled={
                        props.suggestedClassSettingEnabled
                      }
                    />
                    <TableRowErrors
                      columnsLength={tableHeaders.length}
                      tableErrors={createErrors(index)}
                      tableRowClasses="!bg-white"
                    />
                    <Show
                      when={singleWeightTooMuch(props.data().loadItems[index])}
                    >
                      <TableRowErrors
                        type="warning"
                        columnsLength={11}
                        tableErrors={[
                          `The single item weight entered is over ${LTLWeightLimit.Single} lbs, possibly exceeding weight limitations. Please reach out to LTL@armstrongtransport.com for assistance with pricing.`,
                        ]}
                        tableRowClasses="!bg-white !border-none"
                      />
                    </Show>
                    <Show
                      when={
                        (props.data().loadItems[index]?.lengthInch ?? 0) > 95
                      }
                    >
                      <TableRowErrors
                        type="warning"
                        columnsLength={11}
                        tableRowClasses="!bg-white !border-none"
                        tableErrors={[
                          'The length entered qualifies as an overlength item. Select the appropriate accessorial. If none apply, please reach out to LTL@armstrongtransport.com for assistance.',
                        ]}
                      />
                    </Show>
                  </>
                )}
              </Index>
            </TableBody>
            <TableFooter>
              <TableRow sx={LTlFormStyles.tableFooterRow}>
                <TableCell sx={cellStyles}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      const loadItems = [
                        ...props.data().loadItems,
                        DEFAULT_LOAD_ITEM,
                      ];
                      setFields('loadItems', loadItems);
                    }}
                  >
                    <AddCircleOutlineOutlined class="!text-[#026EA1]" />
                  </IconButton>
                </TableCell>
                <TableCell colspan={3.5} sx={{ border: 0 }}></TableCell>
                <TableCell colspan={3.5} sx={{ border: 0 }}>
                  <Box
                    displayRaw="flex"
                    alignItems="center"
                    justifyContent="end"
                    gap={2}
                  >
                    <Box flex={1}>
                      <TextInput
                        label="Total Linear Feet"
                        fullWidth
                        inputProps={{
                          readOnly: !Boolean(
                            props.data().isLinearFeetOverridden,
                          ),
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          props.data().linearFeet === ''
                            ? 0
                            : props.data().linearFeet
                        }
                        onChange={(e) => setFields('linearFeet', e)}
                        variant="outlined"
                        size="small"
                        sxProps={{
                          '& fieldset': {
                            border: !Boolean(
                              props.data().isLinearFeetOverridden,
                            )
                              ? 'none'
                              : '2px solid #E0E0E0',
                          },
                          width: '140px',
                        }}
                      />
                    </Box>
                    <Box
                      flex={1}
                      displayRaw="flex"
                      gap={0.2}
                      alignItems="center"
                    >
                      <CheckboxInput
                        label="Override"
                        checked={props.data().isLinearFeetOverridden}
                        onChange={async (e) => {
                          setFields('isLinearFeetOverridden', e);
                          setLoadItemDimChange(true);
                          await getCalculationsFromLoadItems();
                          if (!e) {
                            setFields('linearFeet', props.data().linearFeet);
                          }
                        }}
                      />
                      <Tooltip
                        textWidth="420px"
                        text="The Total Linear Feet is a vital element of the LTL Quote Tool, ensuring accurate estimations for efficient freight management. Users can manually adjust this calculated value by enabling the 'Override' checkbox."
                      >
                        <Info class="!text-[#026EA1] " />
                      </Tooltip>
                    </Box>
                    <Box flex={1}>
                      <CheckboxInput
                        label="Stackable"
                        checked={props.data().isStackable}
                        onChange={(e) => {
                          setFields('isStackable', e);
                          setLoadItemDimChange(true);
                          void getCalculationsFromLoadItems();
                        }}
                      />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  colspan={2}
                  sx={LTlFormStyles.totalWeightCell as StyledProps}
                >
                  <TextInput
                    label="Total Shipment Weight"
                    InputProps={{ disableUnderline: true, readOnly: true }}
                    value={getTotalWeight(props.data().loadItems)}
                    variant="filled"
                    size="small"
                    classes={
                      getTotalWeight(props.data().loadItems) >
                      LTLWeightLimit.Total
                        ? '!bg-red-100'
                        : ''
                    }
                  />
                </TableCell>
                <TableCell colspan={2} sx={LTlFormStyles.totalShipmentPCF}>
                  <TextInput
                    label="Shipment PCF"
                    InputProps={{ disableUnderline: true, readOnly: true }}
                    value={getTotalShipmentPCF(props.data().loadItems)}
                    variant="filled"
                    size="small"
                  />
                </TableCell>
              </TableRow>
              <Show
                when={
                  getTotalWeight(props.data().loadItems) > LTLWeightLimit.Total
                }
              >
                <TableRowErrors
                  type="warning"
                  columnsLength={11}
                  tableErrors={[
                    ' The weight entered exceeds 10,000 lbs, qualifying for volume LTL. Please reach out to LTL@armstrongtransport.com for assistance with pricing.',
                  ]}
                  tableRowClasses="!bg-white"
                />
              </Show>
            </TableFooter>
          </Table>
        </Box>
      </Stack>
      <BasicModal
        id="sidebar-ltl-product-catalog"
        footer={false}
        width="1300px"
        maxWidth="95vw"
        title=" Select LTL Product Catalog"
      >
        <LTLProductCatalog
          customerId={props.data().customerId!}
          goBackButtonText="< Back to LTL Quoting"
          goBack={() => closeModal('sidebar-ltl-product-catalog')}
          onRowClick={(rowData: LTLCatalogs) => {
            addListItemFromLTLCatalog(rowData);
          }}
        />
      </BasicModal>
    </>
  );
};
