import { Grid, Stack } from '@suid/material';
import { Typography } from '@components/Typography';
import { Accessor, Setter } from 'solid-js';
import { SelectField } from '@components/forms';
import { SelectChangeEvent } from '@suid/material/Select';

import { searchItems } from '../constants';

type SelectSearchCategoryPropsType = {
  category: Accessor<string>;
  setCategory: Setter<string>;
  onChange: (e: SelectChangeEvent<string>) => void;
};

export const SelectSearchCategory = (props: SelectSearchCategoryPropsType) => {
  const menuItems = searchItems.map((item) => ({
    value: item.label,
    label: (
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <item.icon />
        </Grid>
        <Grid item xs={10}>
          <Stack>
            <Typography variant="body1">{item.label}</Typography>
            <Typography variant="caption">{item.disclaimer}</Typography>
          </Stack>
        </Grid>
      </Grid>
    ),
  }));

  return (
    <SelectField
      label=""
      renderValue={(value) => value}
      menuItems={menuItems}
      value={props.category()}
      onChange={props.onChange}
      setFitContent
    />
  );
};
