import { createStore, produce } from 'solid-js/store';

import { EditHistoryState } from './types';
import { getEditHistory } from './services';

const [editHistoryStore, setEditHistoryStore] = createStore<EditHistoryState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  items: [],
  overlay: false,
});

export const fetchEditHistory = async (orderId: string) => {
  try {
    setEditHistoryStore('isLoading', true);
    const resp = await getEditHistory(orderId);
    const formatedResp = resp.map((item) => {
      item.timeStamp = new Date(item.timeStamp).toLocaleString();
      return item;
    });
    setEditHistoryStore(
      produce((draft: EditHistoryState) => {
        draft.items = formatedResp;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setEditHistoryStore(
      produce((draft: EditHistoryState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading history';
        draft.isLoading = false;
      }),
    );
  }
};
export { editHistoryStore, setEditHistoryStore };
