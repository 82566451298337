import { createSignal } from 'solid-js';
import { Paper, Stack } from '@suid/material';
import { Typography } from '@components';

import { LTLForm } from './LTLForm';
import { saveQuoteClasses as cls } from './classes';
import { QuoteResults } from './components/quoteResults/LTLQuoteResults';

export const LTL = (props: { isModal?: boolean }) => {
  const [activeView, setActiveView] = createSignal('quote-form');
  //NOTE WE don't need this, probably,m it can lead to bad performance, since we already have state and signals
  // const [load] = createSignal<ILoadEditViewModel | undefined>(
  //   orderStore.order.loads[orderStore.activeTab.index] ?? undefined,
  // );
  // const [quote] = createSignal<LtlQuoteViewModel | undefined>(
  //   orderStore.order.loads.length > 0
  //     ? orderStore.order.loads[orderStore.activeTab.index].ltlQuote
  //     : undefined,
  // );
  return (
    <>
      <Stack spacing={1.4} p={2}>
        <Typography variant="h1" class={cls.heading}>
          LTL Quoting
        </Typography>
        <Paper elevation={3}>
          <Stack
            p={1.4}
            bgcolor="white"
            class={cls.tabContainer}
            height={Boolean(props.isModal) ? '' : '85vh'}
          >
            <div class={activeView() === 'quote-form' ? 'block' : 'hidden'}>
              <LTLForm setActiveView={setActiveView} />
            </div>
            <div class={activeView() === 'quote-result' ? 'block' : 'hidden'}>
              <QuoteResults setActiveView={setActiveView} />
            </div>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};
