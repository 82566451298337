import { ApiResponse, apiClient, v3Client } from '@api/apiClient';
import { useKeyDownEvent } from '@solid-primitives/keyboard';
import {
  CarrierAutoCompleteModel,
  Contact,
  ICustomerContact,
  ILocationSearch,
  LastUsedTopStopModel,
  UserAutoComplete,
} from '@store/orders';
import { Close, Search } from '@suid/icons-material';
import {
  Breakpoint,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Popper,
  TextField,
  Theme,
} from '@suid/material';
import { SxProps } from '@suid/system';
import { AxiosResponse } from 'axios';
import { debounce, get } from 'lodash';
import {
  For,
  JSX,
  Show,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
  untrack,
} from 'solid-js';

import ClickAwayListener from './ClickAwayListener';

export type ATGAutoCompleteItem =
  | ICustomerContact
  | LastUsedTopStopModel
  | Contact
  | string
  | ILocationSearch
  | CarrierAutoCompleteModel
  | Record<string, unknown>
  | UserAutoComplete;

export type AutocompleteProps = {
  endpoint?: string;
  debounceDelay?: number;
  debounce?: boolean;
  onSearchError?: (error: string) => void;
  onSearchComplete?: () => void;
  onItemSelect?: (item: ATGAutoCompleteItem, isProgrammatic: boolean) => void;
  onClearValue?: () => void;
  renderItem?: (item: ATGAutoCompleteItem) => JSX.Element;
  label: string;
  placeholder?: string;
  id: string;
  value?: string;
  onChange?: (value: string) => void;
  // this has to be passed even if we are not using it for types to work
  defaultValue?: string;
  version?: string;
  responseFieldName?: string;
  name?: string;
  error?: string | string[] | null;
  customButton?: () => JSX.Element;
  renderItemStyle?: SxProps<Theme<Breakpoint>>;
  disableClear?: boolean;
  minWidth?: number;
  variant?: 'outlined' | 'standard' | 'filled';
  startAdornment?: JSX.Element;
  class?: string;
  onInput?: (e: Event) => void;
  size?: 'medium' | 'small';
  hideIcon?: boolean;
  required?: boolean;
  query?: string;
  items?: ATGAutoCompleteItem[];
  local?: boolean;
  shrink?: boolean;
  disabled?: boolean;
};

const defaultValue = {
  debounceDelay: 300,
  debounce: true,
  defaultValue: '',
  error: null,
};

export const Autocomplete = (props: AutocompleteProps) => {
  const mp = mergeProps(props, defaultValue);

  const [anchorEl, setAnchorEl] = createSignal<HTMLInputElement | null>(null);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [isDropdownOpen, setDropdownOpen] = createSignal<boolean>(false);
  const [searchQuery, setSearchQuery] = createSignal<string>(
    get(props, 'defaultValue') ?? ('' as string),
  );
  const [searchResults, setSearchResults] = createSignal<ATGAutoCompleteItem[]>(
    [],
  );
  const [activeIndex, setActiveIndex] = createSignal(-1);
  let popupRef: HTMLUListElement;

  createEffect(() => {
    if (Boolean(mp.value)) {
      setSearchQuery(mp.value as string);
    } else {
      setSearchQuery('');
    }
  });

  function calculateWidth() {
    const textFieldElement = document.getElementById(props.id);
    if (textFieldElement) {
      return textFieldElement.clientWidth;
    }
    return 450;
  }

  const handleInputFocus = (event: MouseEvent) => {
    setAnchorEl(event.target as HTMLInputElement);
    event.stopPropagation();
  };

  const setLocalItems = () => {
    const items = props.items?.filter((item) => {
      const stringItem = get(item, props.responseFieldName ?? '') as string;
      return stringItem.toLowerCase().includes(searchQuery().toLowerCase());
    });
    setSearchResults(items ?? []);
  };

  const fetchData = async () => {
    let response:
      | AxiosResponse<ApiResponse<ATGAutoCompleteItem[]>>
      | { data: ATGAutoCompleteItem[] }
      | ATGAutoCompleteItem[];
    let items;
    if (props.version === 'v3') {
      response = await v3Client.get<
        string,
        { data: ATGAutoCompleteItem[] } | ATGAutoCompleteItem[]
      >(`${props.endpoint}${searchQuery().trimStart()}`);
      if ('data' in response) {
        items = response.data;
      } else {
        items = response;
      }
      setSearchResults(items);
    } else {
      try {
        response = await apiClient.get<
          string,
          AxiosResponse<ApiResponse<ATGAutoCompleteItem[]>>
        >(`${props.endpoint}=${encodeURIComponent(searchQuery().trimStart())}`);

        // TODO: The type of `response` here need to be handled properly.
        // We shouldn't have to guess what response is.
        if ('value' in response) {
          items = response.value as ATGAutoCompleteItem[];
        } else {
          response = await apiClient.get<
            string,
            AxiosResponse<ApiResponse<ATGAutoCompleteItem[]>>
          >(`${props.endpoint}=${searchQuery().trimStart()}`);

          // TODO: The type of `response` here need to be handled properly.
          // We shouldn't have to guess what response is.
          if ('value' in response) {
            items = response.value as ATGAutoCompleteItem[];
          } else {
            items = response as unknown as ATGAutoCompleteItem[];
          }
        }

        // As per requirements, we only need to show the first 50 results
        // in our autocomplete list. The limit can be tweaked, but keep in
        // mind that the list absolutely needs to be limited, otherwise it
        // can crash the app or cause performance issues.
        setSearchResults(items.slice(0, 50));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
  };

  const debounceDelay = 300; // milliseconds

  const performSearch = debounce(async (query: string) => {
    if (Boolean(props.local)) {
      setLocalItems();
      return;
    }
    if (query.trim().length > 2 && query !== '') {
      setLoading(true);
      await fetchData();
    } else {
      setSearchResults([]);
    }

    setLoading(false);
  }, debounceDelay);

  const handleInputChange = (e: Event) => {
    if (untrack(isDropdownOpen) === false) {
      setDropdownOpen(true);
    }
    const inputValue = (e.target as HTMLInputElement).value;

    setSearchQuery(inputValue);

    mp.onChange && mp.onChange(inputValue);

    void performSearch(inputValue);
  };

  const handleSelect = (item: ATGAutoCompleteItem, isProgrammatic = false) => {
    setDropdownOpen(false);
    if (props.onItemSelect) {
      props.onItemSelect(item, isProgrammatic);
    }
    setActiveIndex(-1);
  };

  const handleEnterSelect = () => {
    if (
      isNaN(activeIndex()) ||
      activeIndex() < 0 ||
      activeIndex() >= searchResults().length
    ) {
      return;
    }

    handleSelect(searchResults()[activeIndex()], true);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setDropdownOpen(false);
    if (props.onClearValue) {
      props.onClearValue();
    }
    setActiveIndex(-1);
  };

  const event = useKeyDownEvent();

  createEffect(() => {
    const e = event();

    if (e && searchResults().length > 0 && isDropdownOpen()) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        setActiveIndex((prevIndex) => {
          if (e.key === 'ArrowUp') {
            if (prevIndex === -1) {
              return searchResults().length - 1;
            }

            return prevIndex - 1;
          }

          return (prevIndex + 1) % searchResults().length;
        });
      } else if (e.key === 'Enter') {
        handleEnterSelect();
      }
    }
  });

  createEffect(() => {
    if (activeIndex() > -1 && activeIndex() < searchResults().length) {
      const popupDiv = popupRef.parentNode as unknown as HTMLDivElement;
      const itemsRef = popupRef.querySelectorAll('li.MuiListItem-root');
      const item = itemsRef[activeIndex()];
      const popupHeight = popupDiv.clientHeight;
      const popupScrollTop = popupDiv.scrollTop;
      // @ts-expect-error Nothing
      const itemOffsetTop = item.offsetTop;

      if (
        popupScrollTop + popupHeight - 20 <= itemOffsetTop ||
        popupScrollTop > itemOffsetTop
      ) {
        item.scrollIntoView();
      }
    }
  });

  function renderEndAdornment() {
    if (loading()) {
      return <CircularProgress size={props.size} />;
    } else if (searchQuery()) {
      return (
        <Close
          sx={{ cursor: 'pointer' }}
          fontSize={props.size}
          onClick={handleClearSearch}
        />
      );
    } else if (Boolean(props.hideIcon)) {
      return <></>;
    }

    return <Search sx={{ cursor: 'pointer' }} fontSize={props.size} />;
  }

  let observer: IntersectionObserver;

  onMount(() => {
    const textFieldElement = document.getElementById(
      props.id,
    ) as HTMLInputElement;
    if (Boolean(textFieldElement)) {
      observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry) => {
            if (Boolean(!entry.isIntersecting)) {
              setDropdownOpen(false);
            }
          });
        },
        {
          root: null,
          threshold: 0.1,
        },
      );

      observer.observe(textFieldElement);
    }
  });

  onCleanup(() => {
    if (Boolean(observer)) {
      observer.disconnect();
    }
  });

  return (
    <>
      <TextField
        required={props.required}
        name={props.name}
        id={props.id}
        onFocus={(e: unknown) => handleInputFocus(e)}
        fullWidth
        onChange={handleInputChange}
        value={searchQuery()}
        placeholder={props.placeholder}
        variant={props.variant ?? 'outlined'}
        class={props.class}
        label={props.label}
        onInput={props.onInput}
        size={props.size}
        InputLabelProps={{ shrink: props.shrink }}
        InputProps={{
          startAdornment: props.startAdornment,
          endAdornment:
            props.disableClear !== true
              ? props.customButton ?? renderEndAdornment()
              : '',
          autoComplete: 'off',
          sx: {
            height: props.size === 'small' ? '36px' : undefined,
            fontSize: props.size === 'small' ? '14px' : undefined,
          },
        }}
        error={Boolean(props.disabled) ? undefined : Boolean(props.error)}
        helperText={props.error?.length > 0 ? props.error[0] : ''}
        sx={{
          backgroundColor: 'white',
          '& .MuiInputLabel-root.Mui-focused': {
            fontWeight: 600, //Label Font weight when focused
          },
          '& .MuiInputLabel-root.MuiFormLabel-filled:not(.Mui-disabled):not(.Mui-error)':
            {
              fontWeight: 600, //Label Font weight when there is a value
              color: '#123b50', //Label color when there is a value
            },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '1px',
              borderColor: '#80b6cf',
              fontSize: '14px',
              color: '#123b50',
              fontWeight: 600,
            },
          },
          height: props.size === 'small' ? '36px' : undefined,
          fontSize: props.size === 'small' ? '14px' : undefined,
        }}
        disabled={props.disabled}
      />
      <Show when={searchResults().length > 0}>
        <Popper
          component={'div'}
          open={isDropdownOpen()}
          anchorEl={anchorEl()}
          placement="bottom-start"
          class="!bg-white shadow-lg max-h-60 overflow-auto !border-1 "
          style={{ width: `${calculateWidth() + 40}px`, 'z-index': 1300 }}
        >
          <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
            <List
              ref={(el) => {
                popupRef = el;
              }}
            >
              <For each={searchResults()}>
                {(item: ATGAutoCompleteItem, i) => {
                  return (
                    <ListItem
                      onClick={() => handleSelect(item)}
                      classes={{
                        root: `hover:bg-gray-100 cursor-pointer ${
                          activeIndex() === i() ? 'bg-gray-100' : ''
                        }`,
                      }}
                      sx={props.renderItemStyle ? props.renderItemStyle : {}}
                    >
                      {props.renderItem ? (
                        props.renderItem(item)
                      ) : (
                        <ListItemText
                          class="ml-5"
                          primary={get(item, props.responseFieldName ?? 'name')}
                        />
                      )}
                    </ListItem>
                  );
                }}
              </For>
            </List>
          </ClickAwayListener>
        </Popper>
      </Show>

      <Show
        when={
          searchResults().length === 0 && searchQuery().length > 3 && !loading()
        }
      >
        <Popper
          component={'div'}
          open={isDropdownOpen()}
          anchorEl={anchorEl()}
          placement="bottom-start"
          class="!bg-white shadow-lg max-h-60 overflow-auto !border-1"
          style={{ width: `${calculateWidth() + 40}px`, 'z-index': 1300 }}
        >
          <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
            <List>
              <ListItem classes={{ root: 'hover:bg-gray-100' }}>
                <ListItemText
                  primary="No results found"
                  secondary="Please try with different name"
                />
              </ListItem>
            </List>
          </ClickAwayListener>
        </Popper>
      </Show>
    </>
  );
};
