import { TextAreaField, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import * as yup from 'yup';
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@suid/material';
import { Button, NewAutoComplete, ToastType } from '@components';
import { createSignal, For, onMount } from 'solid-js';
import { Delete } from '@suid/icons-material';
import {
  addEditGroup,
  Group,
  officeDetailStore,
} from '@store/office/officeDetails';
import { handleToast } from '@utils/utils';

type Props = {
  selectedRow: Group | null;
  onClose: () => void;
};

type AccountingGroup = {
  accountingGroup: string;
  id: number;
  isActive: boolean;
  name: string;
  office: string;
  officeId: number;
  phone: string;
  thumbnail: string | null;
  userName: string;
};
const initialGroupPayload = {
  id: 0,
  name: '',
  description: null,
  leader: null,
  groupLeaderId: null,
  leaderEmail: null,
  office: null,
  officeId: undefined,
  members: [],
  individual: false,
  status: '',
  userIds: null,
  dateCreated: '',
};

const AddOrEditGroup = (props: Props) => {
  const [groupLeader, setGroupLeader] = createSignal<{
    id: number | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });
  const [personList, setPersonList] = createSignal<AccountingGroup[]>([]);
  const { form, data, setData, errors, isSubmitting, setIsSubmitting, reset } =
    createForm({
      initialValues: {
        name: '',
        description: '',
      },
      extend: validator({
        schema: yup.object().shape({
          name: yup.string().required('Name is required'),
          description: yup
            .string()
            .nullable()
            .notRequired()
            .max(300, 'Maximum of 300 characters'),
        }),
      }),
      onSubmit: async (values) => {
        const { selectedRow } = props;
        let payload: Group = initialGroupPayload;
        if (selectedRow) {
          payload = {
            ...values,
            dateCreated: selectedRow.dateCreated,
            groupLeaderId: groupLeader().id ?? null,
            id: selectedRow.id,
            individual: selectedRow.individual,
            leader: groupLeader().name,
            leaderEmail: selectedRow.leaderEmail,
            office: selectedRow.office,
            officeId: selectedRow.officeId,
            status: selectedRow.status,
            userIds: selectedRow.userIds,
            members: personList(),
          };
        } else {
          payload = {
            ...values,
            dateCreated: new Date().toISOString(),
            groupLeaderId: groupLeader().id ?? null,
            id: 0,
            individual: false,
            leader: groupLeader().name,
            leaderEmail: null,
            office: null,
            officeId: officeDetailStore.officeDetail?.id,
            status: 'Active',
            userIds: null,
            members: personList(),
          };
        }
        await handleSubmission(payload);
      },
    });

  const handleSubmission = async (values: Group) => {
    setIsSubmitting(true);
    await addEditGroup(values);
    setIsSubmitting(false);
    reset();
    props.onClose();
  };

  const RenderPersonList = () => {
    const handleRadioChange = (personId: number, name: string) => {
      setGroupLeader({ id: personId, name });
    };

    const handleDeleteClick = (personId: number) => {
      setPersonList(personList().filter((itm) => itm.id !== personId));
    };

    return (
      <Box>
        <For each={personList()}>
          {({ id, name }) => (
            <Box class="flex items-center justify-between">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="radio-buttons-group"
                onChange={(e) => handleRadioChange(id, e.target.value)}
                value={groupLeader().name}
              >
                <FormControlLabel
                  value={name}
                  control={<Radio />}
                  label={name}
                />
              </RadioGroup>
              <IconButton onClick={() => handleDeleteClick(id)}>
                <Delete class="text-red-700" />
              </IconButton>
            </Box>
          )}
        </For>
      </Box>
    );
  };

  const handleSelectItem = (item: AccountingGroup) => {
    if (typeof item !== 'string') {
      const exists = personList().some((person) => person.id === item.id);
      if (exists) {
        handleToast(ToastType.Error, 'User already exists');
      } else {
        setPersonList([...personList(), item]);
      }
    }
  };

  onMount(() => {
    if (props.selectedRow) {
      setData('name', props.selectedRow.name);
      setData('description', props.selectedRow.description ?? '');
      setGroupLeader({
        id: props.selectedRow.groupLeaderId ?? null,
        name: props.selectedRow.leader,
      });
      setPersonList(props.selectedRow.members as AccountingGroup[]);
    }
  });
  return (
    <form ref={form}>
      <Box sx={{ flexGrow: 1 }} class="p-[10px]">
        <Grid container item xs={12} spacing={4} class="!mb-6">
          <Grid item xs={12}>
            <TextInput
              label="Name"
              error={errors().name}
              value={data().name}
              onChange={(itm: string) => setData('name', itm)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextAreaField
              label="Description"
              rows={2}
              value={data().description}
              onChange={(itm: string) => setData('description', itm)}
              error={errors().description}
              maxLength={300}
            />
          </Grid>
          <Grid item xs={12}>
            <Box class="mb-[10px]">
              <Typography class="!text-[16px] !font-medium">
                Group Leader
              </Typography>
              <Typography class="!mt-[10px] !text-[18px] !font-medium">
                {groupLeader().name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box class="mb-[10px]">
              <Typography class="!text-[20px]">
                Members (select leader)
              </Typography>
              <Divider />
            </Box>
            <NewAutoComplete
              endpoint="user/autocomplete/?term="
              version="v3"
              responseFieldName="name"
              onItemSelect={(item) => {
                handleSelectItem(item as AccountingGroup);
              }}
              id="autocomplete-field-user"
              label="Add a Person"
              shouldPerformSearch={true}
            />
          </Grid>
          <Grid item xs={12}>
            <RenderPersonList />
          </Grid>
          <Grid item xs={12}>
            <Button
              isLoading={isSubmitting()}
              label="Save"
              type="submit"
              variant="contained"
              disabled={isSubmitting()}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default AddOrEditGroup;
