import { v3ODataClient } from '@api/apiClient';

import {
  FactoringCompaniesApiResponse,
  FactoringCompaniesQueryParams,
} from './types';

export async function fetchFactoringCompanies(
  params: FactoringCompaniesQueryParams,
) {
  try {
    const response: FactoringCompaniesApiResponse = await v3ODataClient.get(
      'FactoringCompanies',
      { params },
    );
    if (Boolean(response))
      return {
        items: response.value,
        count: response['@odata.count'],
      };
  } catch (error: unknown) {
    throw new Error('Failed to Approved Carriers');
  }
}
