import { ToastType } from '@components';
import { handleToast } from '@utils/utils';
import { produce } from 'solid-js/store';

import {
  fetchATGLookupValuesCall,
  fetchCollectionsTierCall,
  getCollectionsByCustomer,
  updateCollectionsTierCall,
  updateCollectionsKeyReasonCall,
  updateNextActionCall,
  updateCollectionsRiskPercentCall,
  applyCollectionActionsCall,
  addCollectionCommentCall,
  getCollectionCommentsCall,
  deleteCollectionCommentCall,
  sendToCollectionsCall,
  editCollectionCommentCall,
} from './services';
import { collectionDetailsStore, setCollectionDetailsStore } from './store';
import {
  ActionNote,
  AddCollectionCommentPayload,
  CollectionActionPayload,
  CollectionDetails,
  EditCollectionCommentPayload,
} from './types';

export const fetchCollectionsByCustomer = async (customerId: number) => {
  setCollectionDetailsStore('isCollectionLoading', true);
  const resp = await getCollectionsByCustomer(customerId);
  if (resp && resp.success) {
    setCollectionDetailsStore(
      produce((draft) => {
        draft.collectionDetails = resp.data;
        draft.isCollectionLoading = false;
      }),
    );
  } else {
    handleToast(ToastType.Error, resp?.message as string);
    setCollectionDetailsStore('isError', true);
  }
  setCollectionDetailsStore('isCollectionLoading', false);
};

export const updateCollectionDetailsState = (
  updates: Partial<CollectionDetails>,
) => {
  Object.entries(updates).forEach(([key, value]) => {
    setCollectionDetailsStore(
      'collectionDetails',
      key as keyof CollectionDetails,
      value,
    );
  });
};

export const updateActionNotesState = (updates: Partial<ActionNote>) => {
  Object.entries(updates).forEach(([key, value]) => {
    setCollectionDetailsStore('actionNotes', key as keyof ActionNote, value);
  });
};

export const updateCollectionsRiskPercent = async (payload: {
  ids: number[];
  collectionsRiskPercent: number | string;
}) => {
  setCollectionDetailsStore('isLoading', true);
  const resp = await updateCollectionsRiskPercentCall(payload);
  setCollectionDetailsStore('isLoading', false);
  return resp;
};

export const applyCollectionActions = async (
  payload: CollectionActionPayload[],
  cb?: () => void,
) => {
  setCollectionDetailsStore('isLoading', true);
  const res = await applyCollectionActionsCall(payload);
  if (res && res.success) {
    handleToast(ToastType.Success, 'Actions applied successfully');
    updateActionNotesState({
      actions: [],
      comment: '',
      orderId: 0,
    });
    cb && cb();
  } else {
    handleToast(ToastType.Error, res?.message as string);
  }
  setCollectionDetailsStore('isLoading', false);
};

export const fetchCollectionsTier = async () => {
  const data = await fetchCollectionsTierCall();
  if (data) {
    setCollectionDetailsStore('collectionTier', data);
  }
};

export const updateCollectionsTier = async (payload: {
  id: string;
  tierId: string;
}) => {
  setCollectionDetailsStore('isLoading', true);
  const res = await updateCollectionsTierCall(payload);
  if (res && res.success) {
    handleToast(ToastType.Success, 'Collections Tier successfully assigned!');
    updateCollectionDetailsState({
      customer: {
        ...collectionDetailsStore.collectionDetails.customer,
        collectionTierId: payload.tierId,
      },
    });
  }
  setCollectionDetailsStore('isLoading', false);
};

export const fetchATGLookupValues = async () => {
  const data = await fetchATGLookupValuesCall();
  if (data) {
    setCollectionDetailsStore('aTGLookupValue', data);
  }
};

export const updateCollectionsKeyReason = async (payload: {
  id: number;
  keyReasonId: number;
}) => {
  setCollectionDetailsStore('isLoading', true);
  const res = await updateCollectionsKeyReasonCall(payload);
  if (res && res.success) {
    handleToast(ToastType.Success, 'Key Resaon successfully assigned!');
    updateCollectionDetailsState({
      customer: {
        ...collectionDetailsStore.collectionDetails.customer,
        keyReasonId: Number(payload.keyReasonId),
      },
    });
  }
  setCollectionDetailsStore('isLoading', false);
};

export const updateNextAction = async (payload: {
  id: string;
  stringDate: string | null;
}) => {
  setCollectionDetailsStore('isLoading', true);
  const resp = await updateNextActionCall(payload);
  setCollectionDetailsStore('isLoading', false);
  return resp;
};

export const getCollectionComments = async (id: number) => {
  const resp = await getCollectionCommentsCall(id);
  if (resp) {
    setCollectionDetailsStore('collectionComments', resp);
  }
};

export const addCollectionComment = async (
  payload: AddCollectionCommentPayload,
  callback: () => void,
) => {
  setCollectionDetailsStore('isLoading', true);
  const resp = await addCollectionCommentCall(payload);
  if (resp && resp.success) {
    const collectionComments = [...collectionDetailsStore.collectionComments];
    handleToast(ToastType.Success, 'Comment added successfully');
    collectionComments.unshift(resp.data);
    setCollectionDetailsStore('collectionComments', collectionComments);
    callback();
  }
  setCollectionDetailsStore('isLoading', false);
};

export const deleteCollectionComment = async (commentId: number) => {
  setCollectionDetailsStore('isLoading', true);
  const resp = await deleteCollectionCommentCall(commentId);
  const collectionComments = [
    ...collectionDetailsStore.collectionComments,
  ].filter((c) => c.id !== commentId);
  if (resp && resp.success) {
    setCollectionDetailsStore('collectionComments', collectionComments);
    handleToast(ToastType.Success, 'Comment deleted successfully');
  }
  setCollectionDetailsStore('isLoading', false);
};

export const sendToCollections = async (invoiceIds: number[]) => {
  setCollectionDetailsStore('isLoading', true);
  const resp = await sendToCollectionsCall(invoiceIds);
  if (resp && resp.success) {
    handleToast(ToastType.Success, 'Invoice sent to collections successfully');
  } else {
    handleToast(ToastType.Error, resp?.message as string);
  }
  setCollectionDetailsStore('isLoading', false);
};

export const editCollectionComment = async (
  payload: EditCollectionCommentPayload,
  callback: () => void,
) => {
  setCollectionDetailsStore('isLoading', true);
  const resp = await editCollectionCommentCall(payload);
  if (resp && resp.success) {
    const collectionComments = [
      ...collectionDetailsStore.collectionComments,
    ].map((c) =>
      c.id === payload.id ? { ...c, comment: payload.comment } : c,
    );
    handleToast(ToastType.Success, 'Comment updated successfully');
    setCollectionDetailsStore('collectionComments', collectionComments);
    callback();
  } else {
    handleToast(ToastType.Error, resp?.message as string);
  }
  setCollectionDetailsStore('isLoading', false);
};
