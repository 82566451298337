export const LTLHeaderText = (name: string) => {
  return <div class="text-base font-medium	">{name}</div>;
};

export const getAccessorialString = (
  listOfAccessorial: string[] | undefined,
) => {
  let result = '';
  if (!Boolean(listOfAccessorial)) {
    return '';
  }
  const totalLength = listOfAccessorial!.length - 1;
  listOfAccessorial?.map((acc, i) => {
    if (i === totalLength) {
      result = result + `${acc}`;
      return;
    }
    result = result + `${acc}, `;
  });
  return result;
};
