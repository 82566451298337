import { createTheme } from '@suid/material';

const theme = createTheme({
  components: {
    MuiTableCell: {
      defaultProps: {
        size: 'small',
      },
    },
  },
  palette: {
    primary: {
      main: '#1B4960',
    },
    secondary: {
      main: '#468DB5',
    },
  },
  typography: {},
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1120,
      xl: 1536,
    },
  },
});

export default theme;
