import { Card, ToastType } from '@components';
import { TextAreaField } from '@components/forms';
import {
  addCustomerGeneralComment,
  customerDetailsStore,
  deleteCustomerGeneralComment,
  editCustomerGeneralComment,
  updateCustomerDetailsState,
} from '@store/customers/customerDetails';
import DeleteIcon from '@suid/icons-material/Delete';
import { Grid, IconButton, Stack } from '@suid/material';
import { handleToast, isAdmin } from '@utils/utils';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { Component, For } from 'solid-js';
import { createStore } from 'solid-js/store';
import * as yup from 'yup';

type InitialFormVals = {
  comment: string;
  commentType: string;
  id?: string;
  type: string;
  isLoading: boolean;
  editingCommentId: number | null;
  editingCommentValue: string;
};

const schema = yup.object().shape({
  comment: yup
    .string()
    .required('Comment with length greater than 0 is required to Approve.'),
});

export const GeneralCustomerComments: Component = () => {
  const [formValues, setFormValues] = createStore<InitialFormVals>({
    comment: '',
    commentType: '',
    type: '',
    isLoading: false,
    editingCommentId: null,
    editingCommentValue: '',
  });
  const [errorValues, setErrorValues] = createStore({
    comment: '',
  });

  const validateField = (field: string, value: string) => {
    try {
      schema.validateSyncAt(field, { [field]: value });
      setErrorValues({ ...errorValues, [field]: '' });
    } catch (e: unknown) {
      if (e instanceof yup.ValidationError) {
        setErrorValues({ ...errorValues, [field]: e.message });
      }
    }
  };

  const handleAddComment = async (comment: string) => {
    const resp = await addCustomerGeneralComment(
      {
        id: get(customerDetailsStore, 'customer.id'),
        commentType: isAdmin() ? 'Admin' : 'User',
        comment: comment.trim(),
        type: 'Customer',
      },
      () => {
        setFormValues('isLoading', false),
          handleToast(ToastType.Success, 'Comment Saved');
      },
    );
    const comments = [...customerDetailsStore.customer.comments];
    comments.unshift(resp);
    updateCustomerDetailsState({ ...customerDetailsStore, comments });
    setFormValues('comment', '');
  };

  const handleDeleteComment = async (id: number) => {
    await deleteCustomerGeneralComment(id).then((res) => {
      if (Boolean(res)) {
        const comments = customerDetailsStore.customer.comments.filter(
          (comment) => comment.id !== id,
        );
        updateCustomerDetailsState({ ...customerDetailsStore, comments });
        handleToast(ToastType.Success, 'Comment Deleted');
      }
    });
  };

  const handleEditComment = (id: number, comment: string) => {
    setFormValues('editingCommentId', id);
    setFormValues('editingCommentValue', comment);
  };

  const handleSaveEdit = async (id: number) => {
    const updatedComment = formValues.editingCommentValue.trim();

    if (!updatedComment) return;

    let commentToUpdate = null;
    const updatedComments = [];

    for (const comment of customerDetailsStore.customer.comments) {
      if (comment.id === id) {
        const updated = { ...comment, comment: updatedComment };
        updatedComments.push(updated);
        commentToUpdate = updated;
      } else {
        updatedComments.push(comment);
      }
    }

    if (commentToUpdate) {
      await editCustomerGeneralComment(
        {
          id: commentToUpdate.id,
          comment: updatedComment,
          objectId: commentToUpdate.objectId,
          type: 'Customer',
        },
        () => {
          setFormValues('editingCommentId', null);
          handleToast(ToastType.Success, 'Comment Updated');
        },
      );

      updateCustomerDetailsState({
        ...customerDetailsStore,
        comments: updatedComments,
      });
    }
  };

  const handleAddKeyDown = async (e: KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      formValues.comment.trim() !== '' &&
      !formValues.isLoading
    ) {
      e.preventDefault();
      setFormValues('isLoading', true);
      await handleAddComment(formValues.comment);
    }
  };
  const handleEditKeyDown = async (e: KeyboardEvent, id: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleSaveEdit(id);
    }
  };

  return (
    <Card
      startTitle="Comments"
      accordion={false}
      maxContentHeight={280}
      loading={customerDetailsStore.isLoading}
    >
      <Stack spacing={2}>
        <TextAreaField
          rows={3}
          label="Add a Comment"
          placeholder="Add Comment"
          value={formValues.comment}
          onChange={(value: string) => {
            setFormValues('comment', value);
            validateField('comment', value);
          }}
          onKeyPress={(e) => handleAddKeyDown(e)}
          error={errorValues.comment}
        />
        <For each={customerDetailsStore.customer.comments}>
          {({ name, timestamp, comment, id }) => (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  backgroundColor: '#CCCC',
                  minHeight: '30px',
                  padding: '0px 5px',
                }}
              >
                <Grid item xs={4} sx={{ fontWeight: '700' }}>
                  {name}
                </Grid>
                <Grid
                  item
                  container
                  xs={8}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item sx={{ fontSize: '14px', marginRight: '10px' }}>
                    {Boolean(timestamp)
                      ? DateTime.fromISO(timestamp, { zone: 'utc' })
                          .setZone('local')
                          .toFormat('MMM dd yyyy HH:mm a')
                          .toUpperCase()
                      : ''}
                  </Grid>
                  <Grid item>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteComment(id)}
                    >
                      <DeleteIcon fontSize="small" class="text-red-700" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ marginTop: '5px', marginLeft: '5px' }}>
                {formValues.editingCommentId === id ? (
                  <TextAreaField
                    rows={1}
                    value={formValues.editingCommentValue}
                    onChange={(value: string) =>
                      setFormValues('editingCommentValue', value)
                    }
                    onKeyPress={(e) => handleEditKeyDown(e, id)}
                    onBlur={() => setFormValues('editingCommentId', null)}
                  />
                ) : (
                  <div onClick={() => handleEditComment(id, comment)}>
                    {comment}
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </For>
      </Stack>
    </Card>
  );
};
