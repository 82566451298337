import { Popover, Typography } from '@suid/material';
import { createSignal } from 'solid-js';

type Props = {
  id: number;
  value: string | null | undefined;
  width?: string;
};

export const PopoverCell = (props: Props) => {
  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

  const handlePopoverOpen = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = () => Boolean(anchorEl());
  return (
    <div>
      <Typography
        fontSize={12}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-haspopup="true"
        aria-owns={open() ? `mouse-over-popover${props.id}` : undefined}
        style={{
          width: Boolean(props.width) ? props.width : '400px',
          'overflow-wrap': 'anywhere',
          'white-space': 'pre-wrap',
        }}
      >
        {props.value?.length !== undefined && props.value.length > 119
          ? props.value.slice(0, 119) + '...'
          : props.value}
      </Typography>
      {props.value?.length !== undefined && props.value.length > 119 && (
        <Popover
          id={`mouse-over-popover${props.id}`}
          sx={{ pointerEvents: 'none' }}
          open={Boolean(anchorEl())}
          anchorEl={anchorEl()}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography
            style={{
              'overflow-wrap': 'break-word',
              'max-width': '800px',
            }}
          >
            {props.value}
          </Typography>
        </Popover>
      )}
    </div>
  );
};
