export const stopsListClasses = {
  container: 'rounded-md bg-gray-100 flex flex-col items-start p-2',
  heading: 'text-[#1B4960] text-lg font-semibold mb-2 flex text-[20px]',
  overflowContaier:
    'overflow-y-scroll max-h-[250px] scroll-m-0 scroll-p-0 w-[100%]',
};

export default {
  sectionContainer: 'flex text-[#1B4960] mb-3 items-center',
  subHeading: 'font-semibold text-[22px]',
  underlineText:
    'inline-flex ml-auto underline uppercase text-[14px] font-bold cursor-pointer',
  classDateTime:
    'bg-white rounded !p-3 rounded outline-none !border-2 h-[56px] mb-[16px]',
  mb16: { marginBottom: '16px' },
  wFull: { width: '100%' },
  reminderTxt:
    'text-[#1B4960] font-roboto text-base font-normal flex underline border-0 hover:bg-white absolute',
  subHeading2:
    'text-[#1B4960] font-roboto text-base font-normal flex text-lg mb-[8px]',
  modalHeading:
    'text-[#1B4960] text-center bg-[#F5F5F5] py-3 font-semibold mb-2 text-[22px]',
  modalHeadingLeftAlign:
    'text-[#1B4960] text-left py-3 font-semibold mb-2 text-[22px] pl-[20px]',
  xIcon: 'font-bold inline-flex relative top-[18px] px-[4px]',
  popoverClass: 'flex flex-col w-[400px]',
  timePickerInputClass:
    'bg-white !p-3 rounded-[4px] outline-none !border-2 mt-[16px] !border-[#d2d5d9] h-[50px] !border-thin',
  timePickerInputClassNoMargin:
    'bg-white !p-3 rounded-[4px] outline-none !border-2 !border-[#d2d5d9] h-[50px] !border-thin',
  timePickerClass: 'mt-[20px]',
  actionButtons: 'flex justify-end',
  submitButton: '!my-[20px]',
};
