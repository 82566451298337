import { FlagType } from '@store/orders';

import EFSIssuedFlag from '../../../../assets/EFSIssued.svg';
import GetPaperworkFlag from '../../../../assets/Paperwork.svg';
import RefundFlag from '../../../../assets/Refund.svg';
import BlueFlag from '../../../../assets/blueFlag.svg';
import GreenFlag from '../../../../assets/greenFlag.svg';
import HotLoadFlag from '../../../../assets/hotLoad.svg';
import MissingPaperworkFlag from '../../../../assets/missingPaperwork.svg';
import NeedsApprovalFlag from '../../../../assets/needApproval.svg';
import OrangeFlag from '../../../../assets/orangeFlag.svg';
import RedFlag from '../../../../assets/redFlag.svg';
import YellowFlag from '../../../../assets/yellowFlag.svg';
import tenderUpload from '../../../../assets/tenderUpload.svg';

const defaultColor = '#000';
const defaultFlag = '';
export const FlagColors = {
  red: '#ff5856',
  blue: '#2989d8',
  green: '#068a06',
  yellow: '#fabf2d',
  orange: '#ffb238',
};

export const FlagColorsToHex: Record<FlagType, string> = {
  [FlagType.Red]: FlagColors.red,
  [FlagType.Blue]: FlagColors.blue,
  [FlagType.Green]: FlagColors.green,
  [FlagType.Yellow]: FlagColors.yellow,
  [FlagType.Orange]: FlagColors.orange,
  [FlagType.BillingHold]: defaultColor,
  [FlagType.BillingNote]: defaultColor,
  [FlagType.EFSIssued]: defaultColor,
  [FlagType.Flagged]: defaultColor,
  [FlagType.GetPaperwork]: defaultColor,
  [FlagType.Hold]: defaultColor,
  [FlagType.HotLoad]: defaultColor,
  [FlagType.Issue]: defaultColor,
  [FlagType.MissingPaperwork]: defaultColor,
  [FlagType.NeedsApproval]: defaultColor,
  [FlagType.NeedsPaperwork]: defaultColor,
  [FlagType.RefundRequest]: defaultColor,
  [FlagType.TenderUpdated]: '#F59D25',
  [FlagType.Uploaded]: defaultColor,
  [FlagType.None]: defaultColor,
};

export const FlagAssets: Record<FlagType, string> = {
  [FlagType.Red]: RedFlag,
  [FlagType.Blue]: BlueFlag,
  [FlagType.Green]: GreenFlag,
  [FlagType.Yellow]: YellowFlag,
  [FlagType.Orange]: OrangeFlag,
  [FlagType.BillingHold]: defaultFlag,
  [FlagType.BillingNote]: defaultFlag,
  [FlagType.EFSIssued]: EFSIssuedFlag,
  [FlagType.Flagged]: defaultFlag,
  [FlagType.GetPaperwork]: GetPaperworkFlag,
  [FlagType.Hold]: defaultFlag,
  [FlagType.HotLoad]: HotLoadFlag,
  [FlagType.Issue]: defaultFlag,
  [FlagType.MissingPaperwork]: MissingPaperworkFlag,
  [FlagType.NeedsApproval]: NeedsApprovalFlag,
  [FlagType.NeedsPaperwork]: defaultFlag,
  [FlagType.RefundRequest]: RefundFlag,
  [FlagType.TenderUpdated]: tenderUpload,
  [FlagType.Uploaded]: defaultFlag,
  [FlagType.None]: defaultFlag,
};

export const getLoadColor = (loadStatus: string | undefined) => {
  let bgColor: string = '';
  switch (loadStatus) {
    case 'Assigned':
      bgColor = '#FFFFC1';
      break;

    case 'Unassigned':
      bgColor = '#FFC1C1';
      break;

    case 'Dispatched':
      bgColor = '#AAD9ED';
      break;

    case 'Loaded':
      bgColor = '#9ACC9A';
      break;

    case 'At Shipper':
      bgColor = '#AEA3FF';
      break;

    case 'At Receiver':
      bgColor = '#FFFFFF';
      break;

    case 'Out On Delivery':
      bgColor = '#F7BF51';
      break;

    case 'In Transit':
      bgColor = '#67E667';
      break;

    case 'On Hold':
      bgColor = '#F97C6B';
      break;

    case 'Tendered':
      bgColor = '#c1fcff';
      break;

    case 'Empty':
      bgColor = '#FFF';
      break;

    case 'Can Get':
      bgColor = '#E4E6EF';
      break;
  }
  return bgColor;
};
