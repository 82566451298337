import { Typography } from '@components';
import { orderStore } from '@store/orders';
import { Show } from 'solid-js';

import classes from './classes';

export const CustomerInvoice = () => {
  const cls = classes.customerInfo;
  return (
    <Show
      when={orderStore.order.invoicedDate != null || orderStore.order.paidDate}
    >
      <div class={cls.datesContainer}>
        <Show when={orderStore.order.invoicedDate}>
          <div class={cls.invoiceDate}>
            <Typography
              component="p"
              variant="body1"
              class={cls.invoiceDateTitle}
            >
              Invoiced Date
            </Typography>
            <Typography
              component="p"
              variant="body2"
              class={cls.invoiceDateValue}
            >
              {orderStore.order.invoicedDate ?? 'N/A'}
            </Typography>
          </div>
        </Show>
        <Show when={orderStore.order.paidDate}>
          <div class={cls.invoiceDate}>
            <Typography
              component="p"
              variant="body1"
              class={cls.invoiceDateTitle}
            >
              Paid Date
            </Typography>
            <Typography
              component="p"
              variant="body2"
              class={cls.invoiceDateValue}
            >
              {orderStore.order.paidDate ?? 'N/A'}
            </Typography>
          </div>
        </Show>
      </div>
    </Show>
  );
};
