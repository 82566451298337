export const classes = {
  card: 'flex gap-[5px] justify-between text-base',
  heading: 'text-[#026EA1DE] underline font-bold text-base mb-[7px]',
  imageContainer: 'max-w-[150px]',
  headingAmount:
    'text-[#026EA1DE] underline font-bold text-base text-center mb-[7px]',
  infoRow:
    'my-[10px] text-[#666666] whitespace-pre-wrap break-words break-all block',
  label: 'text-[#000000DE] font-bold ',
  AccessorialsRow: 'flex flex-col gap-4 items-center',
  amountDataContainer: 'flex gap-[7.5px]  pl-[12px] pb-[16px] justify-center',
  displayAmount: 'text-[#06910B] font-bold',
  displayPayee: 'text-[#666666] font-[12px]',
  divider: 'border border-[#BDBDBD]',
  requoteShipmentBtn:
    'bg-[#248670] text-[#FFFFFF] font-medium px-[16px] py-[6px] rounded uppercase flex items-center justify-center',
};
