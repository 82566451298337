import {
  BasicModal,
  Button,
  ContactDropdown,
  IconLabel,
  ToastType,
} from '@components';
import { TextAreaField } from '@components/forms';
import { closeModal, openModal } from '@store/modals';
import {
  addNewContactToCarrier,
  addNewContactToCarrierStoreAndUpdateOrderStore,
  carrierStore,
  ILoadEditViewModel,
  orderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { Business, Email, PersonAdd, Phone } from '@suid/icons-material';
import { Box, Grid, Stack } from '@suid/material';
import {
  formatContactPhone,
  formatPhoneNumber,
} from '@utils/phoneNumberFormat';
import { handleToast } from '@utils/utils';
import { AddNewContact } from '@views/order/components/addNewContact';
import { RatingsForm } from '@views/order/components/models';
import { RATE_CARRIER_ID } from '@views/order/constants';
import { isEmpty } from 'lodash';
import { Show } from 'solid-js';

import { carrieContactCardClasses as classes } from '../classes';
import { FieldDisplay } from './FieldDisplay';
import { Rating } from './Rating';
import { SubDetails } from './SubDetails';
import {
  getCarrierContact,
  getContactEmailVerificationIcon,
  getContactPhoneVerificationIcon,
} from './utils';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
  isCarrierSelected: boolean;
};

export const CarrierContact = (props: PropsT) => {
  const newContactModalId = `newContactPopupID-${props.tabIndex}`;

  const rateCarrierModalId = `${RATE_CARRIER_ID}-${props.tabIndex}-rate`;
  const carrierSelectedStyles = classes.iconClasses;

  const contactPhoneVerificationIcon = getContactPhoneVerificationIcon(
    props.load,
    props.tabIndex,
  );
  const contactEmailVerificationIcon = getContactEmailVerificationIcon(
    props.load,
    props.tabIndex,
  );

  const handleAddContact = async (
    payload: Record<string, string | number | boolean | null>,
  ) => {
    try {
      const res = await addNewContactToCarrier(payload);

      if (Boolean(res)) {
        addNewContactToCarrierStoreAndUpdateOrderStore(res);
      }
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  return (
    <Stack>
      <Stack class="border border-solid rounded-lg border-gray-500 p-2 bg-white mb-2">
        <Stack direction="row" gap={2}>
          <Box flex={1}>
            <ContactDropdown
              contacts={carrierStore.carrier[props.tabIndex]?.contacts}
              onChange={(value) => {
                updateLoadPropertyAtIndex({
                  carrierContactId: value,
                });
              }}
              contactId={
                Number(props.load.carrierContactId) ||
                (!isEmpty(carrierStore.carrier[props.tabIndex]?.contacts)
                  ? carrierStore.carrier[props.tabIndex]?.contacts[0]?.id
                  : undefined)
              }
              disabled={!props.isCarrierSelected || orderStore.isReadOnly}
              respectVerification={orderStore.carrierContactLockdownEnabled}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              label="New Contact"
              onClick={() => openModal(newContactModalId)}
              startIcon={<PersonAdd />}
              disabled={!props.isCarrierSelected || orderStore.isReadOnly}
              class={`${classes.w100} !text-armstrong-sm`}
            />
          </Box>
        </Stack>
        <Stack direction="row" gap={3} pt="7px">
          <Box class="min-w-[110px]">
            <FieldDisplay
              startIcon={
                // @ts-expect-error You can pass a fixed px size.
                <Phone class={carrierSelectedStyles} fontSize="20px" />
              }
              label={
                <a
                  href={`tel: ${formatContactPhone(
                    getCarrierContact(props.load, props.tabIndex),
                    'link',
                  )}`}
                  class="text-armstrong-sm font-medium"
                >
                  {formatContactPhone(
                    getCarrierContact(props.load, props.tabIndex),
                    'display',
                  )}
                </a>
              }
              endIcon={
                <Show
                  when={
                    Boolean(orderStore.carrierContactLockdownEnabled) &&
                    contactPhoneVerificationIcon !== ''
                  }
                  fallback={<></>}
                >
                  <Box class="flex item-center h-full pt-[2px]">
                    <img src={contactPhoneVerificationIcon} width="14px" />
                  </Box>
                </Show>
              }
            />
          </Box>
          <Box class="min-w-[110px]">
            <FieldDisplay
              startIcon={
                // @ts-expect-error You can pass a fixed px size.
                <Email class={carrierSelectedStyles} fontSize="20px" />
              }
              label={
                <a
                  href={`mailto:${carrierStore.carrier[
                    props.tabIndex
                  ]?.contacts?.find((v) => v.id === props.load.carrierContactId)
                    ?.email}`}
                  class={`${classes.anchorStyleClasses} text-armstrong-sm font-medium`}
                >
                  {
                    carrierStore.carrier[props.tabIndex]?.contacts?.find(
                      (v) => v.id === props.load.carrierContactId,
                    )?.email
                  }
                </a>
              }
              endIcon={
                <Show
                  when={
                    Boolean(orderStore.carrierContactLockdownEnabled) &&
                    contactEmailVerificationIcon !== ''
                  }
                  fallback={<></>}
                >
                  <Box class="flex item-center h-full pt-[2px]">
                    <img src={contactEmailVerificationIcon} width="14px" />
                  </Box>
                </Show>
              }
            />
          </Box>
        </Stack>
        <Box class="mt-1">
          <FieldDisplay
            startIcon={
              <Box class="text-black font-medium text-sm mr-2">Corp Phone</Box>
            }
            label={
              <a
                href={`tel: ${formatPhoneNumber(
                  carrierStore.carrier[props.tabIndex]?.corporatePhoneNumber,
                  'link',
                )}`}
                class="text-sm font-medium"
              >
                {formatPhoneNumber(
                  carrierStore.carrier[props.tabIndex]?.corporatePhoneNumber,
                  'display',
                )}
              </a>
            }
            endIcon={
              <Show
                when={
                  Boolean(orderStore.carrierContactLockdownEnabled) &&
                  contactPhoneVerificationIcon !== ''
                }
                fallback={<></>}
              >
                <Box class="flex item-center h-full pt-[2px]">
                  <img src={contactPhoneVerificationIcon} width="14px" />
                </Box>
              </Show>
            }
          />
        </Box>
      </Stack>
      <Stack direction="row" gap={1}>
        <Grid item flex={1}>
          <Stack flex={1} gap={1}>
            <div class="mt-1">
              <IconLabel
                label="Address"
                labelClasses="text-xs mb-1"
                contentClasses={classes.smallIconLabelContentClasses}
                icon={<Business class={carrierSelectedStyles} />}
                content={`${
                  carrierStore.carrier[props.tabIndex]?.address?.line1 || ''
                } ${
                  carrierStore.carrier[props.tabIndex]?.address?.line2 ?? ''
                } ${
                  carrierStore.carrier[props.tabIndex]?.address?.city || ''
                } ${
                  carrierStore.carrier[props.tabIndex]?.address?.state || ''
                } ${carrierStore.carrier[props.tabIndex]?.address?.zip || ''}`}
                textColorCondition={false}
              />
            </div>
            <Box>
              <Rating
                load={props.load}
                tabIndex={props.tabIndex}
                isCarrierSelected={props.isCarrierSelected}
                rateCarrierModalId={rateCarrierModalId}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item>
          <Stack flex={1} gap={1} maxWidth="180px" minWidth="140px">
            <Box>
              <SubDetails
                name="MC #"
                data={
                  carrierStore.carrier[props.tabIndex]?.mc ?? (
                    <span class="text-[#000000] opacity-40">-</span>
                  )
                }
                isCarrierSelected={props.isCarrierSelected}
              />
            </Box>
            <Box>
              <SubDetails
                name="DOT #"
                data={
                  carrierStore.carrier[props.tabIndex]?.dot ?? (
                    <span class="text-[#000000] opacity-40">-</span>
                  )
                }
                isCarrierSelected={props.isCarrierSelected}
              />
            </Box>
            <Box>
              <SubDetails
                name="Fallouts"
                data={
                  carrierStore.carrier[props.tabIndex]?.fallouts ?? (
                    <span class="text-[#000000] opacity-40">-</span>
                  )
                }
                isCarrierSelected={props.isCarrierSelected}
              />
            </Box>
            <Box>
              <SubDetails
                name="Load Count"
                data={
                  carrierStore.carrier[props.tabIndex]?.totalLoads ?? (
                    <span class="text-[#000000] opacity-40">-</span>
                  )
                }
                isCarrierSelected={props.isCarrierSelected}
              />
            </Box>
          </Stack>
        </Grid>
      </Stack>
      <Box mt={2}>
        <div class="flex gap-x-1">
          <TextAreaField
            label="Carrier Instructions"
            rows={8}
            placeholder="This area is for Carrier Instructions that will appear on the Carrier Rate Confirmation."
            value={props.load.carrierInstructions ?? ''}
            onChange={(value) => {
              updateLoadPropertyAtIndex({
                carrierInstructions: value,
              });
            }}
            maxLength={800}
            disabled={orderStore.isReadOnly}
            invertPadding
          />
          <Show when={props.load.mode === 'LTL'} fallback={<></>}>
            <TextAreaField
              label="Terminal Information"
              rows={8}
              placeholder="This area is for Terminal Information that will appear on the BOL."
              value={props.load.terminal}
              onChange={(value) => {
                updateLoadPropertyAtIndex({
                  terminal: value,
                });
              }}
              maxLength={500}
              disabled={orderStore.isReadOnly}
              invertPadding
            />
          </Show>
        </div>
      </Box>
      <BasicModal
        id={rateCarrierModalId}
        title="Rate Carrier"
        width={'35vw'}
        header={false}
        onClose={() => closeModal(rateCarrierModalId)}
        footer={false}
        showClose={false}
        modalStyles={{
          background: 'white',
          borderRadius: '5px',
        }}
      >
        <RatingsForm
          onClose={() => closeModal(rateCarrierModalId)}
          modalId={rateCarrierModalId}
        />
      </BasicModal>
      <BasicModal
        id={newContactModalId}
        title="New Contact"
        width="1000px"
        footer={false}
      >
        <AddNewContact
          modalId={newContactModalId}
          initiatePath="OrderDetails"
          onSubmit={handleAddContact}
        />
      </BasicModal>
    </Stack>
  );
};
