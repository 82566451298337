import { MenuItemType } from '@components';

export const getMenuItems = (
  lockEnabled: boolean,
  isSmallScreen: boolean,
): MenuItemType[] => {
  if (isSmallScreen) {
    return [
      { label: 'Copy Order', value: 'Copy Order' },
      { label: 'Copy Multiple', value: 'Copy Multiple' },

      {
        label: 'Save As Template',
        value: 'Save As Template',
        disabled: lockEnabled,
      },
      {
        label: 'Add Billing Hold/Note',
        value: 'Add Billing Hold/Note',
        disabled: lockEnabled,
      },
      { label: 'Void Order', value: 'Void Order', disabled: lockEnabled },
    ];
  }

  return [
    { label: 'Copy Multiple', value: 'Copy Multiple' },
    {
      label: 'Save As Template',
      value: 'Save As Template',
      disabled: lockEnabled,
    },
    {
      label: 'Add Billing Hold/Note',
      value: 'Add Billing Hold/Note',
      disabled: lockEnabled,
    },
    { label: 'Void Order', value: 'Void Order', disabled: lockEnabled },
  ];
};
