import { v3Client } from '@api/apiClient';

import { ClaimsInfo } from './types';

export async function getClaims() {
  try {
    const response = await v3Client.get('/load/claims');
    return response as unknown as ClaimsInfo[];
  } catch (error: unknown) {
    throw new Error('Fetching claims failed');
  }
}
