import { MenuItemType } from '@components';
import { DateTime } from 'luxon';

export const filterByItems: MenuItemType[] = [
  {
    value: '',
    label: 'MISC',
    disabled: true,
  },
  {
    value: 'unassigned',
    label: 'Unassigned',
  },
  { value: 'myUnassigned', label: 'My Unassigned' },
  { value: 'hotLoads', label: 'Hot Loads' },
  { value: 'needsPaperwork', label: 'Needs Paperwork' },
  { value: 'ltlLoads', label: 'LTL Loads' },
  {
    value: 'byPerson',
    label: 'By Person',
    disabled: true,
  },
  { value: 'myCustomers', label: 'My Customers' },
  { value: 'myLoads', label: 'My Loads' },
  {
    value: 'byDate',
    label: 'By Date',
    disabled: true,
  },
  {
    value: 'pickByYesterday',
    label: 'Pick By Yesterday',
  },
  { value: 'pickByToday', label: 'Pick By Today' },
  { value: 'pickByTomorrow', label: 'Pick By Tomorrow' },
  { value: 'pickByLastWeek', label: 'Pick By Last Week' },
  { value: 'pickByThisWeek', label: 'Pick By This Week' },
  { value: 'dropOffYesterday', label: 'Dropped Off Yesterday' },
  { value: 'dropOffToday', label: 'Drops Off Today' },
  { value: 'dropOffTomorrow', label: 'Drops Off Tomorrow' },
  { value: 'dropOffLastWeek', label: 'Dropped Off Last Week' },
  { value: 'dropOffThisWeek', label: 'Drops Off This Week' },
  { value: 'dropOffNextWeek', label: 'Drops Off Next Week' },
  { value: 'oldLoads', label: 'Old Loads' },
];

type FilterTypeT = 'text' | 'date';
type OperatorT = 'OR' | 'AND';
type FilterOpT =
  | 'equals'
  | 'inRange'
  | 'contains'
  | 'startsWith'
  | 'endsWith'
  | 'greaterThan'
  | 'lessThan';

type GridFilterT =
  | {
      filter: string;
      filterType: FilterTypeT;
      type: FilterOpT;
    }
  | {
      filterType: 'set';
      values: string[];
    }
  | {
      dateFrom: string;
      dateTo: string | undefined;
      filterType: 'date';
      type: FilterOpT;
    };

type GridConditionalFilterT = {
  filterType: FilterTypeT;
  operator: OperatorT;
  conditions: GridFilterT[];
};

export type FilterT = {
  label: string;
  getFilter: () => GridFilterT | GridConditionalFilterT;
};

const newDate = (startDate?: Date) => {
  const date = startDate !== undefined ? new Date(startDate) : new Date();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const pickDate = (
  delta = 0,
  dateOverride?: Date,
  dateTo?: Date,
): GridFilterT => {
  const date = dateOverride !== undefined ? dateOverride : newDate();
  date.setDate(date.getDate() + delta);

  return {
    dateFrom: `${date.toISOString().substring(0, 10)} 00:00:00`,
    dateTo:
      dateTo !== undefined
        ? `${dateTo.toISOString().substring(0, 10)} 00:00:00`
        : undefined,
    filterType: 'date',
    type: dateTo === undefined ? 'equals' : 'inRange',
  };
};

export const dateRange = (from: DateTime, to: DateTime): GridFilterT => {
  return {
    dateFrom: `${from.toISODate() ?? ''} ${from.toFormat('HH:mm:ss')}`,
    dateTo: `${to.toISODate() ?? ''} ${to.toFormat('HH:mm:ss')}`,
    filterType: 'date',
    type: 'inRange',
  };
};

export const dateEquals = (date: DateTime): GridFilterT => {
  return {
    dateFrom: `${date.toISODate() ?? ''} ${date.toFormat('HH:mm:ss')}`,
    dateTo: undefined,
    filterType: 'date',
    type: 'equals',
  };
};

export const filterRulesByValue: Record<string, FilterT[]> = {
  unassigned: [
    {
      label: 'status',
      getFilter() {
        return {
          filterType: 'set',
          values: ['unassigned'],
        };
      },
    },
  ],
  hotLoads: [
    {
      label: 'flagType',
      getFilter() {
        return {
          filterType: 'set',
          values: ['Hot Load'],
        };
      },
    },
  ],
  needsPaperwork: [
    {
      label: 'flagType',
      getFilter() {
        return {
          filterType: 'set',
          values: ['Missing Paperwork', 'Get Paperwork'],
        };
      },
    },
  ],
  myUnassigned: [
    {
      label: 'my',
      getFilter() {
        return {
          filter: 'yes',
          filterType: 'text',
          type: 'equals',
        };
      },
    },
    {
      label: 'status',
      getFilter() {
        return {
          filterType: 'set',
          values: ['unassigned'],
        };
      },
    },
  ],
  myLoads: [
    {
      label: 'my',
      getFilter() {
        return {
          filter: 'yes',
          filterType: 'text',
          type: 'equals',
        };
      },
    },
  ],
  ltlLoads: [
    {
      label: 'mode',
      getFilter() {
        return {
          filterType: 'set',
          values: ['LTL'],
        };
      },
    },
  ],
  myCustomers: [
    {
      label: 'myCustomers',
      getFilter() {
        return {
          filter: 'yes',
          filterType: 'text',
          type: 'equals',
        };
      },
    },
  ],
  pickByYesterday: [
    {
      label: 'pickDate',
      getFilter() {
        // eslint-disable-next-line no-console
        console.log('here!!');
        return pickDate(-1);
      },
    },
  ],
  pickByTomorrow: [
    {
      label: 'pickDate',
      getFilter() {
        return pickDate(1);
      },
    },
  ],
  pickByToday: [
    {
      label: 'pickDate',
      getFilter() {
        return pickDate();
      },
    },
  ],
  pickByThisWeek: [
    {
      label: 'pickDate',
      getFilter() {
        const date = newDate();
        const day = date.getDay();
        date.setDate(date.getDate() - day);

        const dateTo = newDate();
        dateTo.setDate(dateTo.getDate() + (7 - day));

        return pickDate(0, date, dateTo);
      },
    },
  ],
  pickByLastWeek: [
    {
      label: 'pickDate',
      getFilter() {
        const date = newDate();
        const day = date.getDay();
        date.setDate(date.getDate() - day - 7);

        const dateTo = newDate(date);
        dateTo.setDate(dateTo.getDate() + 6);

        return pickDate(0, date, dateTo);
      },
    },
  ],
  pickByNextWeek: [
    {
      label: 'pickDate',
      getFilter() {
        const date = newDate();
        const day = date.getDay();
        date.setDate(date.getDate() - day + 7);

        const dateTo = newDate(date);
        dateTo.setDate(dateTo.getDate() + 6);

        return pickDate(0, date, dateTo);
      },
    },
  ],
  dropOffYesterday: [
    {
      label: 'dropDate',
      getFilter() {
        const today = DateTime.now().startOf('day');
        const yesterday = today.plus({ days: -1 });

        return dateEquals(yesterday);
      },
    },
  ],
  dropOffToday: [
    {
      label: 'dropDate',
      getFilter() {
        const today = DateTime.now().startOf('day');
        return dateEquals(today);
      },
    },
  ],
  dropOffTomorrow: [
    {
      label: 'dropDate',
      getFilter() {
        const tomorrow = DateTime.now().startOf('day').plus({ day: 1 });
        return dateEquals(tomorrow);
      },
    },
  ],
  dropOffLastWeek: [
    {
      label: 'dropDate',
      getFilter() {
        const thisWeek = DateTime.now().startOf('week');
        const lastWeek = thisWeek.plus({ week: -1 });

        return dateRange(lastWeek, lastWeek.endOf('week'));
      },
    },
  ],
  dropOffThisWeek: [
    {
      label: 'dropDate',
      getFilter() {
        const startOfWeek = DateTime.now().startOf('week');
        const endOfWeek = startOfWeek.endOf('week');

        return dateRange(startOfWeek, endOfWeek);
      },
    },
  ],
  dropOffNextWeek: [
    {
      label: 'dropDate',
      getFilter() {
        const nextWeek = DateTime.now().startOf('week').plus({ week: 1 });
        const nextWeekEnd = nextWeek.endOf('week');

        return dateRange(nextWeek, nextWeekEnd);
      },
    },
  ],
  oldLoads: [
    {
      label: 'dropDate',
      getFilter() {
        const twoWeeksAgo = DateTime.now().plus({ week: -2 });

        return {
          dateFrom: twoWeeksAgo.toISODate(),
          dateTo: undefined,
          filterType: 'date',
          type: 'lessThan',
        };
      },
    },
  ],
};

export const columnsToolPanel = {
  id: 'columns',
  labelDefault: 'Columns',
  labelKey: 'columns',
  iconKey: 'columns',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: {
    suppressRowGroups: true,
    suppressValues: true,
    suppressPivots: true,
    suppressPivotMode: true,
    suppressColumnFilter: false,
    suppressColumnSelectAll: false,
    suppressColumnExpandAll: false,
  },
};

export const filtersToolPanel = {
  id: 'filters',
  labelDefault: 'Filters',
  labelKey: 'filters',
  iconKey: 'filters',
  toolPanel: 'agFiltersToolPanel',
  toolPanelParams: {
    suppressSetFilter: false,
  },
};
