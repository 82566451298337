import { Button, IconLabel, Popover, BasicModal } from '@components';
import { domainUrl } from '@constants/urls';
import { A, useNavigate } from '@solidjs/router';
import { LTLRateQuoteRequest, LTLRateQuoteResponse } from '@store/ltl';
import CheckCircleOutlinedIcon from '@suid/icons-material/CheckCircleOutlined';
import AddCircleOutlineOutlinedIcon from '@suid/icons-material/AddCircleOutlineOutlined';
import { getFalveyPricingQuoteData, saveTempQuote } from '@store/ltl/services';
import { setLtlQuoteState } from '@store/ltl/store';
import {
  FalveyInsuranceQuoteInLoad,
  LTLStoreQuoteResponse,
} from '@store/ltl/types';
import { closeModal, openModal } from '@store/modals';
import {
  GetWriteToOldTables,
  mergeOrderWithQuote,
  orderStore,
} from '@store/orders';
import { Delete, InfoOutlined } from '@suid/icons-material';
import SaveAsIcon from '@suid/icons-material/SaveAs';
import { Box, Grid, Paper, Stack } from '@suid/material';
import { routeOrderDetailsQuote } from '@utils/routeOrderDetails';
import { formatAmount } from '@utils/utils';
import { AgGridSolidRef } from '@ag-grid-community/solid';
import { Show, createSignal, onMount } from 'solid-js';
import { calculateMargin } from '@common/utils';
import { openDialogBox } from '@store/dialogBox';

import ViewCostDetailsPopover from '../ViewCostDetailsPopover';
import ViewTerminalPopover from '../ViewTerminalPopover';
import { quoteDetailsRowRenderClasses as cls } from './classes';
import { InsuranceUpdateAndConfirmationForm } from './InsuranceUpdateAndConfirmation';
interface QuoteProps {
  data: LTLRateQuoteResponse;
  request?: LTLRateQuoteRequest;
  isGetQuote?: boolean;
  type?: string;
  quoteStatus?: string;
  customerId?: number;
  id: string;
  gridRef?: AgGridSolidRef;
  setRowId: (id: string) => void;
  declaredValue?: number;
  falveyInsuranceVals?: FalveyInsuranceQuoteInLoad;
}

export const QuoteStatus = (quoteStatus: string) => {
  const statusColorMap: { [key: string]: string } = {
    Active: 'bg-[#31637F]',
    'Load Created': 'bg-[#31637F]',
    Expired: 'bg-[#F59D25]',
    Deleted: 'bg-[#B00020]',
  };
  const statusColor = statusColorMap[quoteStatus] || 'bg-[#31637F]';
  return <span class={`${cls.statusBtn} ${statusColor}`}>{quoteStatus}</span>;
};

// eslint-disable-next-line complexity
export const NewQuoteDetailsRowRender = (props: QuoteProps) => {
  const [saveQuoteButton, setSaveQuoteButton] = createSignal<boolean>(false);
  const [writeToOld, setWriteToOld] = createSignal<boolean>(false);

  const [savedQuoteResponse, setSavedQuoteResponse] =
    createSignal<LTLStoreQuoteResponse | null>(null);
  const [isStoreQuoteLoading, setIsStoreQuoteLoading] =
    createSignal<boolean>(false);
  const [isQuoteSaving, setIsQuoteSaving] = createSignal<boolean>(false);
  const navigate = useNavigate();
  const defaultProps = {
    minWidth: '1000px',
    width: '90%',
    height: '250px',
  };

  onMount(async () => {
    setWriteToOld(!!((await GetWriteToOldTables()) ?? false));
  });

  const getCustomerPriceWithInsurace = () => {
    let totalCustomerCost = 0;
    if (props.falveyInsuranceVals) {
      totalCustomerCost +=
        props.falveyInsuranceVals.quotePremium +
        props.falveyInsuranceVals.quoteServiceFee;
    }
    return formatAmount(
      totalCustomerCost + (props.data.customerRateQuote?.totalCost ?? 0),
    );
  };

  const getAgentPriceWithInsurace = () => {
    let totalAgentCost = 0;

    if (props.falveyInsuranceVals) {
      totalAgentCost += props.falveyInsuranceVals.quotePremium;
    }
    return formatAmount(totalAgentCost + props.data.totalCost);
  };
  const saveQuoteFunction = async (
    withInsurance?: boolean,
    updateQuoteRespWithId?: boolean,
    ltlInsuranceVals?: FalveyInsuranceQuoteInLoad,
  ) => {
    //updateQuoteRespWithId is used to update the quote response with the correlationId in order to update the declared Value and other falvey related values in a already saved quote
    const requestDetails = Boolean(withInsurance)
      ? {
          ...props.request,
          declaredValue: ltlInsuranceVals?.shippingSumInsured,
          premiumFee: ltlInsuranceVals?.quotePremium,
          serviceFee: ltlInsuranceVals?.quoteServiceFee,
        }
      : props.request;
    const response = await saveTempQuote({
      QuoteResponse: Boolean(updateQuoteRespWithId)
        ? {
            ...props.data,
            ltlQuoteId: savedQuoteResponse()?.correlationId,
            lTLQuoteId: savedQuoteResponse()?.correlationId,
          }
        : props.data,
      customerId: props.customerId,
      RequestDetails: requestDetails,
    });
    return response as LTLStoreQuoteResponse;
  };

  const handleSaveQuote = async () => {
    setIsQuoteSaving(true);
    await saveQuoteFunction(
      Boolean(props.declaredValue),
      true,
      props.falveyInsuranceVals,
    ).then((resp) => {
      if (Boolean(resp)) {
        setSavedQuoteResponse(resp);
        setSaveQuoteButton(true);
      }
    });
    setIsQuoteSaving(false);
  };

  const getFalveyPricingQuoteDataCall = async (
    declaredValue: number,
    ltlQuoteId?: string,
  ) => {
    const additionalKeys = Boolean(ltlQuoteId) ? { ltlQuoteId } : {};
    await getFalveyPricingQuoteData({
      shipmentTypeID: '139015',
      declaredValue,
      currency: 'USD',
      shippingMerchandiseDescription: '',
      ...additionalKeys,
    });
  };

  const handleSelectQuote = async (
    withInsurance?: boolean,
    insuranceVals?: FalveyInsuranceQuoteInLoad,
  ) => {
    let ltlQuoteId;
    if (!Boolean(props.isGetQuote)) {
      ltlQuoteId = props.data.lTLQuoteId;
      const declaredValue = Boolean(withInsurance)
        ? insuranceVals?.shippingSumInsured ?? 0
        : 0;
      if (Boolean(withInsurance) || props.falveyInsuranceVals) {
        await getFalveyPricingQuoteDataCall(
          declaredValue,
          ltlQuoteId as string,
        );
      }
    } else {
      if (saveQuoteButton()) {
        ltlQuoteId = savedQuoteResponse()?.correlationId;
        if (Boolean(withInsurance) || props.falveyInsuranceVals) {
          await getFalveyPricingQuoteDataCall(
            insuranceVals?.shippingSumInsured ?? 0,
            ltlQuoteId as string,
          );
        }
      } else {
        setIsStoreQuoteLoading(true);
        const response = await saveQuoteFunction(
          withInsurance,
          true,
          insuranceVals,
        );
        ltlQuoteId = response.correlationId;
        setIsStoreQuoteLoading(false);
      }
    }
    closeModal(props.data.quoteID);
    if (orderStore.order.id !== 0 || Boolean(orderStore.copiedOrderId)) {
      closeModal('ltl-quoting-modal');
      // navigate to order details page with orderid and correlationId
      await mergeOrderWithQuote(String(ltlQuoteId), orderStore.order);
    } else {
      const isNative = routeOrderDetailsQuote(String(ltlQuoteId));
      if (isNative) {
        const path = `/order/details/?ltlQuoteId=${ltlQuoteId}`;
        navigate(path);
      }
    }
    closeModal('ltl-quoting-modal');
  };

  const getInsurancePremium = () => {
    if (props.falveyInsuranceVals) {
      return formatAmount(props.falveyInsuranceVals.quotePremium);
    }
    return '--';
  };

  const getInsuranceServicePremium = () => {
    if (props.falveyInsuranceVals) {
      const { quoteServiceFee, quotePremium } = props.falveyInsuranceVals;
      return formatAmount(quoteServiceFee + quotePremium);
    }
    return '--';
  };
  return (
    <Box p={2} class={Boolean(props.isGetQuote) ? '' : cls.cardContainer}>
      <Grid
        container
        spacing={2}
        sx={
          Boolean(props.isGetQuote)
            ? {
                width: '100%',
              }
            : defaultProps
        }
      >
        <Grid item sm={12} lg={Boolean(props.declaredValue) ? 7 : 8}>
          <Paper elevation={3} class="!px-3 !py-4 h-full w-full">
            <Grid container class="!mb-3">
              <Grid item md={6}>
                <Stack>
                  <Box class="font-semibold text-base">
                    {props.data.shipper}
                  </Box>
                  <Box class="text-sm font-normal">
                    Carrier Quote #{' '}
                    <span class="font-medium">{props.data.quoteID}</span>
                  </Box>
                </Stack>
              </Grid>
              <Grid item md={6} class="!flex !items-center !justify-end">
                <Popover
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  eleName={
                    <A
                      href="#"
                      activeClass="!no-underline flex items-center text-[#307eac]"
                    >
                      <InfoOutlined />
                      <span class="ml-1 pt-[1px]">Terminal Info</span>
                    </A>
                  }
                >
                  <ViewTerminalPopover {...props.data.terminalInfo} />
                </Popover>
                <span class={`${cls.marginText} mr-4 inline-flex`}>
                  {props.customerId !== undefined && props.customerId !== 0
                    ? `Margin: ${calculateMargin(
                        props.data.customerRateQuote?.totalCost ?? 0,
                        props.data.totalCost,
                      )}%`
                    : ''}
                </span>
                {writeToOld() && (
                  <Show
                    when={Boolean(props.isGetQuote === true)}
                    fallback={
                      <span
                        onClick={() => {
                          props.setRowId(props.id);
                          openDialogBox('deleteSavedQuote');
                        }}
                      >
                        <Delete class="!text-[#B00020] cursor-pointer" />
                      </span>
                    }
                  >
                    <Button
                      disabled={saveQuoteButton() || isQuoteSaving()}
                      variant="contained"
                      startIcon={<SaveAsIcon />}
                      label={saveQuoteButton() ? 'Saved' : 'Save Quote'}
                      onClick={handleSaveQuote}
                      isLoading={isQuoteSaving()}
                    />
                  </Show>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} class="!mb-0">
              <Grid item xs={12} lg={3} class={cls.logoBox}>
                <img
                  src={`${domainUrl}${props.data.logoImage}`}
                  alt="carrier logo"
                  class={cls.carrierLogo}
                />
              </Grid>
              <Grid item xs={12} lg={9} class="!flex !items-center gap-6">
                <Stack spacing={2}>
                  <Box>
                    Estimated transit days:{' '}
                    <span class="font-semibold">
                      {props.data.transitDays} business days
                    </span>
                  </Box>
                  <Box>
                    Estimated delivery date:{' '}
                    <span class="font-semibold">
                      {Boolean(props.data.estimatedDeliveryDate)
                        ? new Date(
                            props.data.estimatedDeliveryDate as string,
                          ).toLocaleDateString()
                        : '--'}
                    </span>
                  </Box>
                  <Box>{props.data.serviceType}</Box>
                </Stack>
                <Stack spacing={1} class="!h-full">
                  <IconLabel
                    label="Movement Type"
                    content={props.data.movementType ?? '--'}
                    classes={'flex-none mr-3'}
                  />
                  <IconLabel
                    label="Max Carrier Liability"
                    content={
                      <Box>
                        <span class="mr-2">
                          <span class="font-semibold">New: </span>
                          {formatAmount(props.data.maxNewLiability!)}
                        </span>
                        <span>
                          <span class="font-semibold">Used: </span>
                          {formatAmount(props.data.maxUsedLiability!)}
                        </span>
                      </Box>
                    }
                    classes={'flex-none'}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          lg={Boolean(props.declaredValue) ? 2.5 : 4}
          class={Boolean(props.isGetQuote) ? '' : cls.cardContainer}
        >
          <Paper elevation={3} class="!px-3 !py-4 h-full w-full">
            <Box class="flex flex-col h-full justify-between">
              <Box class="flex items-center">
                <span class="text-[#1b495f] font-semibold">Cost</span>
                <span class="ml-auto mt-[-1px] mr-[-22px]">
                  <Popover
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    eleName={<InfoOutlined sx={{ color: '#307eac' }} />}
                    classes="!p-0"
                  >
                    <ViewCostDetailsPopover data={props.data} />
                  </Popover>
                </span>
              </Box>
              <Box class="flex">
                <Stack>
                  <span class="font-semibold">Agent</span>
                  <span class="text-[#666666] text-sm ml-4">Carrier Rate</span>
                </Stack>
                <Stack class="!ml-auto">
                  <span class="text-[#26856f]">
                    {formatAmount(props.data.totalCost)}
                  </span>
                  <span class="text-sm ml-auto text-[#666666]">
                    {formatAmount(props.data.totalCost)}
                  </span>
                </Stack>
              </Box>
              {props.data.customerRateQuote?.totalCost != null && (
                <Box class="flex">
                  <span class="font-semibold">Customer</span>
                  <span class="!ml-auto text-[#26856f]">
                    {formatAmount(props.data.customerRateQuote.totalCost)}
                  </span>
                </Box>
              )}
              <Box class="flex mt-2">
                <Button
                  variant="contained"
                  startIcon={<CheckCircleOutlinedIcon />}
                  class="!bg-[#248670] w-full"
                  onClick={handleSelectQuote}
                  label="Select Rate"
                  isLoading={isStoreQuoteLoading()}
                  disabled={isStoreQuoteLoading()}
                />
                <Show when={!Boolean(props.declaredValue)}>
                  <Button
                    variant="contained"
                    class={`${
                      props.customerId !== 0 && '!bg-[#066da1]'
                    } !ml-2 w-full`}
                    onClick={() => openModal(props.data.quoteID)}
                    label="Add Insurance"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    disabled={props.customerId === 0}
                  />
                </Show>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Show when={Boolean(props.declaredValue)}>
          <Grid
            item
            xs={12}
            lg={2.5}
            class={Boolean(props.isGetQuote) ? '' : cls.cardContainer}
          >
            <Paper elevation={3} class="!px-3 !py-4 h-full w-full">
              <Box class="flex flex-col h-full justify-between">
                <Box class="font-semibold flex mb-4 items-center">
                  <span class="text-[#1b495f] ">w/ Insurance</span>
                  <span class="ml-auto mt-[-1px] mr-[-22px]">
                    <Popover
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      eleName={<InfoOutlined sx={{ color: '#307eac' }} />}
                      classes="!p-0"
                    >
                      <ViewCostDetailsPopover
                        withInsurance={true}
                        data={props.data}
                        ltlInsuranceVals={props.falveyInsuranceVals}
                      />
                    </Popover>
                  </span>
                </Box>
                <Box class="flex">
                  <Stack>
                    <span class="font-semibold">Agent</span>
                    <span class="text-[#666666] text-sm ml-4">
                      Carrier Rate
                    </span>
                    <span class="text-[#666666] text-sm ml-4">
                      Insurance Rate
                    </span>
                  </Stack>
                  <Stack class="!ml-auto">
                    <span class="text-[#26856f]">
                      {getAgentPriceWithInsurace()}
                    </span>
                    <span class="text-sm ml-auto text-[#666666]">
                      {formatAmount(props.data.totalCost)}
                    </span>
                    <span class="text-sm ml-auto text-[#666666]">
                      {getInsurancePremium()}
                    </span>
                  </Stack>
                </Box>
                {props.data.customerRateQuote?.totalCost != null && (
                  <Box class="flex mt-2">
                    <Stack>
                      <span class="font-semibold">Customer</span>
                      <span class="text-[#666666] text-sm ml-4">
                        Insurance & Fees
                      </span>
                    </Stack>
                    <Stack class="!ml-auto">
                      <span class="text-[#26856f]">
                        {getCustomerPriceWithInsurace()}
                      </span>
                      <span class="text-sm ml-auto text-[#666666]">
                        {getInsuranceServicePremium()}
                      </span>
                    </Stack>
                  </Box>
                )}
                <Button
                  label="SELECT INSURANCE RATE"
                  onClick={() => openModal(props.data.quoteID)}
                  class="!bg-[#066da1] !mt-4"
                  startIcon={<CheckCircleOutlinedIcon />}
                />
              </Box>
            </Paper>
          </Grid>
        </Show>
      </Grid>
      <BasicModal id={props.data.quoteID} width={'650px'} footer={false}>
        <InsuranceUpdateAndConfirmationForm
          items={props.request?.loadItems ?? []}
          onConfirmation={async (val) => {
            await handleSelectQuote(true, val as FalveyInsuranceQuoteInLoad);
          }}
          onCancel={() => {
            closeModal(props.data.quoteID);
          }}
          declaredValue={props.declaredValue}
          customerCost={props.data.customerRateQuote?.totalCost}
          carrierCost={props.data.totalCost}
          updateDeclaredValue={(val) => setLtlQuoteState('declaredValue', val)}
          isStoreQuoteLoading={isStoreQuoteLoading}
          falveyInsuranceQuote={{
            quotePremium: props.falveyInsuranceVals?.quotePremium ?? 0,
            quoteServiceFee: props.falveyInsuranceVals?.quoteServiceFee ?? 0,
            shippingSumInsured: props.declaredValue ?? 0,
          }}
        />
      </BasicModal>
    </Box>
  );
};
