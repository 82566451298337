import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as SSelect,
} from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { capitalizeWord } from '@utils/utils';
import { splitProps } from 'solid-js';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      'max-height': `${ITEM_HEIGHT * 6 + ITEM_PADDING_TOP}px`,
    },
  },
};
type Styles<T = string> = {
  [key: string]: T | Styles<T>;
};
const iconStyles = {
  position: 'absolute',
  height: '100%',
  width: '40px',
  padding: '6px',
  top: '0',
  right: '0',
  color: 'rgb(27, 102, 143)',
  border: '1px solid #ccc',
  background: '#fff',
  zIndex: 1,
  borderTopRightRadius: 'inherit',
  borderBottomRightRadius: 'inherit',
};

export type MenuItemType = {
  label: string;
  value: string;
  disabled?: boolean;
};

export type BasicSelectProps = {
  label: string;
  menuItems: (string | MenuItemType)[];
  onChange?: (e: SelectChangeEvent) => void;
  value?: string | string[] | null;
  multiple?: boolean;
  width?: string | number;
  sxProps?: Record<string, string>;
  menuProps?: Record<string, string>;
  customIconStyle?: Styles;
  size?: 'small' | 'medium';
  boxedIcon?: boolean;
  name?: string;
  padEnabled?: boolean;
  renderValue?: (selected: string) => string;
  disabled?: boolean;
};

const defaultRenderValue = (selected: string | string[]) => {
  return typeof selected !== 'string'
    ? `${selected.length} column(s) selected`
    : capitalizeWord(selected);
};

export const Select = (props: BasicSelectProps) => {
  const [load, rest] = splitProps(props, [
    'menuItems',
    'name',
    'label',
    'value',
    'onChange',
    'multiple',
    'width',
    'sxProps',
    'menuProps',
    'customIconStyle',
    'boxedIcon',
    'size',
    'renderValue',
    'disabled',
  ]);
  const getStyles = () => {
    if (Boolean(load.customIconStyle)) {
      return { ...load.sxProps, ...load.customIconStyle };
    } else if (Boolean(load.boxedIcon)) {
      return {
        ...load.sxProps,
        background: '#fff',
        '.MuiSelect-icon': iconStyles,
      };
    }
    return { ...load.sxProps, background: '#fff' };
  };

  const isItemSelected = (value: string, selected: string[]) => {
    return selected.indexOf(value) !== -1;
  };

  const renderSelectOption = (item: string | MenuItemType) => {
    if (typeof item === 'string') {
      return (
        <MenuItem value={item}>
          {Boolean(load.multiple) && (
            <Checkbox checked={isItemSelected(item, props.value as string[])} />
          )}
          <ListItemText primary={item} />
        </MenuItem>
      );
    }

    const disabled = item.disabled !== undefined && item.disabled;
    const isValueEmpty = item.value === '';
    const padEnabled = props.padEnabled !== undefined && props.padEnabled;

    return (
      <MenuItem value={item.value} disabled={item.disabled}>
        <Box
          paddingLeft={
            padEnabled && !disabled && !isValueEmpty ? '15px' : undefined
          }
        >
          {Boolean(load.multiple) && (
            <Checkbox checked={props.value.indexOf(item.value) > -1} />
          )}
          <ListItemText primary={item.label} />
        </Box>
      </MenuItem>
    );
  };
  return (
    <FormControl
      sx={{ width: load.width ?? '100%' }}
      size={load.size ?? 'small'}
    >
      <InputLabel>{props.label}</InputLabel>
      <SSelect
        {...rest}
        name={load.name}
        labelId={load.label}
        multiple={load.multiple}
        label={load.label}
        value={load.value}
        onChange={load.onChange}
        input={<OutlinedInput label={load.label} />}
        renderValue={load.renderValue ?? defaultRenderValue}
        MenuProps={{ ...load.menuProps, ...MenuProps }}
        sx={{
          ...getStyles(),
          fontSize: '14px',
          '& label': { fontSize: '14px' },
        }}
        disabled={load.disabled}
      >
        {load.menuItems.map((item: string | MenuItemType) =>
          renderSelectOption(item),
        )}
      </SSelect>
    </FormControl>
  );
};
