import { Financials, ICustomerDetails } from './types';

export const INITIAL_CUSTOMER_DETAILS: ICustomerDetails = {
  id: null,
  creditInsuranceId: null,
  creditInsuranceCoverageType: null,
  creditInsuranceCoverageTypeName: null,
  creditInsurancePolicyDate: null,
  createdDate: null,
  name: null,
  taxId: null,
  physicalAddress1: null,
  physicalAddress2: null,
  physicalCity: null,
  physicalState: null,
  physicalZip: null,
  mailingAddress1: null,
  mailingAddress2: null,
  mailingCity: null,
  mailingState: null,
  mailingZip: null,
  creditLimit: 0,
  insuredFor: 0,
  accountManagerName: null,
  salesManagerName: null,
  invoiceMethod: null,
  billingInstructions: null,
  status: 'Customer',
  paymentTerms: null,
  collectionTerms: null,
  userName: null,
  password: null,
  accountingSysId: null,
  loadInstructions: null,
  optOutBalanceEmail: false,
  optOutPastDueDateBalanceEmail: false,
  allowAutoDispatch: false,
  enableLTL: false,
  enableCSP: false,
  ediMapping: null,
  verified: undefined,
  invoicePaperworkRequired: undefined,
  alwaysTrackLoads: false,
  isPublic: false,
  customerExists: false,
  chargeCreditCardFee: null,
  trackingXId: null,
  accountManagerOfficeId: null,
  salesManagerOfficeId: null,
  accountManagerId: null,
  trackingServiceId: null,
  salesManagerId: null,
  prospectId: null,
  collectionsUserId: null,
  commissionDiscount: null,
  accountingSysBalance: null,
  ediTemplateId: null,
  parentId: null,
  needsPreapproval: undefined,
  outstandingOrders: null,
  orderSummary: null,
  quotes: [],
  comments: [],
  contacts: [],
  requests: [],
  groups: [],
  financials: {} as Financials,
  collectionComments: [],
  trackings: null,
  metadata: [],
  requiredDocumentTypes: [],
  aTGLiability: null,
  agentLiability: null,
  agentLiabilityNote: null,
  agentLiabilityEffectiveDate: null,
  claimedBy: null,
  claimedById: null,
  pcMilerVersion: null,
  collectionTierId: null,
  nextActionDate: null,
  keyReasonId: null,
  insurance: null,
  ltlCostPlus: null,
  businessTypeName: null,
  businessTypeCode: null,
  businessTypeId: null,
  businessTypeDisplayName: null,
  loadPending: false,
  paymentType: 0,
  requestedCustomerDoNotSolicit: null,
  isApprovedCustomerDoNotSolicit: null,
  isCustomerCentralized: null,
  seasonality: null,
  approvedCustomerDoNotSolicitDate: null,
  holdRetraction: false,
  revisitRetractionDate: null,
  exceptionReason: null,
  seasonalityComment: '',
  trackingVisibilityProvider: null,
  capacityGroupId: null,
  defaultLogoUrl: null,
  pendingCreditRequests: [],
  ediRequest: null,
  outstandingBalance: 0,
};
