import { produce } from 'solid-js/store';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import { fetchAllOffices } from './services';
import { setOfficeStore } from './store';
import { OfficeInfo } from './types';

export const getAllOfficesData = async () => {
  try {
    setOfficeStore('isLoading', true);
    const resp = await fetchAllOffices({
      $format: 'json',
      $orderby: 'CreatedDate desc',
    });
    if (Boolean(resp)) {
      setOfficeStore(
        produce((draft) => {
          draft.offices = resp as OfficeInfo[];
          draft.isLoading = false;
          draft.isError = false;
        }),
      );
    }
  } catch (error: unknown) {
    setOfficeStore('isLoading', false);
    handleToast(ToastType.Error, 'Fetching offices failed');
  }
};
