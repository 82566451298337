import { Notification } from '@components';
import { orderStore } from '@store/orders';
import { Grid } from '@suid/material';
import { uniq } from 'lodash';
import { Component, For, Show, createEffect, createSignal } from 'solid-js';

const AdjustmentErrors: Component = () => {
  const [errors, setErrors] = createSignal<string[]>([]);

  createEffect(() => {
    const errors = [];
    for (const key in orderStore.orderFormError) {
      const newKey = key.split('.')[1];
      if (newKey === 'retraction') {
        errors.push(orderStore.orderFormError[key]?.[0]);
      }
    }
    setErrors(uniq(errors as string[]));
  });

  return (
    <Show when={errors().length > 0}>
      <Grid item xs={12}>
        <For each={errors()}>
          {(error) => {
            return <Notification type="error" text={error} />;
          }}
        </For>
      </Grid>
    </Show>
  );
};

export default AdjustmentErrors;
