import { Layout } from '@layouts/index';
import { Box, Stack } from '@suid/material';
import { Component, createEffect, onCleanup, Show } from 'solid-js';
import {
  factoringCompanyDetailsStore,
  getFactoringCompanyData,
  setFactoringCompanyDetailsStore,
} from '@store/factoringCompany/factoringCompanyDetails';
import { useParams } from '@solidjs/router';
import { CarrierDetailsHeaderSkeleton } from '@views/carriers/carrierDetails/components';
import { produce } from 'solid-js/store';
import { FactoringCompany } from '@store/carriers';

import {
  FactoringCompanyDetailsHeader,
  FactoringCompanyDetailsTabPanel,
} from './components';

export const FactoringCompanyDetails: Component = () => {
  const params = useParams();

  createEffect(async () => {
    if (params.id) {
      await getFactoringCompanyData(params.id);
      setFactoringCompanyDetailsStore('creationMode', false);
    } else {
      setFactoringCompanyDetailsStore(
        produce((state) => {
          state.factoringCompanyDetails = {} as FactoringCompany;
          state.creationMode = true;
        }),
      );
    }
  });

  onCleanup(() => {
    setFactoringCompanyDetailsStore(
      produce((state) => {
        state.factoringCompanyDetails = {} as FactoringCompany;
        state.creationMode = true;
        state.factoringCompanyDetailsErrors = null;
      }),
    );
  });

  return (
    <Layout>
      <Stack class="bg-[#E1ECF2] h-full overflow-scroll">
        <Box class="bg-white" id="customer-detail-page-header">
          <Show
            when={!factoringCompanyDetailsStore.isLoading}
            fallback={<CarrierDetailsHeaderSkeleton />}
          >
            <FactoringCompanyDetailsHeader />
          </Show>
        </Box>
        <Box class="m-4 p-2 bg-white ">
          <FactoringCompanyDetailsTabPanel />
        </Box>
      </Stack>
    </Layout>
  );
};
