import { LastUsedTopStopModel } from '@store/orders';
import { StopsCard, Notification } from '@components';
import { Divider } from '@suid/material';

import { stopsListClasses } from './classes';

interface StopListProps {
  heading: string;
  stops: LastUsedTopStopModel[];
  setAddressFromStopList: (stop: LastUsedTopStopModel) => void;
  customerId: number | null;
}

export const StopsList = (props: StopListProps) => {
  return (
    <div class={stopsListClasses.container}>
      <span class={stopsListClasses.heading}>{props.heading}</span>
      {Boolean(props.customerId) ? (
        <div class={stopsListClasses.overflowContaier}>
          {props.stops.slice(0, 10).map((stop) => (
            <>
              <StopsCard
                stop={stop}
                stopCardClick={(stop) => {
                  props.setAddressFromStopList(stop);
                }}
              />
              <Divider />
            </>
          ))}
        </div>
      ) : (
        <Notification
          type="warning"
          text="Please select a customer for the order to view stops."
        />
      )}
    </div>
  );
};
