export default {
  headerContainer:
    ' md:items-center md:justify-between text-white px-2 md:px-6 py-2 bg-[#0E2C3B] flex flex-wrap gap-3 flex-col md:flex-row justify-center',
  innerContainer:
    'flex gap-4 md:gap-5 items-center !justify-center md:!justify-between',
  logoTitle: {
    color: '#A5C6DA',
    fontFamily: 'Roboto',
    fontSize: '1.5rem',
    fontWeight: '400',
    lineHeight: '32px',
  },
};
