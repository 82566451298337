import { Button } from '@components';
import { closeModal } from '@store/modals';
import { orderStore, setOrderToVoid } from '@store/orders';

export const voidModalId = 'VoidModal';

export const VoidModal = () => {
  return (
    <>
      <div class="font-semibold ">
        Are you sure you want to void this order?
      </div>
      <div class="flex gap-2 mt-2 justify-end mr-2">
        <Button
          label="Yes"
          onClick={() => {
            void setOrderToVoid(orderStore.order.id);
            closeModal(voidModalId);
          }}
        />
        <Button label="Cancel" onClick={() => closeModal(voidModalId)} />
      </div>
    </>
  );
};
