/* eslint-disable no-console */
import { LinkComponent, ToastType } from '@components';
import { DialogBox } from '@components/DialogBox';
import { closeDialogBox, openDialogBox } from '@store/dialogBox';
import {
  DeleteFile,
  DocumentEntityType,
  DocumentModel,
  DocumentUploadResponse,
  UploadDocument,
} from '@store/documents';
import DeleteIcon from '@suid/icons-material/Delete';
import InfoIcon from '@suid/icons-material/Info';
import {
  Box,
  CircularProgress,
  TextField as FileUploadInput,
  FormControl,
  FormLabel,
  Grid,
} from '@suid/material';
import { ConfigManager } from '@utils/ConfigManager';
import { createError } from '@utils/errorUtils';
import { handleToast, isAdmin } from '@utils/utils';
import { HoverPop } from '@views/carrierMatching/HoverPop';
import { differenceWith } from 'lodash';
import { createEffect, createSignal, onCleanup, onMount, Show } from 'solid-js';

import { classes } from './FileUpload.style';

export type Props = {
  height?: number;
  width?: number;
  label?: string;
  disabled?: boolean;
  alreadyUploadedDocuments?: DocumentModel[];
  documentEntityType: DocumentEntityType;
  entityId: number;
  onChange?: (files: fileUpload[]) => void;
  required?: boolean;
  acceptedFileTypes?:
    | 'pdf'
    | 'jpeg'
    | 'jpg'
    | 'png'
    | 'doc'
    | 'docx'
    | 'all'
    | 'url'
    | string;
  showAttachFiles?: boolean;
};

type fileUpload = {
  file?: File | null;
  document: DocumentModel;
};

const DEFAULT_HEIGHT = 150;
const DEFAULT_WIDTH = 100;
const DEFAULT_LABEL = 'Click or Drag a File To Upload';

// eslint-disable-next-line complexity
function FileUpload(props: Readonly<Props>) {
  const [files, setFiles] = createSignal<fileUpload[]>(
    props.alreadyUploadedDocuments?.map((x) => {
      return {
        document: x,
      } as fileUpload;
    }) ?? [],
  );
  const [fileToDelete, setFileToDelete] = createSignal<fileUpload | null>(null);
  const [loading, setLoading] = createSignal(false);
  const [inputRef, setInputRef] = createSignal<HTMLInputElement>();
  const [dropZoneRef, setDropZoneRef] = createSignal<HTMLDivElement>();
  const [buttonRef, setButtonRef] = createSignal<HTMLDivElement>();
  const renderedItems: fileUpload[] | null = [];
  const reconcileFileListToApiUploadResponse = (
    uploadResponse: DocumentUploadResponse,
  ): fileUpload[] => {
    const uploads: fileUpload[] = [];
    uploadResponse.results.forEach((res) => {
      uploads.push({
        document: {
          name: res.name,
          url: res.url,
        },
      });
    });
    return uploads;
  };

  createEffect(() => {
    const diff = differenceWith(
      props.alreadyUploadedDocuments ?? [],
      files(),
      (a, b) => a.name === b.document.name,
    );

    if (diff.length > 0) {
      setFiles((prev) => {
        const newFiles = diff.map((doc) => {
          return {
            document: doc,
          } as fileUpload;
        });

        return [...prev, ...newFiles];
      });
    }
  });

  // Event handler for file change
  const handleFileChange = async (event: Event, bypassState = false) => {
    const target = event.target as HTMLInputElement;
    const selectedFiles = target.files;
    const acceptedFileTypes =
      props.acceptedFileTypes === undefined ? 'all' : props.acceptedFileTypes;

    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    if (acceptedFileTypes) {
      const acceptedTypes = acceptedFileTypes.split(',');
      for (const file of selectedFiles) {
        if (
          !acceptedTypes.some((type) => {
            return file.type.includes(type.trim()) || type === 'all';
          })
        ) {
          target.value = '';
          return;
        }
      }
    }

    if (!bypassState) {
      setLoading(true);
    }

    try {
      const response = await UploadDocument({
        documentEntityType: props.documentEntityType,
        entityId: props.entityId,
        file: selectedFiles,
      });
      const newFiles = reconcileFileListToApiUploadResponse(response);

      setFiles((prevFiles) => {
        const allFiles = [...prevFiles, ...newFiles];
        props.onChange?.(allFiles);
        return allFiles;
      });
    } catch (error) {
      const err = createError(
        error,
        `Failed to upload ${selectedFiles[0].name}`,
      );
      handleToast(ToastType.Error, err.message);
    }

    if (!bypassState) {
      setLoading(false);
    }
  };

  const onDragOver = (e: DragEvent) => {
    e.preventDefault();
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = '#d3ebf0';
      dropZone.style.border = '3px solid #026ea1';
    }
  };

  const onDragLeave = (e: DragEvent) => {
    e.preventDefault();
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = 'rgb(242, 246, 248)';
      dropZone.style.border = '3px solid transparent';
    }
  };

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.style.backgroundColor = 'rgb(242, 246, 248)';
      dropZone.style.border = '3px solid transparent';
    }

    const fileList: File[] = [];
    const promises: Promise<void>[] = [];
    let unsupportedFileType = false;

    setLoading(true);

    [...(e.dataTransfer?.items || [])].forEach((item) => {
      if (item.type === 'text/x-moz-url' || item.type === 'text/uri-list') {
        unsupportedFileType = true;
      } else if (item.kind === 'file') {
        const file = item.getAsFile();

        if (file !== null) {
          fileList.push(file);

          const event = {
            target: {
              files: [file],
            },
          } as unknown as Event;

          promises.push(handleFileChange(event, true));
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (unsupportedFileType) {
      handleToast(
        ToastType.Error,
        'The file you dropped cannot be uploaded. Please download it to your computer and try uploading again',
      );
    }

    void Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  onMount(() => {
    window.onbeforeunload = () => {
      if (loading()) {
        return 'There are pending file uploads. Are you sure you want to leave/refresh?';
      }
    };

    const dropZone = dropZoneRef();

    if (dropZone) {
      dropZone.addEventListener('dragover', onDragOver);
      dropZone.addEventListener('dragenter', onDragOver);
      dropZone.addEventListener('dragleave', onDragLeave);
      dropZone.addEventListener('drop', onDrop);
      dropZone.style.transition = '0.2s ease-in-out';
      dropZone.style.border = '3px solid transparent';
      dropZone.style.borderRadius = '6px';
    }

    buttonRef()?.addEventListener('click', () => {
      inputRef()?.click();
    });
  });

  onCleanup(() => {
    dropZoneRef()?.removeEventListener('dragover', onDragOver);
    dropZoneRef()?.removeEventListener('dragenter', onDragOver);
    dropZoneRef()?.removeEventListener('dragleave', onDragLeave);
    dropZoneRef()?.removeEventListener('drop', onDrop);
  });

  const requestFileDeletion = (file: fileUpload) => {
    const index = renderedItems.findIndex(
      (x) => x.document.name === file.document.name,
    );
    if (index > -1) {
      renderedItems.splice(index, 1);
      setFileToDelete(file);
      openDialogBox('deleteUploadedDocument');
    }
  };

  const confirmFileDeletion = async () => {
    if (fileToDelete()) {
      const url = fileToDelete()?.document.url;
      if (url != null) {
        setLoading(true);

        try {
          const response = await DeleteFile(url);

          if (response) {
            setFiles((prevFiles) => {
              const newFiles = prevFiles.filter(
                (file) => file !== fileToDelete(),
              );
              setFileToDelete(null);
              props.onChange?.(newFiles);
              return newFiles;
            });
            closeDialogBox('deleteUploadedDocument');
          }
        } catch (error) {
          handleToast(ToastType.Error, (error as Error).message);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const cancelFileDeletion = () => {
    if (!renderedItems.includes(fileToDelete() as fileUpload)) {
      renderedItems.push(fileToDelete() as fileUpload);
    }
    setFileToDelete(null);
    closeDialogBox('deleteUploadedDocument');
  };

  const renderFileList = (
    <Grid>
      {files().map((file) => {
        if (!renderedItems.includes(file)) {
          renderedItems.push(file);
        }

        return (
          <Grid container>
            <Grid item xs={12}>
              <div class="flex ml-2 gap-x-2 items-center">
                <LinkComponent
                  target={'_blank'}
                  url={`${
                    ConfigManager.domainUrl
                  }api/document/ServeDocument/?url=${
                    file.file ? file.file : file.document.url
                  }`}
                  title={file.file ? file.file.name : file.document.name}
                />
                {!loading() && !renderedItems.includes(file) && file.file ? (
                  <Box sx={classes.LoadingTextBoxStyles()}>
                    {file.file.size / 1000}kb • Loading
                  </Box>
                ) : file.file ? (
                  <Box sx={classes.LoadingTextBoxStyles()}>
                    {file.file.size / 1000}kb
                  </Box>
                ) : (
                  <></>
                )}
                <Show when={isAdmin() && !loading()}>
                  <DeleteIcon
                    class="cursor-pointer"
                    sx={classes.DeleteIconStyles()}
                    onClick={() => requestFileDeletion(file)}
                  />
                </Show>
              </div>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  const handleSubmit = async () => {
    await confirmFileDeletion();
  };

  return (
    <>
      <FormControl
        variant="standard"
        sx={classes.fileUploadContainerStyles()}
        ref={setDropZoneRef}
      >
        <Grid
          container
          sx={{
            ...classes.iconLabelContainer(props.height ?? DEFAULT_HEIGHT),
            ...classes.fileUploadInputStyles(
              props.height ?? DEFAULT_HEIGHT,
              props.width ?? DEFAULT_WIDTH,
            ),
            cursor: 'pointer',
          }}
          ref={setButtonRef}
          height="40px"
        >
          <FormLabel sx={classes.inputLabelStyles(props.disabled ?? false)}>
            {loading() ? (
              <>Files are uploading please wait</>
            ) : (
              props.label ?? (
                <div class="flex items-center cursor-pointer">
                  <div>{DEFAULT_LABEL}</div>
                  <div class="relative z-10 ml-2">
                    <HoverPop anchor={<InfoIcon />} render={true}>
                      <div class="p-2">
                        SVG, PNG, JPG, GIF, CSV, TIFF, BMP, or, PDF (max. 5mb)
                      </div>
                    </HoverPop>
                  </div>
                </div>
              )
            )}
          </FormLabel>
        </Grid>
        <FileUploadInput
          {...props}
          inputProps={{
            multiple: true,
          }}
          inputRef={setInputRef}
          type="file"
          sx={{
            visibility: 'hidden',
          }}
          disabled={(props.disabled ?? false) || loading()}
          onChange={handleFileChange}
          required={props.required}
        />
        {(props.showAttachFiles ?? true) && files().length > 0 && (
          <>{renderFileList}</>
        )}
        <Show when={loading()}>
          <Box sx={classes.BoxStyles()}>
            <Box>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Show>
      </FormControl>
      <DialogBox
        id="deleteUploadedDocument"
        title="Are you sure you want to delete this document?"
        onSubmit={handleSubmit}
        onSubmitText="Delete"
        onCancel={cancelFileDeletion}
      />
    </>
  );
}

export default FileUpload;
