import { Placeholder } from '@components/Skeleton';
import PeopleIcon from '@suid/icons-material/People';
import EmailIcon from '@suid/icons-material/Email';
import FaxIcon from '@suid/icons-material/Fax';
import PhoneIcon from '@suid/icons-material/Phone';
import { Box, Grid, Paper, Typography } from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { JSX, Show, createEffect, createSignal, mergeProps } from 'solid-js';
import { ContactProps } from '@common/types';

import classes from './classes';

type RowProps = {
  icon: JSX.Element;
  text: string;
  hideIcon?: boolean;
};

const typographyStyle = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: 'rgba(0, 0, 0, 0.6)',
};

const contactStyle = {
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.15px',
};

const Row = ({ icon, text, hideIcon = false }: RowProps) => (
  <Box class={classes.rowStyle}>
    <Show when={!hideIcon}>{icon}</Show>
    <Typography
      variant="subtitle1"
      sx={{ marginLeft: hideIcon ? 0 : 1, ...typographyStyle }}
    >
      {text}
    </Typography>
  </Box>
);

type ReassignContactCard<T> = {
  contact: T | undefined;
  showIcon?: boolean;
  showType?: boolean;
  type?: string;
  iconColor?: string;
  personIconSize?: string;
  isLoading?: boolean;
};

const typePhoneNumber = ['string', 'number'];

export const ReassignContactCard = <T extends ContactProps>(
  props: ReassignContactCard<T>,
) => {
  const [phoneNumber, setPhoneNumber] = createSignal<string>('-');
  props = mergeProps(
    {
      showIcon: false,
      showType: false,
      type: 'Type',
      iconColor: '#026EA1',
      isLoading: false,
    },
    props,
  );

  const CardPlaceholder = () => {
    return (
      <>
        <Grid container spacing={2} width={200}>
          <Grid item xs={12}>
            <Placeholder variant="text" height={60} />
            <Placeholder variant="rectangular" height={40} />
          </Grid>
        </Grid>
      </>
    );
  };
  createEffect(() => {
    if (Boolean(props.contact)) {
      setPhoneNumber(formatPhoneNumber(props.contact?.phone1));
    }
    const ext = props.contact?.phone1Ext;
    if (Boolean(ext)) {
      setPhoneNumber(`${phoneNumber} Ext. ${props.contact?.phone1Ext}`);
    }
  });

  return (
    <Paper elevation={0} class={classes.paperClass}>
      <Show when={props.isLoading === false} fallback={<CardPlaceholder />}>
        <Grid container>
          <Show when={props.showIcon}>
            <Box class={`${props.personIconSize != null ? 'pr-5' : 'pr-3'}`}>
              <Box class={classes.roundText}>
                <Typography class={classes.roundTextCircle}>A</Typography>
              </Box>
            </Box>
          </Show>
          <Box>
            <Box class={classes.accountStyle}>
              <Typography variant="body1" sx={contactStyle}>
                {props.contact?.name != null ? props.contact.name : '-'}
              </Typography>
              <div class={classes.groupsStyle}>
                <PeopleIcon class={classes.personIconStyle} />
                {props.contact?.accountingGroup}
              </div>
            </Box>
            <Row
              icon={<PhoneIcon sx={{ color: props.iconColor, fontSize: 16 }} />}
              text={phoneNumber()}
            />
            <Row
              icon={<EmailIcon sx={{ color: props.iconColor, fontSize: 16 }} />}
              text={props.contact?.email ?? '-'}
            />
            <Row
              icon={<FaxIcon sx={{ color: props.iconColor, fontSize: 16 }} />}
              text={
                typePhoneNumber.includes(typeof props.contact?.fax)
                  ? formatPhoneNumber(props.contact?.fax ?? '')
                  : '-'
              }
            />
            <Row
              icon={<FaxIcon sx={{ color: props.iconColor, fontSize: 16 }} />}
              text={props.contact?.office ?? '-'}
            />
          </Box>
          <Box>
            <Show when={props.showType}>
              <Box class={classes.typeBoxStyle}>
                <Typography>{props.type}</Typography>
              </Box>
            </Show>
          </Box>
        </Grid>
      </Show>
    </Paper>
  );
};
