import { apiClient, v3Client, v3FileUploadClient } from '@api/apiClient';
import { ToastType } from '@components';
import { SavedQuoteInfo } from '@store/ltl';
import { LTLCatalogs } from '@store/orders';
import { createError } from '@utils/errorUtils';
import { handleToast } from '@utils/utils';

import { setCustomerDetailsStore } from './store';
import {
  AddOrEditCustomerCommentPayload,
  AddProductDetails,
  Comment,
  CreateEditRequestPayload,
  CreditRequestPayload,
  CustomerClaimHistoryItem,
  CustomerEditHistoryItem,
  CustomerGeneralDocuments,
  CustomerPaymentHistoryItem,
  DocumentDetails,
  ediConfigurationType,
  Group,
  ICreditRequest,
  ICustomerDetails,
  ICustomerUserNameExists,
  SaveEDIConfigResponse,
  V4ApiResponse,
} from './types';

export const fetchCustomerData = async (customerId: string) => {
  try {
    const response: V4ApiResponse<ICustomerDetails> = await apiClient.get(
      `Customer/getcustomerdata/${customerId}`,
    );
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer detail Data');
  }
};

export async function addCustomerGeneralComment(
  payload: AddOrEditCustomerCommentPayload,
) {
  try {
    const response = await v3Client.post(
      `Comment/Customer/${payload.id}`,
      payload,
    );
    return response.data as unknown as Comment[];
  } catch (error: unknown) {
    throw createError(error, 'Failed to Add Comment on Customer general tab');
  }
}
export async function editCustomerGeneralComment(
  payload: AddOrEditCustomerCommentPayload,
  cb?: () => void,
) {
  try {
    const response = await v3Client.post(
      `Comment/Update/${payload.id}`,
      payload,
    );
    return response.data as unknown as Record<string, string | number>;
  } catch (error: unknown) {
    throw createError(error, 'Failed to edit Comment on Customer general tab');
  } finally {
    cb && cb();
  }
}

export async function deleteCustomerGeneralComment(commentId: number) {
  try {
    const response: {
      success: boolean;
    } = await v3Client.delete(`/Comment/Customer/${commentId}`);
    if (Boolean(response.success)) {
      return response.success;
    }
  } catch (error: unknown) {
    const e = createError(error, 'Failed to delete comment');
    handleToast(ToastType.Error, e.message);
  }
}

export const getCustomerUserNameExists = async (
  payload: ICustomerUserNameExists,
) => {
  try {
    const response = await v3Client.get(
      `Customer/CustomerUserNameExists?username=${payload.username}&customerId=${payload.customerId}&customercontactId=${payload.customercontactId}`,
    );
    return response;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch CustomerUserNameExists');
    throw createError(error, 'Failed to fetch CustomerUserNameExists');
  }
};

export const fetchCustomerDocumentType = async () => {
  try {
    const response = await v3Client.get('DocumentType');
    return response as unknown as DocumentDetails[];
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer document Data');
    throw createError(error, 'Failed to fetch customer document Data');
  }
};

export async function fetchCustomerEditHistory(id: string) {
  try {
    const response: V4ApiResponse<CustomerEditHistoryItem[]> =
      await apiClient.get(`customer/edithistory/${id}`);
    return response.value;
  } catch (error: unknown) {
    const e = createError(
      error,
      'Failed to fetch customer edit history. Please try again later',
    );
    handleToast(ToastType.Error, e.message);
    throw e;
  }
}

export async function getCustomerClaimsHistory(id: string) {
  try {
    const response: V4ApiResponse<CustomerClaimHistoryItem[]> =
      await apiClient.get(`/customer/claimhistory/${id}`);
    return response.value;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to fetch customer claim history. Please try again later.',
      );
    }
    throw new Error('Failed to fetch customer claim history');
  }
}
export const fetchCustomerPaymentHistory = async (customerId: string) => {
  try {
    const response: CustomerPaymentHistoryItem[] = await v3Client.get(
      `Customer/CustomerPayments/${customerId}?Id=${customerId}`,
    );
    return response;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer payment history');
    throw createError(error, 'Failed to fetch customer payment history');
  }
};

export const addProductDetails = async (payload: AddProductDetails) => {
  try {
    const response = await apiClient.post('LtlCatalog', payload);
    handleToast(ToastType.Success, 'Product Added Successfully');
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to add product');
    throw createError(error, 'Failed to Add Product Details');
  }
};

export const editProductDetails = async (payload: AddProductDetails) => {
  try {
    const response = await apiClient.put('LtlCatalog', payload);
    handleToast(ToastType.Success, 'Product Updated Successfully');
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to edit product');
    throw createError(error, 'Failed to Edit Product Details');
  }
};

export const deleteProductDetails = async (id: string) => {
  try {
    const res: {
      isSuccess: boolean;
    } = await apiClient.delete(`LtlCatalog/${id}`);
    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'Product Deleted Successfully');
      return res;
    }
    handleToast(ToastType.Error, 'Failed to delete product');
  } catch (error: unknown) {
    throw createError(error, 'Failed to Delete Product Details');
  }
};

export const fetchProductById = async (id: string) => {
  try {
    const response = await apiClient.get(`LtlCatalog/${id}`);
    return response as unknown as AddProductDetails;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch product by id');
    throw createError(error, 'Failed to fetch product by id');
  }
};

export async function fetchCapacityGroups() {
  try {
    const response = await v3Client.get('Group/CapacityGroups');
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to fetch customer capacity groups');
    handleToast(ToastType.Error, e.message);
    throw e;
  }
}

export const fetchEdiConfiguration = async (customerId: string) => {
  try {
    const response = await v3Client.get(
      `EDIConfig/GetEDIConfiguerationsForCustomer/${customerId}`,
    );
    return response as unknown as ediConfigurationType;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer detail Data');
    throw createError(error, 'Failed to fetch customer detail Data');
  }
};

export const fetchEdiProviders = async () => {
  try {
    const response = await v3Client.get('EDIConfig/GetProviders');
    return response as unknown as string[];
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer detail Data');
    throw createError(error, 'Failed to fetch customer detail data');
  }
};

export const sendEdiConfigurationData = async (data: unknown) => {
  try {
    const response: SaveEDIConfigResponse = await v3Client.post(
      'EDIConfig/SaveEDIConfiguerations',
      data,
    );
    return response;
  } catch (error: unknown) {
    throw createError(error, 'Failed to Save EDI Configuration Data');
  }
};
export const fetchCustomerSavedQuotes = async (customerId: string) => {
  try {
    const response = await v3Client.get(
      `LTL/SavedQuotesByCustomerId?customerId=${customerId}`,
    );
    const values = response as unknown as { data: SavedQuoteInfo[] };
    return values.data;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch Customer Saved Quotes Data');
    throw createError(error, 'Failed to Fetch Customer Saved Quotes Data');
  }
};

//TODO : we can create a new instance for File Upload using axios
export const uploadProductCatalog = async (id: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await v3FileUploadClient.post(
      `LTLCatalog/Import/${id}`,
      formData,
    );
    return response as unknown as {
      success: boolean;
      message: string;
      data: {
        validEntities: LTLCatalogs[];
      };
    };
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to upload product catalog');
    throw createError(error, 'Failed to upload product catalog');
  }
};

export const uploadQuoteFile = async (id: string, file: File) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await v3FileUploadClient.post(
      `Quote/Import/${id}`,
      formData,
    );
    const resp = response as unknown as {
      success: boolean;
      message: string;
      data: {
        imported: string[];
        notImported: {
          name: string;
          reason: string;
        }[];
      };
    };
    if (resp.data.imported.length > 0) {
      handleToast(ToastType.Success, 'Quote file uploaded successfully');
      return true;
    } else if (resp.data.notImported.length > 0) {
      handleToast(ToastType.Error, resp.data.notImported[0].reason);
      return false;
    }
  } catch (error: unknown) {
    throw createError(error, 'Failed to upload quote file');
  }
};

export const deleteCollectionCommentsCall = async (commentId: number) => {
  try {
    const response = await v3Client.delete(`Comment/Customer/${commentId}`);
    if ('success' in response) {
      handleToast(ToastType.Success, 'Collection comment deleted successfully');
      return true;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to delete collection comment');
  }
};

export const addCollectionCommentCall = async (
  customerId: number,
  comment: string,
) => {
  try {
    const resp = await v3Client.post(`Comment/Customer/${customerId}`, {
      comment,
      id: customerId,
      commentType: 'Collections',
      type: 'Customer',
    });
    if ('success' in resp && 'data' in resp) {
      handleToast(ToastType.Success, 'Collection comment added successfully');
      return resp.data as unknown as Comment;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to add collection comment');
  }
};

export const creditIncreaseCall = async (payload: ICreditRequest) => {
  try {
    await v3Client.post('Request/CreditIncrease', payload);
  } catch (error: unknown) {
    throw createError(error, 'Failed to increase credit');
  }
};

export const addInsurance = async (payload: Record<string, unknown>) => {
  try {
    setCustomerDetailsStore('isLoading', true);
    const response = await v3Client.post('Insurance/Post', payload);
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Failed to add insurance');
    handleToast(ToastType.Error, e.message);
  }
  setCustomerDetailsStore('isLoading', false);
};

export const updateCustomerDetailsCall = async (payload: ICustomerDetails) => {
  try {
    const res: V4ApiResponse<ICustomerDetails> = await apiClient.put(
      'Customer',
      payload,
    );

    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'customer changes saved');
      return res.value;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to update customer details');
  }
};

export const createEdiRequestCall = async (
  payload: CreateEditRequestPayload,
) => {
  try {
    const response: { success: boolean } = await v3Client.post(
      'Request/EDISetup',
      payload,
    );
    return response;
  } catch (error: unknown) {
    throw createError(error, 'Failed to create edi request');
  }
};

export const customerCreditRequest = async (payload: CreditRequestPayload) => {
  try {
    const response: { success: boolean } = await v3Client.post(
      `Request/CreditRequestResponse/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    const e = createError(error, 'Request Resolved failed manually');
    handleToast(ToastType.Error, e.message);
    throw e;
  }
};
export const fetchCustomerDetailsDocuments = async (customerId: string) => {
  try {
    const response = await v3Client.get(`Document/Customer/${customerId}`);
    return response as unknown as CustomerGeneralDocuments[];
  } catch (error: unknown) {
    const e = createError(error, 'Failed to fetch customer document data');
    handleToast(ToastType.Error, e.message);
    throw e;
  }
};

export const uploadCustomerDetailsDocument = async (
  customerId: string,
  file: File,
  type: 'Public' | 'Confidential' | 'Misc' | 'Logo',
) => {
  try {
    const formData = new FormData();
    formData.append(`${file.name}`, file);
    const response = await v3FileUploadClient.post(
      `Document/Customer/${customerId}/${type}`,
      formData,
    );
    return response.data as unknown as { url: string };
  } catch (error: unknown) {
    throw createError(error, 'Failed to upload customer document');
  }
};

export const deleteCustomerDetailsDocument = async (
  payload: Record<string, string>,
) => {
  try {
    const response = await v3Client.post('Document/DeleteFile', payload);
    return response as unknown as { success: boolean };
  } catch (error: unknown) {
    throw createError(error, 'Failed to delete customer document');
  }
};

export const updateCustomerDefaultLogoUrl = async (payload: {
  defaultLogoUrl: string | null;
  id: number;
}) => {
  try {
    const response: {
      success: boolean;
    } = await v3Client.put(
      `Customer/UpdateDefaultLogoUrl/${payload.id}`,
      payload,
    );
    return response.success;
  } catch (error: unknown) {
    throw createError(error, 'Failed to update customer default logo URL');
  }
};

export async function fetchCustomerOfficeGroupsCall(officeId: number) {
  try {
    const response = await v3Client.get<Group[]>(
      `Office/Groups/${officeId}?Id=${officeId}`,
    );
    return response as unknown as Group[];
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'There was a issue fetching office groups.');
    throw new Error(JSON.stringify(error));
  }
}

export const updateCustomerComment = async (
  payload: Record<string, string | number | null>,
) => {
  try {
    const resp: { success: boolean } = await v3Client.post(
      `Comment/Update/${payload.id}`,
      payload,
    );
    return resp.success;
  } catch (error: unknown) {
    const e = createError(error, 'Updating customer comments failed');
    handleToast(ToastType.Error, e.message);
  }
};
