import { Button } from '@components';
import {
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@suid/material';
import CheckBoxOutlinedIcon from '@suid/icons-material/CheckBoxOutlined';
import {
  IOrderViewModel,
  LineItemViewModel,
  orderStore,
  updateOrderState,
} from '@store/orders';
import { Delete } from '@suid/icons-material';
import { Component, Index, Show } from 'solid-js';
import { formatAmount } from '@utils/utils';
import { TextInput } from '@components/forms';
import { cloneDeep } from 'lodash';

import cls from '../classes';
import { removeTableRow } from '../../carrier/stops/stopsEditor/utilFunctions';
import EDICompareChangeStops from './EDICompareChangeStops';

type CurrentChangesProps = {
  order: IOrderViewModel;
  noUpdate: boolean;
};

type UpdateButtonProps = {
  onClick: () => void;
  classes: string;
  noUpdate: boolean;
};

const EDIChangeComparison = (props: CurrentChangesProps) => {
  const headers = ['Type', 'Rate', 'Qty', 'Total', ''];

  const UpdateButton: Component<UpdateButtonProps> = (props) => {
    return !props.noUpdate ? (
      <div class={`${props.classes} text-right mt-auto mb-[2px]`}>
        <Button
          startIcon={<CheckBoxOutlinedIcon class="text-[#ffffff]" />}
          label="UPDATE"
          class="updateRateClass"
          variant="contained"
          sx={{ backgroundColor: '#388E3C' }}
          onClick={props.onClick}
        />
      </div>
    ) : null;
  };

  const updateLineItems = (
    key: string,
    value: string | number,
    index: number,
  ) => {
    const lineItems = [...orderStore.order.lineItems];
    if (lineItems[index].operationType === 'None') {
      lineItems[index] = {
        ...lineItems[index],
        [key]: value,
        operationType: 'Update',
      };
    } else {
      lineItems[index] = { ...lineItems[index], [key]: value };
    }
    updateOrderState({ lineItems: lineItems });
  };

  const renderActionColumn = (id: number, rowData: LineItemViewModel) => {
    if (props.noUpdate) {
      return (
        <IconButton
          onClick={() => {
            removeTableRow(id, orderStore.order, 'order', 'lineItems');
          }}
        >
          <Delete class="text-[#B00020] cursor-pointer" />
        </IconButton>
      );
    }
    return (
      <UpdateButton
        onClick={() => {
          copyLineItems(rowData);
        }}
        classes="w-[50%]"
        noUpdate={props.noUpdate}
      />
    );
  };

  const copyLineItems = (lineItem: LineItemViewModel) => {
    const originalLineItemIndex = orderStore.order.lineItems.findIndex(
      (x) => x.type === lineItem.type && x.operationType !== 'Delete',
    );
    // If line item exists, update with new changes
    if (originalLineItemIndex !== -1) {
      const lineItems = [...orderStore.order.lineItems];
      lineItems[originalLineItemIndex] = {
        ...lineItems[originalLineItemIndex],
        rate: lineItem.rate,
        quantity: lineItem.quantity,
        operationType: 'Update',
      };
      updateOrderState({ lineItems: lineItems });
    }
    // otherwise, add it to the list of line items
    else {
      const clonedLineItem = cloneDeep(lineItem);
      const lineItems = [...orderStore.order.lineItems];
      lineItems.push({
        ...clonedLineItem,
        operationType: 'Insert',
      });
      updateOrderState({ lineItems: lineItems });
    }
  };

  return (
    <div class={cls.container}>
      <div class={cls.containerHeading}>
        <span class={cls.containerHeadingText}>
          {props.noUpdate ? 'Current' : 'Load Tender Changes'}
        </span>
      </div>
      <Divider sx={{ marginBottom: '10px' }} />
      <div class={cls.currentChangesSubHeading}>
        <span class="text-lg font-bold">LineItems</span>
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((item) => (
                  <TableCell>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <Index each={props.order.lineItems}>
                {(rowData, index) => (
                  <Show when={rowData().operationType !== 'Delete'}>
                    <TableRow>
                      <TableCell>{rowData().type}</TableCell>
                      <TableCell>
                        <TextInput
                          label=""
                          type="number"
                          significantDigits={3}
                          placeholder="Rate"
                          value={rowData().rate}
                          onChange={(value: number | null) => {
                            updateLineItems(
                              'rate',
                              value === null ? 0 : value,
                              index,
                            );
                          }}
                          error={
                            orderStore.orderFormError &&
                            orderStore.orderFormError[
                              `lineItems[${index}].rate`
                            ]
                          }
                          noErrorMessage
                          classes={'bg-white rounded-md !w-[100px]'}
                          disabled={!props.noUpdate}
                        />
                      </TableCell>
                      <TableCell>
                        <TextInput
                          label=""
                          type="number"
                          significantDigits={3}
                          placeholder="Quantity"
                          value={rowData().quantity}
                          onChange={(value: number | null) => {
                            updateLineItems(
                              'quantity',
                              value === null ? 0 : value,
                              index,
                            );
                          }}
                          error={
                            orderStore.orderFormError &&
                            orderStore.orderFormError[
                              `lineItems[${index}].quantity`
                            ]
                          }
                          noErrorMessage
                          classes={'bg-white rounded-md !w-[100px]'}
                          disabled={!props.noUpdate}
                        />
                      </TableCell>
                      <TableCell>
                        <span class="font-bold inline-block w-[100%] bg-[#C8E6C9] p-4">
                          ={' '}
                          {formatAmount(
                            Number(rowData().quantity) * Number(rowData().rate),
                          )}
                        </span>
                      </TableCell>
                      <TableCell>
                        {renderActionColumn(index, rowData())}
                      </TableCell>
                    </TableRow>
                  </Show>
                )}
              </Index>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div class={cls.currentChangesSubHeading}>
        <span class="text-lg font-bold">Stops</span>
      </div>
      <div>
        <EDICompareChangeStops noUpdate={props.noUpdate} order={props.order} />
      </div>
      <Divider />
    </div>
  );
};

export default EDIChangeComparison;
