import { v3Client } from '@api/apiClient';
import { ToastType } from '@components';
import { handleToast } from '@utils/utils';

import { ILTLCustomerData } from './types';

export const fetchCustomerLTLData = async (customerId: string) => {
  try {
    const response = await v3Client.get(`LTLSettings/${customerId}`);
    return response as unknown as ILTLCustomerData;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to fetch customer LTL Data');
    throw new Error(`'Failed to fetch customer LTL Data:  ${error}`);
  }
};

export const updateCustomerLTLData = async (
  customerId: number,
  payload: ILTLCustomerData,
) => {
  try {
    const response = await v3Client.put(`LTLSettings/${customerId}`, payload);
    handleToast(ToastType.Success, 'LTL Setting saved Successfully');
    return response.data as unknown as ILTLCustomerData;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to save customer LTL Data');
    throw new Error(`'Failed to save customer LTL Data:  ${error}`);
  }
};
