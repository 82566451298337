import { createStore } from 'solid-js/store';

import { IFuelSurchargeStore } from './types';

const [fuelSurchargeStore, setFuelSurchargeStore] =
  createStore<IFuelSurchargeStore>({
    fuelSurchargeData: null,
    isLoading: false,
    errors: [],
  });

export { fuelSurchargeStore, setFuelSurchargeStore };
