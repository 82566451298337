import { Box, Grid, Skeleton, Stack } from '@suid/material';

const LTLQuotingLoadingSkeleton = () => (
  <form>
    <Box p={1}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Skeleton variant="text" height={40} />
          <Skeleton variant="text" height={40} />
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Skeleton variant="text" height={40} />
            </Grid>
            <Grid item xs={12} md={4.5}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
            <Grid item xs={12} md={4.5}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
            <Grid item xs={12} md={3}>
              <Skeleton variant="rectangular" height={80} />
            </Grid>
          </Grid>
        </Box>
        <Skeleton variant="rectangular" height={200} />
        <Box>
          <Skeleton variant="rectangular" height={60} />
        </Box>
      </Stack>
    </Box>
  </form>
);

export default LTLQuotingLoadingSkeleton;
