import { Button, Divider, ListItemIcon, Menu, MenuItem } from '@suid/material';
import { For, createSignal } from 'solid-js';
import { A } from '@solidjs/router';

import { NavLink } from './NavBar';

type MainMenuItemProps = {
  navlink: NavLink;
};

export default function MainMenuItem({ navlink }: MainMenuItemProps) {
  const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);
  const open = () => Boolean(anchorEl());
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {navlink.path.length ? (
        <Button
          LinkComponent={'a'}
          href={navlink.path}
          id={navlink.id}
          aria-controls={open() ? navlink.id : undefined}
          aria-haspopup="true"
          aria-expanded={open() ? 'true' : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          class="!text-[#A5C6DA]"
        >
          {navlink.label}
        </Button>
      ) : (
        <>
          <Button
            id={navlink.id}
            aria-controls={open() ? navlink.id : undefined}
            aria-haspopup="true"
            aria-expanded={open() ? 'true' : undefined}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            class="!text-[#A5C6DA]"
          >
            {navlink.label}
          </Button>
          <Menu
            hideBackdrop={false}
            id={navlink.id}
            anchorEl={anchorEl()}
            open={open()}
            onClose={handleClose}
          >
            <For each={navlink.children}>
              {(child) => (
                <>
                  <MenuItem class="min-w-[170px]">
                    {child.icon !== undefined && (
                      <ListItemIcon>{child.icon}</ListItemIcon>
                    )}
                    <A href={child.path} class="capitalize">
                      {child.label}
                    </A>
                  </MenuItem>
                  {child.seperator === true ? <Divider /> : null}
                </>
              )}
            </For>
          </Menu>
        </>
      )}
    </>
  );
}
