import { Rating, Button } from '@components';
import { Divider, Grid } from '@suid/material';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { printLog } from '@utils/utils';
import { isEmpty, size } from 'lodash';
import {
  RatePayloadType,
  orderStore,
  rateCarrier,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { createSignal } from 'solid-js';
import { TextInput } from '@components/forms';

import { formSchema } from './validation';
import cls from './classes';

type FormDataType = {
  rating: number;
  comment: string;
};

export const RatingsForm = (props: {
  onClose: () => void;
  modalId: string;
}) => {
  const [isLoading, setIsLoading] = createSignal<boolean>(false);

  const { form, data, setFields, errors } = createForm<FormDataType>({
    initialValues: {
      rating: 3,
      comment: '',
    },
    extend: validator({ schema: formSchema }),
    onSubmit: (values) => {
      void handleOnSubmit(values);
    },
    onError: (errors) => {
      printLog(errors);
    },
  });

  const handleOnSubmit = async (values: FormDataType) => {
    try {
      setIsLoading(true);
      const payload: RatePayloadType = {
        carrierId: orderStore.order.loads[orderStore.activeTab.index].carrierId,
        loadId: orderStore.order.loads[orderStore.activeTab.index].id,
        rating: values.rating,
        comment: values.comment,
      };
      const response = await rateCarrier(payload);
      if (!isEmpty(response) && Boolean(response.isSuccess)) {
        updateLoadPropertyAtIndex({
          canRate: false,
        });
      }
      props.onClose();
    } catch (error) {
      printLog(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form ref={form}>
      <Grid container spacing={2} class={cls.dialogContentContainer}>
        <Grid item xs={12}>
          <div class="flex justify-center text-2xl">
            How Did This Carrier Do?
          </div>
          <Divider class={cls.divider} />
        </Grid>
        <Grid item xs={12} class={cls.ratingContainer}>
          <Rating
            currentRating={data().rating}
            totalRatings={5}
            readOnly={false}
            onChange={(newRating) => setFields('rating', newRating)}
            class="text-[#FF9800] mr-[10px] text-xl"
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="comment"
            multiline={true}
            rows={5}
            value={data().comment}
            label={'Comment'}
            placeholder={''}
            classes="w-full"
            onChange={(value) => {
              setFields('comment', value as string);
            }}
            error={size(errors().comment) > 0 ? errors().comment[0] : ''}
          />
        </Grid>
      </Grid>
      <Grid class="flex mt-4 justify-end gap-5">
        <Button
          label={'Submit'}
          variant="contained"
          type="submit"
          disabled={isLoading()}
          isLoading={isLoading()}
        />
        <Button
          label={'Cancel'}
          variant={'text'}
          onClick={() => props.onClose()}
          disabled={isLoading()}
        />
      </Grid>
    </form>
  );
};
