import { dateComparator, dateFormatter } from '@store/loadboard/utils';
import { ColDef, ValueFormatterParams } from '@ag-grid-community/core';
import { createSignal } from 'solid-js';
import { render } from 'solid-js/web';
import { OfficeInfo } from '@store/office';

import {
  OfficeNameAndDescription,
  OfficeFullAddress,
  TerminationDateAndReason,
  OfficeStatus,
} from './OfficeGridColumns';

const cellStyle = {
  padding: '10px',
  'word-break': 'break-word',
  'white-space': 'normal',
  'line-height': '1.6 ',
};

const commonFilterParams = ['contains', 'startsWith'];

const statusMapper = {
  true: 'Active',
  false: 'Inactive',
};

const valueFormatter = (params: ValueFormatterParams) => {
  return statusMapper[params.value as keyof typeof statusMapper];
};

export const officeColumnDefs: ColDef[] = [
  {
    field: 'active',
    headerName: 'Status',
    cellStyle: cellStyle,
    cellRenderer: (params: { data: OfficeInfo }) => {
      const container = document.createElement('div');
      const [Component] = createSignal(OfficeStatus(params.data));
      render(() => <Component />, container);
      return container;
    },
    filterParams: {
      valueFormatter,
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    filter: 'agTextColumnFilter',
    cellRenderer: (params: { data: OfficeInfo }) => {
      const container = document.createElement('div');
      const [Component] = createSignal(OfficeNameAndDescription(params.data));
      render(() => <Component />, container);
      return container;
    },
    cellStyle: cellStyle,
    autoHeight: true,
    filterParams: {
      filterOptions: commonFilterParams,
    },
  },
  {
    field: 'address1',
    headerName: 'Address',
    filter: 'agTextColumnFilter',
    cellRenderer: (params: { data: OfficeInfo }) => {
      const container = document.createElement('div');
      const [Component] = createSignal(OfficeFullAddress(params.data));
      render(() => <Component />, container);
      return container;
    },
    cellStyle: cellStyle,
    filterParams: {
      filterOptions: commonFilterParams,
    },
  },

  {
    field: 'manager',
    headerName: 'Manager',
    filter: 'agTextColumnFilter',
    cellStyle: cellStyle,
    filterParams: {
      filterOptions: commonFilterParams,
    },
  },
  {
    field: 'recruiter',
    headerName: 'Recruiter',
    filter: 'agTextColumnFilter',
    cellStyle: cellStyle,
    filterParams: {
      filterOptions: commonFilterParams,
    },
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
    },
    valueFormatter: dateFormatter,
    cellStyle: cellStyle,
  },
  {
    field: 'terminationDate',
    headerName: 'Termination Date',
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateComparator,
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
    },
    cellRenderer: (params: { data: OfficeInfo }) => {
      const container = document.createElement('div');
      const [Component] = createSignal(TerminationDateAndReason(params.data));
      render(() => <Component />, container);
      return container;
    },
    valueFormatter: dateFormatter,
    cellStyle: cellStyle,
  },
];
