const apiUrl = import.meta.env.VITE_API_URL as string;
const env = import.meta.env.VITE_ENV as string;
const showLayout = import.meta.env.VITE_SHOW_LAYOUT as string;
const isDebugging = import.meta.env.VITE_IS_DEBUG as string;
const domainUrl = import.meta.env.VITE_DOMAIN_URL as string;
const v4OrderDetailsUrl = import.meta.env.VITE_ORDER_DETAILS_HOST as string;
const yarpHost = import.meta.env.VITE_YARP_HOST as string;
const selectedEnvironment = import.meta.env.VITE_SELECTED_ENVIRONMENT as string;
const agGridLicensePicks =
  'CompanyName=Armstrong Transport Group,LicensedGroup=armstrongtransportgroupdevelopment,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-039566,SupportServicesEnd=14_March_2024_[v2]_MTcxMDM3NDQwMDAwMA==1f8cb4f318cfeeae76b68a1c94278c77';
const falveyUrl = import.meta.env.VITE_FALVEY_API_URL as string;

export const ConfigManager = {
  apiUrl,
  env,
  showLayout: !!(showLayout && showLayout.toLowerCase() === 'true'),
  isDebugging: isDebugging,
  domainUrl: domainUrl,
  agGridLicensePicks,
  v4OrderDetailsHost: v4OrderDetailsUrl,
  yarpHost,
  selectedEnv: selectedEnvironment,
  falveyUrl,
};
