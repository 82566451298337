import { Typography } from '@components';
import { OfficeInfo } from '@store/office';
import { Box } from '@suid/material';
import { DateTime } from 'luxon';
import { checkIcon, inactiveIcon } from '@assets';

import { officeClasses as cls } from './classes';

export const OfficeNameAndDescription = (data: OfficeInfo) => {
  return (
    <Box class="flex flex-col">
      <Typography variant="h1" class={cls.textLarge}>
        {data.name}
      </Typography>
      {Boolean(data.description) && (
        <Typography variant="h6" class={cls.textBold}>
          {data.description}
        </Typography>
      )}
    </Box>
  );
};

export const OfficeFullAddress = (data: OfficeInfo) => {
  return (
    <Box class="flex flex-col">
      <Typography variant="h1" class={cls.text}>
        {data.address1}
      </Typography>
      {Boolean(data.address2) && (
        <Typography variant="h1" class={cls.text}>
          {data.address2}
        </Typography>
      )}
      <Typography variant="h1" class={cls.text}>
        {data.city}, {data.state} {data.zip}
      </Typography>
    </Box>
  );
};

export const getFormattedDate = (date: string | null | undefined) => {
  if ([undefined, null, ''].includes(date)) {
    return '';
  }
  return DateTime.fromISO(date as string).toFormat('MM/dd/yyyy');
};

export const TerminationDateAndReason = (data: OfficeInfo) => {
  return (
    <Box class="flex flex-col">
      <Typography variant="h1" class={cls.text}>
        {getFormattedDate(data.terminationDate)}
      </Typography>
      {Boolean(data.terminationReason) && (
        <Typography variant="h1" class={cls.text}>
          {data.terminationReason}
        </Typography>
      )}
    </Box>
  );
};

export const OfficeStatus = (data: OfficeInfo) => {
  const statusIcon = data.active ? checkIcon : inactiveIcon;
  return (
    <Box class="flex gap-3">
      <img src={statusIcon} alt="status" class="size-6"></img>
      <Typography variant="h1" class="!text-base !font-normal">
        {data.active ? 'Active' : 'Inactive'}
      </Typography>
    </Box>
  );
};
