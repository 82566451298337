import { Card, ToastType } from '@components';
import { DialogBox } from '@components/DialogBox';
import { TextAreaField } from '@components/forms';
import {
  carrierDetailsStore,
  deleteCarrierComment,
  postCarrierComment,
  updateCarrierDetailsState,
} from '@store/carriers';
import {
  addCustomerGeneralComment,
  customerDetailsStore,
  deleteCustomerGeneralComment,
  updateCustomerDetailsState,
} from '@store/customers/customerDetails';
import {
  addFactoringCompanyDetailsComment,
  deleteFactoringCompanyDetailsComment,
  factoringCompanyDetailsStore,
  updateFactoringCompanyDetailsState,
} from '@store/factoringCompany/factoringCompanyDetails';
import {
  Comments,
  OperationType,
  addCommentInOrderStore,
  deleteCommentInOrderStore,
  deleteOrderComment,
  orderStore,
  postOrderComment,
} from '@store/orders';
import { userStore } from '@store/user';
import { Divider, Stack, Typography } from '@suid/material';
import { handleToast } from '@utils/utils';
import { DELETE_COMMENT_DIALOG_ID } from '@views/order/constants';
import { DateTime } from 'luxon';
import { Component, JSX, createSignal } from 'solid-js';
import Button from '@components/Button/Button';

import { SingleCommentCard } from './SingleCommentCard';

const scrollContainerStyle = {
  overflowY: 'scroll',
  maxHeight: '466px',
  '&::-webkit-scrollbar': {
    width: '0.3em',
  },
};

const scrollCarrierContainerStyle = {
  overflowY: 'auto',
  maxHeight: '466px',
};

const dividerStyle = { color: '#ccc', margin: '13px 0px' };

export type NewATGOnlyNoteProps = {
  type: 'Carrier' | 'Order' | 'Customer' | 'FactoringCompany';
  comments: Comments[];
  cardTitle: string;
  loading?: boolean;
};

export const NewATGOnlyNote: Component<NewATGOnlyNoteProps> = (props) => {
  const [commentIdToDelete, setCommentIdToDelete] = createSignal<number | null>(
    null,
  );
  const [newComment, setNewComment] = createSignal({
    comment: '',
    loading: false,
  });

  const handleDelete = async () => {
    if (commentIdToDelete() === null) return;
    let res: boolean | undefined = false;
    switch (props.type) {
      case 'Order': {
        res = await deleteOrderComment(commentIdToDelete()!);
        Boolean(res) && deleteCommentInOrderStore(commentIdToDelete()!);
        break;
      }
      case 'Carrier': {
        res = await deleteCarrierComment(commentIdToDelete()!);
        Boolean(res) &&
          updateCarrierDetailsState({
            comments: carrierDetailsStore.carrierDetails?.comments.filter(
              (comment) => comment.id !== commentIdToDelete(),
            ),
          });
        break;
      }
      case 'Customer': {
        res = await deleteCustomerGeneralComment(commentIdToDelete()!);
        Boolean(res) &&
          updateCustomerDetailsState({
            comments: customerDetailsStore.customer.comments.filter(
              (comment) => comment.id !== commentIdToDelete(),
            ),
          });
        break;
      }
      case 'FactoringCompany': {
        res = await deleteFactoringCompanyDetailsComment(commentIdToDelete()!);
        Boolean(res) &&
          updateFactoringCompanyDetailsState({
            comments:
              factoringCompanyDetailsStore.factoringCompanyDetails.comments.filter(
                (comment) => comment.id !== commentIdToDelete(),
              ),
          });
        break;
      }
      default:
        break;
    }
    Boolean(res) &&
      handleToast(ToastType.Success, 'Comment deleted successfully');
  };

  const handleAddNewComment = async () => {
    try {
      setNewComment((prev) => ({ ...prev, loading: true }));
      const comment = newComment().comment.trim();

      const payload = {
        type: props.type,
        comment,
        commentType: userStore.user.roles ? userStore.user.roles[0] : null,
      };

      switch (props.type) {
        case 'Order': {
          const res = await postOrderComment({
            objectId: orderStore.order.id,
            ...payload,
          });
          addCommentInOrderStore(res);
          break;
        }
        case 'Carrier': {
          const carrierDetails = carrierDetailsStore.carrierDetails;
          if (!carrierDetails) return;
          const res = await postCarrierComment({
            objectId: carrierDetails.id,
            ...payload,
          });
          updateCarrierDetailsState({
            comments: [...carrierDetails.comments, res],
          });
          break;
        }
        case 'Customer': {
          const customerDetails = customerDetailsStore.customer;
          const res = await addCustomerGeneralComment({
            id: customerDetails.id,
            ...payload,
          });
          updateCustomerDetailsState({
            comments: [...customerDetails.comments, res],
          });
          break;
        }
        case 'FactoringCompany': {
          const factoringCompanyDetails =
            factoringCompanyDetailsStore.factoringCompanyDetails;
          const res = await addFactoringCompanyDetailsComment({
            id: factoringCompanyDetails.id,
            ...payload,
          });
          updateFactoringCompanyDetailsState({
            comments: [...factoringCompanyDetails.comments, res],
          });
          break;
        }
        default:
          break;
      }
      handleToast(ToastType.Success, 'Comment added successfully');
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to add comment');
    } finally {
      setNewComment(() => ({ comment: '', loading: false }));
    }
  };

  const handleSubmit = () => {
    const comment = newComment().comment.trim();

    if (comment.length > 0 && !Boolean(newComment().loading)) {
      void handleAddNewComment();
    }
  };

  const handleCommentOnChange = (value: string) => {
    setNewComment((prev) => ({ ...prev, comment: value }));
  };

  const sortedComments = (comments: Comments[]) => {
    const sortedComments = comments.toSorted((a, b) => {
      const timestampA = DateTime.fromISO(a.timestamp!);
      const timestampB = DateTime.fromISO(b.timestamp!);
      return timestampB.diff(timestampA).as('milliseconds');
    });
    const commentsArr: JSX.Element[] = [];
    sortedComments.forEach((comment) => {
      if (comment.operationType == OperationType.Delete) return;
      commentsArr.push(
        <SingleCommentCard
          type={props.type}
          comment={comment}
          setCommentIdToDelete={setCommentIdToDelete}
        />,
      );
    });
    return commentsArr;
  };

  return (
    <Card startTitle={props.cardTitle} raised={true} loading={props.loading}>
      <div class="mb-3">
        <form>
          <TextAreaField
            name="instructions"
            label=""
            rows={3}
            value={newComment().comment}
            onChange={handleCommentOnChange}
          />{' '}
          <Button
            onClick={() => {
              handleSubmit();
            }}
            type="button"
            label="Save"
            sx={{ background: '#468DB5', color: '#FFF' }}
            class="!mt-1"
          />
        </form>
      </div>
      <div class="text-[#123B50] mb-3">
        <Typography>Previous Notes</Typography>
      </div>
      <div>
        {props.comments.length > 0 ? (
          <Stack
            sx={
              props.type === 'Carrier'
                ? scrollCarrierContainerStyle
                : scrollContainerStyle
            }
          >
            {...sortedComments(props.comments)}
            <Divider sx={dividerStyle} />
          </Stack>
        ) : (
          'No Comments to display'
        )}
      </div>
      <DialogBox
        id={DELETE_COMMENT_DIALOG_ID}
        title={'Are you sure you want to delete this note ?'}
        onSubmit={() => handleDelete()}
        onClose={() => setCommentIdToDelete(null)}
      />
    </Card>
  );
};
