import { BasicModal, ToastType, Typography } from '@components';
import { CheckboxInput, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { denyCheckRequest } from '@store/Global/service';
import { CheckRequestType, pendingChecksTypes } from '@store/Global/types';
import { closeModal } from '@store/modals';
import { Box, Stack } from '@suid/material';
import { handleToast } from '@utils/utils';
import { Accessor } from 'solid-js';

type Props = {
  requestCheckData: Accessor<CheckRequestType | undefined>;
  advancesData: Accessor<pendingChecksTypes[]>;
  setAdvancesData: (data: pendingChecksTypes[]) => void;
};

const DenyCheckRequest = (props: Props) => {
  const { form, data, setFields, handleSubmit } = createForm({
    initialValues: {
      checkCarrierRisk: false,
      checkLoadHistory: false,
      comment: '',
    },
    onSubmit: async () => {
      const payload = {
        comment: denyComment(),
        id: props.requestCheckData()?.requestId as number,
      };
      const response = await denyCheckRequest(payload);
      if (response && response.success) {
        handleToast(ToastType.Success, 'deny check request successfully');
        closeModal('pending-checks');
        const filteredData = props
          .advancesData()
          .filter((item) => item.id !== props.requestCheckData()?.requestId);
        props.setAdvancesData(filteredData);
      }
    },
  });

  const denyComment = () => {
    let denyComments = '';

    if (data().checkCarrierRisk || data().checkLoadHistory || data().comment) {
      const reasons = [];
      if (data().checkCarrierRisk) reasons.push('Carrier Risk');
      if (data().checkLoadHistory) reasons.push('Load History');
      denyComments =
        reasons.join(' & ') + (data().comment ? ` - ${data().comment}` : '');
      denyComments = `Reason for Denial: ${denyComments}`;
    }
    return denyComments;
  };
  return (
    <BasicModal
      title="Deny Request"
      id={'deny-pending-checks'}
      onSubmit={() => {
        handleSubmit();
      }}
      onClose={() => {
        closeModal('deny-pending-checks');
      }}
      showClose={false}
      width={'700px'}
      footer={true}
    >
      <form ref={form}>
        <Box
          sx={{
            height: 'auto',
            maxHeight: '580px',
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h6" class="!text-xl !font-bold">
              Check Reasons and add comments below (Optional)
            </Typography>
            <Box class="flex gap-4">
              <CheckboxInput
                label="Carrier Risk"
                checked={data().checkCarrierRisk}
                onChange={(e) => {
                  setFields('checkCarrierRisk', e);
                }}
              />
              <CheckboxInput
                label="Load History"
                checked={data().checkLoadHistory}
                onChange={(e) => {
                  setFields('checkLoadHistory', e);
                }}
              />
            </Box>
            <TextInput
              label="Comments"
              value={data().comment}
              onChange={(e) => setFields('comment', e as string)}
              multiline
              rows={4}
            />
          </Stack>
        </Box>
      </form>
    </BasicModal>
  );
};

export default DenyCheckRequest;
