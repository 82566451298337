import { ToastType, Typography } from '@components';
import { TextInput } from '@components/forms';
import {
  blacklistCarrier,
  carrierDetailsStore,
  updateCarrierDetailsState,
} from '@store/carriers';
import { userStore } from '@store/user';
import { Box, Stack } from '@suid/material';
import { handleToast } from '@utils/utils';
import { Component, createSignal, Show } from 'solid-js';
import AtgLoader from '@components/AtgLoader';
import { setCarrierStore } from '@store/orders';

import cls from './classes';

type CarrierDetailsBlacklistProps = {
  closePopover: () => void;
  carrierId: number | undefined;
  tabIndex?: number;
};

export const CarrierDetailsBlacklist: Component<
  CarrierDetailsBlacklistProps
> = (props) => {
  const [coment, setComment] = createSignal('');
  const [loading, setLoading] = createSignal(false);

  const onSubmit = async (e: KeyboardEvent) => {
    if (
      Boolean(props.carrierId) &&
      !loading() &&
      e.key === 'Enter' &&
      !e.shiftKey &&
      coment().length > 0
    ) {
      setLoading(true);
      const res = await blacklistCarrier({
        id: props.carrierId!,
        comment: coment(),
      });
      if (Boolean(res)) {
        if (carrierDetailsStore.carrierDetails) {
          updateCarrierDetailsState({
            blacklist: {
              bannedBy: userStore.user.name,
              comment: coment(),
              bannedDate: new Date().toISOString(),
              carrierId: props.carrierId!,
              id: 0,
              officeId: userStore.user.officeId,
            },
          });
        }

        if (props.tabIndex !== undefined) {
          setCarrierStore('carrier', props.tabIndex, 'blacklisted', true);
        }
        handleToast(
          ToastType.Success,
          'The Carrier has been blacklisted for your office',
        );
        props.closePopover();
      }
      setLoading(false);
    }
  };

  return (
    <Stack spacing={1} minWidth={350} maxWidth={'90%'}>
      <Show
        when={!loading()}
        fallback={
          <Box class="h-[600px] fixed top-[50%] left-[50%]">
            <AtgLoader />
          </Box>
        }
      >
        <Box bgcolor="#F5F5F5" px={2} py={1}>
          <Typography
            variant="body1"
            component="p"
            class={cls.blacklistCommentTitle}
          >
            Enter a Comment
          </Typography>
        </Box>
        <Box class="px-3 pt-2 pb-3">
          <TextInput
            label="Comment"
            multiline
            value={coment()}
            onChange={(e: string) => setComment(e)}
            rows={4}
            fullWidth
            variant="outlined"
            maxLength={2000}
            onKeyPress={onSubmit}
          />
        </Box>
      </Show>
    </Stack>
  );
};
