import { ErrorT } from '@api/types';

export const errorsToString = (
  errors: (ErrorT | string)[],
  baseError = '',
): string => {
  return errors
    .reduce((acc: string, v) => {
      if (typeof v === 'string') {
        return `${acc}\n${v}`;
      }

      return `${acc}\n${v.errorMessage}`;
    }, baseError)
    .trim();
};

/**
 * Given an error, or errors, this function creates a new `Error` object that, besides the
 * base error message (`baseError`), combines the given errors into one message.
 * @param error The error-like object
 * @param baseError The base error message to show
 * @param simple Whether to show a simple error or not (applies to array of errors)
 * @returns The new and combined error object
 */
export const createError = (
  error: unknown,
  baseError: string,
  simple = false,
): Error => {
  // @ts-expect-error Error could be an array
  if ('map' in error && !simple) {
    baseError = errorsToString(
      error as unknown as (ErrorT | string)[],
      baseError,
    );
  } else if (typeof error === 'string') {
    baseError = `${baseError}: ${error}`;
  } else if (error instanceof Error) {
    baseError = `${baseError}: ${error.message}`;
  } else {
    baseError = `${baseError}: ${JSON.stringify(error ?? 'undefined')}`;
  }

  const err = new Error(baseError);
  err.cause = error;

  return err;
};
