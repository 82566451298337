import {
  Breakpoint,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Theme,
} from '@suid/material';
import { SxProps } from '@suid/system';
import * as ST from '@suid/types';
import { Component, For, JSXElement, mergeProps, splitProps } from 'solid-js';

import FormErrorComponent from './FormErrorComponent';

type RadioOption = {
  label: string | JSXElement;
  value: string | boolean;
  disabled?: boolean;
};

export type RadioInputProps = {
  name?: string;
  label: string;
  options: RadioOption[];
  value?: string | boolean;
  onChange?: (e: ST.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  defaultValue?: string;
  error?: string | string[] | null;
  disabled?: boolean;
  id?: string; // make it required
  optionStyles?: SxProps<Theme<Breakpoint>>;
  radioStyles?: Record<string, string>;
  classes?: string;
  direction: 'horizontal' | 'vertical';
  labelClass?: string;
  radioSize?: 'small' | 'medium';
  gap?: number;
};

export const RadioInput: Component<RadioInputProps> = (props) => {
  props = mergeProps({ direction: 'horizontal', classes: 'w-[100%]' }, props);
  const [load, rest] = splitProps(props, [
    'value',
    'onChange',
    'options',
    'name',
    'label',
    'disabled',
    'required',
    'defaultValue',
    'id',
    'classes',
    'direction',
  ]);
  return (
    <FormControl
      name={load.name}
      error={Boolean(props.error)}
      component="fieldset"
      required={load.required}
      disabled={load.disabled}
      class={load.classes}
    >
      <FormLabel
        id={load.id}
        component="legend"
        required={props.required}
        sx={{ fontSize: 14 }}
        class={props.labelClass ?? ''}
      >
        {load.label}
      </FormLabel>
      <RadioGroup
        {...rest}
        id={props.id}
        defaultValue={load.value}
        value={load.value}
        onChange={load.onChange}
      >
        <div
          class={`flex gap-${props.gap ?? 2} ${
            load.direction === 'horizontal' ? 'flex-row' : 'flex-col'
          }`}
        >
          <For each={load.options}>
            {(option) => (
              <FormControlLabel
                value={option.value}
                control={
                  <Radio sx={props.radioStyles} size={props.radioSize} />
                }
                label={option.label}
                component="span"
                sx={props.optionStyles}
              />
            )}
          </For>
        </div>
      </RadioGroup>
      <FormErrorComponent error={props.error} showHelpertext={true} />
    </FormControl>
  );
};
