import { ChevronLeft, ChevronRight } from '@suid/icons-material';
import { Skeleton } from '@suid/material';
import { For, JSX, Show } from 'solid-js';

import cls from './classes';

interface TabsComponentProps {
  tabs: Array<string> | Array<JSX.Element>;
  activeTab: number;
  onTabClick: (index: number) => void;
  actionButton?: JSX.Element;
  loading?: boolean;
  tabStyleClasses?: string;
  tabContainerStyles?: string;
  activeStyling?: string;
  id?: string;
  border?: boolean;
  noPadding?: boolean;
  showChevrons?: boolean;
}
const TabsComponent = (props: TabsComponentProps) => {
  let containerRef: HTMLDivElement | undefined;
  let contentRef: HTMLDivElement | undefined;
  const scrollLeft = () => {
    if (containerRef) {
      containerRef.scrollTo({
        left: containerRef.scrollLeft - 500,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (containerRef) {
      containerRef.scrollTo({
        left: containerRef.scrollLeft + 500,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div class={cls.tabsContainer} id={props.id}>
      <Show when={props.showChevrons}>
        <div
          class={cls.leftButton}
          onClick={() => scrollLeft()}
          style={{
            'pointer-events': 'auto',
            cursor: 'pointer',
          }}
        >
          <ChevronLeft />
        </div>
      </Show>
      <div class={`${cls.tabsContainerRef}`} ref={containerRef}>
        <Show when={props.loading === true}>
          <div class="mb-2 flex flex-row ">
            <For each={['1', '2']}>
              {() => (
                <div class="mr-2">
                  <Skeleton variant="rectangular" width={150} height={50} />
                </div>
              )}
            </For>
          </div>
        </Show>
        <Show when={!Boolean(props.loading)}>
          <div class={`flex ${props.tabContainerStyles} `} ref={contentRef}>
            {props.tabs.map((tab, index) => (
              <div
                class={
                  Boolean(props.border) && !Boolean(props.activeTab === index)
                    ? ' border-gray-300 border-l border-t border-r rounded-t-lg'
                    : ''
                }
              >
                <div
                  class={`
                flex flex-1 flex-col items-center justify-end cursor-pointer
                text-[12px] font-medium w-fit
                ${props.tabStyleClasses}
                ${
                  props.activeTab === index
                    ? `border-[#016fa1ff] border-b-2 
                    text-[#016fa1ff] ${props.activeStyling}`
                    : ''
                } 
                ${Boolean(props.noPadding) ? '' : 'px-[15px] py-[9px]'}
                `}
                  onClick={() => {
                    // NOTE: This is a fix to stop event propogation issue
                    if (props.tabs.length <= index) return;
                    props.onTabClick(index);
                  }}
                >
                  {tab}
                </div>
              </div>
            ))}
            {Boolean(props.actionButton) && props.actionButton}
          </div>
        </Show>
      </div>
      <Show when={props.showChevrons}>
        <div
          class={cls.rightButton}
          onClick={scrollRight}
          style={{
            cursor: 'pointer',
          }}
        >
          <ChevronRight />
        </div>
      </Show>
    </div>
  );
};

export default TabsComponent;
