import ExpandLessIcon from '@suid/icons-material/ExpandLess';
import ExpandMoreIcon from '@suid/icons-material/ExpandMore';
import WarningAmberIcon from '@suid/icons-material/WarningAmber';
import { Box, Stack } from '@suid/material';
import { Show, createSignal } from 'solid-js';
import { effect } from 'solid-js/web';

import { ErrorRow } from './ErrorRow';
import { ErrorT } from './types';

type PropsT = {
  message: string | JSX.Element;
  errors: ErrorT[];
  showWarningIcon?: boolean;
  fullWidth?: boolean;
  collapsible?: boolean;
  overflowHeight?: string;
};

export const ErrorsTable = (props: PropsT) => {
  const [show, setShow] = createSignal(
    props.collapsible !== undefined ? !props.collapsible : true,
  );

  effect(() => {
    setShow(props.collapsible !== undefined ? !props.collapsible : true);
  });

  return (
    <Box
      sx={{
        display: 'flex',
      }}
      justifyContent="center"
      marginBottom="25px"
    >
      <Box
        maxWidth={props.fullWidth ?? false ? '100%' : '800px'}
        width={props.fullWidth ?? false ? '100%' : undefined}
        borderRadius="8px"
        backgroundColor="#fff"
        padding="8px 16px 8px 16px"
        border="2px solid #b00020"
      >
        <Stack direction="row">
          {(props.showWarningIcon ?? false) && (
            <Box marginRight="20px">
              <WarningAmberIcon style={{ color: '#b00020' }} />
            </Box>
          )}
          <Box width="100%">
            <Box paddingBottom="10px">
              <Stack direction="row" gap="10px" justifyContent="space-between">
                <Box>{props.message}</Box>
                {(props.collapsible ?? false) && (
                  <Box
                    onClick={() => setShow((prev) => !prev)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {show() ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Box>
                )}
              </Stack>
            </Box>
            <Show when={show() || props.overflowHeight === undefined}>
              <Box maxHeight={props.overflowHeight ?? '328px'} overflow="auto">
                <Stack direction="column" gap="7px">
                  {props.errors.map((error) => {
                    return (
                      <ErrorRow
                        title={error.title}
                        message={error.body}
                        onView={error.onView}
                      />
                    );
                  })}
                </Stack>
              </Box>
            </Show>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
