import { isEmpty } from 'lodash';
import * as yup from 'yup';

export const addressBookSchema = yup.object({
  name: yup
    .string()
    .required('Please Enter the Location Name')
    .max(150, 'Maximum of 150 characters'),
  description: yup
    .string()
    .nullable()
    .notRequired()
    .max(100, 'Maximum of 100 characters'),
  address1: yup
    .string()
    .required('Please Enter the corrsponding Address')
    .max(100, 'Maximum of 100 characters'),
  addressLine2: yup
    .string()
    .nullable()
    .notRequired()
    .max(100, 'Maximum of 100 characters'),
  city: yup.string().required().max(50, 'Maximum of 50 characters'),
  state: yup.string().required().max(2, 'Maximum of 2 characters'),
  zip: yup.string().max(10).required(),
  countryCode: yup.string().required(),
  contactName: yup.string().nullable().max(50, 'Maximum of 50 characters'),
  contactPhone: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup.string().min(10, 'Phone number must be at least 10 digits');
  }),
  openTimeFrom: yup.string(),
  openTimeTo: yup.string(),
  specialInstructions: yup
    .string()
    .nullable()
    .notRequired()
    .max(2000, 'Maximum of 2000 characters'),
  drivingDirections: yup
    .string()
    .nullable()
    .notRequired()
    .max(2000, 'Maximum of 2000 characters'),
  internalNote: yup
    .string()
    .nullable()
    .notRequired()
    .max(2000, 'Maximum of 2000 characters'),
  isDefaultAddress: yup.boolean(),
});
