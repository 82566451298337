import { OfficeDetailType } from '@store/office/officeDetails';

export const OfficeGroup_Model_Id = 'officeGroupModalId';
export const OfficeGroup_Delete_Id = 'officeGroupDeleteId';

export const AccountingGroups = [
  { label: 'Accounting Group A', value: 1 },
  { label: 'Accounting Group B', value: 2 },
  { label: 'Accounting Group C', value: 3 },
  { label: 'Accounting Group D', value: 4 },
];

export const OfficeType = [
  { label: 'Corportate', value: '1' },
  { label: 'Agent', value: '2' },
];

export const ContractTypes = ['Original', '1 Year', '3 Year', '5 Year'];

export const DEFAULT_OFFICE_DETAIL: OfficeDetailType = {
  id: 0,
  name: '',
  description: null,
  officeManager: '',
  officeManagerId: '',
  accountingGroupId: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  officeType: '2',
  commissionPercentage: 0.7,
  terminationReason: null,
  terminationDate: null,
  recruitedByUserId: '',
  recruitedByUser: null,
  isActive: true,
  contractSigned: false,
  dormantCustomerOptIn: false,
  startDate: null,
  contractSignedDate: null,
  contractType: '',
  notes: null,
  lat: null,
  long: null,
  accountingSysId: '',
  groups: [],
  capacityGroups: [],
  postingGroups: [],
  users: [],
  roles: [],
  managerIds: [],
  supervisorIds: [],
  partnerships: {},
};
