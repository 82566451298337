import { Layout } from '@layouts/index';
import { Box, Stack } from '@suid/material';
import { Notification } from '@components';
import { Search } from '@suid/icons-material';
import { createSignal } from 'solid-js';
import { TextInput } from '@components/forms';

import { LoadTenderTabPanel } from './components';

const LoadTenders = () => {
  const [loadTender, setLoadTender] = createSignal<number>();
  return (
    <Layout>
      <Box class="m-4 flex justify-between">
        <Notification
          type="info"
          text="The system checks for new load tenders every 5 minutes."
          sxProps="!w-auto"
        />
        <TextInput
          label=""
          type="number"
          size="small"
          placeholder="Search Load Tender"
          value={loadTender()}
          onChange={(e) => {
            setLoadTender(e);
          }}
          endAdornment={<Search class={'pointer-events-auto'} />}
          sxProps={{ width: '250px' }}
        />
      </Box>
      <Stack class="bg-[#E1ECF2] overflow-scroll">
        <Box class="m-4 p-2 bg-white mb-[70px] max-md:mb-[80px]">
          <LoadTenderTabPanel />
        </Box>
      </Stack>
    </Layout>
  );
};

export default LoadTenders;
