// import { MyForm } from '@components';
import { Layout } from '@layouts/index';
import { createSignal, Match, Suspense, Switch, useTransition } from 'solid-js';
import CircularProgress from '@suid/material/CircularProgress';

const Home = () => {
  const [tab, setTab] = createSignal(0);
  const [pending, start] = useTransition();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const updateTab = (index) => () => start(() => setTab(index));

  return (
    <Layout>
      <ul className="inline">
        <li class={{ selected: tab() === 0 }} onClick={updateTab(0)}>
          Uno
        </li>
        <li class={{ selected: tab() === 1 }} onClick={updateTab(1)}>
          Dos
        </li>
        <li class={{ selected: tab() === 2 }} onClick={updateTab(2)}>
          Tres
        </li>
      </ul>
      <div class="tab" class={{ pending: pending() }}>
        <Suspense fallback={<CircularProgress />}>
          <Switch>
            <Match when={tab() === 0}>
              <span>1</span>
            </Match>
            <Match when={tab() === 1}>
              <span>2</span>
            </Match>
            <Match when={tab() === 2}>
              <span>3</span>
            </Match>
          </Switch>
        </Suspense>
      </div>
    </Layout>
  );
};

export default Home;
