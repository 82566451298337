import { getRoundedRate } from '@common/utils';
import { LineItemViewModel, orderStore } from '@store/orders';
import { sum } from 'lodash';

export const carrierPay = (item: LineItemViewModel[]) => {
  return item.reduce(
    (total, item) => total + getRoundedRate(item.rate as number, item.quantity),
    0,
  );
};

export const vendorPay = (index: number) => {
  if (orderStore.order.vendors) {
    return (orderStore.order.vendors[index].lineItems || []).reduce(
      (total, item) => total + getRoundedRate(item.rate, item.quantity),
      0,
    );
  }
};

export const totalOrderExpenses = () => {
  return (
    sum(
      orderStore.order.loads
        .filter((i) => i.status !== 'Void')
        .map((item) => {
          return carrierPay(item.lineItems ?? []);
        }),
    ) +
    sum(
      (orderStore.order.vendors || []).map((_, i: number) => {
        return vendorPay(i);
      }),
    )
  );
};

export const customerPay = () => {
  return orderStore.order.lineItems.length > 0
    ? orderStore.order.lineItems.reduce(
        (acc, item) =>
          acc + getRoundedRate(Number(item.rate), Number(item.quantity)),
        0,
      )
    : 0;
};
