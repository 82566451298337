import { v3Client } from '@api/apiClient';
import { ToastType } from '@components';
import { handleToast } from '@utils/utils';

import {
  AddCollectionCommentPayload,
  ATGLookupValue,
  CollectionActionPayload,
  CollectionCommentResp,
  CollectionResponse,
  CollectionsByCustomerResp,
  CollectionsDashboardItem,
  CollectionsRiskPercentPayload,
  CollectionsTier,
  EditCollectionCommentPayload,
  IComment,
} from './types';

export const fetchCollectionsDashboard = async () => {
  try {
    const response: CollectionsDashboardItem[] = await v3Client.get(
      'Accounting/CollectionsList',
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to fetch collection list. Please try again later.',
      );
    }
    throw new Error('Failed to fetch collection list.');
  }
};

export const getCollectionsByCustomer = async (customerId: number) => {
  try {
    const response: CollectionsByCustomerResp = await v3Client.get(
      `Accounting/CollectionsByCustomer?id=${customerId}`,
    );
    return response;
  } catch (error: unknown) {
    handleToast(
      ToastType.Error,
      'Failed to fetch Collections By Customer Data. Please try again later.',
    );
  }
};

export const updateCollectionsRiskPercentCall = async (
  payload: CollectionsRiskPercentPayload,
) => {
  try {
    const response: CollectionResponse = await v3Client.post(
      'Order/UpdateCollectionsRiskPercent',
      payload,
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to update Collection Risk Percent. Please try again later.',
      );
    }
  }
};

export const applyCollectionActionsCall = async (
  payload: CollectionActionPayload[],
) => {
  try {
    const response: CollectionResponse = await v3Client.post(
      'Accounting/ApplyCollectionActions',
      payload,
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to apply Collection Actions. Please try again later.',
      );
    }
  }
};

export const fetchCollectionsTierCall = async () => {
  try {
    const response: CollectionsTier[] = await v3Client.get('CollectionsTier');
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to fetch collection tier. Please try again later.',
      );
    }
  }
};

export const updateCollectionsTierCall = async (payload: {
  id: string;
  tierId: string;
}) => {
  try {
    const response: CollectionResponse = await v3Client.put(
      `Customer/CollectionsTier/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to update collection tier. Please try again later.',
      );
    }
  }
};

export const fetchATGLookupValuesCall = async () => {
  try {
    const response: ATGLookupValue[] = await v3Client.get('ATGLookupValue');
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to fetch ATG lookup values. Please try again later.',
      );
    }
  }
};

export const updateCollectionsKeyReasonCall = async (payload: {
  id: number;
  keyReasonId: number;
}) => {
  try {
    const response: CollectionResponse = await v3Client.put(
      `Customer/CollectionsKeyReason/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to update collection key reason. Please try again later.',
      );
    }
  }
};

export const updateNextActionCall = async (payload: {
  id: string;
  stringDate: string | null;
}) => {
  try {
    const response: CollectionResponse = await v3Client.put(
      `Customer/NextAction/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to update next action. Please try again later.',
      );
    }
  }
};

export const getCollectionCommentsCall = async (customerId: number) => {
  try {
    const response: IComment[] = await v3Client.get(
      `Customer/GetCollectionComments?id=${customerId}`,
    );
    return response;
  } catch (error: unknown) {
    handleToast(
      ToastType.Error,
      'Failed to fetch Collection Comments. Please try again later.',
    );
  }
};

export const deleteCollectionCommentCall = async (commentId: number) => {
  try {
    const response: { success: boolean; data: IComment } =
      await v3Client.delete(`Comment/Customer/${commentId}`);
    return response;
  } catch (error: unknown) {
    handleToast(
      ToastType.Error,
      'Failed to delete Collection Comment. Please try again later.',
    );
  }
};

export const addCollectionCommentCall = async (
  payload: AddCollectionCommentPayload,
) => {
  try {
    const response: CollectionCommentResp = await v3Client.post(
      `Comment/Customer/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    handleToast(
      ToastType.Error,
      'Failed to add Collection Comment. Please try again later.',
    );
  }
};

export const sendToCollectionsCall = async (invoiceIds: number[]) => {
  try {
    const response: CollectionResponse = await v3Client.post(
      'Accounting/SendToCollections',
      invoiceIds,
    );
    return response;
  } catch (error: unknown) {
    handleToast(
      ToastType.Error,
      'Failed to send invoices to Collections. Please try again later.',
    );
  }
};

export const editCollectionCommentCall = async (
  payload: EditCollectionCommentPayload,
) => {
  try {
    const response: CollectionCommentResp = await v3Client.post(
      `Comment/Update/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    handleToast(
      ToastType.Error,
      'Failed to edit Collection Comment. Please try again later.',
    );
  }
};
