import { MenuItemType, ToastType, openToast } from '@components';
import { AddressDetails } from '@components/Autocomplete/AddressAutoComplete';
import { userRoles } from '@constants/common';
import { datadogRum } from '@datadog/browser-rum';
import { useParams } from '@solidjs/router';
import {
  CustomerDetailState,
  ICustomerDetails,
} from '@store/customers/customerDetails';
import { CreateCustomerState } from '@store/customers/types';
import { loadBoardStore } from '@store/loadboard';
import { orderStore } from '@store/orders';
import { userStore } from '@store/user';
import { ColumnState, IRowNode } from '@ag-grid-community/core';
import { get, intersection, isEmpty, map, mapValues } from 'lodash';
import { DateTime } from 'luxon';

export * from './printUtils';

export function validURL(str: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(str);
}

export function capitalizeWord(str: string): string {
  if (str.length === 0) {
    return str;
  }
  const words = str.split(/(?=[A-Z])/);
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join('');
}

interface NumberFormatOptions {
  localeMatcher?: string;
  style?: string;
  currency?: string;
  currencyDisplay?: string;
  currencySign?: string;
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

export const formatAmount = (
  amount?: number,
  options?: NumberFormatOptions,
  customFallback = '-',
  showInCurrencyFormat?: boolean,
) => {
  if (typeof amount === 'number' && !isNaN(amount)) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 2,
      ...options,
      currency: !isEmpty(get(options, 'currency'))
        ? get(options, 'currency')
        : 'USD',
    });
    let formattedAmount = formatter.format(amount);
    if (Boolean(showInCurrencyFormat) && amount < 0) {
      formattedAmount = `(${formattedAmount.replace('-', '')})`;
    }
    return formattedAmount;
  }

  return customFallback;
};

export function addressFormatter(
  address1: string | undefined | null,
  city: string | undefined | null,
  stateOrProvince: string | undefined | null,
  zip: string | undefined | null,
) {
  let address: string = '';
  if (address1 == undefined) {
    return '';
  }
  address = address1 + ' ' + city + ', ' + stateOrProvince + ' ' + zip;

  return address;
}
export function getDisplayTime(timestamp: string) {
  if (!timestamp) {
    return '';
  }
  const dateTime = DateTime.fromISO(timestamp);
  return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
}

export const convertTimestamp = (timestamp: string) => {
  if (!timestamp) {
    return {
      value: {},
      label: '',
    };
  }
  const [year, month, day, hours, minutes] = timestamp
    .split(/[-T:]/)
    .map(Number);

  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes));

  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();

  let period = 'AM';
  let formattedHour = hour;

  if (hour >= 12) {
    period = 'PM';
    if (hour > 12) {
      formattedHour = hour - 12;
    }
  } else if (hour === 0) {
    formattedHour = 12;
  }

  const label = `${formattedHour < 10 ? `0${formattedHour}` : formattedHour}:${
    minute < 10 ? '0' + minute : minute
  } ${period}`;
  return {
    label,
    value: {
      hour,
      minute,
      second: 0,
    },
  };
};

export const convertDateTime = (date: string, time: string) => {
  if (!date || !time) return null;
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    throw new Error('Invalid date format');
  }
  const [hoursMinutes, period] = time.split(' ');
  //eslint-disable-next-line
  let [hours, minutes] = hoursMinutes.split(":").map(Number);
  if (period === 'PM' && hours < 12) hours += 12;
  if (period === 'AM' && hours === 12) hours = 0;

  const year = parsedDate.getFullYear();
  const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = parsedDate.getDate().toString().padStart(2, '0');

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${year}-${month}-${day}T${formattedHours}:${formattedMinutes}:00`;
};

export function validEmail(email: string): boolean {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  return emailRegex.test(email);
}

export const handleToast = (type: ToastType, errorMessage: string) => {
  if (type === ToastType.Error) {
    errorMessage = `${errorMessage}\n${datadogRum.getInternalContext()
      ?.session_id}`;
  }

  openToast({
    type,
    message: errorMessage,
  });
};

export const getBrowserCurrentTime = (): string => {
  return new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000,
  ).toISOString();
};

export const createSelectMenuItemsFroObjectOfArrays = (
  data: Record<string, string[]>,
) => {
  return mapValues(data, (array) =>
    map(array, (item) => ({ label: item, value: item })),
  );
};

export const convertDateTimeV2 = (date: string, time: string) => {
  if (!date) return time;
  if (!time) return date;

  const [datePart] = date.split('T');
  const [, timePart] = time.split('T');
  if (!Boolean(timePart)) {
    return `${datePart}T00:00:00`;
  }

  return `${datePart}T${timePart}`;
};

const focusableTags =
  'a:not([disabled]), button:not([disabled]), input:not([disabled]), [tabindex]:not([disabled]):not([tabindex="0"])';

export const focusNextElement = (el = document.activeElement) => {
  if (el === null) {
    return;
  }

  const elements =
    el.parentNode?.querySelectorAll(focusableTags) ?? ([] as Element[]);

  if (elements.length < 2) {
    focusNextElement(el.parentElement);
    return;
  }

  const elIdx = Array.prototype.findIndex.call(elements, (element) => {
    return el === element || element === document.activeElement;
  });

  if (elIdx === elements.length - 1) {
    focusNextElement(el.parentElement);
    return;
  }

  if (elIdx > -1) {
    // @ts-expect-error `focus` is a valid function.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-condition
    elements[elIdx + 1]?.focus?.();
  }
};

export const userHasRole = (role: string) => {
  return userStore.user.roles != null && userStore.user.roles.includes(role);
};

export const isCreate = () => {
  const params = useParams();
  const isCreate = !params.id;
  return isCreate;
};

export const isAdmin = () =>
  userHasRole(userRoles.ADMIN) || userHasRole(userRoles.ADMIN_LITE);

export const isManager = () => userHasRole(userRoles.MANAGER);

export const isCarrierRelation = () => userHasRole(userRoles.CARRIER_RELATIONS);

export const isSuperAdmin = () => userHasRole(userRoles.SUPER_ADMIN);

export const isReadonly = () => {
  return (
    intersection(userStore.user.roles, [userRoles.ADMIN, userRoles.ADMIN_LITE])
      .length <= 0
  );
};

export const isLTLAdmin = () => {
  return (
    intersection(userStore.user.roles, [
      userRoles.ADMIN,
      userRoles.ADMIN_LITE,
      userRoles.SUPER_ADMIN,
    ]).length > 0
  );
};

export const customerEditable = () => {
  if (isSuperAdmin()) return true;
  else if (isAdmin() && orderStore.order.status === 'Open') return true;
  return isCreate();
};

export const canReassign = () => {
  return (
    intersection(userStore.user.roles, [
      userRoles.ADMIN,
      userRoles.MANAGER,
      userRoles.SUPERVISOR,
    ]).length > 0
  );
};

export const isCapacity = () => {
  return userHasRole(userRoles.CAPACITY) as boolean;
};

export const isPrivate = () => {
  return (
    isCapacity() ||
    orderStore.order.loads.some(
      (load) => load.sharedWithOfficeId === userStore.user.officeId,
    )
  );
};
export const orderTableErrors = (
  fields: string[],
  path: string,
  index: number,
) => {
  const errors: string[] = [];
  fields.forEach((field) => {
    if (!orderStore.orderFormError) return null;
    const fieldError =
      orderStore.orderFormError[
        path.replace('{index}', `${index}`).replace('{field}', field)
      ];
    if (Array.isArray(fieldError)) {
      errors.push(fieldError[0]);
    }
  });
  return errors;
};

export const getEligibleEntries = <
  T extends { id: number; operationType: string },
>(
  items: T[] | undefined | null,
): T[] => {
  return items?.filter((item) => item.operationType !== 'Delete') || [];
};

export const sdbm = (str: string) => {
  const letters = str.split('');
  return letters.reduce((code, letter) => {
    return (code = letter.charCodeAt(0) + (code << 6) + (code << 16) - code);
  }, 0);
};

export const findMenuItemByValue = (
  value: string,
  items: MenuItemType[],
): MenuItemType => {
  return (
    items.find((item) => item.value === value) ?? {
      value: '',
      label: '',
    }
  );
};

export const doesExternalFilterFactory = (
  filter: string,
  handlerType: 'group' | 'customer',
) => {
  if (handlerType === 'group') {
    return function (node: IRowNode) {
      const data = (node as { data: unknown }).data;
      const comp =
        loadBoardStore.membersByGroupName[filter].includes(
          (data as { assignedToId: string }).assignedToId,
        ) ||
        loadBoardStore.membersByGroupName[filter].includes(
          (data as { ownerId: string }).ownerId,
        );
      return comp;
    };
  }

  return function (node: unknown) {
    const data = (node as { data: unknown }).data;
    const int: number = Object.values(
      loadBoardStore.officeGroupIdsByMachingNames[filter],
    )[0];
    const string: string = int.toString();

    if ((data as { customerGroups: string }).customerGroups) {
      const comp = (data as { customerGroups: string }).customerGroups
        .split(',')
        .includes(string);
      return comp;
    }
    return false;
  };
};

export const isExternalFilterPresent = () => true;

export const getSortModelFromColumnState = (columns: ColumnState[]) => {
  return columns
    .filter((c) => c.sort !== null)
    .map((c) => ({
      colId: c.colId,
      sort: c.sort,
      sortIndex: c.sortIndex ?? 0,
    }))
    .sort((a, b) => (a.sortIndex >= b.sortIndex ? 1 : -1));
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export function invalidUrlPatterns(string: string) {
  const invalidPatterns = ['//.'];
  return invalidPatterns.some((pattern) => string.indexOf(pattern) !== -1);
}

export function isValidHttpUrl(string: string) {
  const pattern = new RegExp(
    '^(http(s)?:\\/\\/)' + // protocol
      '((\\bw{3}\\.\\b))?' +
      '[-a-zA-Z0-9@@:%._+~#=]{1,256}\\.' +
      '[a-zA-Z0-9()]{1,6}' +
      '\\b(?:[-a-zA-Z0-9()@@:%_\\+.~#?&\\/=]*)$',
  );
  return pattern.test(string);
}

export const hasPermission = (permission: string) => {
  return (
    userStore.user.permissions &&
    userStore.user.permissions.length > 0 &&
    userStore.user.permissions.includes(permission)
  );
};

export const handleGooglePlacesSelect = (
  place: AddressDetails,
  updateFormState: (state: Partial<ICustomerDetails>) => void,
  prefix: string,
) => {
  const { address1 = '', city = '', state = '', zipCode = '' } = place;
  if (Boolean(updateFormState)) {
    updateFormState({
      [`${prefix}Address1`]: address1,
      [`${prefix}City`]: city,
      [`${prefix}State`]: state,
      [`${prefix}Zip`]: zipCode,
    });
  }
};

export const setSameAsOtherAddress = (
  sourceType: 'mailing' | 'physical',
  customerStore: CreateCustomerState | CustomerDetailState,
  setupNewCustomerState: (state: Partial<ICustomerDetails>) => void,
) => {
  const targetType = sourceType === 'mailing' ? 'physical' : 'mailing';
  const { customer }: { customer: Partial<ICustomerDetails> } = customerStore;

  setupNewCustomerState({
    [`${targetType}Address1`]: customer[`${sourceType}Address1`],
    [`${targetType}Address2`]: customer[`${sourceType}Address2`],
    [`${targetType}City`]: customer[`${sourceType}City`],
    [`${targetType}State`]: customer[`${sourceType}State`],
    [`${targetType}Zip`]: customer[`${sourceType}Zip`],
  });
};

export const getActiveStopIndices = () => {
  const indices: number[] = [];
  orderStore.order.loads[orderStore.activeTab.index]?.stops?.forEach(
    (stop, index) => {
      if (stop.operationType !== 'Delete') {
        indices.push(index);
      }
    },
  );
  return indices;
};

export const trimDecimalValue = (
  value: string,
  maxPreDecimal: number,
  maxPostDecimal: number,
  allowNegative: boolean,
): string => {
  const cleanedValue = value.replace(/(?!^)-/g, '');

  const isNegative = allowNegative && cleanedValue.startsWith('-');
  const absoluteValue = isNegative ? cleanedValue.slice(1) : cleanedValue;

  const [integerPart, fractionalPart] = absoluteValue.split('.');

  const trimmedIntegerPart = integerPart.slice(0, maxPreDecimal);

  const trimmedFractionalPart = fractionalPart
    ? fractionalPart.slice(0, maxPostDecimal)
    : '';

  let trimmedValue = trimmedFractionalPart
    ? `${trimmedIntegerPart}.${trimmedFractionalPart}`
    : trimmedIntegerPart;

  if (absoluteValue.endsWith('.') && !trimmedFractionalPart) {
    trimmedValue += '.';
  }

  return isNegative ? `-${trimmedValue}` : trimmedValue;
};
