export const DUMMY_DATA = {
  totalRecords: 25,
  data: [
    {
      loadId: 3041984,
      orderId: 1924594,
      customerName: 'UNIVERSITY FURNISHINGS',
      customerId: 360114,
      referenceNumber: null,
      carrierName: 'Roadrunner Transportation Services',
      status: 'Dispatched',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'James Loyd',
      ownerId: 782174,
      assignedTo: 'James Loyd',
      accountManager: 'Demo Account',
      salesManager: 'Wilson Rodriguez',
      coveredBy: 'James Loyd',
      coveredById: 782174,
      origin: 'DENVER, NC',
      mode: 'TL',
      destination: 'MONROE, NC',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2023-02-18T12:00:00',
      pickUpTime: null,
      dropDate: '2023-02-21T12:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 65,
      assignedToId: 782174,
      loadNumber: '1924594-1',
    },
    {
      loadId: 3068705,
      orderId: 1948898,
      customerName: 'zAlbertsons AZ',
      customerId: 646418,
      referenceNumber: 'EDI_TEST_914109738',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'System Notification',
      ownerId: 1,
      assignedTo: 'System Notification',
      accountManager: 'Marc Bodley',
      salesManager: 'Marc Bodley',
      coveredBy: ' ',
      coveredById: null,
      origin: 'GONZALES, CA',
      mode: 'TL',
      destination: 'SPOKANE, WA',
      pickUps: 4,
      dropOffs: 1,
      pickDate: '2024-10-06T12:00:00',
      pickUpTime: null,
      dropDate: '2024-10-11T12:00:00',
      dropOffTime: null,
      equipment: 'R',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 1,
      loadNumber: '1948898-1',
    },
    {
      loadId: 3070162,
      orderId: 1950205,
      customerName: 'zAlbertsons AZ',
      customerId: 646418,
      referenceNumber: 'EDI_TEST_584827750',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'System Notification',
      ownerId: 1,
      assignedTo: 'System Notification',
      accountManager: 'Marc Bodley',
      salesManager: 'Marc Bodley',
      coveredBy: ' ',
      coveredById: null,
      origin: 'SALINAS, CA',
      mode: 'TL',
      destination: 'SPOKANE, WA',
      pickUps: 3,
      dropOffs: 1,
      pickDate: '2024-10-13T12:00:00',
      pickUpTime: null,
      dropDate: '2024-10-18T12:00:00',
      dropOffTime: null,
      equipment: 'R',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 1,
      loadNumber: '1950205-1',
    },
    {
      loadId: 1776511,
      orderId: 435643,
      customerName: 'ARMSTRONG TRANSPORT GROUP',
      customerId: 358231,
      referenceNumber: null,
      carrierName: 'Global Tranz Enterprises**Broker Only',
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Aaron Thrift',
      ownerId: 763890,
      assignedTo: 'Aaron Thrift',
      accountManager: 'Aaron Thrift',
      salesManager: 'Aaron Thrift',
      coveredBy: 'Aaron Thrift',
      coveredById: 763890,
      origin: 'Charlotte, NC',
      mode: 'LTL',
      destination: 'Charlotte, NC',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2017-01-17T12:00:00',
      pickUpTime: null,
      dropDate: '2017-01-17T12:00:00',
      dropOffTime: null,
      equipment: 'V',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: 'plastic',
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 0,
      assignedToId: 763890,
      loadNumber: '435643-1',
    },
    {
      loadId: 2958054,
      orderId: 1601293,
      customerName: 'Demo EDI Customer',
      customerId: 619930,
      referenceNumber: 'EdiUnitTestShipmentId_743650000',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'System Notification',
      ownerId: 1,
      assignedTo: 'System Notification',
      accountManager: 'Rob Liss',
      salesManager: 'Mark Arce',
      coveredBy: ' ',
      coveredById: null,
      origin: 'ELK GROVE VILLAGE, IL',
      mode: 'TL',
      destination: 'BLOOMFIELD, MO',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-03-07T12:00:00',
      pickUpTime: null,
      dropDate: '2022-03-12T12:00:00',
      dropOffTime: null,
      equipment: 'F',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 1,
      loadNumber: '1601293-1',
    },
    {
      loadId: 2962279,
      orderId: 1605200,
      customerName: 'Test 50',
      customerId: 524230,
      referenceNumber: null,
      carrierName: 'Old Dominion Freight Line, Inc',
      status: 'Assigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Joe Crachiolo',
      ownerId: 783959,
      assignedTo: 'Joe Crachiolo',
      accountManager: 'Demo Account',
      salesManager: 'Demo Account',
      coveredBy: 'Joe Crachiolo',
      coveredById: 783959,
      origin: 'Portland, OR',
      mode: 'LTL',
      destination: 'Douglas, GA',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2021-06-04T12:00:00',
      pickUpTime: '',
      dropDate: '2021-06-07T12:00:00',
      dropOffTime: null,
      equipment: 'F',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: 'stuff',
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783959,
      loadNumber: '1605200-1',
    },
    {
      loadId: 2963049,
      orderId: 1605899,
      customerName: 'Sysco Corp',
      customerId: 783452,
      referenceNumber: 'EdiUnitTestShipmentId_48250150',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Shawn Saras',
      ownerId: 783420,
      assignedTo: 'Shawn Saras',
      accountManager: 'Parvesh Kumar',
      salesManager: 'Marc Bodley',
      coveredBy: ' ',
      coveredById: null,
      origin: 'PLYMOUTH, WI',
      mode: 'TL',
      destination: 'LINCOLN, NE',
      pickUps: 3,
      dropOffs: 1,
      pickDate: '2022-04-15T12:00:00',
      pickUpTime: null,
      dropDate: '2022-04-20T12:00:00',
      dropOffTime: null,
      equipment: 'V',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783420,
      loadNumber: '1605899-1',
    },
    {
      loadId: 2964131,
      orderId: 1606891,
      customerName: 'Leprino Foods',
      customerId: 624749,
      referenceNumber: '125209417_QA_TC',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Shawn Saras',
      ownerId: 783420,
      assignedTo: 'Shawn Saras',
      accountManager: 'Travis Brewer',
      salesManager: 'Travis Brewer',
      coveredBy: ' ',
      coveredById: null,
      origin: 'FOWLER, CA',
      mode: 'TL',
      destination: 'TRACY, CA',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2021-05-10T12:00:00',
      pickUpTime: null,
      dropDate: '2021-05-11T12:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783420,
      loadNumber: '1606891-1',
    },
    {
      loadId: 3039548,
      orderId: 1922395,
      customerName: 'Laurens Video Game Store',
      customerId: 781617,
      referenceNumber: '',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Thy Duong',
      ownerId: 783973,
      assignedTo: 'Thy Duong',
      accountManager: 'Parvesh Kumar Org',
      salesManager: 'Parvesh Kumar Org',
      coveredBy: 'Thy Duong',
      coveredById: 783973,
      origin: 'Charlotte, NC',
      mode: 'TL',
      destination: 'Silver Spring, MD',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2023-09-10T12:00:00',
      pickUpTime: '',
      dropDate: '2023-09-11T12:00:00',
      dropOffTime: '',
      equipment: 'F',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 405,
      assignedToId: 783973,
      loadNumber: '1922395-1',
    },
    {
      loadId: 3039611,
      orderId: 1922458,
      customerName: 'Laurens Video Game Store',
      customerId: 781617,
      referenceNumber: '',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Thy Duong',
      ownerId: 783973,
      assignedTo: 'Thy Duong',
      accountManager: 'Parvesh Kumar Org',
      salesManager: 'Parvesh Kumar Org',
      coveredBy: 'Thy Duong',
      coveredById: 783973,
      origin: 'Charlotte, NC',
      mode: 'TL',
      destination: 'Silver Spring, MD',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2023-09-01T12:00:00',
      pickUpTime: '',
      dropDate: '2023-09-20T12:00:00',
      dropOffTime: null,
      equipment: 'F',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 405,
      assignedToId: 783973,
      loadNumber: '1922458-1',
    },
    {
      loadId: 3049558,
      orderId: 1931290,
      customerName: 'HelloFresh',
      customerId: 795383,
      referenceNumber: null,
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Parvesh Kumar',
      ownerId: 783971,
      assignedTo: 'Parvesh Kumar',
      accountManager: 'Scot Thompson',
      salesManager: 'Scot Thompson',
      coveredBy: 'Parvesh Kumar',
      coveredById: 783971,
      origin: 'Charlotte, NC',
      mode: 'TL',
      destination: 'Charlotte, NC',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2023-04-14T12:00:00',
      pickUpTime: null,
      dropDate: '2023-04-21T12:00:00',
      dropOffTime: null,
      equipment: 'F/SD',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 0,
      assignedToId: 783971,
      loadNumber: '1931290-1',
    },
    {
      loadId: 3058257,
      orderId: 1939114,
      customerName: 'Laurens Video Game Store',
      customerId: 781617,
      referenceNumber: null,
      carrierName: 'Blue Ice Transport Inc',
      status: 'Assigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Katie ElHamahmy',
      ownerId: 783969,
      assignedTo: 'Katie ElHamahmy',
      accountManager: 'Parvesh Kumar Org',
      salesManager: 'Parvesh Kumar Org',
      coveredBy: 'Katie ElHamahmy',
      coveredById: 783969,
      origin: 'Charlotte, NC',
      mode: 'TL',
      destination: 'Charlotte, NC',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2023-06-23T12:00:00',
      pickUpTime: null,
      dropDate: '2023-06-26T12:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 0,
      assignedToId: 783969,
      loadNumber: '1939114-1',
    },
    {
      loadId: 3065874,
      orderId: 1946361,
      customerName: 'Lulu the Customer',
      customerId: 804646,
      referenceNumber: null,
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'John Economou',
      ownerId: 784116,
      assignedTo: 'John Economou',
      accountManager: 'James David',
      salesManager: 'Mike Edmondson',
      coveredBy: 'John Economou',
      coveredById: 784116,
      origin: 'Northbrook, IL',
      mode: 'TL',
      destination: 'Milwaukee, WI',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2023-08-17T12:00:00',
      pickUpTime: null,
      dropDate: '2023-08-17T12:00:00',
      dropOffTime: null,
      equipment: 'DD',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 69,
      assignedToId: 784116,
      loadNumber: '1946361-1',
    },
    {
      loadId: 3046999,
      orderId: 1925830,
      customerName: 'James Thomas Loyd',
      customerId: 800137,
      referenceNumber: '123456',
      carrierName: 'Blue Ice Transport Inc',
      status: 'Assigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Albert Huynh',
      ownerId: 784248,
      assignedTo: 'Albert Huynh',
      accountManager: 'James Loyd',
      salesManager: 'James Loyd',
      coveredBy: 'Albert Huynh',
      coveredById: 784248,
      origin: null,
      mode: 'TL',
      destination: null,
      pickUps: 1,
      dropOffs: 2,
      pickDate: null,
      pickUpTime: null,
      dropDate: null,
      dropOffTime: null,
      equipment: 'V53',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: 'Chips And Dip',
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 2349,
      assignedToId: 784248,
      loadNumber: '1925830-2',
    },
    {
      loadId: 2993039,
      orderId: 1632432,
      customerName: 'Test Customer 88938',
      customerId: 362140,
      referenceNumber: null,
      carrierName: 'Forward Air',
      status: 'Assigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Jen White',
      ownerId: 783921,
      assignedTo: 'Jen White',
      accountManager: 'System Notification',
      salesManager: 'System Notification',
      coveredBy: 'Jen White',
      coveredById: 783921,
      origin: 'Huntersville, NC',
      mode: 'LTL',
      destination: 'Harrisburg, NC',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-02-25T05:00:00',
      pickUpTime: null,
      dropDate: '2022-02-25T05:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783921,
      loadNumber: '1632432-1',
    },
    {
      loadId: 2995120,
      orderId: 1634325,
      customerName: 'Tillamook County Creamery Association',
      customerId: 561414,
      referenceNumber: 'BlueJayProviderTEST',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Mark Taylor',
      ownerId: 783697,
      assignedTo: 'Mark Taylor',
      accountManager: 'James Dolan',
      salesManager: 'James Dolan',
      coveredBy: ' ',
      coveredById: null,
      origin: 'MOUNTAIN HOME, ID',
      mode: 'TL',
      destination: 'MIRA LOMA, CA',
      pickUps: 1,
      dropOffs: 3,
      pickDate: '2022-03-01T12:00:00',
      pickUpTime: null,
      dropDate: '2022-03-03T12:00:00',
      dropOffTime: null,
      equipment: 'R53',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783697,
      loadNumber: '1634325-1',
    },
    {
      loadId: 2984466,
      orderId: 1624747,
      customerName: 'Laurens Video Game Store',
      customerId: 781617,
      referenceNumber: null,
      carrierName: 'Customized Logistics Group DBA Logistics Titans',
      status: 'Dispatched',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Leigh Taylor',
      ownerId: 570191,
      assignedTo: 'Leigh Taylor',
      accountManager: 'Parvesh Kumar Org',
      salesManager: 'Parvesh Kumar Org',
      coveredBy: 'Leigh Taylor',
      coveredById: 570191,
      origin: 'Johnson City, TN',
      mode: 'LTL',
      destination: 'Charlotte, NC',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-01-19T12:00:00',
      pickUpTime: null,
      dropDate: '2022-01-19T12:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 161,
      assignedToId: 570191,
      loadNumber: '1624747-1',
    },
    {
      loadId: 3005027,
      orderId: 1643215,
      customerName: 'A & K Railroad Materials',
      customerId: 783994,
      referenceNumber: null,
      carrierName: 'XPO Logistics',
      status: 'Assigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Parvesh Kumar',
      ownerId: 783971,
      assignedTo: 'Parvesh Kumar',
      accountManager: 'Eric Covington',
      salesManager: 'Eric Covington',
      coveredBy: 'Parvesh Kumar',
      coveredById: 783971,
      origin: 'SILVER SPRING, MD',
      mode: 'LTL',
      destination: 'Jacksonville, FL',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-05-14T12:00:00',
      pickUpTime: null,
      dropDate: '2022-05-28T12:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783971,
      loadNumber: '1643215-1',
    },
    {
      loadId: 3005525,
      orderId: 1643664,
      customerName: 'Tillamook County Creamery Association',
      customerId: 561414,
      referenceNumber: '123609',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Parvesh Kumar',
      ownerId: 783971,
      assignedTo: 'Parvesh Kumar',
      accountManager: 'James Dolan',
      salesManager: 'James Dolan',
      coveredBy: ' ',
      coveredById: null,
      origin: 'MOUNTAIN HOME, ID',
      mode: 'TL',
      destination: 'TERRELL, TX',
      pickUps: 1,
      dropOffs: 2,
      pickDate: '2022-05-19T12:00:00',
      pickUpTime: null,
      dropDate: '2022-05-23T12:00:00',
      dropOffTime: null,
      equipment: 'R53',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783971,
      loadNumber: '1643664-1',
    },
    {
      loadId: 3007377,
      orderId: 1645315,
      customerName: 'Oatly Salt Lake Division',
      customerId: 799398,
      referenceNumber: '144016931_SHAWN_00',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Shawn Saras',
      ownerId: 783420,
      assignedTo: 'Shawn Saras',
      accountManager: 'Tiffany Ladish',
      salesManager: 'Tiffany Ladish',
      coveredBy: ' ',
      coveredById: null,
      origin: 'OGDEN, UT',
      mode: 'TL',
      destination: 'TEMPLE, TX',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-05-24T12:00:00',
      pickUpTime: null,
      dropDate: '2022-05-26T12:00:00',
      dropOffTime: null,
      equipment: 'R53',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783420,
      loadNumber: '1645315-1',
    },
    {
      loadId: 3008731,
      orderId: 1646534,
      customerName: 'Danone NA',
      customerId: 790197,
      referenceNumber: '144311785_20220608_00',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Shawn Saras',
      ownerId: 783420,
      assignedTo: 'Shawn Saras',
      accountManager: 'Jesse Longoria',
      salesManager: 'Jesse Longoria',
      coveredBy: ' ',
      coveredById: null,
      origin: 'SPEARFISH, SD',
      mode: 'TL',
      destination: 'MOUNT CRAWFORD, VA',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-06-02T12:00:00',
      pickUpTime: null,
      dropDate: '2022-06-06T12:00:00',
      dropOffTime: null,
      equipment: 'V',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783420,
      loadNumber: '1646534-1',
    },
    {
      loadId: 3015701,
      orderId: 1652797,
      customerName: 'Lauren s Video Game Store',
      customerId: 781617,
      referenceNumber: null,
      carrierName: 'Roadrunner Transportation Services',
      status: 'Dispatched',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Katie ElHamahmy',
      ownerId: 783969,
      assignedTo: 'Katie ElHamahmy',
      accountManager: 'Parvesh Kumar Org',
      salesManager: 'Parvesh Kumar Org',
      coveredBy: 'Katie ElHamahmy',
      coveredById: 783969,
      origin: 'Atlanta, GA',
      mode: 'LTL',
      destination: 'Fort Mill, SC',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-07-28T12:00:00',
      pickUpTime: null,
      dropDate: '2022-07-29T12:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783969,
      loadNumber: '1652797-1',
    },
    {
      loadId: 3021695,
      orderId: 1658177,
      customerName: 'Pixelle Specialty Solutions',
      customerId: 795206,
      referenceNumber: '842536793_00_01',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Srini R',
      ownerId: 783972,
      assignedTo: 'Srini R',
      accountManager: 'Warm Lead',
      salesManager: 'Warm Lead',
      coveredBy: ' ',
      coveredById: null,
      origin: 'SPRING GROVE, PA',
      mode: 'TL',
      destination: 'MONROE, WI',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-08-23T12:00:00',
      pickUpTime: null,
      dropDate: '2022-08-24T12:00:00',
      dropOffTime: null,
      equipment: 'F',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: null,
      assignedToId: 783972,
      loadNumber: '1658177-1',
    },
    {
      loadId: 3032641,
      orderId: 1916211,
      customerName: 'Hormel Foods',
      customerId: 802491,
      referenceNumber: 'testJ10',
      carrierName: null,
      status: 'Unassigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'John Economou',
      ownerId: 784116,
      assignedTo: 'John Economou',
      accountManager: 'William Webb',
      salesManager: 'William Webb',
      coveredBy: ' ',
      coveredById: null,
      origin: 'WOODVILLE, WI',
      mode: 'TL',
      destination: 'CHEYENNE, WY',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2023-11-09T12:00:00',
      pickUpTime: null,
      dropDate: '2023-11-11T12:00:00',
      dropOffTime: null,
      equipment: 'F',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: 514447,
      miles: null,
      assignedToId: 784116,
      loadNumber: '1916211-1',
    },
    {
      loadId: 3034619,
      orderId: 1917976,
      customerName: 'A & K Railroad Materials',
      customerId: 783994,
      referenceNumber: null,
      carrierName: 'TForce Freight',
      status: 'Assigned',
      office: 'ATG Corp',
      officeId: 63,
      owner: 'Parvesh Kumar',
      ownerId: 783971,
      assignedTo: 'Parvesh Kumar',
      accountManager: 'Eric Covington',
      salesManager: 'Eric Covington',
      coveredBy: 'Parvesh Kumar',
      coveredById: 783971,
      origin: 'Toledo, OH',
      mode: 'LTL',
      destination: 'SILVER SPRING, MD',
      pickUps: 1,
      dropOffs: 1,
      pickDate: '2022-12-22T12:00:00',
      pickUpTime: null,
      dropDate: '2022-12-22T12:00:00',
      dropOffTime: null,
      equipment: '',
      flagId: null,
      flagText: null,
      flagType: null,
      claim: false,
      assignedToGroupId: null,
      product: null,
      lastCheckCallDate: null,
      lastCheckCallComment: null,
      sharedWithOfficeId: null,
      confirmationSigned: false,
      postingId: null,
      miles: 467,
      assignedToId: 783971,
      loadNumber: '1917976-1',
    },
  ],
  permissionGranted: true,
};

export const ORDER_DUMMY_DATA = {
  Id: 1954750,
  CustomerId: 783452,
  CustomerContact: {
    Id: 21226,
    ObjectId: 783452,
    ObjectType: 'Customer',
    Name: 'Overdue Invoice',
    Phone1: null,
    Extension1: null,
    Phone2: null,
    Extension2: null,
    Fax: null,
    Email: '000-scsaccounting@corp.sysco.com',
    Type: 'Freight',
    Status: 'Active',
    CreatedDate: '2017-05-26T00:00:00',
    ModifiedDate: '2023-05-16T19:19:13.137',
    MailerEmails: false,
    Password: null,
    UserName: null,
    Phone: null,
  },
  UsersViewing: [],
  ChargeCreditCardFee: false,
  CustomerName: 'Sysco Corp',
  CustomerCredit: 0.0,
  CustomerContactId: 21226,
  AvailableCredit: null,
  ReferenceNumber: 'CS08465052_SHAWN_01',
  PaymentTerms: 'Net 30',
  Status: 'Open',
  AssignedId: 783420,
  Owner: 'Shawn Saras',
  BillingHold: false,
  AgingOut: false,
  LineItems: [
    {
      Id: 178702973,
      Type: 'Line Haul',
      Description: null,
      SettledDate: null,
      CreatedDate: '12/22/2023',
      Status: 'Unsettled',
      Quantity: 1.0,
      Rate: 1653.13,
      Estimate: false,
      ReadOnly: false,
      Synched: false,
      Adjustment: false,
      ReferenceNumber: '',
      StopId: null,
      DescriptionWithParanthesis: '',
      RateDetail: '$1,653.13',
    },
    {
      Id: 178702974,
      Type: 'Stop Off',
      Description: null,
      SettledDate: null,
      CreatedDate: '12/22/2023',
      Status: 'Unsettled',
      Quantity: 0.0,
      Rate: 100.0,
      Estimate: false,
      ReadOnly: false,
      Synched: false,
      Adjustment: false,
      ReferenceNumber: '',
      StopId: null,
      DescriptionWithParanthesis: '',
      RateDetail: '$100.00 * 0.000 = $0.00',
    },
    {
      Id: 178702981,
      Type: 'Fuel',
      Description: null,
      SettledDate: null,
      CreatedDate: '12/22/2023',
      Status: 'Unsettled',
      Quantity: 123.0,
      Rate: 245.23,
      Estimate: false,
      ReadOnly: false,
      Synched: false,
      Adjustment: false,
      ReferenceNumber: '',
      StopId: null,
      DescriptionWithParanthesis: '',
      RateDetail: '$245.23 * 123.000 = $30,163.29',
    },
  ],
  Payments: [],
  Adjustments: [],
  Loads: [
    {
      Id: 3074975,
      Editable: true,
      HasBeenSharedWithCapacity: false,
      HideCustomerName: false,
      Carrier: null,
      OrderId: 1954750,
      LoadNum: '1954750-1',
      Status: 'Unassigned',
      Sequence: 1,
      Type: null,
      CustomerReferenceNumber: 'CS08465052_SHAWN_01',
      CarrierContact: null,
      CustomerContact: {
        Id: 21226,
        ObjectId: 783452,
        ObjectType: 'Customer',
        Name: 'Overdue Invoice',
        Phone1: null,
        Extension1: null,
        Phone2: null,
        Extension2: null,
        Fax: null,
        Email: '000-scsaccounting@corp.sysco.com',
        Type: 'Freight',
        Status: 'Active',
        CreatedDate: '2017-05-26T00:00:00',
        ModifiedDate: '2023-05-16T19:19:13.137',
        MailerEmails: false,
        Password: null,
        UserName: null,
        Phone: null,
      },
      CoveredById: null,
      CoveredBy: null,
      CarrierId: null,
      CarrierName: null,
      CarrierActionPlan: false,
      CarrierStatus: null,
      CarrierContactId: null,
      Equipment: 'R, Reefer',
      Mode: 'TL',
      TeamRequired: false,
      HazmatRequired: false,
      TarpRequired: false,
      CarrierInstructions: '  ',
      Terminal: null,
      PracticalMileage: 1627.68,
      ShortestMileage: 1578.36,
      Driver: null,
      DriverPhoneNumber: null,
      TruckNumber: null,
      TrailerNumber: '0000000000',
      TrackingNumber: null,
      Product: null,
      TempInstructions: 'SET REEFER TEMP PER BOL',
      BillingHold: false,
      BillingHoldReason: null,
      BillingHoldDescription: null,
      BilledDate: null,
      PaidDate: null,
      TrackingEnabled: false,
      EnableTracking: false,
      AssignedToUserId: 783420,
      AssignedToGroupId: null,
      AssignedToUser: 'Shawn Saras',
      AssignedToGroup: null,
      AssignedToOffice: null,
      LegalText: null,
      ProfitSplit: null,
      SharedWithOfficeId: null,
      OD: false,
      FreightValue: null,
      LoadTracking: [],
      NMFC: null,
      Weight: 0.0,
      MarketRate: null,
      Class: null,
      MinPrice: 1.0,
      MaxPrice: 111.0,
      LineItems: [
        {
          Id: 163947193,
          Type: 'Line Haul',
          Description: null,
          SettledDate: null,
          CreatedDate: '12/22/2023',
          Status: 'Unsettled',
          Quantity: 1.0,
          Rate: 0.0,
          Estimate: false,
          ReadOnly: false,
          Synched: false,
          Adjustment: false,
          ReferenceNumber: '',
          StopId: null,
          DescriptionWithParanthesis: '',
          RateDetail: '$0.00',
        },
        {
          Id: 163947194,
          Type: 'Accessorial',
          Description: 'DRIVER MUST CALL WHEN LOADED VERIFYING CASES AND PO',
          SettledDate: null,
          CreatedDate: '12/22/2023',
          Status: 'Unsettled',
          Quantity: 0.0,
          Rate: 250.0,
          Estimate: false,
          ReadOnly: false,
          Synched: false,
          Adjustment: false,
          ReferenceNumber: '',
          StopId: null,
          DescriptionWithParanthesis:
            '(DRIVER MUST CALL WHEN LOADED VERIFYING CASES AND PO)',
          RateDetail: '$250.00 * 0.000 = $0.00',
        },
        {
          Id: 163947195,
          Type: 'Accessorial',
          Description: 'ONTIME DELIVERY',
          SettledDate: null,
          CreatedDate: '12/22/2023',
          Status: 'Unsettled',
          Quantity: 0.0,
          Rate: 100.0,
          Estimate: false,
          ReadOnly: false,
          Synched: false,
          Adjustment: false,
          ReferenceNumber: '',
          StopId: null,
          DescriptionWithParanthesis: '(ONTIME DELIVERY)',
          RateDetail: '$100.00 * 0.000 = $0.00',
        },
        {
          Id: 163947196,
          Type: 'Accessorial',
          Description: 'DRIVER MUST ACCEPT MACROPOINT',
          SettledDate: null,
          CreatedDate: '12/22/2023',
          Status: 'Unsettled',
          Quantity: 0.0,
          Rate: 300.0,
          Estimate: false,
          ReadOnly: false,
          Synched: false,
          Adjustment: false,
          ReferenceNumber: '',
          StopId: null,
          DescriptionWithParanthesis: '(DRIVER MUST ACCEPT MACROPOINT)',
          RateDetail: '$300.00 * 0.000 = $0.00',
        },
      ],
      Payments: [],
      Stops: [
        {
          Id: 7764249,
          CustomerId: 0,
          LoadId: 3074975,
          StopDateTime: '2023-12-18T00:00:00',
          Description: '',
          Address1: 'Hanna Ct',
          Address2: '',
          City: 'Charlotte',
          State: 'NC',
          Zip: '28212',
          LocationName: 'CARGILL KITCHEN SOLUTIONS 1',
          Contact: 'CALL FOR APPOINTMENT',
          Phone: '7634973700',
          PickUp: true,
          DropOff: false,
          StopOrder: 0,
          Origin: true,
          Destination: false,
          CreatedDate: '2023-12-22T14:30:48.3433333',
          ModifiedDate: '2023-12-22T14:34:22.4066667',
          ReferenceNum: null,
          Time: null,
          Instructions:
            'NANC VENDOR EFFECTIVE 8/3/09 CARRIERS WILL NEED TO CONFIRM NO PALLET EXCHANGE WIT H THE SUPPLIER WHEN SCHEDULING YOUR APPOINTMENT. @@ NO PALLET EXCHANGE @@',
          Directions: null,
          NeedsAppointment: false,
          AppointmentDate: null,
          DriverInTime: null,
          DriverOutTime: null,
          AppointmentNum: null,
          Email: '',
          StopCode: '3581_10',
          Arrived: false,
          Lat: null,
          Long: null,
          CountryCode: 'USA',
          PracticalMileageToNextStop: 1163.43,
          ShortestMileageToNextStop: 1125.27,
          Load: null,
          LoadItems: [],
          OrderLineItems: [],
          TimeZoneOffset: 0,
          StopType: 'Pickup',
        },
        {
          Id: 7764248,
          CustomerId: 0,
          LoadId: 3074975,
          StopDateTime: '2023-12-18T00:00:00',
          Description: '',
          Address1: '2864 EAGANDALE BLVD',
          Address2: '',
          City: 'EAGAN',
          State: 'MN',
          Zip: '55121',
          LocationName: 'MN BEST MAID COOKIE CO',
          Contact: 'APPOINTMENT CONTACT',
          Phone: '6514545180',
          PickUp: true,
          DropOff: false,
          StopOrder: 1,
          Origin: false,
          Destination: false,
          CreatedDate: '2023-12-22T14:30:48.3433333',
          ModifiedDate: '2023-12-22T14:34:22.4066667',
          ReferenceNum: null,
          Time: null,
          Instructions: '?? NO PALLET EXCHANGE ??',
          Directions: null,
          NeedsAppointment: false,
          AppointmentDate: null,
          DriverInTime: null,
          DriverOutTime: null,
          AppointmentNum: null,
          Email: '',
          StopCode: '222_222',
          Arrived: false,
          Lat: null,
          Long: null,
          CountryCode: 'USA',
          PracticalMileageToNextStop: 17.86,
          ShortestMileageToNextStop: 16.58,
          Load: null,
          LoadItems: [],
          OrderLineItems: [],
          TimeZoneOffset: 0,
          StopType: 'Pickup',
        },
        {
          Id: 7764247,
          CustomerId: 0,
          LoadId: 3074975,
          StopDateTime: '2023-12-18T00:00:00',
          Description: '',
          Address1: '21755 CEDAR AVE',
          Address2: '',
          City: 'LAKEVILLE',
          State: 'MN',
          Zip: '55044',
          LocationName: 'HORMEL FOOD SALES LLC FZ',
          Contact: 'SUPPLIER CONTACT',
          Phone: '9524691221',
          PickUp: true,
          DropOff: false,
          StopOrder: 2,
          Origin: false,
          Destination: false,
          CreatedDate: '2023-12-22T14:30:48.3433333',
          ModifiedDate: '2023-12-22T14:34:22.4066667',
          ReferenceNum: null,
          Time: null,
          Instructions: '',
          Directions: null,
          NeedsAppointment: false,
          AppointmentDate: null,
          DriverInTime: null,
          DriverOutTime: null,
          AppointmentNum: null,
          Email: '',
          StopCode: '694682_04',
          Arrived: false,
          Lat: null,
          Long: null,
          CountryCode: 'USA',
          PracticalMileageToNextStop: 446.38,
          ShortestMileageToNextStop: 436.51,
          Load: null,
          LoadItems: [],
          OrderLineItems: [],
          TimeZoneOffset: 0,
          StopType: 'Pickup',
        },
        {
          Id: 7764246,
          CustomerId: 0,
          LoadId: 3074975,
          StopDateTime: '2023-12-20T00:00:00',
          Description: '',
          Address1: '1915 KANSAS CITY ROAD',
          Address2: '',
          City: 'OLATHE',
          State: 'KS',
          Zip: '66061',
          LocationName: 'BNCC SYSCO KANSAS CITY INC.',
          Contact: 'INBOUND SCHEDULING',
          Phone: null,
          PickUp: false,
          DropOff: true,
          StopOrder: 3,
          Origin: false,
          Destination: true,
          CreatedDate: '2023-12-22T14:30:48.3433333',
          ModifiedDate: '2023-12-22T14:34:22.4066667',
          ReferenceNum: null,
          Time: null,
          Instructions:
            'TEMP. FOR REFRIDGE./PERISH. &LT; 40 F & FROZEN &LT; 0 F OR TEMP. ON S HIPPER S B/L CARRIER ISSUES: LIANG LU 281-589-5657 OR VIA EMAIL LIANG.LU@SYSCO.COM CARRIER IS RESPONSIBLE FOR OBTAINING ALL PICKUP AND DELIVERY APPOINTME NTS ACCORDINGLY REEFER TRAILERS MUST BE EQUIPPED WITH AIR CHUTE TO ENSURE PROPER AIRFL PRECOOL REEFERS TO 2 DEG F BELOW REQUIRED TEMP AT LEAST 2 HR BEFORE LO FOR SHIPMENTS REQUIRING REFRIGERATION-TRAILER REEFER UNIT MUST RUN CON TINUOUSLY UNLESS OTHERWISE NOTED',
          Directions: null,
          NeedsAppointment: false,
          AppointmentDate: null,
          DriverInTime: null,
          DriverOutTime: null,
          AppointmentNum: null,
          Email: '',
          StopCode: '057',
          Arrived: false,
          Lat: null,
          Long: null,
          CountryCode: 'USA',
          PracticalMileageToNextStop: null,
          ShortestMileageToNextStop: null,
          Load: null,
          LoadItems: [],
          OrderLineItems: [],
          TimeZoneOffset: 0,
          StopType: 'Dropoff',
        },
      ],
      Comments: [],
      LastInternalNote:
        'Removed the following from Carrier Instructions: CHARGE: 1653.13; SOC 100.00; FSCOTR 245.23; ',
      Warnings: null,
      Flags: [
        {
          Id: 2051671,
          LoadId: 3074975,
          OrderId: 1954750,
          SetBy: 'System Notification',
          Description:
            'Customer sent an update to this Load tender. Please confirm any updates and Remove This Flag afterwards. Contact edi@armstrongtransport.com if you want loads to automatically update.',
          EmailId: null,
          SetByUserId: 1,
          SetDate: '2023-12-22T14:35:21.4666667',
          FlagType: 'Tender Updated',
          Cleared: false,
          Editable: true,
          CommentCount: 0,
          SnoozeDate: null,
          SnoozeTimes: 0,
          DisputedOrderFlags: null,
          Commentable: false,
          Comments: [],
        },
      ],
      Claim: null,
      CanRate: true,
      Synched: false,
      EDI: false,
      EquipmentOptions: null,
      Items: [
        {
          Id: 1446713,
          LoadId: 3074975,
          NMFC: null,
          Class: null,
          Item: 'Cases',
          Description: 'P03000003',
          Quantity: 33333,
          Charge: null,
          PickUpId: 7764247,
          DropOffId: 7764246,
          Weight: 3300.0,
          UOM: 'lbs',
          Volume: null,
          Hazmat: false,
          HeightInch: null,
          LengthInch: null,
          WidthInch: null,
          Height: null,
          Length: null,
          Width: null,
          Pieces: null,
          DescriptionWithParanthesis: '(P03000003)',
          DisplayDimensions: '  x  x ',
          LoadItemHazmatDetail: null,
        },
        {
          Id: 1446714,
          LoadId: 3074975,
          NMFC: null,
          Class: null,
          Item: 'Cases',
          Description: 'P05723395540',
          Quantity: 918,
          Charge: null,
          PickUpId: 7764248,
          DropOffId: 7764246,
          Weight: 12845.0,
          UOM: 'lbs',
          Volume: null,
          Hazmat: false,
          HeightInch: null,
          LengthInch: null,
          WidthInch: null,
          Height: null,
          Length: null,
          Width: null,
          Pieces: null,
          DescriptionWithParanthesis: '(P05723395540)',
          DisplayDimensions: '  x  x ',
          LoadItemHazmatDetail: null,
        },
        {
          Id: 1446715,
          LoadId: 3074975,
          NMFC: null,
          Class: null,
          Item: 'Cases',
          Description: 'P05723390860',
          Quantity: 1018,
          Charge: null,
          PickUpId: 7764249,
          DropOffId: 7764246,
          Weight: 12458.0,
          UOM: 'lbs',
          Volume: null,
          Hazmat: false,
          HeightInch: null,
          LengthInch: null,
          WidthInch: null,
          Height: null,
          Length: null,
          Width: null,
          Pieces: null,
          DescriptionWithParanthesis: '(P05723390860)',
          DisplayDimensions: '  x  x ',
          LoadItemHazmatDetail: null,
        },
      ],
      PostingId: null,
      SignedRateCon: false,
      SignedConfirmationDate: null,
      CustomerId: 783452,
      Ramps: false,
      MacropointInterval: null,
      QuoteID: null,
      QuoteItems: null,
      QuoteStops: null,
      LtlQuote: null,
      TrackingService: null,
      LTLSubCarrier: null,
      TenderedToTransfloDate: null,
      CurrencyType: 'USD',
      CanVoidLoad: true,
      CargoValueIsHigh: null,
      LTLShipmentData: {
        LoadId: 0,
        ShipmentId: null,
        ProNumber: null,
        PickupNumber: null,
        TrackingURL: null,
        ReferenceNumbers: null,
      },
      LTLQuoteId: null,
      EnableLoadCarrierRestrictionOverride: true,
      EnableLoadCarrierRestrictionOverrideOffice: null,
      LoadCarrierRestrictionOverride: null,
      UserId: 783420,
    },
  ],
  Vendors: [],
  Comments: [
    {
      Id: 4707408,
      ObjectId: 1954750,
      UserId: 783420,
      Name: 'Shawn Saras',
      Type: 'System',
      Comment:
        'Removed the following from Carrier Instructions: CHARGE: 1653.13; SOC 100.00; FSCOTR 245.23; ',
      Timestamp: '2023-12-22T14:30:48.0733333',
      Thumbnail:
        '/api/document/ServeDocument/?url=User/783420/profile_photo_132808714679264366_thumbnail.png',
    },
  ],
  Metadata: [],
  CustomerPrePayments: [],
  CollectionActions: null,
  Warnings: [],
  Flags: [],
  InvoicedDate: null,
  PaidDate: null,
  Editable: true,
  Role: null,
  Synched: false,
  Private: false,
  HideCustomerName: false,
  Partnerships: {},
  EDI: true,
  TrackLoads: false,
  RequiredTrackingService: 4,
  TrackingVisibilityProvider: null,
  TrackingServiceName: null,
  AccountingSysId: null,
  LoadTenderId: 121327,
  BidType: 'Spot Market',
  SpotId: null,
  SpotQuote: null,
  Show214: true,
  CurrencyType: 'USD',
  DueDate: null,
  DaysPastDue: 0,
  CanVoidOrder: true,
  NeedsApprovalFlag: null,
  HoldRetraction: false,
  RevisitRetractionDate: null,
  ExceptionReason: null,
  UserAssigned: null,
};
