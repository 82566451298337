export default {
  tableCellStyle:
    'p-4 text-base font-normal font-weight-400 leading-20.02 tracking-0.17 text-black border-b border-divider',

  tableFooterCellStyle:
    'text-base font-normal font-weight-700 leading-20.02 tracking-0.17 text-black',

  tableRowSx: 'hover: & .MuiSvgIcon-root { visibility: visible; }',

  headerCellSx:
    'text-base font-normal font-weight-500 leading-24 tracking-0.17 text-black',
};
