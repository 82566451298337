import { BasicModal, Button, DialogBox } from '@components';
import { openDialogBox } from '@store/dialogBox';
import { closeModal } from '@store/modals';
import {
  deleteOrderTemplate,
  fetchOrderTemplateData,
  orderStore,
  setOrderStore,
} from '@store/orders';
import { Delete, Edit, GroupsRounded } from '@suid/icons-material';
import { Box, Grid, Stack } from '@suid/material';
import { size } from 'lodash';
import { DateTime } from 'luxon';
import { createSignal } from 'solid-js';

export const TemplateListModal = () => {
  const [orderTemplateIdToDelete, setOrderTemplateIdToDelete] =
    createSignal<number>(0);
  const [templateIdInfoLoading, setTemplateIdInfoLoading] = createSignal<
    number | null
  >(null);

  const handleOrderTemplateDelete = async () => {
    await deleteOrderTemplate(orderTemplateIdToDelete());
  };

  const handleOrderTemplateEdit = async (templateId: number) => {
    setTemplateIdInfoLoading(templateId);
    await fetchOrderTemplateData(templateId, false, () => {
      setOrderStore('isTemplateMode', true); //this is to put isTemplateMode to true to control different views
      setTemplateIdInfoLoading(null);
    });
    closeModal('orderTemplateModal');
  };

  return (
    <BasicModal
      id={'orderTemplateModal'}
      title="Manage Templates"
      width="600px"
      footer={false}
    >
      <div class="overflow-auto h-[400px]">
        {orderStore.orderTemplates.map((template, idx) => {
          return (
            <Grid container class="!p-1">
              <Grid item xs={template.editable ? 8 : 12}>
                <Stack>
                  <span class="text-[20px]">{template.name}</span>
                  <span class="text-[18px]">
                    Owner: <b>{template.owner}</b>{' '}
                    {template.public && (
                      <GroupsRounded sx={{ color: 'green' }} />
                    )}
                  </span>
                  <span>
                    Created On:{' '}
                    {DateTime.fromISO(template.createdDate).toFormat(
                      'MM/dd/yyyy',
                    )}
                  </span>
                </Stack>
              </Grid>
              {template.editable && (
                <Grid item xs={4}>
                  <Grid class="!text-right">
                    <Button
                      label="Edit"
                      size="small"
                      startIcon={<Edit />}
                      isLoading={
                        orderStore.loading &&
                        templateIdInfoLoading() === template.id
                      }
                      onClick={() => handleOrderTemplateEdit(template.id)}
                    />
                    <span
                      onClick={() => {
                        setOrderTemplateIdToDelete(template.id);
                        openDialogBox('deleteOrderTemplate');
                      }}
                    >
                      <Delete
                        sx={{ color: 'red' }}
                        class="cursor-pointer ml-2"
                      />
                    </span>
                  </Grid>
                </Grid>
              )}
              {idx !== size(orderStore.orderTemplates) - 1 && (
                <Box class="my-2 w-full border-t border-gray-300" />
              )}
            </Grid>
          );
        })}
        <DialogBox
          id="deleteOrderTemplate"
          title={`Are you sure you want to delete this template ${orderTemplateIdToDelete()}?`}
          onSubmit={() => handleOrderTemplateDelete()}
          onClose={() => setOrderTemplateIdToDelete(0)}
        />
      </div>
    </BasicModal>
  );
};
