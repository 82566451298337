import { datadogRum } from '@datadog/browser-rum';
import { ConfigManager } from '@utils/ConfigManager';
import { logError } from '@utils/errorLogging';
import { render } from 'solid-js/web';

// import { makeServer } from './mocks/server';
import App from './App';

import './components/Grid/AgGridStyles.css';
import './index.css';

datadogRum.init({
  applicationId: '1af8e89d-cf79-489d-8788-773e0a946941',
  clientToken: 'pubd8297b4db4ef7b0e588b1bf9b0f72e27',
  site: 'datadoghq.com',
  service: 'vnext-ui',
  env: ConfigManager.selectedEnv,
  version: '4.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  trackFrustrations: true,
  allowedTracingUrls: [(url) => url.startsWith(ConfigManager.apiUrl)],
});

datadogRum.startSessionReplayRecording();

// if (process.env.NODE_ENV === 'development') {
// makeServer();
// }

try {
  const root = document.getElementById('root');

  if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
    throw new Error(
      'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
    );
  }

  render(() => <App />, root!);
} catch (error) {
  logError('unknown', error as Error);
}

window.onerror = (msg, _, lineNo, columnNo, error) => {
  if (error === undefined) {
    error = new Error(msg instanceof Event ? JSON.stringify(msg) : msg);
    error.stack = `${lineNo}:${columnNo}`;
  }

  // eslint-disable-next-line no-console
  console.log(error);

  logError('uncaughtError', error);
};
