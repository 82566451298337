import { PhoneInput, TextInput } from '@components/forms';
import { UserCreateType } from '@store/office/officeDetails';
import { Alert, Box, IconButton, Stack } from '@suid/material';
import { Accessor, createSignal } from 'solid-js';
import VisibilityIcon from '@suid/icons-material/Visibility';
import VisibilityOffIcon from '@suid/icons-material/VisibilityOff';

type errorProps = {
  data: Accessor<UserCreateType>;
  errors: Accessor<{ [K in keyof UserCreateType]: string[] | null }>;
  setFields: (key: keyof UserCreateType, value: unknown) => void;
};

export const UserForm = (props: errorProps) => {
  const [showPassword, setShowPassword] = createSignal(false);
  const [showConfirmPassword, setShowConfirmPassword] = createSignal(false);

  const handleTogglePasswordVisibility = (event: {
    preventDefault: () => void;
    field: 'password' | 'confirmPassword';
  }) => {
    event.preventDefault();
    if (event.field === 'password') {
      setShowPassword(!showPassword());
    } else {
      setShowConfirmPassword(!showConfirmPassword());
    }
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Stack>
          <TextInput
            label="Email/Username"
            name="email"
            value={props.data().email}
            onChange={(value) => props.setFields('email', value)}
            error={props.errors().email}
          />
          <Alert severity="warning">
            If an existing user has a new email address, do not create a new
            account. Simply edit their old account and change the email address.
          </Alert>
        </Stack>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <TextInput
            label="First name"
            name="firstName"
            value={props.data().firstName}
            onChange={(value) => props.setFields('firstName', value)}
            error={props.errors().firstName}
          />
          <TextInput
            label="Last name"
            name="lastName"
            value={props.data().lastName}
            onChange={(value) => props.setFields('lastName', value)}
            error={props.errors().lastName}
          />
        </Stack>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <PhoneInput
            label="Phone"
            name="phoneNumber"
            value={props.data().phoneNumber}
            onChange={(value) => props.setFields('phoneNumber', value)}
            error={props.errors().phoneNumber}
          />
          <TextInput
            label="Ext"
            name="phone1Ext"
            value={props.data().phone1Ext}
            onChange={(value) => props.setFields('phone1Ext', value)}
            error={props.errors().phone1Ext}
          />
        </Stack>
      </Box>
      <Box>
        <Stack direction="row" spacing={2}>
          <PhoneInput
            label="Alt number"
            name="mobileNumber"
            value={props.data().mobileNumber}
            onChange={(value) => props.setFields('mobileNumber', value)}
            error={props.errors().mobileNumber}
          />
          <TextInput
            label="Ext"
            name="phone2Ext"
            value={props.data().phone2Ext}
            onChange={(value) => props.setFields('phone2Ext', value)}
            error={props.errors().phone2Ext}
          />
        </Stack>
      </Box>
      <Box>
        <PhoneInput
          label="Fax number"
          name="fax"
          value={props.data().fax}
          onChange={(value) => props.setFields('fax', value)}
          error={props.errors().fax}
        />
      </Box>
      <Box>
        <TextInput
          label="Password"
          name="password"
          type={showPassword() ? 'text' : 'password'}
          value={props.data().password}
          onChange={(value) => props.setFields('password', value)}
          error={props.errors().password}
          endAdornment={
            <IconButton
              onClick={(e) =>
                handleTogglePasswordVisibility({
                  preventDefault: () => e.preventDefault(),
                  field: 'password',
                })
              }
            >
              {showPassword() ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          }
        />
      </Box>
      <Box>
        <TextInput
          label="Confirm Password"
          type={showConfirmPassword() ? 'text' : 'password'}
          name="confirmPassword"
          value={props.data().confirmPassword}
          onChange={(value) => props.setFields('confirmPassword', value)}
          error={props.errors().confirmPassword}
          endAdornment={
            <IconButton
              onClick={(e) =>
                handleTogglePasswordVisibility({
                  preventDefault: () => e.preventDefault(),
                  field: 'confirmPassword',
                })
              }
            >
              {showConfirmPassword() ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          }
        />
      </Box>
    </Stack>
  );
};
