import { BasicTable, Map } from '@components';
import { carrierUrls } from '@constants/urls';
import { NearbyCarriersReturn, orderStore } from '@store/orders';
import { Grid } from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import EmailIcon from '@suid/icons-material/Email';
import PhoneIcon from '@suid/icons-material/Phone';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { Column } from '@components/BasicTable/BasicTable';

export const NearbyCarrier = () => {
  const columns: Column<NearbyCarriersReturn>[] = [
    {
      key: 'carrier',
      name: 'Carrier',
      minWidth: 250,
      renderCell: (rowData) => {
        return (
          <Grid container direction={'column'}>
            <Grid item>
              <span style={{ 'font-weight': 'bold' }}>
                {rowData.carrier}
                {'  '}
              </span>
            </Grid>
            <Grid item>
              MC: {rowData.mc} / DOT: {rowData.dot}
            </Grid>
          </Grid>
        );
      },
    },
    {
      key: 'location',
      name: 'Location',
      minWidth: 100,
      renderCell: (rowData) => {
        return <span>{rowData.location}</span>;
      },
    },
    {
      key: 'dropDate',
      name: 'Drop Date',
      renderCell: (rowData) => {
        return <span>{rowData.dropDate}</span>;
      },
      minWidth: 100,
    },
    {
      key: 'equipment',
      name: 'Equip',
      renderCell: (rowData) => {
        return <span>{rowData.equipment1}</span>;
      },
    },
    {
      key: 'truckNumber',
      name: 'Truck #',
      minWidth: 100,
      renderCell: (rowData) => {
        return <span>{rowData.truckNum}</span>;
      },
    },
    {
      key: 'trailerNumber',
      name: 'Trailer #',
      textAlign: 'left',
      minWidth: 100,
      renderCell: (rowData) => {
        return <span>{rowData.trailerNum}</span>;
      },
    },
    {
      key: 'contact',
      minWidth: 275,
      textAlign: 'left',
      name: 'Contact',
      renderCell: (rowData) => {
        return (
          <Grid container direction={'column'}>
            <Grid item>
              <span style={{ 'font-weight': 'bold' }}>
                {rowData.contact} ({rowData.contactType})
              </span>
            </Grid>
            <Grid item>
              <Grid container direction={'row'}>
                <Grid item>
                  <div style={{ color: '#026EA1' }}>
                    <PhoneIcon />
                  </div>
                </Grid>
                <Grid item>{formatPhoneNumber(rowData.phone)}</Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction={'row'} flex={1}>
                <Grid item>
                  <div style={{ color: '#026EA1' }}>
                    <EmailIcon />
                  </div>
                </Grid>
                <Grid item>{rowData.email}</Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <div style={{ width: '88.3vw' }}>
      <BasicTable<NearbyCarriersReturn>
        onRowClick={(rowData) => {
          window.open(carrierUrls.profile(rowData.carrierId), '_blank');
        }}
        columns={columns}
        rows={orderStore.nearbyCarriers.map((carrier) => ({
          carrier: carrier.carrier,
          carrierId: carrier.carrierId,
          mc: carrier.mc,
          dot: carrier.dot,
          location: `${carrier.dropCity},${carrier.dropState}`,
          dropDate: convertUtcToLocalAndFormat(
            carrier.dropDate,
            'MMM dd, yyyy',
          ),
          equipment1: carrier.equipment1,
          truckNum: carrier.truckNum,
          trailerNum: carrier.trailerNum,
          contact: carrier.contact,
          contactType: carrier.contactType,
          phone: carrier.phone,
          email: carrier.email,
        }))}
      ></BasicTable>
      <Map
        center={[0, 0]}
        height={350}
        width={'100%'}
        zoom={3}
        coordinates={orderStore.mapCords}
      />
    </div>
  );
};
