import { lazy } from 'solid-js';

const Customer = lazy(() => import('./Customer'));
const CustomerDetails = lazy(() => import('./customerDetail/CustomerDetails'));
const DormantAccounts = lazy(() => import('./dormantAccounts/DormantAccounts'));
const NewCustomer = lazy(() => import('./newCustomer'));

export const DormantAccountsRoute = {
  path: '/customer/dormant-accounts',
  component: DormantAccounts,
};

export const CustomersRoute = {
  path: '/customer',
  component: Customer,
};

export const CustomerDetailsRoute = {
  path: '/customer/details/:id',
  component: CustomerDetails,
};

export const NewCustomerRoute = {
  path: '/customer/create',
  component: NewCustomer,
};
