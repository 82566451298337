import { Box, Grid, Typography } from '@suid/material';
import { LTLQuoteTerminals } from '@store/ltl';

import TerminalDestinationCard from './components/TerminalDestinationCard';
interface ViewTerminalPopover {
  data: LTLQuoteTerminals;
}
export const ViewTerminalPopover = (props: LTLQuoteTerminals) => {
  return (
    <Box class="max-w-[600px]">
      <Typography variant="h4" class="text-center">
        Terminal Info
      </Typography>
      <Grid container spacing={2} class="p-4">
        <Grid item xs={6}>
          <Typography variant="h6">Origin</Typography>
          <TerminalDestinationCard {...props.pickupTerminal} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Destination</Typography>
          <TerminalDestinationCard {...props.deliveryTerminal} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewTerminalPopover;
