import { Paper } from '@suid/material';
import WarningIcon from '@suid/icons-material/Warning';
import { carrierStore } from '@store/orders';

export const MatchedBanner = () => {
  return (
    <Paper
      elevation={4}
      sx={{
        border: 1,
        borderColor: '#f59d25',
        borderWidth: '3px',
        marginBottom: 2,
      }}
    >
      <div class="flex items-center p-4 gap-x-4">
        <WarningIcon sx={{ color: '#f59d25' }} />
        <div class="text-sm font-medium">{`Please review the information for ${carrierStore.carrier[0]?.name}
             and click "Save" to ensure your Load is covered. Any unsaved changes will be lost if you leave this page.
            `}</div>
      </div>
    </Paper>
  );
};
