import { Box, Grid } from '@suid/material';
import { createForm } from '@felte/solid';
import { Button, ToastType } from '@components';
import { validator } from '@felte/validator-yup';
import { useParams } from '@solidjs/router';
import { createUserAction } from '@store/office/officeDetails/actions';
import { createSignal } from 'solid-js';
import { closeModal } from '@store/modals';
import { UserCreateType } from '@store/office/officeDetails';
import { handleToast } from '@utils/utils';

import validationSchema from './validations';
import { UserForm } from './UserForm';
import { RolesPermission } from './RolesPermission';

export const ModalContent = () => {
  const params = useParams();
  const [roles, setRoles] = createSignal<string[]>([]);
  const [permissions, setPermissions] = createSignal<string[]>([]);
  const { form, data, errors, setFields, reset } = createForm<UserCreateType>({
    onSubmit: async (values) => {
      const data = { ...values };
      data.officeId = params.id;
      data.name = `${data.firstName} ${data.lastName}`;
      data.userRoles = roles();
      data.permissions = permissions();
      try {
        await createUserAction(data);
        reset();
        closeModal('createNewUser');
        handleToast(ToastType.Success, 'User created Successfully');
      } catch (error) {
        handleToast(
          ToastType.Error,
          'Unable to create user, something went wrong',
        );
      }
    },
    extend: validator({ schema: validationSchema }),
  });
  return (
    <form ref={form}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <UserForm data={data} errors={errors} setFields={setFields} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RolesPermission
              selectedPermissions={permissions}
              setSelectedPermissions={setPermissions}
              setSelectedRole={setRoles}
              selectedRole={roles}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pt: 4,
        }}
      >
        <Button size="medium" type="submit" label="Create User" />
      </Box>
    </form>
  );
};
