export const quoteDetailsRowRenderClasses = {
  cardContainer: '!flex !flex-wrap justify-end',
  cardHeaderRight: 'flex flex-wrap justify-end items-center divide-x-2 gap-2',
  viewTerminalInfoLink:
    '!font-medium !pl-2 !text-[#026EA1] !text-sm !underline',
  costDetailsLink: '!font-medium !pl-2 !text-[#026EA1] !text-sm',
  costDetailsInfoIcon: '!mr-1',
  marginText: '!pl-2 !text-sm',
  marginTextContainer: '!font-medium',
  StatusTextContainer: '!font-medium flex gap-1 items-center',
  carrierLogo: ' !max-h-[120px] !max-w-[150px] !w-[125px] !object-fill',
  labelText: '!text-xs !text-[#00000099]',
  infoText: '!text-base !text-wrap !break-words ',
  infoTextBold: '!text-base !font-medium !text-wrap !break-words ',
  amountHeading: '!text-xl !w-full !font-medium !text-center',
  carrierAmountText: '!text-[#06910B] !text-base !text-center',
  amountDetailsContainer: 'flex divide-x-2 justify-center w-full gap-3',
  liabilityText: '!flex !gap-2 ',
  statusBtn:
    'w-[91px] !h-7 flex justify-center items-center text-[#FFFFFF] rounded text-xs',
  logoBox: '!flex !justify-center !w-full !flex-col',
};
export const savedLTLQuotesActionsClasses = {
  buttonBgColor: '!bg-[#248670]',
  orderBtn: '!bg-[#1B4960]',
  moreVertiIcon: 'cursor-pointer',
};
