/* eslint-disable complexity */
import { Button, Notification, ToastType } from '@components';
import { AddressCard } from '@components/Contacts/AddressCard';
import {
  CheckboxInput,
  PhoneInput,
  SelectField,
  TextInput,
} from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import { carrierStore, orderStore } from '@store/orders';
import { Box, Divider, Grid, IconButton } from '@suid/material';
import { handleToast } from '@utils/utils';
import { Accessor, createSignal, onMount, Show } from 'solid-js';
import VisibilityIcon from '@suid/icons-material/Visibility';
import VisibilityOffIcon from '@suid/icons-material/VisibilityOff';
import {
  Contact,
  getCustomerUserNameExists,
} from '@store/customers/customerDetails';
import { debounce } from 'lodash';
import AtgLoader from '@components/AtgLoader';

import { classes } from './addNewContact.style';
import { CarrierTypes, CustomerTypes } from './constant';
import { ExistingContactObj } from './type';
import { contactSchema } from './validation';
// import {
//   userHasPermission,
//   userHasRole,
// } from '../carrier/stops/stopsEditor/utilFunctions';

export type Props = {
  modalId: string;
  editObject?: Accessor<Contact | null>;
  handleClose?: () => void;
  initiatePath:
    | 'CarrierDetails'
    | 'CustomerDetails'
    | 'OrderDetails'
    | 'FactoringCompanyDetails';
  onSubmit: (
    values: Record<string, string | number | boolean | null>,
  ) => Promise<void> | void;
};

type ContactFormValues = {
  id?: number;
  name: string;
  type: string;
  phone1: string;
  phoneVerified: boolean;
  extension1: string | null;
  phone2: string;
  extension2: string | null;
  fax: string | null;
  email: string;
  emailVerified: boolean;
  cpn: string;
  createdDate: string;
  uniqueId: number | null;
  objectType: string;
  verifiedDisabled: boolean;
  verificationStatus: number;
  userName: string | null;
  password: string | null;
  phone?: string;
  mailerEmails?: boolean;
  operationType?: string;
};

function AddNewContact(props: Props) {
  const [showPassword, setShowPassword] = createSignal(false);
  const [isUserNameLoading, setIsUserNameLoading] =
    createSignal<boolean>(false);
  const contact = props.editObject ? props.editObject() : undefined;

  const { form, errors, data, isSubmitting, setIsSubmitting, setData } =
    createForm<ContactFormValues>({
      initialValues: {
        name: '',
        type: '',
        phone1: '',
        phoneVerified: false,
        extension1: null,
        phone2: '',
        extension2: null,
        fax: null,
        email: '',
        emailVerified: false,
        cpn:
          carrierStore.carrier[orderStore.activeTab.index]
            ?.corporatePhoneNumber || '',
        createdDate: new Date().toISOString(),
        uniqueId: -1,
        objectType: '',
        verifiedDisabled: false,
        verificationStatus: 0,
        userName: null,
        password: null,
        mailerEmails: false,
      },
      extend: validator({ schema: contactSchema }),
      onSubmit: async (values) => {
        await handleFormSubmit(values);
      },
    });

  onMount(() => {
    if (Boolean(contact)) {
      setData('name', contact?.name ?? '');
      setData('type', contact?.type ?? '');
      setData('phone1', contact?.phone1 ?? '');
      setData('extension1', contact?.extension1 ?? null);
      setData('phone2', contact?.phone2 ?? '');
      setData('extension2', contact?.extension2 ?? null);
      setData('email', contact?.email ?? '');
      setData('emailVerified', contact?.emailVerified ?? false);
      setData('fax', contact?.fax ?? null);
      setData('userName', contact?.userName ?? null);
      setData('password', contact?.password ?? null);
      setData('phoneVerified', contact?.phoneVerified ?? false);
      setData('createdDate', contact?.createdDate ?? '');
      setData('uniqueId', contact?.uniqueId ?? -1);
      setData('objectType', contact?.objectType ?? '');
      setData('verifiedDisabled', contact?.verifiedDisabled ?? false);
      setData('verificationStatus', contact?.verificationStatus ?? 0);
      setData('id', contact?.id ?? undefined);
      setData('mailerEmails', contact?.mailerEmails ?? false);
      setData('operationType', contact?.operationType ?? 'None');
    }
  });
  const handleFormSubmit = async (values: ContactFormValues) => {
    const payload = { ...values };

    const removeProperties = (propsToRemove: string[]) => {
      propsToRemove.forEach((prop) => delete payload[prop]);
    };

    switch (props.initiatePath) {
      case 'OrderDetails':
        removeProperties([
          'uniqueId',
          'createdDate',
          'objectType',
          'verifiedDisabled',
          'verificationStatus',
          'phone',
          'mailerEmails',
        ]);
        payload.objectId = carrierStore.carrier[orderStore.activeTab.index].id;
        break;
      case 'CustomerDetails':
        removeProperties([
          'cpn',
          'phoneVerified',
          'emailVerified',
          'verifiedDisabled',
          'verificationStatus',
          'createdDate',
        ]);
        payload.phone = values.phone1;
        payload.objectType = 'Customer';
        break;
      case 'FactoringCompanyDetails':
        removeProperties([
          'cpn',
          'phoneVerified',
          'emailVerified',
          'verifiedDisabled',
          'verificationStatus',
          'createdDate',
          'uniqueId',
          'mailerEmails',
        ]);
        payload.phone = values.phone1;
        payload.objectType = 'Customer';
        break;
      case 'CarrierDetails':
        removeProperties(['cpn', 'userName', 'password', 'objectId', 'phone']);
        payload.verificationStatus =
          values.phoneVerified || values.emailVerified ? 1 : 0;
        payload.objectType = 'Carrier';
        break;
      default:
        break;
    }

    try {
      setIsSubmitting(true);
      await props.onSubmit(payload);
      closeModal(props.modalId);
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to Add New Contact');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getAddressCard = (contact: ExistingContactObj) => {
    return (
      <Grid item xs={12} class={classes.addressCard}>
        <AddressCard
          contact={contact}
          type={contact.type}
          mode={contact.type === 'Billing' ? 'warning' : 'info'}
          showType={true}
          iconColor={'#2F6C9D'}
        />
      </Grid>
    );
  };

  // const userCanMarkVerified = () => {
  //   return (
  //     userHasPermission('Edit Carrier Contacts') &&
  //     userHasRole('Carrier Relations')
  //   );
  // };

  const onCancelBtnTap = () => {
    closeModal(props.modalId);
    props.handleClose && props.handleClose();
  };

  const gridVal = props.initiatePath === 'OrderDetails' ? 6 : 12;

  const handleLoginName = debounce(async (value: string) => {
    const payload = {
      username: value,
      customerId: 0,
      customercontactId: 0,
    };
    setIsUserNameLoading(true);
    const res = await getCustomerUserNameExists(payload);
    if (Boolean(res.data)) {
      handleToast(
        ToastType.Error,
        `${value} already exists in the system as a username. Please choose another one.`,
      );
    }
    setIsUserNameLoading(false);
  }, 300);

  const customerOrCarrierTypeMenuItems = () => {
    if (props.initiatePath === 'CustomerDetails') {
      if (contact && (Boolean(contact.userName) || Boolean(contact.password))) {
        return CustomerTypes.filter(
          (type) => type === 'Billing' || type === 'Freight',
        );
      }
      return CustomerTypes;
    }
    if (props.initiatePath === 'FactoringCompanyDetails') {
      return ['Billing', 'Payments', 'Remittance', 'Other'];
    }
    return CarrierTypes;
  };

  const handleTogglePasswordVisibility = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    setShowPassword(!showPassword());
  };

  return (
    <form ref={form} class={classes.rowFormGrid}>
      <Box sx={{ flexGrow: 1 }}>
        <Show when={isUserNameLoading()}>
          <Box class="absolute top-[50%] left-[50%] z-50">
            <AtgLoader />
          </Box>
        </Show>
        <Grid container item xs={12} spacing={2} class="!mb-6">
          <Grid
            item
            xs={12}
            lg={props.initiatePath === 'OrderDetails' ? 6 : 12}
          >
            <Notification
              type="info"
              text="Please add/edit contact information below. You can assign multiple billing or freight contacts."
            />
          </Grid>
          {props.initiatePath === 'OrderDetails' && (
            <Grid item xs={12} lg={6}>
              <Notification type="info" text="Existing contacts" />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} px={1}>
          <Grid item xs={12} lg={gridVal}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  label="Name"
                  error={errors().name}
                  required
                  value={data().name}
                  onChange={(e) => setData('name', e as string)}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectField
                  label="Type"
                  placeholder="Select Type"
                  menuItems={customerOrCarrierTypeMenuItems()}
                  value={data().type}
                  onChange={(e) => setData('type', e.target.value)}
                  error={errors().type}
                />
              </Grid>
              <Grid item xs={12} lg={9}>
                <PhoneInput
                  label="Phone Number"
                  value={data().phone1}
                  onChange={(e) => setData('phone1', e)}
                  error={errors().phone1}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <TextInput
                  label="Ext1."
                  type="number"
                  value={data().extension1 ?? ''}
                  onChange={(e) => setData('extension1', e as string)}
                  error={errors().extension1}
                  maxLength={7}
                />
              </Grid>
              {/* {props.initiatePath !== 'CustomerDetails' &&
                props.initiatePath !== 'FactoringCompanyDetails' && (
                  <Grid item xs={6} lg={3}>
                    <CheckboxInput
                      label="Verified?"
                      name="phoneVerified"
                      checked={data().phoneVerified}
                      onChange={(e) => setData('phoneVerified', e)}
                      disabled={!userCanMarkVerified()}
                    />
                  </Grid>
                )} */}
              <Grid item xs={9} lg={9}>
                <PhoneInput
                  label="Alt"
                  value={data().phone2}
                  onChange={(e) => setData('phone2', e)}
                  error={errors().phone2}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label="Ext2."
                  type="number"
                  error={errors().extension2 ?? ''}
                  value={data().extension2}
                  onChange={(e) => setData('extension2', e as string)}
                  maxLength={7}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Email"
                  type="email"
                  value={data().email}
                  onChange={(val: string) => {
                    if (!val) {
                      setData('mailerEmails', false);
                    }
                    setData('email', val);
                  }}
                  error={errors().email}
                />
              </Grid>
              {/* {props.initiatePath !== 'CustomerDetails' &&
                props.initiatePath !== 'FactoringCompanyDetails' && (
                  <Grid item xs={3}>
                    <CheckboxInput
                      label="Verified?"
                      checked={data().emailVerified}
                      onChange={(e) => setData('emailVerified', e)}
                      disabled={!userCanMarkVerified()}
                    />
                  </Grid>
                )} */}
              <Grid item xs={12}>
                <PhoneInput
                  label="Fax"
                  value={data().fax ?? ''}
                  onChange={(e) => setData('fax', e)}
                  error={errors().fax}
                />
              </Grid>
              {['CarrierDetails', 'OrderDetails'].includes(
                props.initiatePath,
              ) && (
                <Show when={data().email}>
                  <Grid item xs={12}>
                    <CheckboxInput
                      label="Receive Mailer Emails"
                      checked={data().mailerEmails}
                      onChange={(e) => setData('mailerEmails', e)}
                    />
                  </Grid>
                </Show>
              )}
              {['CustomerDetails', 'FactoringCompanyDetails'].includes(
                props.initiatePath,
              ) &&
                ['Billing', 'Freight'].includes(data().type) && (
                  <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        label="Login Name"
                        value={data().userName ?? ''}
                        onChange={async (value) => {
                          setData('userName', value as string);
                          await handleLoginName(value as string);
                        }}
                        error={errors().userName}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInput
                        type={showPassword() ? 'text' : 'password'}
                        label="Password"
                        value={data().password ?? ''}
                        onChange={(e) => setData('password', e as string)}
                        endAdornment={
                          <IconButton onClick={handleTogglePasswordVisibility}>
                            {showPassword() ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        }
                        error={errors().password}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid item container spacing={2}>
              {props.initiatePath === 'OrderDetails' && (
                <Grid item xs={12}>
                  <TextInput
                    label="Corporate Phone Number1"
                    disabled
                    value={data().cpn}
                    onChange={(e) => setData('cpn', e as string)}
                  />
                </Grid>
              )}
              <Grid xs={12} item>
                <Grid
                  item
                  spacing={1}
                  container
                  maxHeight={'450px'}
                  overflow="auto"
                >
                  {carrierStore.carrier[orderStore.activeTab.index]?.contacts
                    .length &&
                    carrierStore.carrier[
                      orderStore.activeTab.index
                    ]?.contacts.map((contact) => getAddressCard(contact))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            gap={2}
            displayRaw="flex"
            alignItems="center"
            justifyContent="end"
            class={classes.buttonFooterGrid}
          >
            <Button
              label="Save"
              isLoading={isSubmitting()}
              disabled={isSubmitting()}
              type="submit"
              variant="contained"
            />
            <Button
              label="Cancel"
              isLoading={isSubmitting()}
              disabled={isSubmitting()}
              variant="text"
              onClick={onCancelBtnTap}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default AddNewContact;
