import { Card, Notification, Typography } from '@components';
import { TextInput } from '@components/forms';
import {
  factoringCompanyDetailsStore,
  updateFactoringCompanyDetailsState,
} from '@store/factoringCompany/factoringCompanyDetails';
import { Box, Grid, Stack } from '@suid/material';
import { Component } from 'solid-js';

export const FactoringCompanyPortalLoginCard: Component = () => {
  return (
    <Card
      startTitle="Factoring Company Portal Login"
      loading={factoringCompanyDetailsStore.isLoading}
    >
      <Stack spacing={2}>
        <Notification
          type="info"
          text={
            <Typography variant="body2" component="p">
              "Factoring companies can log in to view their assigned carriers
              and their loads by navigating to{' '}
              <a
                href="https://www.atgfr8.com/FactoringCompany/Portal"
                target="_blank"
                class="text-[#026EA1]"
              >
                atgfr8.com/FactoringCompany/Portal
              </a>{' '}
              with these credentials."
            </Typography>
          }
        />
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Grid item container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Username"
                value={
                  factoringCompanyDetailsStore.factoringCompanyDetails
                    .userName ?? ''
                }
                onChange={(val) => {
                  updateFactoringCompanyDetailsState({
                    userName: val as string,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextInput
                label="Password"
                value={
                  factoringCompanyDetailsStore.factoringCompanyDetails
                    .password ?? ''
                }
                onChange={(val) => {
                  updateFactoringCompanyDetailsState({
                    password: val as string,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Card>
  );
};
