import { getQuotes, getSavedQuotes } from '@store/ltl/services';
import { createStore, produce } from 'solid-js/store';

import {
  AccessorialState,
  LTLLoadItem,
  LTLQuote,
  LTLQuoteState,
  LTLQuotingResultState,
  LTLRateQuoteRequestState,
  SavedQuotesState,
} from './types';
import { LTLRateQuoteRequest, LTLRateQuoteResponse } from './v3types';

const [savedQuoteStore, setSavedQuoteStore] = createStore<SavedQuotesState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  items: [],
  overlay: false,
});

const [accessorialStore, setAccessorialStore] = createStore<AccessorialState>({
  isLoading: true,
  isError: false,
  errorMessage: '',
  items: [],
  overlay: false,
});
const [ltlQuotingResultStore, setLTLQuotingResultStore] =
  createStore<LTLQuotingResultState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    items: [],
    overlay: false,
  });
const [ltlRateQuoteRequest, setLTLRateQuoteRequest] =
  createStore<LTLRateQuoteRequestState>({
    isLoading: true,
    isError: false,
    errorMessage: '',
    items: {} as LTLRateQuoteRequest,
    overlay: false,
  });

const [ltlQuoteState, setLtlQuoteState] = createStore<LTLQuoteState>({
  isLoading: false,
  isError: false,
  errorMessage: '',
  quote: undefined,
  overlay: false,
  falveyPricingQuote: undefined,
  declaredValue: undefined,
  isFalveyInsuranceFlow: false,
  isShortQuoteEnabled: false,
});

// @ts-expect-error stuff
window.ltlQuote = { ltlQuoteState, setLtlQuoteState };

export const setLTLQuote = (ltlQuote: LTLQuote) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      draft.quote = ltlQuote;
    }),
  );
};

export const setCustomerInfo = (customerId: number, customerName: string) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        draft.quote.customerId = customerId;
        draft.quote.customerName = customerName;
      }
    }),
  );
};

export const setOriginOrDestinationInfo = (info: {
  type: 'Origin' | 'Destination';
  city?: string | null;
  state?: string | null;
  zip: string;
  country?: string | null;
}) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        if (info.type === 'Origin') {
          draft.quote.originCity = info.city;
          draft.quote.originState = info.state;
          draft.quote.originZip = info.zip;
          draft.quote.originCountry = info.country;
        } else {
          draft.quote.destinationCity = info.city;
          draft.quote.destinationState = info.state;
          draft.quote.destinationZip = info.zip;
          draft.quote.destinationCountry = info.country;
        }
      }
    }),
  );
};

export const setLoadItems = (items: LTLLoadItem[]) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        draft.quote.loadItems = items;
      }
    }),
  );
};

export const setPickupDate = (date: Date | string) => {
  setLtlQuoteState(
    produce((draft: LTLQuoteState) => {
      if (draft.quote) {
        draft.quote.pickupDate = date;
      }
    }),
  );
};
export const fetchSavedQuotes = async () => {
  try {
    setSavedQuoteStore('isLoading', true);
    const resp = await getSavedQuotes();
    setSavedQuoteStore(
      produce((draft: SavedQuotesState) => {
        draft.items = resp;
        draft.isLoading = false;
      }),
    );
  } catch (e) {
    setSavedQuoteStore(
      produce((draft: SavedQuotesState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading saved quotes';
        draft.isLoading = false;
      }),
    );
  } finally {
    setSavedQuoteStore('isLoading', false);
  }
};

export const fetchQuotes = async (request: LTLRateQuoteRequest) => {
  try {
    setLTLQuotingResultStore('isLoading', true);
    const resp = await getQuotes(request);
    if (Boolean(resp)) {
      setLTLQuotingResultStore(
        produce((draft: LTLQuotingResultState) => {
          draft.items = resp as LTLRateQuoteResponse[];
          draft.customerId = request.customerId;
          draft.isLoading = false;
          draft.isError = false;
        }),
      );
      setLTLRateQuoteRequest(
        produce((draft: LTLRateQuoteRequestState) => {
          draft.items = request;
          draft.isLoading = false;
        }),
      );
    } else {
      setLTLQuotingResultStore(
        produce((draft: LTLQuotingResultState) => {
          draft.isLoading = false;
          draft.items = [];
          draft.isError = true;
        }),
      );
    }
  } catch (e) {
    setLTLQuotingResultStore(
      produce((draft: LTLQuotingResultState) => {
        draft.isError = true;
        draft.errorMessage = 'Failed loading quotes';
        draft.isLoading = false;
      }),
    );
  }
};
export const clearLtlQuoteResults = () => {
  setLTLQuotingResultStore(
    produce((draft: LTLQuotingResultState) => {
      draft.items = [];
    }),
  );
};

export {
  accessorialStore,
  ltlQuoteState,
  ltlQuotingResultStore,
  ltlRateQuoteRequest,
  savedQuoteStore,
  setAccessorialStore,
  setLtlQuoteState,
  setLTLQuotingResultStore,
  setLTLRateQuoteRequest,
  setSavedQuoteStore,
};
