import { Card } from '@components';
import { SelectField, TextInput } from '@components/forms';
import {
  factoringCompanyDetailsStore,
  updateFactoringCompanyDetailsState,
} from '@store/factoringCompany/factoringCompanyDetails';
import { Grid } from '@suid/material';
import { Component } from 'solid-js';
import {
  FactoringCompanyBillingTerms,
  FactoringCompanyStatuses,
} from '@store/factoringCompany/factoringCompanyDetails/constants';

import { FactoryCompanyDetailsAddress } from './FactoryCompanyDetailsAddress';
import { validateFieldForFactoring } from './utils';

export const FactoringCompanyDetailsCard: Component = () => {
  return (
    <Card
      startTitle="Factoring Company Details"
      loading={factoringCompanyDetailsStore.isLoading}
    >
      <Grid item container spacing={2}>
        <Grid item xs={12} lg={8}>
          <TextInput
            label="Name"
            value={
              factoringCompanyDetailsStore.factoringCompanyDetails.name || ''
            }
            onChange={async (val: string) => {
              updateFactoringCompanyDetailsState({
                name: val,
              });
              await validateFieldForFactoring('name', 'name');
            }}
            error={
              factoringCompanyDetailsStore.factoringCompanyDetailsErrors?.name
            }
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SelectField
            label="Status"
            value={factoringCompanyDetailsStore.factoringCompanyDetails.status}
            menuItems={FactoringCompanyStatuses}
            onChange={(e) => {
              updateFactoringCompanyDetailsState({
                status: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="Payment Notification"
            value={
              factoringCompanyDetailsStore.factoringCompanyDetails.remitTo || ''
            }
            onChange={async (val: string) => {
              updateFactoringCompanyDetailsState({
                remitTo: val,
              });
              await validateFieldForFactoring('remitTo', 'remitTo');
            }}
            error={
              factoringCompanyDetailsStore.factoringCompanyDetailsErrors
                ?.remitTo
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectField
            label="Billing Terms"
            value={
              factoringCompanyDetailsStore.factoringCompanyDetails
                .billingTerms || ''
            }
            menuItems={FactoringCompanyBillingTerms}
            onChange={(e) => {
              updateFactoringCompanyDetailsState({
                billingTerms: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="ACH Account #"
            value={
              factoringCompanyDetailsStore.factoringCompanyDetails.achAccount ??
              ''
            }
            onChange={(val) => {
              updateFactoringCompanyDetailsState({
                achAccount: val as string,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextInput
            label="ACH Routing #"
            value={
              factoringCompanyDetailsStore.factoringCompanyDetails.achRouting ??
              ''
            }
            maxLength={9}
            onChange={(val) => {
              updateFactoringCompanyDetailsState({
                achRouting: val as string,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FactoryCompanyDetailsAddress addressType="physical" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FactoryCompanyDetailsAddress addressType="mailing" />
        </Grid>
      </Grid>
    </Card>
  );
};
