import { createStore } from 'solid-js/store';

import { CarrierLaneSearchState } from './types';

const [laneSearchStore, setLaneSearchStore] =
  createStore<CarrierLaneSearchState>({
    isLoading: false,
    isError: false,
    errorMessage: '',
    laneSearch: [],
    overlay: false,
  });

export { laneSearchStore, setLaneSearchStore };
