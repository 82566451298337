import { ConfigManager } from '@utils/ConfigManager';

export const domainUrl = ConfigManager.domainUrl;

export const orderUrls = {
  orderSheet: (id: number) =>
    `${domainUrl}Document/OrderSummary/${id}?controls=true`,
  customerConfirmation: (id: number) =>
    `${domainUrl}Document/CustomerRateConfirmation/${id}?controls=true`,
  invoice: (id: number) => `${domainUrl}Document/Invoice/${id}?controls=true`,
  profile: (id: number) => `${domainUrl}Customer/Details/${id}`,
  carrierDetails: (id: number) => `${domainUrl}/Carrier/Details/${id}`,
  editHistory: (id: number) => `${domainUrl}Order/EditHistory/${id}`,
  officeDetails: (id: number) => `${domainUrl}/Office/Details/${id}`,
  orderDetails: (id: number) => `${domainUrl}/Order/Details/${id}`,
  claim: (id: number) => `${domainUrl}/Claim/${id}`,
};

export const carrierUrls = {
  rateConfirmation: (id: number) =>
    `${domainUrl}Document/CarrierRateConfirmation/${id}?controls=true`,
  billOfLading: (id: number) =>
    `${domainUrl}Document/BillOfLading/${id}?controls=true`,
  profile: (id: number) => `${domainUrl}Carrier/Details/${id}`,
  claim: (id: number) => `${domainUrl}Claim/${id}`,
  playLists: () => `${domainUrl}Carrier/Playlists`,
  balanceReport: (id: number) =>
    `${domainUrl}Document/CarrierOpenBalances/${id}?controls=true`,
};
export const ltlUrls = {
  quoteInfoPDF: (id: string) => {
    return `${domainUrl}/api/SystemDocument/LTLSavedQuotesDocument/${id}?format=pdf`;
  },
};
export const equipmentSearchUrl = `${domainUrl}carrier/equipmentsearch`;

export const customerUrls = {
  report: (id: number) => {
    return `${domainUrl}/Document/CustomerOpenBalances/${id}?controls=true`;
  },
};

export const customerUrl = {
  customerOpenInvoices: (id: number) =>
    `${domainUrl}/Document/CustomerOpenBalances/${id}?controls=true&invoicedOnly=true`,
  customerPastDueInvoices: (id: number) =>
    `${domainUrl}/Document/PastDueInvoices/${id}?controls=true`,
  customerOutstandingBalances: (id: number) =>
    `${domainUrl}/Document/CustomerOpenBalances/${id}?controls=true`,
  productCatalogSampleCsvDownloadLink: () =>
    `${domainUrl}/api/document/servedocument/?url=Global%20Docs/LTL%20Catalog%20Import%20Template.csv`,
  customerQuoteCsvDownloadLink: () =>
    `${domainUrl}/api/document/servedocument/?url=Global%20Docs/Quote_Import_Template.csv`,
  customerDetails: (id: number) => `${domainUrl}/Customer/Details/${id}`,
  customerSystemDocuments: (invoiceUrl: string) => `${domainUrl}/${invoiceUrl}`,
};

export const factoringCompanyUrls = {
  profile: (id: number) => `${domainUrl}/FactoringCompany/Details/${id}`,
};
