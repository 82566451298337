import {
  EFSIssued,
  Paperwork,
  Refund,
  blueFlag,
  greenFlag,
  hotLoad,
  missingPaperwork,
  needApproval,
  orangeFlag,
  redFlag,
  yellowFlag,
  BillingHold,
  BillingNote,
  tenderUpload,
} from '@assets';
1;

import { SpecialFlagTypeEnum } from './types';

export const flagImgs: { [key: string]: string } = {
  'Red Flag': redFlag,
  'Green Flag': greenFlag,
  'Blue Flag': blueFlag,
  'Yellow Flag': yellowFlag,
  'Orange Flag': orangeFlag,
  'Needs Approval': needApproval,
  'Get Paperwork': Paperwork,
  'Refund Request': Refund,
  'EFS Issued': EFSIssued,
  'Missing Paperwork': missingPaperwork,
  'Hot Load': hotLoad,
  'Billing Hold': BillingHold,
  'Billing Note': BillingNote,
  'Tender Updated': tenderUpload,
  'Paper work': Paperwork,
};

export const allOptions = [
  { value: 'Red Flag', label: 'Red Flag' },
  { value: 'Green Flag', label: 'Green Flag' },
  { value: 'Blue Flag', label: 'Blue Flag' },
  { value: 'Yellow Flag', label: 'Yellow Flag' },
  { value: 'Orange Flag', label: 'Orange Flag' },
  { value: 'Hot Load', label: 'Hot Load' },
  { value: 'Needs Approval', label: 'Needs Approval' },
  { value: 'Get Paperwork', label: 'Get Paperwork' },
  { value: 'Refund Request', label: 'Refund Request' },
  { value: 'EFS Issued', label: 'EFS Issued' },
  { value: 'Missing Paperwork', label: 'Missing Paperwork' },
];

const genericMessages =
  'Generic Flag. Will appear pinned to the top of your loadboard';

export const infoMsgObj: { [key: string]: string } = {
  'Red Flag': genericMessages,
  'Green Flag': genericMessages,
  'Blue Flag': genericMessages,
  'Yellow Flag': genericMessages,
  'Orange Flag': genericMessages,
  'Needs Approval':
    'Load needs your attention or approval before it can be processed. <b>Please make any and all necessary edits to the load. Please do not simply leave instructions.</b> <span style="color:red"><u>If you need additional time, you MUST enter a comment to unlock your loadboard</u>.</span>',
  'Get Paperwork': 'Added to AP Calling Queue to retrieve missing paperwork',
  'Refund Request': 'The carrier requested a refund.',
  'EFS Issued': 'We paid carrier an EFS.',
  'Missing Paperwork': 'Add to AR Calling Queue.',
  'Hot Load': 'This load is important. All hands on deck.',
  'Tender Updated': '',
};

export const getOptionsByRole = (userRole: string) => {
  if (userRole === 'admin') {
    return allOptions;
  } else if (userRole === 'agent') {
    return allOptions.filter((option) =>
      [
        'Red Flag',
        'Green Flag',
        'Blue Flag',
        'Yellow Flag',
        'Orange Flag',
        'Hot Load',
      ].includes(option.value),
    );
  }
  // Handle other roles or return a default set of options
  return [];
};

export const flagOptionList: { [key: string]: string } = {
  'Red Flag': 'Red Flag',
  'Green Flag': 'Green Flag',
  'Blue Flag': 'Blue Flag',
  'Yellow Flag': 'Yellow Flag',
  'Orange Flag': 'Orange Flag',
  'Needs Approval': 'Needs Approval',
  'Get Paperwork': 'Get Paperwork',
  'Refund Request': 'Refund Request',
  'EFS Issued': 'EFS Issued',
  'Missing Paperwork': 'Missing Paperwork',
  'Hot Load': 'Hot Load',
  'Billing Hold': 'Billing Hold',
  'Billing Note': 'Billing Note',
  'Tender Updated': 'Tender Updated',
};

export const softDeleteFlagTypes = {
  [SpecialFlagTypeEnum.Uploaded]: true,
  [SpecialFlagTypeEnum.GetPaperwork]: true,
  [SpecialFlagTypeEnum.NeedsApproval]: true,
  [SpecialFlagTypeEnum.EFSIssued]: true,
  [SpecialFlagTypeEnum.QueueRefund]: true,
  [SpecialFlagTypeEnum.MissingPaperwork]: false,
  '': false,
};

export const flagTypesWithMessaging = {
  ...softDeleteFlagTypes,
  [SpecialFlagTypeEnum.MissingPaperwork]: true,
};
