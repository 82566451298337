export default {
  totalLabel: '!text-[#171b1A]  !rounded-sm !overflow-hidden text-end',
  totalAmount: '!bg-[#0000000F]  !text-[#171b1A] px-3 rounded-sm py-2',
  totalAmountRegularFont:
    '!bg-[#0000000F] !text-[#171b1A] px-3 rounded-sm py-2',
  addIcon: 'text-[#0070A2] cursor-pointer',
  deleteIcon: 'text-[#B00020] cursor-pointer',
  tableBg: 'bg-[#BFDFD8]',
  emptyTable: 'w-full h-64 bg-[#BFDFD8]',
  tableRow:
    ' border-t-2 border-[#a8c5bf] !bg-[#BFDFD8] [&:nth-child(1)]:!border-none ',
  tableFooter:
    'text-base font-normal font-weight-700 leading-20.02 tracking-0.17 text-black',
  tableFooterRow: '!border-t-2    !border-[#a8c5bf] !bg-[#BFDFD8]',
  dollarIcon:
    'bg-[#026EA1] text-white !text-4xl p-[2px] !rounded-md cursor-pointer',
  dollarIconApproved:
    'bg-[#248670] text-white !text-4xl p-[2px] !rounded-md cursor-pointer',
  dollarIconRequested:
    'bg-[#AC6E1A] text-white !text-4xl p-[2px] !rounded-md cursor-pointer',
  carrierOptions: 'cursor-pointer hover:bg-gray-100 w-48 h-18',
  editLineItems:
    'text-[#026EA1] font-medium text-sm underline leading-5 cursor-pointer',
  tabChildBox:
    'flex flex-col flex-1 items-center min-w-[120px] w-max [overflow-wrap:anywhere] text-center',
  errorChildBox: 'flex flex-col items-center',
  colorRed: 'text-red-500',
  voidOrderTab: 'bg-red-600 bg-opacity-30',
  errorRow: '!pt-0',
};
