﻿import { BasicModal } from '@components';
import { clearLtlQuoteResults } from '@store/ltl/actions';
import { closeModal } from '@store/modals';
import { LTL } from '@views/ltl/LTL';
type PropsT = {
  id?: string;
};

export const LtlQuotingModal = (props: PropsT) => {
  let suffix = '';

  if (props.id !== undefined) {
    suffix = `-${props.id}`;
  }

  const modalId = `ltl-quoting-modal${suffix}`;

  return (
    <BasicModal
      id={modalId}
      width={'80vw'}
      height={'280vh'}
      showClose={true}
      footer={false}
      header={false}
      mainContainerStyles={{ padding: '0px', overflow: 'hidden' }}
      onClose={() => {
        closeModal(modalId);
        clearLtlQuoteResults();
      }}
    >
      <LTL isModal={true} />
    </BasicModal>
  );
};
