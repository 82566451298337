import { Notification } from '@components/Notification';
import { List, ListItem, Stack, TableCell, TableRow } from '@suid/material';
import { Component, Show, mergeProps } from 'solid-js';

import classes from './classes';

export type TableErrorRowProps = {
  columnsLength: number;
  tableErrors: string[];
  tableRowClasses?: string;
  type?: 'tableError' | 'warning';
};

export const TableRowErrors: Component<TableErrorRowProps> = (
  props: TableErrorRowProps,
) => {
  const mp = mergeProps(
    {
      type: 'tableError',
    },
    props,
  );

  return (
    <Show when={props.tableErrors.length > 0}>
      <TableRow class={`${classes.tableRow} ${props.tableRowClasses}`}>
        <TableCell colspan={props.columnsLength + 1} class={classes.errorRow}>
          <Notification
            type={mp.type}
            tableRowNotification
            text={
              <Stack spacing={0.5}>
                <List disablePadding class={classes.errorListContainer}>
                  {props.tableErrors.map((error) => (
                    <ListItem
                      disablePadding
                      class={classes.errorListLine}
                    >{`${error}`}</ListItem>
                  ))}
                </List>
              </Stack>
            }
          />
        </TableCell>
      </TableRow>
    </Show>
  );
};
