import { apiClient } from '@api/apiClient';
import { ToastType } from '@components';
import { Playlist } from '@store/orders';
import { handleToast } from '@utils/utils';
import { V4ApiResponse } from '@store/customers/customerDetails';

import { Group } from './types';
import { playlistsStore, setPlaylistsStore } from './store';

export async function savePlaylistService(
  playlist: Playlist,
  disableToast = false,
) {
  try {
    let response: V4ApiResponse<Playlist>;
    if (playlist.id === 0) {
      response = await apiClient.post('/playlist', playlist);
    } else {
      response = await apiClient.put('/playlist', {
        ...playlist,
        operationType: 'Update',
      });
    }

    if (Boolean(response.value)) {
      const playlists = [...playlistsStore.playlists];
      const idx = playlists.findIndex((itm) => itm.id === playlist.id);
      playlists[idx] = { ...response.value };
      setPlaylistsStore('playlists', playlists);
    }

    if (response.isSuccess && !disableToast) {
      handleToast(ToastType.Success, 'Playlist saved successfully');
      return true;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'There was a issue saving the playlist');
    return false;
  }
}

export async function deletePlaylistService(playlistId: number) {
  try {
    const res: V4ApiResponse<null> = await apiClient.delete(
      `/playlist/${playlistId}`,
    );

    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'Playlist deleted successfully');
      return true;
    }
    return false;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'There was a issue deleting the playlist');
    return false;
  }
}

export async function fetchCarrierOfficeGroupsCall(officeId: number) {
  try {
    const response: V4ApiResponse<Group[]> = await apiClient.get(
      `Office/groupdetailbyId/${officeId}`,
    );
    return response.value;
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'There was a issue fetching office groups.');
    throw new Error(JSON.stringify(error));
  }
}
