import { produce } from 'solid-js/store';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import {
  deleteAddressBook,
  editAddress,
  fetchAddressBookById,
  fetchAddressBookData,
  postAddress,
} from './services';
import { setAddressBookStore } from './store';
import { AddressBook, AddressBookState } from './types';

export const getAddressBookData = async (customerId: string) => {
  try {
    setAddressBookStore('isLoading', true);
    const resp = await fetchAddressBookData(customerId);
    setAddressBookStore(
      produce((draft: AddressBookState) => {
        draft.isLoading = false;
        draft.items = resp;
        draft.isError = false;
        draft.errorMessage = '';
      }),
    );
  } catch (error) {
    setAddressBookStore(
      produce((draft: AddressBookState) => {
        draft.isError = true;
        draft.items = [];
        draft.isLoading = false;
      }),
    );
  }
};

export const addNewAddress = async (payload: AddressBook) => {
  const { cb, ...restPayload } = payload;
  const res = await postAddress(restPayload);
  if (Boolean(res.success)) {
    let updatedItems = [];
    setAddressBookStore('items', (items) => {
      if (res.data.isDefaultAddress === true) {
        updatedItems = items.map((itm) => {
          return { ...itm, isDefaultAddress: false };
        });
        updatedItems = [...updatedItems, res.data];
      } else {
        updatedItems = [...items, res.data];
      }
      updatedItems.sort((a, b) => a.name.localeCompare(b.name));
      return updatedItems;
    });
    cb && cb('Address Successfully saved!');
  } else {
    setAddressBookStore('addOrEditErrorMessage', res.message.join(', '));
    handleToast(ToastType.Error, 'Address Failed to save!');
  }
  return res;
};

export const editAddressBook = async (payload: AddressBook) => {
  const { cb, ...restPayload } = payload;
  const updatedRes = await editAddress(restPayload);

  if (updatedRes.success) {
    const isDefaultUpdated = updatedRes.data.isDefaultAddress === true;
    setAddressBookStore('items', (items) => {
      const updatedItems = items.map((item) => {
        if (item.id === updatedRes.data.id) {
          return updatedRes.data;
        }
        return isDefaultUpdated ? { ...item, isDefaultAddress: false } : item;
      });
      updatedItems.sort((a, b) => a.name.localeCompare(b.name));
      return updatedItems;
    });
    cb && cb('Address Successfully Updated!');
  } else {
    setAddressBookStore('addOrEditErrorMessage', updatedRes.message.join(', '));
    handleToast(ToastType.Error, 'Address Failed to Update!');
  }

  return updatedRes;
};

export const deleteAddress = async (addressId: string) => {
  try {
    return await deleteAddressBook(addressId);
  } catch (error) {
    setAddressBookStore(
      produce((draft: AddressBookState) => {
        draft.isError = true;
        draft.items = [];
        draft.isLoading = false;
      }),
    );
  }
};

export const getAddressBookById = async (id: string) => {
  try {
    const res = await fetchAddressBookById(id);
    setAddressBookStore('selectedAddress', res);
  } catch (error) {
    throw new Error(`Failed to fetch address: ${error}`);
  }
};
