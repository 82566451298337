import { StyledProps } from '@suid/material';

export const statusSelectStyle = {
  outline: 'none !important',
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0px !important',
  },
};

export const statusSelectSmallStyle = {
  ...statusSelectStyle,
  height: '100%',
  '& .MuiFilledInput-root .MuiSelect-select': {
    fontSize: '14px !important',
  },
};

export const subTextStyle = { opacity: '0.6', fontSize: '12px' };

export const coveredByStyle: StyledProps = {
  textTransform: 'none',
  padding: 0,
  '&:hover': {
    backgroundColor: 'none',
  },
};
