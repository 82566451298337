import { CheckboxGroup, RadioInput } from '@components/forms';
import { Box, Divider, Grid, Typography } from '@suid/material';
import { userHasPermission } from '@views/order/components/carrier/stops/stopsEditor/utilFunctions';
import { Accessor, Setter } from 'solid-js';

type RolesPermissionProps = {
  selectedPermissions: Accessor<string[]>;
  setSelectedPermissions: Setter<string[]>;
  selectedRole: Accessor<string[]>;
  setSelectedRole: Setter<string[]>;
};

export const RolesPermission = (props: RolesPermissionProps) => {
  const permissionArgs = {
    name: 'permissions',
    label: 'Permissions',
    value: props.selectedPermissions(),
    options: [
      {
        label: 'Hide Dashboard',
        value: 'Hide Dashboard',
        disabled: !userHasPermission('Hide Dashboard'),
      },
      {
        label: 'Request EFS Checks',
        value: 'Request EFS Checks',
        disabled: !userHasPermission('Request EFS Checks'),
      },
      {
        label: 'View Settlements',
        value: 'View Settlements',
        disabled: !userHasPermission('View Settlements'),
      },
      {
        label: 'Edit Permissions',
        value: 'Edit Permissions',
        disabled: !userHasPermission('Edit Permissions'),
      },
      {
        label: 'Edit Carrier Contacts',
        value: 'Edit Carrier Contacts',
        disabled: !userHasPermission('Edit Carrier Contacts'),
      },
    ],
    onChange: (selectedValues: string[]) =>
      props.setSelectedPermissions(selectedValues),
    error: '',
  };

  const handleRadioChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    props.setSelectedRole([target.value]);
  };

  return (
    <Grid container direction="row" spacing={3} p={2}>
      <Grid item xs={12} md={6} container direction="column" spacing={2}>
        <Typography variant="h6">Roles</Typography>
        <Box mb={2}>
          <Divider sx={{ mb: 2 }} />
          <RadioInput
            label=""
            direction="vertical"
            value={props.selectedRole()[0]}
            options={[
              { label: 'Accounting', value: 'Accounting' },
              { label: 'Admin', value: 'Admin' },
              { label: 'Admin-Lite', value: 'Admin-Lite' },
              { label: 'Carrier Relations', value: 'Carrier Relations' },
              { label: 'Collections', value: 'Collections' },
              { label: 'Recruiter', value: 'Recruiter' },
              { label: 'Super Admin', value: 'Super Admin' },
            ]}
            onChange={handleRadioChange}
            labelClass="mb-[-6px] text-xs"
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} container direction="column" spacing={2}>
        <Typography variant="h6">Permissions</Typography>
        <Box mb={2}>
          <Divider sx={{ mb: 2 }} />
          <CheckboxGroup {...permissionArgs} />
        </Box>
      </Grid>
    </Grid>
  );
};
