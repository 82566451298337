export const classes = {
  rowFormGrid: 'w-full',

  newContactStack: 'flex-1 pr-2',

  existingContactStack: 'flex-1 pl-4',

  addressCardStack: 'h-96 overflow-y-scroll',

  buttonFooterGrid: 'mt-5 p-1',

  addressCard: 'border-b border-solid border-lightgray',

  dividerGrid: 'mt-2 mb-2',

  titleTypography:
    'text-black !text-xl !font-medium !leading-8 !tracking-[0.15px]',
};
