export default {
  titleStyles: {
    fontFamily: 'Roboto',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '160%',
    color: '#123B50',
  },
  statusSelectStyle: {
    outline: 'none !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px !important',
    },
    maxWidth: '140px',
  },
  cardHeaderSelect: { marginLeft: '20px' },
  basicModal: {
    minWidth: '900px',
    height: '500px',
    background: 'white',
    borderRadius: '5px',
  },
};
