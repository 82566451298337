import * as yup from 'yup';

export const payWithCreditCardFormSchema = yup.object({
  to: yup
    .array()
    .of(
      yup
        .string()
        .email('Invalid email')
        .test('is-valid-email', 'Invalid email', (value) => {
          return yup.string().email().isValidSync(value);
        }),
    )
    .required('Email is required')
    .min(1, 'At least one email is required'),
  subject: yup.string().required('Subject is required'),
  body: yup.string().required('Email body is required'),
  fee: yup.boolean().required('Fee is required'),
});
