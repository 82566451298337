import { BasicModal, Card, DatePicker } from '@components';
import Add from '@suid/icons-material/Add';
import { openModal } from '@store/modals';
import {
  officeDetailStore,
  updateOfficeDetailsState,
} from '@store/office/officeDetails';
import { Box, Stack } from '@suid/material';
import { useParams } from '@solidjs/router';
import { Show } from 'solid-js';
import { TextInput } from '@components/forms';
import { DateTime } from 'luxon';

import { ModalContent } from './modal/ModalContent';
import { UserDetails } from './tabledata';

export const ManageUsers = () => {
  const params = useParams();
  return (
    <Box
      sx={{
        opacity: !params.id ? 0.5 : 1,
        pointerEvents: !params.id ? 'none' : 'auto',
        transition: 'opacity 0.3s',
      }}
    >
      <Show
        when={
          officeDetailStore.officeDetail &&
          !officeDetailStore.officeDetail.isActive
        }
      >
        <Box
          mb={2}
          p={2}
          sx={{
            backgroundColor: '#f2dede',
            borderColor: '#ebccd1',
            color: '#a94442',
          }}
        >
          <Stack direction="row" spacing={2}>
            <DatePicker
              sx={{
                '& .MuiInputLabel-root': {
                  color: '#a94442 !important',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0px !important',
                },
              }}
              label="Termination Date:"
              value={
                officeDetailStore.officeDetail?.terminationDate != null
                  ? DateTime.fromFormat(
                      officeDetailStore.officeDetail.terminationDate,
                      'M/d/yyyy',
                    ).toISO({
                      suppressMilliseconds: true,
                      includeOffset: false,
                    })
                  : ''
              }
              handleChange={(value) => {
                updateOfficeDetailsState({
                  terminationDate:
                    DateTime.fromISO(value).toFormat('MM/dd/yyyy'),
                });
              }}
            />
            <TextInput
              label="Reason"
              value={officeDetailStore.officeDetail?.terminationReason ?? ''}
              onChange={(e: string) =>
                updateOfficeDetailsState({
                  terminationReason: e,
                })
              }
              sxProps={{
                '& .MuiInputLabel-root': {
                  color: '#a94442 !important',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0px !important',
                },
              }}
              error={officeDetailStore.officeDetailErrors?.name}
            />
          </Stack>
        </Box>
      </Show>
      <Card
        loading={officeDetailStore.isLoading}
        startTitle={
          <div>
            Manage Users
            {
              <Add
                sx={{ cursor: 'pointer' }}
                onClick={() => openModal('createNewUser')}
              />
            }
          </div>
        }
      >
        <UserDetails />
        <BasicModal
          title="Create New User"
          id="createNewUser"
          footer={false}
          showClose={true}
          width="70vw"
        >
          <ModalContent />
        </BasicModal>
      </Card>
    </Box>
  );
};
