import { BasicTable, Card } from '@components';
import { SidebarMenuLabelTypes } from '@components/Sidebar/Sidebar';
import { useNavigate } from '@solidjs/router';
import {
  changeQueueVisibility,
  fetchCreditIncreaseRequestCustomers,
  fetchDoNotSolicitRequests,
  fetchPendingCustomers,
} from '@store/Global/service';
import {
  CreditIncreaseRequestCustomer,
  DoNotSolicitRequest,
  PendingCustomers,
} from '@store/Global/types';
import { Delete } from '@suid/icons-material';
import { Box, IconButton } from '@suid/material';
import { routeCustomerDetails } from '@utils/routeCustomerDetails';
import { formatAmount } from '@utils/utils';
import { Component, createSignal, onMount, Setter, Show } from 'solid-js';

type Props = {
  setChecked: Setter<SidebarMenuLabelTypes>;
};

const Customers: Component<Props> = (props) => {
  const [creditIncreaseRequestCustomers, setCreditIncreaseRequestCustomers] =
    createSignal<CreditIncreaseRequestCustomer[]>([]);
  const [doNotSolicitRequests, setDoNotSolicitRequests] = createSignal<
    DoNotSolicitRequest[]
  >([]);
  const [pendingCustomers, setPendingCustomers] = createSignal<
    PendingCustomers[]
  >([]);
  const [isLoading, setIsLoading] = createSignal(false);

  const navigate = useNavigate();

  const getAllPendingCustomers = async () => {
    const resp = await fetchPendingCustomers();
    resp && setPendingCustomers(resp);
  };

  const getCreditIncreaseRequestCustomers = async () => {
    const resp = await fetchCreditIncreaseRequestCustomers();
    resp && setCreditIncreaseRequestCustomers(resp);
  };

  const getDoNotSolicitRequests = async () => {
    const resp = await fetchDoNotSolicitRequests();
    resp && setDoNotSolicitRequests(resp);
  };

  onMount(async () => {
    setIsLoading(true);
    await getAllPendingCustomers();
    await getCreditIncreaseRequestCustomers();
    await getDoNotSolicitRequests();
    setIsLoading(false);
  });

  const pendingCustomersColumns = [
    {
      name: 'Customer',
      key: 'name',
      renderCell: (row: PendingCustomers) => (
        <Box
          class="!cursor-pointer hover:underline"
          onClick={() => {
            props.setChecked('');
            const isNative = routeCustomerDetails(row.id);
            if (isNative)
              navigate(`/customer/details/${row.id}`, { replace: true });
          }}
        >
          {row.name}
        </Box>
      ),
    },
    { name: 'Agent', key: 'agent' },
    {
      name: 'Type',
      key: 'type',
    },
    {
      name: 'Pending Load',
      key: 'pendingLoad',
    },
    {
      name: 'Requested',
      key: 'createdDate',
    },
    {
      name: 'Action',
      renderCell: (row: PendingCustomers) => (
        <Show when={row.type === 'Prepaid'}>
          <IconButton onClick={() => clearPrepaid(row.id)}>
            <Delete class="text-[#B00020]" />
          </IconButton>
        </Show>
      ),
    },
  ];

  const creditIncreaseRequestCustomersColumns = [
    {
      name: 'Customer',
      key: 'customer',
    },
    {
      name: 'Amount',
      key: 'for',
      renderCell: (row: CreditIncreaseRequestCustomer) => (
        <>{`${formatAmount(row.for)}`}</>
      ),
    },
    {
      name: 'Agent',
      key: 'requestedBy',
    },
    {
      name: 'Date',
      key: 'requestDate',
    },
    {
      name: 'Comment',
      key: 'details',
    },
  ];

  const doNotSolicitRequestsColumns = [
    {
      name: 'Customer Name',
      key: 'name',
    },
    { name: 'Account Manager', key: 'agent' },
  ];

  const onRowClick = (
    row: PendingCustomers | CreditIncreaseRequestCustomer,
  ) => {
    const isNative = routeCustomerDetails(row.id);
    if (isNative) navigate(`/customer/details/${row.id}`, { replace: true });
  };

  const clearPrepaid = async (id: number) => {
    await changeQueueVisibility(id, false);
    await getAllPendingCustomers();
  };

  return (
    <Box class="w-[1300px] max-w-full h-[600px] overflow-auto bg-white p-2">
      <Card
        startTitle="Pending Customers"
        loading={isLoading()}
        maxContentHeight={180}
      >
        <BasicTable<PendingCustomers>
          columns={pendingCustomersColumns}
          tableHeaderClasses="!bg-[#F2F2F2]"
          rows={pendingCustomers()}
        />
      </Card>
      <Card
        startTitle="Credit Increase Requests"
        loading={isLoading()}
        maxContentHeight={230}
      >
        <BasicTable<CreditIncreaseRequestCustomer>
          columns={creditIncreaseRequestCustomersColumns}
          rows={creditIncreaseRequestCustomers()}
          onRowClick={onRowClick}
          tableHeaderClasses="!bg-[#F2F2F2]"
          tableBodyRowClasses="cursor-pointer"
        />
      </Card>
      <Card
        startTitle="Do Not Solicit Requests"
        loading={isLoading()}
        maxContentHeight={240}
      >
        <BasicTable<DoNotSolicitRequest>
          columns={doNotSolicitRequestsColumns}
          rows={doNotSolicitRequests()}
          tableHeaderClasses="!bg-[#F2F2F2]"
          tableBodyRowClasses="cursor-pointer"
        />
      </Card>
    </Box>
  );
};

export default Customers;
