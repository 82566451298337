import { ExcelSheetIcon } from '@assets';
import { Button } from '@components/Button';
import { Box } from '@suid/material';
import { AgGridSolidRef } from '@ag-grid-community/solid';
import { Accessor, Component } from 'solid-js';

export type ProductCatalogActionsButtonProps = {
  setEdit: (value: boolean) => void;
  openModal: (modalName: string) => void;
  gridRef: Accessor<AgGridSolidRef | null>;
};

export const ProductCatalogActionsButton: Component<
  ProductCatalogActionsButtonProps
> = (props) => {
  const { setEdit, openModal, gridRef } = props;
  return (
    <Box class="flex justify-between items-center bg-[#F8F8F8] border border-[#BABFC7] p-4">
      <Box>
        <Button
          variant="contained"
          label="Add Product"
          type="button"
          onClick={() => {
            setEdit(false);
            openModal('addEditProductDetails');
          }}
        />
      </Box>
      <Box displayRaw="flex" gap={2} alignItems="center">
        <Button
          variant="contained"
          label="Import"
          type="button"
          class="!bg-[#E0E0E0] !text-black"
          onClick={() => {
            openModal('importProductCatalog');
          }}
        />
        <Button
          startIcon={<img src={ExcelSheetIcon} alt="Excel Sheet" />}
          variant="contained"
          label="Export Excel"
          type="button"
          onClick={() => {
            gridRef()?.api.exportDataAsExcel({
              fileName: 'ProductCatalog.xlsx',
            });
          }}
          class="!bg-[#E0E0E0] !text-black"
        />
      </Box>
    </Box>
  );
};
