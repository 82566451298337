import { Loader } from '@googlemaps/js-api-loader';
import { onMount } from 'solid-js';

import MapConfig from './mapConfig';

const GoogleMapsStreetView = (props: {
  lat: number | undefined;
  lng: number | undefined;
  children?: JSX.Element | unknown;
}) => {
  const APIKey = MapConfig.GoogleGeoCode.GoogleGeoCodeAPIKey;
  onMount(async () => {
    const loader = new Loader({
      apiKey: APIKey,
      version: 'weekly',
    });

    await loader.load();
    const mapDiv = document.getElementById('map') as HTMLElement;
    const location = new google.maps.LatLng(props.lat ?? 0, props.lng ?? 0);
    new google.maps.StreetViewPanorama(mapDiv, {
      position: location,
      pov: { heading: 34, pitch: 10 },
    });
  });

  return <div id="map" style="width:100%; height: 550px" />;
};

export default GoogleMapsStreetView;
