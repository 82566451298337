import { orderStore, TabType } from '@store/orders';
import { isEmpty } from 'lodash';
import { Show } from 'solid-js';

import { LoadContainer } from './loads';
import { VendorContainer } from './vendor/VendorContainer';

const ActiveTabContent = () => {
  return (
    <>
      <Show
        when={
          orderStore.activeTab.type === TabType.load &&
          !isEmpty(orderStore.order.loads[orderStore.activeTab.index])
        }
      >
        <LoadContainer
          edi={orderStore.order.edi}
          load={orderStore.order.loads[orderStore.activeTab.index]}
          index={orderStore.activeTab.index}
          show214={orderStore.order.show214}
        />
      </Show>
      <Show
        when={
          orderStore.activeTab.type === TabType.vendor &&
          orderStore.order.vendors &&
          !isEmpty(orderStore.order.vendors[orderStore.activeTab.index])
        }
      >
        <VendorContainer
          // @ts-expect-error The order is defined here.
          vendor={orderStore.order.vendors[orderStore.activeTab.index]}
          index={orderStore.activeTab.index}
          vendorError={orderStore.vendorFalveyInsuranceError}
        />
      </Show>
    </>
  );
};
export default ActiveTabContent;
