import { apiClient, ApiResponse } from '@api/apiClient';
import { ConfigManager } from '@utils/ConfigManager';
import { logError } from '@utils/errorLogging';
import { createError } from '@utils/errorUtils';
import { printLog } from '@utils/utils';

import { DocumentUploadModel, DocumentUploadResponse } from './types';

export async function UploadDocument(
  params: DocumentUploadModel,
): Promise<DocumentUploadResponse> {
  try {
    const formData = new FormData();
    const filesToUpload = Array.from(params.file);
    // see top answer on https://stackoverflow.com/questions/54269650/why-formdata-does-not-work-with-multiple-files talking about for loop
    // formData needs to have the array appended to it to work
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append('Files', filesToUpload[i]);
    }
    formData.append('entityId', params.entityId.toString());
    formData.append('documentEntityType', params.documentEntityType.toString());

    const response = await fetch(
      `${ConfigManager.apiUrl}/api/document/CreateDocument`,
      {
        method: 'POST',
        body: formData,
        credentials: 'include',
      },
    );

    const apiresponse =
      (await response.json()) as ApiResponse<DocumentUploadResponse>;
    printLog(apiresponse);
    if ('value' in apiresponse) {
      return apiresponse.value;
    }
    throw new Error(
      `Failed to upload document: ${filesToUpload.map((x) => {
        return x.name;
      })}`,
    );
  } catch (error: unknown) {
    logError('UploadDocument', error as Error);
    throw createError(error, 'Failed to upload order document');
  }
}

export async function DeleteFile(params: string): Promise<boolean> {
  try {
    const response = await apiClient.post<ApiResponse<string>>(
      'document/deletefile',
      {
        file: params,
      },
    );
    if ('isSuccess' in response) {
      return response.isSuccess as boolean;
    }
    throw new Error(`Failed to delete order document: ${params}`);
  } catch (error: unknown) {
    logError('DeleteFile', error as Error);
    throw createError(error, 'Failed to delete order document');
  }
}
