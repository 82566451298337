import { Card, DatePicker } from '@components';
import { CheckboxInput, SelectField, TextAreaField } from '@components/forms';
import { officeDetailStore } from '@store/office/officeDetails';
import { updateOfficeDetailsState } from '@store/office/officeDetails/actions';
import { Stack } from '@suid/material';

import { ContractTypes } from '../constants';

export const Contract = () => {
  return (
    <Card startTitle="Contract" loading={officeDetailStore.isLoading}>
      <Stack spacing={2} p={1}>
        <SelectField
          label="Contract Type"
          menuItems={ContractTypes}
          value={officeDetailStore.officeDetail?.contractType}
          onChange={(e) =>
            updateOfficeDetailsState({
              contractType: e.target.value,
            })
          }
        />
        <CheckboxInput
          label="Contract Signed"
          checked={officeDetailStore.officeDetail?.contractSigned ?? false}
          onChange={(e) =>
            updateOfficeDetailsState({
              contractSigned: e as boolean,
            })
          }
        />
        <CheckboxInput
          label="Dormant Customers"
          checked={
            officeDetailStore.officeDetail?.dormantCustomerOptIn ?? false
          }
          onChange={(e) =>
            updateOfficeDetailsState({
              dormantCustomerOptIn: e as boolean,
            })
          }
        />
        <DatePicker
          label="Signed Date:"
          value={officeDetailStore.officeDetail?.contractSignedDate ?? ''}
          handleChange={(value) => {
            updateOfficeDetailsState({
              contractSignedDate: value,
            });
          }}
        />
        <DatePicker
          label="Start Date: "
          value={officeDetailStore.officeDetail?.startDate ?? ''}
          handleChange={(value) => {
            updateOfficeDetailsState({
              startDate: value,
            });
          }}
        />
        <TextAreaField
          rows={5}
          label="Notes:"
          placeholder="Notes"
          value={officeDetailStore.officeDetail?.notes ?? ''}
          onChange={(value) => {
            updateOfficeDetailsState({
              notes: value,
            });
          }}
          error={officeDetailStore.officeDetailErrors?.notes}
        />
      </Stack>
    </Card>
  );
};
