import { Part, createStore, produce } from 'solid-js/store';

import { fetchLoadBoardItems, fetchSavedSearches } from './services';
import {
  ILoadBoardViewModel,
  LoadBoardState,
  SavedSearchCategory,
  SavedSearchT,
} from './types';

const [loadBoardStore, setLoadBoardStore] = createStore<LoadBoardState>({
  items: [] as ILoadBoardViewModel[],
  savedSearches: [],
  filters: [],
  cache: undefined,
  isLoading: true,
  isError: false,
  errorMessage: null,
  updateLock: false,
  overlay: false,
  membersByGroupName: {},
  officeGroupIdsByMachingNames: {},
  filteredItems: [] as ILoadBoardViewModel[],
});

// @ts-expect-error stuff
window.lstore = { loadBoardStore, setLoadBoardStore };

export const saveLoadboardCache = (cache: SavedSearchT | undefined) => {
  setLoadBoardStore(
    produce((draft: LoadBoardState) => {
      draft.cache = cache;
    }),
  );
};

/**
 * Fetches the loadboard load items and the saved searches. It places them on
 * the loadboard store for easy access.
 * @param dummyData Whether to test with dummy data or not.
 */
export const fetchLoadBoardDetails = async (dummyData = false) => {
  try {
    const resp = await fetchLoadBoardItems(dummyData);
    const items = resp.items;

    if (!resp.isEnabled) {
      setLoadBoardStore(
        produce((draft: LoadBoardState) => {
          draft.isError = true;
          draft.errorMessage = resp.message;
          draft.isLoading = false;
        }),
      );
      return;
    }
    resp.items.map((item) => {
      item.stopsRatio = `${item.pickUps} : ${item.dropOffs}`;
    });

    let savedSearches: SavedSearchT[] = [];
    const savedSearchesFromSession = sessionStorage.getItem('savedSearches');

    if (Boolean(savedSearchesFromSession)) {
      savedSearches = JSON.parse(
        savedSearchesFromSession as string,
      ) as SavedSearchT[];
    } else {
      savedSearches = await fetchSavedSearches();
      sessionStorage.setItem('savedSearches', JSON.stringify(savedSearches));
    }

    const { filters, cache } = savedSearches.reduce(
      (prev, s) => {
        if (s.category === SavedSearchCategory.Cache) {
          prev.cache = s;
        } else if (
          s.category === SavedSearchCategory.Basic ||
          s.category === SavedSearchCategory.Advanced
        ) {
          prev.filters.push(s);
        }

        return prev;
      },
      { filters: [], cache: undefined } as {
        filters: SavedSearchT[];
        cache: SavedSearchT | undefined;
      },
    );
    setLoadBoardStore(
      produce((draft: LoadBoardState) => {
        draft.isError = false;
        draft.errorMessage = null;
        draft.isLoading = false;
        draft.items = [...items];
        draft.savedSearches = [...savedSearches];
        draft.filters = [...filters];
        draft.cache = cache !== undefined ? { ...cache } : undefined;
        draft.membersByGroupName = resp.membersByGroupName;
        draft.officeGroupIdsByMachingNames = resp.officeGroupIdsByMachingNames;
      }),
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    setLoadBoardStore(
      produce((draft: LoadBoardState) => {
        draft.isError = true;
        draft.errorMessage = 'Error fetching loadboard';
        draft.isLoading = false;
      }),
    );
  }
};

export const appendNewSavedSearch = (search: SavedSearchT) => {
  setLoadBoardStore(
    produce((draft: LoadBoardState) => {
      draft.savedSearches = [...draft.savedSearches, search];

      if (
        search.category === SavedSearchCategory.Advanced ||
        search.category === SavedSearchCategory.Basic
      ) {
        draft.filters = [...draft.filters, search];
      }

      // Update of the session storage version of savedSearches
      sessionStorage.setItem(
        'savedSearches',
        JSON.stringify(draft.savedSearches),
      );
    }),
  );
};

export const deleteSavedSearch = (search: SavedSearchT) => {
  setLoadBoardStore(
    produce((draft: LoadBoardState) => {
      const idx = draft.savedSearches.findIndex((s) => s.id === search.id);
      const idxFilters = draft.filters.findIndex((s) => s.id === search.id);

      if (idx > -1) {
        draft.savedSearches.splice(idx, 1);
        draft.savedSearches = [...draft.savedSearches];
      }

      if (idxFilters > -1) {
        draft.filters.splice(idxFilters, 1);
        draft.filters = [...draft.filters];
      }

      // Update of the session storage version of savedSearches
      sessionStorage.setItem(
        'savedSearches',
        JSON.stringify(draft.savedSearches),
      );
    }),
  );
};

export const setLoadBoardItems = (items: ILoadBoardViewModel[]) => {
  setLoadBoardStore(
    produce((draft: LoadBoardState) => {
      draft.items = [...items];
    }),
  );
};

export const setLoadboardItem = (load: ILoadBoardViewModel, i: number) => {
  setLoadBoardStore(
    'items',
    produce((loads) => {
      for (const prop in load) {
        // @ts-expect-error Ignoring the type error here.
        loads[i][prop] = prop;
      }
    }),
  );
};

export const updateLoadboardItemField = (
  loadId: number,
  field: Part<ILoadBoardViewModel, keyof ILoadBoardViewModel>,
  value: unknown,
) => {
  const idx = loadBoardStore.items.findIndex((l) => l.loadId === loadId);

  if (idx >= 0) {
    setLoadBoardStore('items', idx, field, value);
  } else {
    // eslint-disable-next-line no-console
    console.log(`updateLoadboardItemField(${loadId}): Invalid index`);
  }
};

export const setFilteredItems = (items: ILoadBoardViewModel[]) => {
  setLoadBoardStore('filteredItems', items);
};

export { loadBoardStore, setLoadBoardStore };
