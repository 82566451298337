export default {
  trackingHeader: 'flex gap-4 items-center pb-3',
  statusSelect: 'flex-1 h-fit !w-fit rounded-md',
  trackingOptions:
    '!bg-white !font-medium hover:!bg-white !text-black !shadow-none',
  infoIcon: 'text-[24px] text-[#fff] cursor-pointer',
  subjectField: 'pb-4',
  noneText: 'text-sm',
  stackStyle: 'mb-4',
  trackingButton: '!bg-[#fff] !font-medium hover:!bg-[#fff] !text-[#000]',
  loaderStyle: 'h-[300px] flex justify-center items-center',
};
