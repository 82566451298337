import { BasicModal } from '@components';
import { CheckboxInput, TextInput } from '@components/forms';
import { closeModal } from '@store/modals';
import { saveOrderTemplate } from '@store/orders';
import { Stack } from '@suid/material';
import { createStore } from 'solid-js/store';

type TemplateInfoType = {
  name: string;
  public: boolean;
  nameError: string;
};

export const saveAsTemplateModalId = 'saveAsTemplateModal';

export const SaveAsTemplateModal = () => {
  const [templateInfo, setTemplateInfo] = createStore<TemplateInfoType>({
    name: '',
    public: true,
    nameError: '',
  });

  const resetState = () => {
    setTemplateInfo({
      name: '',
      public: true,
      nameError: '',
    });
  };

  const handleSubmit = async () => {
    const name = templateInfo.name.trim();

    if (name.length === 0) {
      setTemplateInfo('nameError', 'Template name is required');
      return;
    }

    await saveOrderTemplate(name, templateInfo.public);
    resetState();
    closeModal(saveAsTemplateModalId);
  };

  const handletemplateNameChange = (val: string) => {
    if (val.length > 0 && templateInfo.nameError.length > 0) {
      setTemplateInfo('nameError', '');
    }

    setTemplateInfo('name', val);
  };

  return (
    <BasicModal
      id={saveAsTemplateModalId}
      title="Save New Template"
      width="400"
      onClose={() => {
        resetState();
        closeModal(saveAsTemplateModalId);
      }}
      onSubmit={handleSubmit}
      preventCloseOnSubmit
    >
      <Stack spacing={2}>
        <TextInput
          label="Template Name"
          placeholder="Enter template name"
          fullWidth
          value={templateInfo.name}
          required
          onChange={(val) => handletemplateNameChange(val as string)}
          error={templateInfo.nameError}
        />
        <CheckboxInput
          label={
            <b>
              {' '}
              Public (everyone in my office can use this template for their
              loads)
            </b>
          }
          checked={templateInfo.public}
          onChange={(val) => setTemplateInfo('public', val as boolean)}
        />
      </Stack>
    </BasicModal>
  );
};
