import { createStore } from 'solid-js/store';

import { AddressBookState } from './types';

const [addressBookStore, setAddressBookStore] = createStore<AddressBookState>({
  isLoading: false,
  isError: false,
  errorMessage: '',
  items: [],
  addOrEditErrorMessage: '',
  selectedAddress: null,
});

export { addressBookStore, setAddressBookStore };
