import { ToastType } from '@components/Toast';
import { getCarrierPlaylists, setPlaylistsStore } from '@store/carriers';
import {
  createPlaylist,
  fetchCopyOrderDetails,
  getOrderTemplates,
  fetchOrderDetails,
  orderStore,
  Playlist,
  populateOrderWithQuote,
} from '@store/orders';
import { userStore } from '@store/user';
import { handleToast } from '@utils/utils';
import { ErrorT } from '@api/types';

import {
  mapToComponent,
  mapToMessage,
  mapToTitle,
} from './components/ErrorsTable/constants';

export const getAndCheckPlaylists = async () => {
  const playlists = await getCarrierPlaylists();

  if (playlists !== undefined) {
    if (!playlists.some((p) => p.name === 'Favorites')) {
      const favorites: Playlist = {
        id: 0,
        userId: userStore.user.id,
        name: 'Favorites',
        carriers: [],
        groups: [],
        editable: false,
        canEdit: true,
        // @ts-expect-error This is how it's done in V3.
        user: null,
      };

      try {
        // If there's no favorites playlist for this user, create it.
        const favoritesPlaylist = await createPlaylist(favorites);

        if (favoritesPlaylist !== undefined) {
          playlists.push(favoritesPlaylist);
          setPlaylistsStore('playlists', [...playlists]);
        }
      } catch (error) {
        handleToast(ToastType.Error, (error as Error).message);
      }
    }
  }
};

export const checkErrorSection = () => {
  const newErrors = Object.entries(orderStore.orderFormErrorPerSection).reduce(
    (acc, v) => {
      const [key, hasError] = v;
      if (hasError === 0) {
        return acc;
      }

      acc.push({
        // @ts-expect-error This is how it's done in V3.
        title: mapToTitle[key],
        body: mapToMessage[key],
        onView: () => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const id = mapToComponent[key];

          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (id !== undefined) {
            document.querySelector(id)?.scrollIntoView({
              behavior: 'smooth',
            });
          }
        },
      });
      return acc;
    },
    [] as ErrorT[],
  );
  return newErrors;
};

export const setupOrder = async () => {
  // If there is a copy id
  if (Boolean(orderStore.copiedOrderIdUrl)) {
    await fetchCopyOrderDetails(orderStore.copiedOrderIdUrl as string);
  }
  // If there is a quote id
  if (Boolean(orderStore.quoteIdUrl)) {
    await populateOrderWithQuote(orderStore.quoteIdUrl as string);
  }
  // If there is an order id
  if (!orderStore.isCreate) {
    await fetchOrderDetails(
      orderStore.orderIdUrl as string,
      orderStore.quoteIdUrl as string,
    );
  }
  await getOrderTemplates();
};
