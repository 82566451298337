import { CardPlaceholder, Tab, TabsComponent, ToastType } from '@components';
import { Badge, Box } from '@suid/material';
import { createSignal, Match, onMount, Show, Switch } from 'solid-js';
import { createStore } from 'solid-js/store';
import { fetchAllApprovals, fetchDisputedLoads } from '@store/Global/service';
import { AllApprovalsTypes } from '@store/Global/types';
import { handleToast } from '@utils/utils';
import { groupBy } from 'lodash';

import cls from './classes';
import { ApprovalCard } from './components';

export const tabsStyle = {
  marginLeft: '20px',
  '& .MuiBadge-badge': {
    backgroundColor: '#777777',
    color: '#ffffff',
  },
};

export const [approvalDetailsStore, setApprovalDetailsStore] = createStore<{
  isLoading: boolean;
  allApprovals: AllApprovalsTypes[];
  disputedLoads: AllApprovalsTypes[];
  approvalGrouping: { [key: string]: AllApprovalsTypes[] };
  disputedGrouping: { [key: string]: AllApprovalsTypes[] };
}>({
  isLoading: false,
  allApprovals: [],
  disputedLoads: [],
  approvalGrouping: {},
  disputedGrouping: {},
});

const Approvals = () => {
  const [activeTab, setActiveTab] = createSignal(0);
  const tabs = ['All Approvals', 'Disputed Loads'];

  onMount(async () => {
    try {
      setApprovalDetailsStore('isLoading', true);
      const [approval, disputedLoads] = await Promise.all([
        fetchAllApprovals(),
        fetchDisputedLoads(),
      ]);
      if (approval) {
        setApprovalDetailsStore('allApprovals', approval);
        approvalsByAgent();
      }
      if (disputedLoads) {
        setApprovalDetailsStore('disputedLoads', disputedLoads);
        disputesByAgent();
      }
      setApprovalDetailsStore('isLoading', false);
    } catch (error) {
      setApprovalDetailsStore('isLoading', false);
      handleToast(
        ToastType.Error,
        'Failed to fetch approvals or disputed loads',
      );
    }
  });

  const approvalsByAgent = () => {
    const grouping = groupBy(approvalDetailsStore.allApprovals, 'agent');
    setApprovalDetailsStore('approvalGrouping', grouping);
  };

  const disputesByAgent = () => {
    const grouping = groupBy(approvalDetailsStore.disputedLoads, 'agent');

    Object.values(grouping).forEach((approvals) => {
      approvals.sort((a) => (a.agent === a.lastCommentBy ? 1 : -1));
    });
    setApprovalDetailsStore('disputedGrouping', grouping);
  };

  const disputeSize = () => {
    let newDisputeCount = 0;
    approvalDetailsStore.disputedLoads.forEach((item: AllApprovalsTypes) => {
      if (item.agent !== item.lastCommentBy) {
        newDisputeCount++;
      }
    });
    return newDisputeCount;
  };

  return (
    <Box class={cls.container}>
      <Show
        when={!approvalDetailsStore.isLoading}
        fallback={<CardPlaceholder />}
      >
        <Box class={cls.tabBox}>
          <TabsComponent
            tabs={tabs.map((item, i) => (
              <Tab>
                {item}
                <Badge
                  badgeContent={
                    item === 'All Approvals'
                      ? approvalDetailsStore.allApprovals.length
                      : approvalDetailsStore.disputedLoads.length
                  }
                  sx={{
                    ...tabsStyle,
                    '& .MuiBadge-badge': {
                      backgroundColor:
                        activeTab() === i ? '#123B50' : '#e0e0e0',
                      color: '#ffffff',
                    },
                  }}
                />
                <Show when={item === 'Disputed Loads'}>
                  <Badge
                    badgeContent={disputeSize()}
                    sx={{
                      ...tabsStyle,
                      '& .MuiBadge-badge': {
                        position: 'absolute',
                        top: '-10px',
                        backgroundColor:
                          activeTab() === i ? '#B00020' : '#e0e0e0',
                        color: '#ffffff',
                      },
                    }}
                  />
                </Show>
              </Tab>
            ))}
            activeTab={activeTab()}
            onTabClick={setActiveTab}
          />
          <Switch>
            <Match when={activeTab() === 0}>
              <ApprovalCard name="AllApprovals" />
            </Match>
            <Match when={activeTab() === 1}>
              <ApprovalCard name="DisputedLoads" />
            </Match>
          </Switch>
        </Box>
      </Show>
    </Box>
  );
};

export default Approvals;
