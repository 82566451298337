import { v3Client } from '@api/apiClient';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import { CustomerContract } from './types';

const fetchCustomerContracts = async (customerId: string, cb?: () => void) => {
  try {
    const response = await v3Client.get(
      `/Customer/Contracts/${customerId}?toCurrency=NAN`,
    );
    return response as unknown as CustomerContract;
  } catch (error) {
    handleToast(ToastType.Error, 'Failed to fetch customer contracts');
  } finally {
    cb && cb();
  }
};

const updateCustomerContractCall = async (
  customerContract: CustomerContract,
) => {
  try {
    await v3Client.post('/Customer/UpdateContracts', customerContract);
    handleToast(ToastType.Success, 'Customer contracts updated successfully');
  } catch (error) {
    handleToast(ToastType.Error, 'Failed to update customer contracts');
  }
};

export { fetchCustomerContracts, updateCustomerContractCall };
