import { datadogRum } from '@datadog/browser-rum';

export function printLog(
  message: unknown,
  other?: unknown,
  ...rest: Record<string, unknown>[]
) {
  if (process.env.NODE_ENV === 'production') return;
  // eslint-disable-next-line no-console
  console.log(message, JSON.stringify(other, null, 2), ...rest);
}

export const printError = (...args: unknown[]) => {
  const sessionId =
    datadogRum.getInternalContext()?.session_id ?? 'No Session ID';

  // eslint-disable-next-line no-console
  console.error(sessionId, ...args);
};
