import {
  ILoadEditViewModel,
  LastUsedTopStopModel,
  PickUpDropOffItem,
} from '@store/orders';
import { userStore } from '@store/user';
import { getEligibleEntries } from '@utils/utils';

export const canRateLtl = (load: ILoadEditViewModel) => {
  const eligibleStops = getEligibleEntries<LastUsedTopStopModel>(load.stops);
  const eligibleItems = getEligibleEntries<PickUpDropOffItem>(load.items);
  if (load.stops && load.items) {
    if (
      load.status === 'Assigned' &&
      eligibleStops.length > 1 &&
      eligibleItems.length > 0 &&
      (userStore.user.permissions?.includes('Rate LTL') ?? false)
    ) {
      return true;
    }
  }
  return false;
};
