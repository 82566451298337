import { Button, ToastType, Typography } from '@components';
import { useNavigate, useParams } from '@solidjs/router';
import {
  officeDetailStore,
  setOfficeDetailStore,
} from '@store/office/officeDetails';
import { saveOfficeDetailApi } from '@store/office/officeDetails/services';
import { Save } from '@suid/icons-material';
import { Box, Grid, Stack } from '@suid/material';
import { routeOfficeDetails } from '@utils/routeOfficeDetails';
import { handleToast } from '@utils/utils';
import { createSignal, Show } from 'solid-js';
import { ValidationError } from 'yup';

import { officeDetailSchema } from '../validation';
import cls from './classes';

type ResponseType = {
  message: string | null;
  success: boolean;
  data: {
    accountingSysId: string;
    id: number;
  };
};

export const OfficeDetailHeader = (props: { id: string }) => {
  const [saveLoading, setSaveLoading] = createSignal<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();

  const handleSaveOfficeDetail = async () => {
    setSaveLoading(true);
    try {
      officeDetailSchema.validateSync(officeDetailStore.officeDetail, {
        abortEarly: false,
      });
      setOfficeDetailStore('officeDetailErrors', null);
      if (officeDetailStore.officeDetail) {
        const updatedOfficeDetail = {
          ...officeDetailStore.officeDetail,
          // startDate: formatDateOrNull(officeDetailStore.officeDetail.startDate),
          // contractSignedDate: formatDateOrNull(
          //   officeDetailStore.officeDetail.contractSignedDate,
          // ),
          // terminationDate: formatDateOrNull(
          //   officeDetailStore.officeDetail.terminationDate,
          // ),
        };
        const response: unknown = await saveOfficeDetailApi(
          updatedOfficeDetail,
          !props.id,
        );
        const data = response as ResponseType;
        if (data.data.id) {
          const officeId = data.data.id;
          const isNative = routeOfficeDetails(officeId);
          isNative && navigate(`/office/details/${officeId}`);
        }
        handleToast(ToastType.Success, 'Office created, you can now add users');
      }
    } catch (validationError: unknown) {
      const errors: Record<string, string[]> = {};
      if (validationError instanceof ValidationError) {
        validationError.inner.forEach((error: ValidationError) => {
          if (error.path !== undefined) {
            errors[error.path] = error.errors;
          }
        });
      }
      setOfficeDetailStore('officeDetailErrors', errors);
    } finally {
      setSaveLoading(false);
    }
  };
  return (
    <Grid
      container
      alignItems="center"
      backgroundColor="white"
      class={cls.headerContainer}
      spacing={2}
    >
      <Grid item xs={3.5}>
        <Stack spacing={0.5}>
          <Box class={cls.customerNameText}>
            <Typography variant="h4" class="w-[300px] line-clamp-1">
              {officeDetailStore.officeDetail?.name}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={8.5} displayRaw="flex" justifyContent="flex-end">
        <Box class={cls.customerActionBtns}>
          <Button
            startIcon={<Save />}
            variant="contained"
            label="SAVE"
            type="submit"
            onClick={handleSaveOfficeDetail}
            isLoading={saveLoading()}
          />
          <Show when={params.id}>
            <Button variant="contained" label="Posting Groups" type="submit" />
          </Show>
        </Box>
      </Grid>
    </Grid>
  );
};
