import { Show } from 'solid-js';
import { orderStore } from '@store/orders';

import NewFalveyLTLQuote from '../ltlQuote/NewFalveyLTLQuote';
import { canRateLtl } from './helpers';

export const LTLInfo = () => {
  const load = () => orderStore.order.loads[orderStore.activeTab.index];
  return (
    <Show
      when={load().mode === 'LTL' && Boolean(load().quoteId) && load().ltlQuote}
    >
      <NewFalveyLTLQuote
        canRateLtl={canRateLtl(load())}
        ltlQuote={load().ltlQuote}
        status={load().status}
      />
    </Show>
  );
};
