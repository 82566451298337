import { Button } from '@components';
import { closeModal } from '@store/modals';
import { Box, Grid, List, ListItemText, Typography } from '@suid/material';
import { Component } from 'solid-js';

export type ShipmentChangesModalProps = {
  modalId: string;
};

export const ShipmentChangesModal: Component<ShipmentChangesModalProps> = (
  props: ShipmentChangesModalProps,
) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} px={1} pt={2}>
        <Grid item xs={12} displayRaw="flex" flexDirection="column" gap={2}>
          <Box>
            <Typography variant="body2" component="p">
              Changing load details risks invalidating the quote and may require
              re-quoting. This includes changing :
            </Typography>
            <List class="!ml-2 !list-disc	!list-inside !p-0	">
              <ListItemText class="!list-item m-0 p-0">
                <Typography variant="body2" component="span">
                  Addresses
                </Typography>
              </ListItemText>
              <ListItemText class="!list-item">
                <Typography variant="body2" component="span">
                  Weight
                </Typography>
              </ListItemText>
              <ListItemText class="!list-item">
                <Typography variant="body2" component="span">
                  Dimensions
                </Typography>
              </ListItemText>
              <ListItemText class="!list-item">
                <Typography variant="body2" component="span">
                  Items
                </Typography>
              </ListItemText>
              <ListItemText class="!list-item">
                <Typography variant="body2" component="span">
                  Counts
                </Typography>
              </ListItemText>
              <ListItemText class="!list-item">
                <Typography variant="body2" component="span">
                  Dates
                </Typography>
              </ListItemText>
            </List>
          </Box>
          <Typography variant="body2" component="p">
            Changes to existing shipments may require you to contact the carrier
            to confirm changes.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          displayRaw="flex"
          alignItems="center"
          justifyContent="end"
        >
          <Button
            label="Close"
            variant="standard"
            onClick={() => closeModal(props.modalId)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
