import { CommentViewModel } from '@store/orders';
import { userStore } from '@store/user';
import DeleteIcon from '@suid/icons-material/Delete';
import { Box, IconButton, Stack } from '@suid/material';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { isAdmin } from '@utils/utils';

type PropsT = {
  comment: CommentViewModel;
  onDelete: () => void;
};

export const LoadComment = (props: PropsT) => {
  const { comment, onDelete } = props;

  return (
    <Box>
      <Box padding="5px" backgroundColor="#f3f3f3">
        <Stack direction="row" justifyContent="space-between">
          <Box fontWeight="bold">{comment.name}</Box>
          <Box>
            <span>
              {convertUtcToLocalAndFormat(
                comment.timestamp as unknown as string,
                'MMMM dd, yyyy h:mm a',
              )}
            </span>
            {comment.userId === userStore.user.id || isAdmin() ? (
              <IconButton size="small" onClick={onDelete}>
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            ) : undefined}
          </Box>
        </Stack>
      </Box>
      <Box padding="5px">{comment.comment}</Box>
    </Box>
  );
};
