import { createSignal, JSX, Match, Show, Switch } from 'solid-js';
import { ConfigManager } from '@utils/ConfigManager';
import { Header, Sidebar, Footer } from '@components';
import { Box } from '@suid/material';
import { isEmpty } from 'lodash';
import { SidebarMenuLabelTypes } from '@components/Sidebar/Sidebar';
import { Close } from '@suid/icons-material';
import {
  Announcement,
  Customers,
  NeedPaperwork,
  Alerts,
  Carriers,
  Advances,
  Approvals,
} from '@views/collapsableSidebar';

import cls from './classes';

type Props = {
  children: JSX.Element;
  showFooter?: boolean;
};

const showLayout = ConfigManager.showLayout;

const footer = (showFooter: boolean): JSX.Element => {
  return showFooter && <Footer />;
};

const Layout = ({ children, showFooter = false }: Props) => {
  const [checked, setChecked] = createSignal<SidebarMenuLabelTypes>('');
  if (!showLayout)
    return (
      <div class={cls.container}>
        <div class={cls.mainContent}>
          <main class={cls.childrenContainer}>{children}</main>
          {footer(showFooter)}
        </div>
      </div>
    );

  return (
    <div class={cls.container}>
      <Header />
      <div class={cls.main} style={cls.mainHeight}>
        <div class={cls.content}>
          <div class={cls.sidebar}>
            <Sidebar setChecked={setChecked} checked={checked} />
          </div>
          <div class={cls.mainContent}>
            <Show when={!isEmpty(checked())}>
              <Box class="absolute bg-[#E1ECF2] p-2 text-black z-50 left-6 top-6 !shadow-xl max-w-[90%] max-h-[calc(100vh-200px)]">
                <Box class="flex justify-between p-2">
                  <h1 class="text-[#123B50] text-2xl">{checked()}</h1>
                  <Close
                    onClick={() => setChecked('')}
                    class="cursor-pointer !text-[#006C9D]"
                  />
                </Box>
                <Box class="p-2">
                  <Switch>
                    <Match when={checked() === 'Announcements'}>
                      <Announcement />
                    </Match>
                    <Match when={checked() === 'Pending Carriers'}>
                      <Carriers />
                    </Match>
                    <Match when={checked() === 'Approvals'}>
                      <Approvals />
                    </Match>
                    <Match when={checked() === 'Alerts'}>
                      <Alerts setChecked={setChecked} />
                    </Match>
                    <Match when={checked() === 'Paperwork'}>
                      <NeedPaperwork />
                    </Match>
                    <Match when={checked() === 'Customers'}>
                      <Customers setChecked={setChecked} />
                    </Match>
                    <Match when={checked() === 'Advances'}>
                      <Advances />
                    </Match>
                  </Switch>
                </Box>
              </Box>
            </Show>
            <main class={cls.childrenContainer}>{children}</main>
            {footer(showFooter)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Layout;
