import { lazy } from 'solid-js';
const Loadboard = lazy(() => import('./Loadboard'));

import { OldLoadsRoute } from './oldLoads';

const LoadboardRoute = {
  path: '/loadboard',
  component: Loadboard,
};

export { LoadboardRoute, OldLoadsRoute };
