import { Card, DatePicker } from '@components';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@suid/material';
import { SelectField, TextAreaField, TextInput } from '@components/forms';
import { createSignal, Index, Show } from 'solid-js';
import { AddCircleOutlineOutlined, Delete } from '@suid/icons-material';
import { DialogBox } from '@components/DialogBox';
import {
  customerDetailsStore,
  updateCustomerDetailsState,
} from '@store/customers/customerDetails';
import { SelectChangeEvent } from '@suid/material/Select';
import { intersection } from 'lodash';
import { userStore } from '@store/user';
import { userRoles } from '@constants/common';

import { insuranceClasses as classes } from './classes';
import { CustomerPolicyType } from '../../constants';

const Insurance = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [deleteRow, setDeleteRow] = createSignal<number | null>(null);

  const headers = ['Coverage Amount', 'Policy Type', 'Policy Date', ''];

  const isReadonly = () => {
    return (
      intersection(userStore.user.roles, [
        userRoles.ADMIN,
        userRoles.ADMIN_LITE,
      ]).length <= 0
    );
  };

  if (isReadonly()) {
    headers.pop();
  }

  const addRow = () => {
    const customerInsuranceItems = [...customerDetailsStore.customer.insurance];
    customerInsuranceItems.push({
      operationType: 'Insert',
      objectId: customerDetailsStore.customer.id,
      objectType: 'Customer',
      policyType: '',
      policyTypeName: '',
      policyDate: '',
      coverageAmount: '0',
    });
    updateCustomerDetailsState({ insurance: customerInsuranceItems });
  };

  const updateInsuranceItems = (
    key: string,
    value: string | number,
    index: number,
  ) => {
    const insuranceItems = [...customerDetailsStore.customer.insurance];
    const { operationType } = insuranceItems[index];
    if (operationType !== 'Insert') {
      insuranceItems[index] = {
        ...insuranceItems[index],
        operationType: 'Update',
        [key]: value,
      };
    } else {
      insuranceItems[index] = {
        ...insuranceItems[index],
        [key]: value,
      };
    }
    updateCustomerDetailsState({ insurance: insuranceItems });
  };

  const handleDeleteInsurance = (index: number) => {
    const insuranceItems = [...customerDetailsStore.customer.insurance];
    const item = insuranceItems[index];
    const { operationType } = insuranceItems[index];

    if (operationType === 'Insert') {
      insuranceItems.splice(index, 1);
    } else {
      insuranceItems[index] = {
        ...item,
        operationType: 'Delete',
      };
    }

    updateCustomerDetailsState({ insurance: insuranceItems });
  };

  return (
    <Box class={classes.mainContainer}>
      <Card startTitle="Insurance" accordion={false}>
        <Grid container gap={1}>
          <Grid item lg={3.9}>
            <TextInput
              label="ATG Liability"
              placeholder="Enter ATG Liability"
              size="small"
              showInCurrencyFormat
              value={customerDetailsStore.customer.atgLiability}
              onChange={(val) =>
                updateCustomerDetailsState({ atgLiability: val as number })
              }
              classes={classes.textFields}
              disabled={isReadonly()}
              type="number"
            />
          </Grid>
          <Grid item lg={3.9}>
            <TextInput
              label="Agent Liability"
              placeholder="Enter Agent Liability"
              size="small"
              showInCurrencyFormat
              value={customerDetailsStore.customer.agentLiability}
              onChange={(val) =>
                updateCustomerDetailsState({ agentLiability: val as number })
              }
              classes={classes.textFields}
              disabled={isReadonly()}
              type="number"
            />
          </Grid>
          <Grid item lg={3.9}>
            <DatePicker
              label="Agent Liability Effective Date"
              value={customerDetailsStore.customer.agentLiabilityEffectiveDate}
              handleChange={(date) =>
                updateCustomerDetailsState({
                  agentLiabilityEffectiveDate: date,
                })
              }
              class={classes.textFields}
              disabled={isReadonly()}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} class={classes.messageTextArea}>
            <TextAreaField
              label="Agent Liability Note"
              rows={5}
              placeholder="Enter your note here."
              value={customerDetailsStore.customer.agentLiabilityNote}
              onChange={(data) =>
                updateCustomerDetailsState({
                  agentLiabilityNote: data,
                })
              }
              disabled={isReadonly()}
            />
          </Grid>
        </Grid>

        <Box class={classes.messageTextArea}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((item) => (
                    <TableCell>{item}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody class={classes.tableBg}>
                <Index each={customerDetailsStore.customer.insurance}>
                  {(item, index) => (
                    <Show when={item().operationType !== 'Delete'}>
                      <TableRow class={classes.tableRow}>
                        <TableCell>
                          <TextInput
                            label=""
                            placeholder="Enter coverage amount"
                            size="medium"
                            classes={classes.textFields}
                            showInCurrencyFormat
                            value={item().coverageAmount ?? '0'}
                            onChange={(val) =>
                              updateInsuranceItems(
                                'coverageAmount',
                                val as number,
                                index,
                              )
                            }
                            disabled={isReadonly()}
                            type="number"
                          />
                        </TableCell>
                        <TableCell>
                          <SelectField
                            label=""
                            placeholder="Select Type"
                            value={item().policyType}
                            menuItems={CustomerPolicyType}
                            onChange={(e: SelectChangeEvent) => {
                              updateInsuranceItems(
                                'policyType',
                                e.target.value,
                                index,
                              );
                            }}
                            noErrorMessage
                            customWidth="300"
                            disabled={isReadonly()}
                            sxProps={{ width: '100%', height: '37px' }}
                          />
                        </TableCell>
                        <TableCell>
                          <DatePicker
                            value={
                              customerDetailsStore.customer.insurance[index]
                                .policyDate
                            }
                            handleChange={(date) => {
                              updateInsuranceItems('policyDate', date, index);
                            }}
                            class={classes.dateField}
                            disabled={isReadonly()}
                          />
                        </TableCell>
                        {!isReadonly() && (
                          <TableCell
                            sx={{ padding: '0px', paddingRight: '5px' }}
                          >
                            <IconButton
                              onClick={() => {
                                handleDeleteInsurance(index);
                              }}
                              disabled={isReadonly()}
                            >
                              <Delete class={classes.deleteIcon} />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    </Show>
                  )}
                </Index>
              </TableBody>
              {!isReadonly() && (
                <TableFooter class={classes.tableFooter}>
                  <TableRow class={classes.tableFooterRow}>
                    <TableCell>
                      <AddCircleOutlineOutlined
                        class={classes.addIcon}
                        onClick={addRow}
                      />
                      <span
                        class="!text-[#1B4960] !font-normal !text-sm ml-2 cursor-pointer"
                        onClick={addRow}
                      >
                        Add Insurance
                      </span>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Card>
      <DialogBox
        id="deleteFinancialInsuranceItem"
        title={'Are you sure you want to delete this line item?'}
        onSubmitText="Delete"
        onSubmit={() => {
          // TODO add functionality to remove the row from the table using operationType
          // removeTableRow(deleteRow()!, '');
          setDeleteRow(null);
        }}
      />
    </Box>
  );
};

export default Insurance;
