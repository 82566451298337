/* eslint-disable */
// @ts-ignore
export function deepConvertPascalToCamelCase(data) {
  const isObject = (obj: any) => obj === Object(obj) && !Array.isArray(obj);

  const convertKeyToCamelCase = (key: string) => {
    return key.charAt(0).toLowerCase() + key.slice(1);
  };

  const convertKeysToCamelCase = (obj: { [x: string]: any }) => {
    return Object.keys(obj).reduce((acc, key) => {
      const camelCaseKey = convertKeyToCamelCase(key);
      // @ts-ignore
      acc[camelCaseKey] = obj[key];
      return acc;
    }, {});
  };

  if (Array.isArray(data)) {
    return data.map((item) => deepConvertPascalToCamelCase(item));
  } else if (isObject(data)) {
    const camelCasedObj = convertKeysToCamelCase(data);
    Object.keys(camelCasedObj).forEach((key) => {
      // @ts-ignore
      camelCasedObj[key] = deepConvertPascalToCamelCase(camelCasedObj[key]);
    });
    return camelCasedObj;
  }
  return data;
}

// @ts-ignore
export function deepConvertCamelToPascalCase(data) {
  const isObject = (obj: any) => obj === Object(obj) && !Array.isArray(obj);

  const convertKeyToPascalCase = (key: string) => {
    return key.charAt(0).toUpperCase() + key.slice(1);
  };

  const convertKeysToPascalCase = (obj: { [x: string]: any }) => {
    return Object.keys(obj).reduce((acc, key) => {
      const pascalCaseKey = convertKeyToPascalCase(key);
      // @ts-ignore
      acc[pascalCaseKey] = obj[key];
      return acc;
    }, {});
  };

  if (Array.isArray(data)) {
    return data.map((item) => deepConvertCamelToPascalCase(item));
  } else if (isObject(data)) {
    const pascalCasedObj = convertKeysToPascalCase(data);
    Object.keys(pascalCasedObj).forEach((key) => {
      // @ts-ignore
      pascalCasedObj[key] = deepConvertCamelToPascalCase(pascalCasedObj[key]);
    });
    return pascalCasedObj;
  }
  return data;
}
/* eslint-enable */
