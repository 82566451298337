import { Button } from '@components';
import { CheckboxInput, TextInput } from '@components/forms';
import { Layout } from '@layouts/index';
import { useParams, useSearchParams } from '@solidjs/router';
import { DocumentEntityType } from '@store/documents';
import { getEffectiveIsEnabledForUserByFeatureName } from '@store/Global/service';
import { setLtlQuoteState } from '@store/ltl/store';
import {
  getBookedStatus,
  getCarrierDetail,
  orderStore,
  saveOrderTemplate,
  setOrderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { userStore } from '@store/user';
import { Box, Grid } from '@suid/material';
import { isPrivate } from '@utils/utils';
import { createEffect, createSignal, onMount, Show } from 'solid-js';

import { Carrier, Customer, OrderPageHeader } from './components';
import { ErrorsTable } from './components/ErrorsTable';
import { UsersViewingComponent } from './components/usersViewingComponent/UsersViewingComponent';
import { checkErrorSection, getAndCheckPlaylists, setupOrder } from './helpers';
import { RenderNeedApprovalModal } from './NeedApprovalModal';

const Order = () => {
  const [templateInfo, setTemplateInfo] = createSignal({
    name: '',
    public: true,
    nameError: '',
  });
  const params = useParams();
  const [query] = useSearchParams();

  //Methods that only need to run once, even if the OrderId changes
  onMount(async () => {
    await userStore.userPromise;
    const resp = await getEffectiveIsEnabledForUserByFeatureName('Falvey');
    setLtlQuoteState('isFalveyInsuranceFlow', Boolean(resp));
    void getAndCheckPlaylists();
  });

  createEffect(() => {
    setOrderStore('isCreate', !params.id);
    setOrderStore('orderIdUrl', params.id);
    setOrderStore('copiedOrderIdUrl', query.copyOrderId);
    setOrderStore('quoteIdUrl', query.ltlQuoteId);
  });

  //Methods that need to re run if the Params or Query changes
  createEffect(() => {
    if (Boolean(userStore.user.id)) {
      void setupOrder();
    }
  });

  //Methods that are dependent on the orderId
  createEffect(() => {
    if (orderStore.order.id) {
      orderStore.order.loads.forEach(async (loadId, ind) => {
        //if no carrier on load see if there is a matching carrier
        if (orderStore.order.loads[ind].carrierId === null) {
          await getBookedStatus(loadId.id);
        }
      });
    }
  });

  //Methods that are dependent on the active load
  createEffect(async () => {
    if (
      orderStore.order.loads[orderStore.activeTab.index]?.carrierId === null &&
      Boolean(
        orderStore.order.loads[orderStore.activeTab.index]?.carrierMatchId,
      )
    ) {
      setOrderStore('isSaving', true);
      setOrderStore('matched', true);
      await getCarrierDetail(
        orderStore.order.loads[orderStore.activeTab.index]
          .carrierMatchId as number,
        0,
      );
      updateLoadPropertyAtIndex({
        carrierId:
          orderStore.order.loads[orderStore.activeTab.index]?.carrierMatchId,
        status: 'Assigned',
      });
      setOrderStore('isSaving', false);
    }
  });

  const handleCloseTemplateMode = () => {
    setOrderStore('isTemplateMode', false);
    window.location.reload(); //reloading because this will save time and effort to reset the state and less code as well can be changed later to a more programming reset
  };

  const handleSaveTemplate = async () => {
    if (templateInfo().name.trim() === '') {
      setTemplateInfo((prev) => ({
        ...prev,
        nameError: 'Template name is required',
      }));
      return;
    }
    setTemplateInfo((prev) => ({ ...prev, nameError: '' }));
    await saveOrderTemplate(templateInfo().name, templateInfo().public, () =>
      setOrderStore('isTemplateMode', false),
    );
  };

  return (
    <Layout>
      <div
        class={`border-2 bg-background h-screen flex flex-col ${
          !Boolean(userStore.user.id)
            ? 'cursor-not-allowed pointer-events-none'
            : ''
        }`}
      >
        <OrderPageHeader />
        <div class="flex-1 overflow-y-auto xl:p-4 p-2">
          <Show when={checkErrorSection().length > 0}>
            <ErrorsTable
              errors={checkErrorSection()}
              message={
                <Box>
                  There were multiple errors that prevented the Order from being
                  saved. Please address the highlighted fields in the follow
                  sections. You can click on each error to jump to the effected
                  field.
                </Box>
              }
            />
          </Show>
          <div class="bg-white p-2 rounded-lg">
            {orderStore.isTemplateMode && (
              <div class="flex p-4 !border-2 mb-2 rounded-lg bg-[#f2dede] align-center">
                <TextInput
                  label="Template Name"
                  value={templateInfo().name}
                  classes="max-w-[30%] !mr-4 bg-white"
                  onChange={(val) =>
                    setTemplateInfo((prev) => ({
                      ...prev,
                      name: val as string,
                    }))
                  }
                  error={templateInfo().nameError}
                />
                <CheckboxInput
                  label={
                    <b>
                      {' '}
                      Public (everyone in my office can use this template for
                      their loads)
                    </b>
                  }
                  checked={templateInfo().public}
                  onChange={(val) =>
                    setTemplateInfo((prev) => ({ ...prev, public: val }))
                  }
                />
                <div class="flex max-h-[40px] ml-auto">
                  <Button
                    label="Save Template"
                    variant="contained"
                    size="small"
                    onClick={handleSaveTemplate}
                    class="!mr-3 !text-[12px]"
                  />
                  <Button
                    label="Cancel"
                    variant="outlined"
                    size="small"
                    onClick={handleCloseTemplateMode}
                    class="!text-[12px]"
                  />
                </div>
              </div>
            )}
            <Show when={Boolean(orderStore.order.id)}>
              <div class="flex">
                <UsersViewingComponent
                  orderId={orderStore.order.id.toString()}
                />
              </div>
            </Show>
            {orderStore.order.needsApprovalFlag && <RenderNeedApprovalModal />}
            <Grid container spacing={2}>
              {!isPrivate() && (
                <Customer
                  lgBreakpoint={4}
                  customerFileUploadProps={{
                    documentEntityType: DocumentEntityType.Load,
                    entityId: orderStore.order.id,
                  }}
                />
              )}
              <Carrier lgBreakpoint={isPrivate() ? 12 : 8} />
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Order;
