import InfoIcon from '@suid/icons-material/Info';
import { Box, Stack } from '@suid/material';

type PropsT = {
  message: string;
  title?: string;
  onView?: () => void;
  showIcon?: boolean;
};

export const ErrorRow = (props: PropsT) => {
  const { message, title } = props;

  return (
    <Box
      backgroundColor="#b00020"
      borderRadius="4px"
      color="#fff"
      padding="4px 8px 4px 8px"
      minHeight="44px"
    >
      <Stack direction="row" gap="6px" minHeight="44px">
        <Box
          sx={{ display: 'flex' }}
          alignItems="center"
          justifyContent="center"
        >
          <InfoIcon />
        </Box>
        <Box flex={1} sx={{ display: 'flex' }} alignItems="center">
          <Box>
            {title !== undefined ? (
              <Box fontSize="16px">{title}</Box>
            ) : undefined}
            <Box fontSize="14px">{message}</Box>
          </Box>
        </Box>
        {props.onView !== undefined ? (
          <Box
            sx={{ cursor: 'pointer', display: 'flex' }}
            justifyContent="center"
            alignItems="center"
            onClick={props.onView}
          >
            <Box>VIEW ERROR</Box>
          </Box>
        ) : undefined}
      </Stack>
    </Box>
  );
};
