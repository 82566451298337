import { ConfigManager } from './ConfigManager';
import { printLog } from './utils';

export const routeCustomerDetails = (customerId: number) => {
  if (window.self !== window.top) {
    if (window.top) {
      ConfigManager.v4OrderDetailsHost.split(',').forEach((host) => {
        try {
          parent.postMessage(
            {
              message: 'navigateToCustomerDetails',
              customerId: customerId,
            },
            {
              targetOrigin: host,
            },
          );
        } catch (e) {
          printLog(
            'There was an error posting message to parent -- this is expected',
            e,
          );
        }
      });
    }
    return false;
  }
  return true;
};
