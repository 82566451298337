import { Box } from '@suid/material';

type PropsT = {
  val: number;
  selected: boolean;
  onClick: () => void;
};

export const Cell = (props: PropsT) => {
  return (
    <Box
      class="flex items-center justify-center cursor-pointer"
      height="40px"
      flexShrink={0}
      sx={{
        backgroundColor: props.selected ? '#026ea1' : 'white',
        color: props.selected ? 'white' : 'initial',
      }}
      onClick={props.onClick}
    >
      {props.val}
    </Box>
  );
};
