import { lazy } from 'solid-js';

const Carrier = lazy(() => import('./Carrier'));
const CarrierDetails = lazy(() => import('./carrierDetails'));
import { CarrierLaneSearchRoute } from './laneSearch';
import { CarrierPlaylists } from './playlists';
const CarrierPacket = lazy(() => import('./carrierDetails/carrierPacket'));

const CarrierRoute = {
  path: '/carrier',
  component: Carrier,
};

const CarrierDetailsRoute = {
  path: '/carrier/details/:id',
  component: CarrierDetails,
};

const NewCarrierDetailsRoute = {
  path: '/carrier/details',
  component: CarrierDetails,
};

const SendCarrierPacketRoute = {
  path: '/carrier/sendcarrierpacket',
  component: CarrierPacket,
};

export {
  CarrierLaneSearchRoute,
  CarrierRoute,
  CarrierPlaylists,
  CarrierDetailsRoute,
  NewCarrierDetailsRoute,
  SendCarrierPacketRoute,
};
