export const INITIAL_COLLECTION_CUSTOMER = {
  id: 0,
  creditInsuranceId: null,
  creditInsuranceCoverageType: null,
  creditInsuranceCoverageTypeName: null,
  creditInsurancePolicyDate: null,
  createdDate: '',
  name: '',
  taxId: null,
  physicalAddress1: null,
  physicalAddress2: null,
  physicalCity: null,
  physicalState: null,
  physicalZip: null,
  mailingAddress1: null,
  mailingAddress2: null,
  mailingCity: null,
  mailingState: null,
  mailingZip: null,
  creditLimit: null,
  insuredFor: null,
  accountManagerName: '',
  salesManagerName: '',
  invoiceMethod: undefined,
  billingInstructions: undefined,
  status: '',
  paymentTerms: '',
  collectionTerms: '',
  userName: null,
  password: null,
  accountingSysId: null,
  loadInstructions: null,
  optOutBalanceEmail: false,
  optOutPastDueDateBalanceEmail: false,
  allowAutoDispatch: true,
  enableLTL: true,
  enableCSP: true,
  ediMapping: null,
  verified: true,
  invoicePaperworkRequired: undefined,
  alwaysTrackLoads: true,
  isPublic: false,
  customerExists: true,
  chargeCreditCardFee: undefined,
  trackingXId: null,
  accountManagerOfficeId: null,
  salesManagerOfficeId: null,
  accountManagerId: null,
  trackingServiceId: null,
  salesManagerId: null,
  prospectId: null,
  collectionsUserId: null,
  commissionDiscount: null,
  accountingSysBalance: null,
  ediTemplateId: null,
  parentId: null,
  needsPreapproval: undefined,
  outstandingOrders: [],
  orderSummary: [],
  quotes: [],
  comments: [],
  contacts: [],
  requests: [],
  groups: [],
  financials: null,
  collectionComments: null,
  trackings: null,
  metadata: [],
  requiredDocumentTypes: [],
  aTGLiability: null,
  agentLiability: null,
  agentLiabilityNote: null,
  agentLiabilityEffectiveDate: null,
  claimedBy: null,
  claimedById: null,
  pcMilerVersion: null,
  collectionTierId: null,
  nextActionDate: null,
  keyReasonId: null,
  insurance: null,
  ltlCostPlus: null,
  businessTypeName: null,
  businessTypeCode: null,
  businessTypeId: null,
  businessTypeDisplayName: null,
  loadPending: false,
  paymentType: '',
  requestedCustomerDoNotSolicit: null,
  isApprovedCustomerDoNotSolicit: null,
  isCustomerCentralized: null,
  seasonality: null,
  approvedCustomerDoNotSolicitDate: null,
  holdRetraction: false,
  revisitRetractionDate: undefined,
  exceptionReason: undefined,
  seasonalityComment: null,
  trackingVisibilityProvider: null,
  capacityGroupId: null,
  defaultLogoUrl: null,
  pendingCreditRequests: [],
  ediRequest: null,
  outstandingBalance: null,
};

export const INITIAL_COLLECTION_DETAILS_STATE = {
  actions: [],
  assignedTo: '',
  buckets: [],
  customer: INITIAL_COLLECTION_CUSTOMER,
  invoices: [],
};
