import { ExcelSheetIcon } from '@assets';
import { Button } from '@components';
import {
  columnsToolPanel,
  filtersToolPanel,
} from '@components/Grid/components/constants';
import { agGridCellStyle } from '@components/Grid/SavedQuotesGrid';
import { factoringCompanyDetailsStore } from '@store/factoringCompany/factoringCompanyDetails';
import { Box, Grid, Stack } from '@suid/material';
import { atgLogo } from '@views/ltl';
import { ColDef } from '@ag-grid-community/core';
import AgGridSolid, { AgGridSolidRef } from '@ag-grid-community/solid';
import { Component } from 'solid-js';

import cls from './classes';

export const FactoringCompanyDetailsCarriersTab: Component = () => {
  let gridRef: AgGridSolidRef;
  const commonDefs = {
    cellStyle: agGridCellStyle,
    filter: 'agTextColumnFilter',
    autoHeight: true,
    filterParams: {
      suppressAndOrCondition: true,
      buttons: ['apply', 'reset'],
    },
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      sort: 'asc',
      ...commonDefs,
    },
    {
      headerName: 'MC',
      field: 'mc',
      ...commonDefs,
    },
    {
      field: 'dot',
      headerName: 'DOT',
      ...commonDefs,
    },
    {
      field: 'mailingCity',
      headerName: 'City',
      ...commonDefs,
    },
    {
      field: 'mailingState',
      headerName: 'State',
      ...commonDefs,
    },
  ];

  return (
    <Stack class={cls.carrierTabGridContainer} spacing={2}>
      <Box displayRaw="flex" alignItems="center" justifyContent="end">
        <Button
          startIcon={<img src={ExcelSheetIcon} alt="Excel Sheet" />}
          variant="contained"
          label="Export to Excel"
          class="cursor-pointer"
          onClick={() =>
            gridRef.api.exportDataAsExcel({
              fileName: 'FactoringCompanyCarriers.xlsx',
            })
          }
        />
      </Box>
      <Grid class={cls.carrierTabGrid}>
        <AgGridSolid
          ref={(ref) => (gridRef = ref)}
          overlayNoRowsTemplate="No data available"
          overlayLoadingTemplate={atgLogo}
          columnDefs={columnDefs}
          rowData={
            factoringCompanyDetailsStore.factoringCompanyDetails.carriers
          }
          class="no-cell-grid"
          gridOptions={{
            defaultColDef: {
              flex: 1,
            },
            pagination: true,
            sideBar: {
              toolPanels: [columnsToolPanel, filtersToolPanel],
              defaultToolPanel: '',
            },
            onGridReady: (params) => {
              factoringCompanyDetailsStore.isLoading
                ? params.api.showLoadingOverlay()
                : params.api.hideOverlay();
            },
          }}
        />
      </Grid>
    </Stack>
  );
};
