import { orderStore } from '@store/orders/store';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { closeModal, openModal } from '@store/modals';
import { Notification } from '@components/Notification';

import { CommentCount } from './CommentCount';
import { NeedsApprovalModal } from './components/models/NeedsApprovalModal';

export const RenderNeedApprovalModal = () => {
  const {
    order: { needsApprovalFlag },
  } = orderStore;
  if (needsApprovalFlag) {
    return (
      <div class="mb-3 relative">
        <Notification
          type={needsApprovalFlag.flagType}
          commentLength={CommentCount(needsApprovalFlag.commentCount)}
          getCustomStyle={() => {
            if (!Boolean(needsApprovalFlag.cleared)) {
              return 'border-[#8BD0FF] bg-[#2989D8]/10 cursor-pointer';
            }
          }}
          text={`Needs Approval - ${needsApprovalFlag.description}`}
          subText={`${needsApprovalFlag.setBy} - ${convertUtcToLocalAndFormat(
            needsApprovalFlag.setDate as string,
            'MMM dd, yyyy h:mm a',
          )}`}
          onClick={() => openModal('needs-approval-modal')}
          cleared={needsApprovalFlag.cleared}
          disputed={
            (needsApprovalFlag.disputedOrderFlags ?? []).find((f) => {
              return f.clearedDate === undefined || f.clearedDate === null;
            }) !== undefined
          }
          needsApprovalFlag
        />
        <NeedsApprovalModal
          modalId="needs-approval-modal"
          onClose={() => closeModal('needs-approval-modal')}
          flagData={needsApprovalFlag}
        />
      </div>
    );
  }
};
