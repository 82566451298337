import { BasicTable } from '@components';
import { Delete } from '@suid/icons-material';
import { Row } from 'src/common/types';

import classes from './classes';

export const Intact = () => {
  const columns = [
    {
      name: 'Type',
      key: 'type',
      renderCell: (rowData: Row) => rowData.type,
    },
    {
      name: 'Description',
      key: 'description',
      renderCell: (rowData: Row) => rowData.description,
    },
    {
      name: 'Reference Number',
      key: 'date',
      renderCell: (rowData: Row) => rowData.reference,
    },
    {
      name: 'Amount',
      key: 'amount',
      renderCell: (rowData: Row) => rowData.amount,
    },
    {
      name: '',
      key: '#',
      renderCell: () => (
        <Delete
          sx={{
            color: '#B00020',
            cursor: 'pointer',
            visibility: 'hidden',
            fontSize: 30,
          }}
        />
      ),
    },
  ];

  const rows = [
    {
      type: 'Line Haul',
      description: 'Bottles of olive oil',
      reference: '12333',
      amount: '10,000',
    },
    {
      type: 'Bast non',
      description: 'Bottles of olive oil',
      reference: '1234423',
      amount: '9,000',
    },
  ];

  const footerRow = [
    {
      key: '#',
    },
    {
      key: 'link',
    },
    {
      renderCell: () => 'Total',
      key: 'uploadedDoc',
    },
    {
      renderCell: () => '$ 19000',
      key: 'uploadDoc',
    },
    {
      key: '#',
    },
  ];
  return (
    <BasicTable
      footerRow={footerRow}
      columns={columns}
      rows={rows}
      tableBodyClasses={classes.tableBg}
      tableFooterClasses={classes.tableFooter}
      tableBodyRowClasses={classes.tableFooterRow}
      cellClasses="!p-2"
    />
  );
};
