import { equipmentTypes } from '@common/commonLists';
import { Button, NewAutoComplete, Typography } from '@components';
import { ATGAutoCompleteItem } from '@components/Autocomplete/Autocomplete';
import { DatePicker } from '@components/DatePicker';
import { CheckboxInput, SelectField, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import {
  ILoadEditViewModel,
  LastUsedTopStopModel,
  availableServices,
  deletePosting,
  editPostLoad,
  orderStore,
  savePostLoad,
  updateLoadPropertyAtIndex,
  updateOrderField,
} from '@store/orders';
import { Help, InfoOutlined } from '@suid/icons-material';
import { Box, Divider, Grid, Stack } from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { printLog } from '@utils/utils';
import { createEffect, createSignal, Show } from 'solid-js';

import cls from './classes';
import { postLoad } from './validation';
import { PostingPricingDetails } from './PostingPricingDetails';

type PostLoadValues = {
  origin: string;
  originCity: string;
  originState: string;
  originCountry: string;
  destination: string;
  destinationCity: string;
  destinationState: string;
  destinationCountry: string;
  date: string;
  equipment: string;
  pickUp: string;
  dropOff: string;
  drops: number;
  boardsPostedTo?: availableServices[];
  servicesToPostTo: availableServices[];
  length: number;
  price: number | null;
  tags: string[];
  internalNote: string;
  comment: string;
  weight: number;
  fullTruckload: boolean;
  mileage: number | null;
  id: null | number;
  loadId: number;
  postingClients: [];
};

type Props = {
  modalId: string;
  tabIndex: number;
  services: availableServices[];
  postingId: number | null;
  load: ILoadEditViewModel;
};

const getLocationString = (stop: LastUsedTopStopModel | undefined) => {
  const { city, state, zip, countryCode } = stop || {};
  return `${city}, ${state} ${zip ?? ''} ${countryCode ?? ''}`;
};

// eslint-disable-next-line complexity
export const PostLoad = (props: Props) => {
  const [isLoading, setLoading] = createSignal(false);
  const [isDeleteLoading, setDeleteLoading] = createSignal(false);
  const [showPricingDetails, setShowPricingDetails] = createSignal(false);

  const Tags = [
    { label: 'Team', key: 'teamRequired' },
    { label: 'Hazmat', key: 'hazmatRequired' },
    { label: 'Ramps Required', key: 'ramps' },
    { label: 'Tarps Required', key: 'tarpRequired' },
    { label: 'Over Dimensions', key: 'dimension' },
  ];

  createEffect(() => {
    if (
      props.load.stops &&
      props.load.stops.length > 0 &&
      props.postingId === null
    ) {
      const origin = props.load.stops.find((stop) => {
        return (
          stop.stopOrder ===
            Math.min(
              ...(props.load.stops ?? []).map(
                (stop) => stop.stopOrder as number,
              ),
            ) && stop.pickUp === true
        );
      });
      const destination = props.load.stops.find((stop) => {
        return (
          stop.stopOrder ===
            Math.max(
              ...(props.load.stops ?? []).map(
                (stop) => stop.stopOrder as number,
              ),
            ) && stop.dropOff === true
        );
      });

      setFields('origin', getLocationString(origin as LastUsedTopStopModel));
      setFields(
        'destination',
        getLocationString(destination as LastUsedTopStopModel),
      );
      setFields('pickUp', origin?.stopDateTime ?? '');
      setFields('dropOff', destination?.stopDateTime ?? '');
      setFields('originCity', origin?.city ?? '');
      setFields('originState', origin?.state ?? '');
      setFields('originCountry', origin?.countryCode ?? '');
      setFields('destinationCity', destination?.city ?? '');
      setFields('destinationState', destination?.state ?? '');
      setFields('destinationCountry', destination?.countryCode ?? '');
      setFields(
        'servicesToPostTo',
        orderStore.availableServices
          .filter((service) => service.quickPostInclude)
          .map((service) => service.name),
      );
      setFields('equipment', props.load.equipment);
      setFields('mileage', props.load.practicalMileage ?? 0);
      setFields('price', null);
      setFields(
        'weight',
        props.load.items?.reduce((acc, v) => {
          return v.operationType !== 'Delete' ? acc + v.weight : acc;
        }, 0) ?? 0,
      );
      setFields('length', getLength());
      setFields('id', 0);
      setFields('drops', drops() >= 2 ? drops() - 2 : 0);
      setFields('loadId', props.load.id);
      setFields('boardsPostedTo', []);
      setFields('internalNote', '');
      setFields('comment', '');
      setFields('fullTruckload', props.load.mode === 'TL');
    } else if (props.postingId !== null) {
      const origin = {
        city: orderStore.managePostLoad.originCity,
        state: orderStore.managePostLoad.originState,
        countryCode: orderStore.managePostLoad.originCountry,
      };
      const destination = {
        city: orderStore.managePostLoad.destinationCity,
        state: orderStore.managePostLoad.destinationState,
        countryCode: orderStore.managePostLoad.destinationCountry,
      };
      setFields('origin', getLocationString(origin as LastUsedTopStopModel));
      setFields(
        'destination',
        getLocationString(destination as LastUsedTopStopModel),
      );
      setFields('originCity', orderStore.managePostLoad.originCity);
      setFields('originState', orderStore.managePostLoad.originState);
      setFields('originCountry', orderStore.managePostLoad.originCountry);
      setFields('destinationCity', orderStore.managePostLoad.destinationCity);
      setFields('destinationState', orderStore.managePostLoad.destinationState);
      setFields(
        'destinationCountry',
        orderStore.managePostLoad.destinationCountry,
      );

      setFields('equipment', orderStore.managePostLoad.equipment);
      setFields('price', orderStore.managePostLoad.price);
      setFields('weight', orderStore.managePostLoad.weight);
      setFields('length', getLength());
      setFields('id', orderStore.managePostLoad.id);
      setFields('loadId', orderStore.managePostLoad.loadId);
      setFields('boardsPostedTo', orderStore.managePostLoad.boardsPostedTo);
      setFields('servicesToPostTo', orderStore.managePostLoad.boardsPostedTo);
      setFields('internalNote', orderStore.managePostLoad.internalNote);
      setFields('comment', orderStore.managePostLoad.comment);
      setFields('fullTruckload', orderStore.managePostLoad.fullTruckload);
      setFields('tags', orderStore.managePostLoad.tags);
      setFields('pickUp', orderStore.managePostLoad.pickUp);
      setFields('dropOff', orderStore.managePostLoad.dropOff);
      setFields('drops', orderStore.managePostLoad.drops);
      setFields('weight', orderStore.managePostLoad.weight);
      setFields('mileage', orderStore.managePostLoad.mileage);
      setFields('length', getLength());
    }
  });

  function getValue<T>(defaultValue: T, storeValue: T): T {
    return props.postingId === null ? defaultValue : storeValue;
  }
  const getNumbers = (str: string): string | null => {
    if (typeof str === 'string' && str) {
      const match = str.match(/\d/g);
      return match ? match.join('') : null;
    }
    return null;
  };

  const getLength = () => {
    const match =
      typeof getValue(
        props.load.equipment,
        orderStore.managePostLoad.equipment,
      ) === 'string'
        ? getValue(
            props.load.equipment,
            orderStore.managePostLoad.equipment,
          )?.match(/[\d]*'/)
        : null;
    if (match) {
      const numbers = getNumbers(match[0]);
      return Number(numbers);
    }
    return Number(
      getValue(
        props.load.items?.reduce((acc, v) => {
          return v.operationType !== 'Delete' ? acc + v.length : acc;
        }, 48) ?? 0,
        orderStore.managePostLoad.length,
      ),
    );
  };

  const drops = () => props.load.stops?.length ?? 0;
  const { form, data, setFields, errors, addField, unsetField } =
    createForm<PostLoadValues>({
      initialValues: {
        pickUp: '',
        equipment: '',
        origin: '',
        destination: '',
        originCity: '',
        originState: '',
        originCountry: '',
        mileage: 0,
        price: null,
        destinationCity: '',
        destinationState: '',
        destinationCountry: '',
        dropOff: '',
        drops: 0,
        fullTruckload: true,
        tags: [],
        weight: 0,
        length: 0,
        id: 0,
        loadId: 0,
        boardsPostedTo: [],
        servicesToPostTo: [],
        internalNote: '',
        comment: '',
      },
      extend: validator({ schema: postLoad }),
      onSubmit: (values) => {
        void handleSubmit(values);
      },
      onError: (errors) => {
        printLog(errors, 'Form Errors');
      },
    });

  const handleSubmit = async (values: PostLoadValues) => {
    updateOrderField('loadsPayments', values);
    setLoading(true);
    const postwithoutBoards = { ...values };
    delete postwithoutBoards.boardsPostedTo;
    try {
      if (props.postingId === null) {
        await savePostLoad(postwithoutBoards);
        setLoading(false);
      } else {
        await editPostLoad(values);
        setLoading(false);
      }
      closeModal(props.modalId);
    } catch (error) {
      printLog('errors', error);
      setLoading(false);
    }
  };

  const OriginDestination = () => {
    const getDropdownOptions = (item: ATGAutoCompleteItem) => {
      return (
        <div>{`${item.city ?? ''}, ${item.state ?? ''} ${
          item.countryCode ?? ''
        } ${item.zip}`}</div>
      );
    };
    const handleOrginSelect = (item: ATGAutoCompleteItem) => {
      setFields(
        'origin',
        `${item.city ?? ''}, ${item.state ?? ''} ${item.countryCode ?? ''} ${
          item.zip
        }`,
      );

      setFields('originCity', item.city);
      setFields('originState', item.state);
    };

    const handleDestinationSelect = (item: ATGAutoCompleteItem) => {
      setFields(
        'destination',
        `${item.city ?? ''}, ${item.state ?? ''} ${item.countryCode ?? ''} ${
          item.zip
        }`,
      );

      setFields('destinationCity', item.city);
      setFields('destinationState', item.state);
    };
    return (
      <Box displayRaw="flex" gap={2} flexDirection="column">
        <NewAutoComplete
          id={`autocomplete-origin-${props.tabIndex}`}
          endpoint="stop/autocomplete?expression"
          defaultValue={''}
          label="Origin"
          value={data().origin}
          error={errors().origin}
          onClearValue={() => setFields('origin', '')}
          onItemSelect={handleOrginSelect}
          renderItem={(item) => getDropdownOptions(item)}
        />
        <NewAutoComplete
          id={`autocomplete-destination-${props.tabIndex}`}
          endpoint="stop/autocomplete?expression"
          defaultValue={''}
          label="Destination"
          value={data().destination}
          error={errors().destination}
          onItemSelect={handleDestinationSelect}
          renderItem={(item) => getDropdownOptions(item)}
          onClearValue={() => setFields('destination', '')}
        />
      </Box>
    );
  };

  const PickDrop = () => {
    return (
      <Box displayRaw="flex" gap={2} flexDirection="column">
        <DatePicker
          label=""
          class="!h-auto"
          placeholder="Pick"
          value={data().pickUp}
          handleChange={(date) => {
            setFields('pickUp', date);
          }}
          error={errors().pickUp}
        />
        <Box gap={0.5} displayRaw="flex" flexDirection="column">
          <DatePicker
            label=""
            class="!h-auto"
            placeholder="Drop"
            value={data().dropOff}
            handleChange={(date) => {
              setFields('dropOff', date);
            }}
            error={errors().dropOff}
          />
          <Typography
            variant="body1"
            component="span"
            class="!text-[#00000099] !text-xs !pl-2"
          >
            Optional
          </Typography>
        </Box>
      </Box>
    );
  };
  const CommentSection = () => {
    return (
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6} class={cls.commentSection}>
          <TextInput
            value={data().comment}
            id="comment"
            multiline={true}
            rows={5}
            label={'Posting Comment'}
            onChange={(value) => setFields('comment', value as string)}
          />
        </Grid>
        <Grid item xs={12} md={6} class={cls.commentSection}>
          <TextInput
            value={data().internalNote}
            id="note"
            multiline={true}
            rows={5}
            label={'Internal Note'}
            onChange={(value) => setFields('internalNote', value as string)}
          />
        </Grid>
      </Grid>
    );
  };
  const handleServices = (item: boolean, name: string, index: number) => {
    if (item) {
      addField('servicesToPostTo', name, index);
    } else {
      const indexToBeDeleted = data().servicesToPostTo.indexOf(name);
      unsetField(`servicesToPostTo.${indexToBeDeleted}`);
    }
  };
  const handleTags = (item: boolean, name: string, index: number) => {
    if (item) {
      addField('tags', name, index);
    } else {
      const indexToBeDeleted = data().tags.indexOf(name);
      unsetField(`tags.${indexToBeDeleted}`);
    }
  };

  const Services = () => {
    const checkForServices = (label: string) => {
      return data().servicesToPostTo.includes(label);
    };
    return (
      <div class={cls.flexWithCol}>
        <Typography variant="body1" children={'Boards'} />

        {props.services.map((val: availableServices, index) => {
          if (props.postingId === null && val.quickPostInclude === true) {
            addField('servicesToPostTo', val.name, index);
          }

          return (
            <CheckboxInput
              checked={checkForServices(val.name)}
              name={val.name}
              label={val.name}
              onChange={(item) => {
                handleServices(item, val.name, index);
              }}
            />
          );
        })}
      </div>
    );
  };
  const TagsSection = () => {
    const checkForTags = (label: string) => {
      return data().tags.includes(label);
    };
    return (
      <div>
        <Typography variant="body1" children={'Tags'} />
        <div class="flex flex-wrap ml-[15px] gap-2 mt-1">
          {Tags.map((val, index) => {
            return (
              <CheckboxInput
                checked={checkForTags(val.label)}
                name={val.label}
                label={val.label}
                onChange={(item) => {
                  handleTags(item, val.label, index);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const handleEquipmentChange = (e: SelectChangeEvent) => {
    const value = e.target.value;
    const match = typeof value === 'string' ? value.match(/[\d]*'/) : null;
    if (match) {
      const numbers = getNumbers(match[0]);
      setFields('length', Number(numbers));
    }
  };
  return (
    <form ref={form}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={showPricingDetails() ? 8 : 12}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <OriginDestination />
                </Grid>
                <Grid item xs={12} md={3}>
                  <PickDrop />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextInput
                    label="Drops"
                    type="number"
                    value={data().drops}
                    onChange={(item) => setFields('drops', Number(item))}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Services />
                </Grid>
              </Grid>
            </Box>
            <Divider class={cls.divider} />
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <SelectField
                    label="Equipment"
                    menuItems={equipmentTypes}
                    value={data().equipment}
                    onChange={(e: SelectChangeEvent) => {
                      handleEquipmentChange(e);
                      setFields('equipment', e.target.value);
                    }}
                  />
                  <CheckboxInput
                    checked={data().fullTruckload}
                    name=""
                    label="Full Truck Load?"
                    onChange={(item) => setFields('fullTruckload', item)}
                  />
                  <TextInput
                    label="Weight (lbs)"
                    value={data().weight}
                    type="number"
                    onChange={(item) => setFields('weight', Number(item))}
                  />
                  <TextInput
                    label="Length (feet)"
                    value={data().length}
                    type="number"
                    onChange={(item) => setFields('length', Number(item))}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={2}>
                  <Typography
                    variant="body1"
                    children={
                      data().mileage === null
                        ? 'Enter mileage and destination'
                        : `Mileage: ~${data().mileage} miles`
                    }
                    class={cls.fontBold}
                  />
                  <Box displayRaw="flex" gap={1}>
                    <TextInput
                      id="price"
                      label="Price (USD)"
                      value={data().price}
                      InputProps={{
                        endAdornment: (
                          <InfoOutlined sx={{ color: '#026EA1' }} />
                        ),
                      }}
                      onChange={(value) => setFields('price', Number(value))}
                    />
                    <Button
                      label="Help"
                      endIcon={<Help />}
                      onClick={() => setShowPricingDetails(true)}
                    />
                  </Box>
                  <TagsSection />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Show when={showPricingDetails()}>
            <Grid item xs={12} md={showPricingDetails() ? 4 : 0}>
              <PostingPricingDetails
                origination={{
                  address: null,
                  city: data().originCity,
                  state: data().originState,
                  country: data().originCountry || 'USA',
                }}
                destination={{
                  address: null,
                  city: data().destinationCity,
                  state: data().destinationState,
                  country: data().destinationCountry || 'USA',
                }}
                equipment={data().equipment}
                setShowPricingDetails={setShowPricingDetails}
              />
            </Grid>
          </Show>
        </Grid>
        <Divider class={cls.divider} />
        <CommentSection />
      </Box>

      <Box displayRaw="flex" mt={3} justifyContent="end" alignItems="center">
        {props.postingId !== null && (
          <Button
            label={'Remove Posting'}
            type="submit"
            variant="contained"
            class="!mr-auto !bg-[red]"
            onClick={async () => {
              setDeleteLoading(true);
              try {
                await deletePosting(props.postingId);
                updateLoadPropertyAtIndex({ postingId: null });
                setDeleteLoading(false);
                closeModal(props.modalId);
              } catch (error) {
                printLog('error', error);
                setDeleteLoading(false);
              }
            }}
            disabled={isDeleteLoading()}
            isLoading={isDeleteLoading()}
          />
        )}
        <Button
          label={props.postingId === null ? 'Post Load' : 'Edit Posting'}
          type="submit"
          variant="contained"
          disabled={isLoading()}
          isLoading={isLoading()}
        />
        {props.postingId === null && (
          <Button
            variant=""
            label="Cancel"
            type="Cancel"
            onClick={() => {
              closeModal(props.modalId);
            }}
          />
        )}
      </Box>
    </form>
  );
};
