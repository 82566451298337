import { IOrderViewModel } from '@store/orders';
import { unwrap } from 'solid-js/store';
import * as yup from 'yup';

export const getDataValue = <T, K extends keyof T>(
  data: () => T,
  property: K,
): T[K] | undefined => data()[property];

export function deepUnwrap<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return (obj as Array<unknown>).map(deepUnwrap) as unknown as T;
  }

  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if ('subscribe' in obj) {
    obj = unwrap(obj);
  }

  const newObj: T = Array.isArray(obj) ? ([] as unknown as T) : ({} as T);

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key as keyof T] = deepUnwrap(obj[key]);
    }
  }

  return newObj;
}

export const compareLineItems = (
  arr1: IOrderViewModel[],
  arr2: IOrderViewModel[],
) => {
  const unwrappedArr2: IOrderViewModel[] = deepUnwrap(arr2);
  const arr1ById = new Map(arr1.map((item) => [item.id, item]));

  const objectsToInsert: IOrderViewModel[] = arr1.filter(
    (item) => item.operationType === 'Insert',
  );

  const objectsToDelete: IOrderViewModel[] = [];
  const objectsToUpdate: IOrderViewModel[] = [];

  unwrappedArr2.forEach((obj1: IOrderViewModel) => {
    const obj2 = arr1ById.get(obj1.id);
    if (!obj2) {
      objectsToDelete.push({ ...obj1, operationType: 'Delete' });
    } else {
      const allKeysMatch = Object.keys(obj1).every(
        (key) =>
          obj1[key as keyof IOrderViewModel] ===
          obj2[key as keyof IOrderViewModel],
      );
      const updatedObj = allKeysMatch ? obj1 : { ...obj1, ...obj2 };

      objectsToUpdate.push({
        ...updatedObj,
        operationType: allKeysMatch ? 'None' : 'Update',
      });
    }
  });

  return [...objectsToInsert, ...objectsToUpdate, ...objectsToDelete];
};

/*eslint-disable */
// Need to disable eslint to make function use generic type
export const createConditionalSchema = <T>(
  schema: yup.ObjectSchema<T> | yup.Schema<T>,
) =>
  yup.lazy((item) =>
    item.operationType === "Delete" ? yup.object().shape({}) : schema,
  );
/*eslint-enable*/
