import MultiLineTruncate from '@components/Notification/MultiLineTruncate';
import { FlagViewModel, orderStore } from '@store/orders';
import { userStore } from '@store/user';
import { Box, Grid } from '@suid/material';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { get, isEmpty } from 'lodash';
import { Accessor } from 'solid-js';

import { notificationClasses as classes } from '../classes';

type PropsT = {
  item: Accessor<FlagViewModel>;
};

export const FlagNotificationTextContent = (props: PropsT) => {
  return (
    <pre class={classes.noteContainer}>
      <span class="font-bold">{props.item().flagType} </span>
      <Box width="8px" sx={{ display: 'inline-block' }}></Box>
      <MultiLineTruncate text={props.item().description} />
    </pre>
  );
};

export const FlagNotificationSubTextContent = (props: PropsT) => {
  return (
    <Grid container direction="row" class={classes.container}>
      <Grid item>
        {/* // check in v3 edit res setBy is coming null */}
        {!isEmpty(get(props.item(), 'setBy'))
          ? props.item().setBy
          : userStore.user.name}{' '}
      </Grid>
      <Grid item>
        <Box width="8px" sx={{ display: 'inline-block' }}></Box>
      </Grid>
      <Grid item class={classes.date}>
        {convertUtcToLocalAndFormat(
          props.item().setDate as string,
          'MMM dd, yyyy h:mm a',
        )}
      </Grid>
    </Grid>
  );
};

const comments = (mp: Accessor<FlagViewModel>) => {
  const flagData = orderStore.order.loads[orderStore.activeTab.index]?.flags;
  return flagData
    ?.filter((item) => item.flagType === mp().flagType)
    .map((item) =>
      Array.isArray(item.comments) ? item.comments.length : item.comments,
    );
};

export const FlagComments = (props: PropsT) => {
  if (
    [
      'Needs Approval',
      'EFS Issued',
      'Get Paperwork',
      'Refund Request',
      'Missing Paperwork',
    ].includes(props.item().flagType) &&
    comments(props.item)
  ) {
    return <div class={classes.flagComments}>{comments(props.item)}</div>;
  }
  return null;
};
