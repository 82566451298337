import { orderStore } from '@store/orders';

import cls from './classes';

export const Collections = () => {
  const collectionActions = orderStore.order.collectionActions;
  return (
    <div class="p-3 !bg-[#BFDFD8]">
      {collectionActions &&
        Object.keys(collectionActions).map((key, index, array) => {
          const collection = collectionActions[key];
          return (
            <div>
              <div class={cls.label}>{key}</div>
              {collection.map((section) => {
                return (
                  <div class={cls.section}>
                    <div class={cls.heading}>{section.action}</div>
                    <div>{section.comment}</div>
                    <div>
                      {section.user} - {section.date}
                    </div>
                  </div>
                );
              })}
              {array.length > 1 && index < array.length - 1 && (
                <div class={cls.divider} />
              )}
            </div>
          );
        })}
    </div>
  );
};
