import { ILoadBoardViewModel } from '@store/loadboard';
import { ICellRendererParams } from '@ag-grid-community/core';
import FlagIcon from '@suid/icons-material/Flag';
import LocalFireDepartmentIcon from '@suid/icons-material/LocalFireDepartment';
import CallIcon from '@suid/icons-material/Call';
import DescriptionIcon from '@suid/icons-material/Description';
import WarningAmberIcon from '@suid/icons-material/WarningAmber';
import QuestionAnswerIcon from '@suid/icons-material/QuestionAnswer';
import { Popover, Typography } from '@suid/material';
import { JSXElement, createSignal } from 'solid-js';

export const FlagTypeCell = (
  data: ICellRendererParams<ILoadBoardViewModel>,
) => {
  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
  const flagType = data.data?.flagType as string;
  const flagText = data.data?.flagText;
  let iconStyle: JSXElement | null = null;

  const handlePopoverOpen = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = () => Boolean(anchorEl());

  const getFlagIcon = (flagColor: string) => {
    return (
      <FlagIcon
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-owns={open() ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        style={{ color: flagColor }}
      />
    );
  };
  // Reviewed with James Approved
  // eslint-disable-next-line complexity
  const getIconType = () => {
    switch (flagType) {
      case 'Red Flag':
        iconStyle = getFlagIcon('#E09A9A');
        break;
      case 'Blue Flag':
        iconStyle = getFlagIcon('#8BD0FF');
        break;
      case 'Green Flag':
        iconStyle = getFlagIcon('#129208');
        break;
      case 'Yellow Flag':
        iconStyle = getFlagIcon('#d6d31a');
        break;

      case 'Orange Flag':
        iconStyle = getFlagIcon('#f3b42d');
        break;
      case 'Hot Load':
        iconStyle = (
          <LocalFireDepartmentIcon
            style={{ color: 'crimson' }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            aria-haspopup="true"
            aria-owns={open() ? 'mouse-over-popover' : undefined}
          />
        );
        break;
      case 'Uploaded':
        iconStyle = (
          <DescriptionIcon
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            aria-haspopup="true"
            aria-owns={open() ? 'mouse-over-popover' : undefined}
          />
        );
        break;
      case 'Tender Updated':
        iconStyle = (
          <WarningAmberIcon
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{ color: '#d6d31a' }}
            aria-haspopup="true"
            aria-owns={open() ? 'mouse-over-popover' : undefined}
          />
        );
        break;
      case 'Needs approval':
        iconStyle = (
          <QuestionAnswerIcon
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            aria-haspopup="true"
            aria-owns={open() ? 'mouse-over-popover' : undefined}
          />
        );
      case 'Get Paperwork':
      case 'Refund Request':
      case 'EFS Issued':
      case 'Missing Paperwork':
        iconStyle = (
          <CallIcon
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            aria-haspopup="true"
            aria-owns={open() ? 'mouse-over-popover' : undefined}
          />
        );
    }
    return iconStyle as JSXElement;
  };

  return (
    <div>
      {getIconType()}
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={open()}
        anchorEl={anchorEl()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {flagText !== null ? (
          <Typography sx={{ p: 1 }}>{flagText}</Typography>
        ) : (
          <Typography sx={{ p: 1 }}>{flagType}</Typography>
        )}
      </Popover>
    </div>
  );
};

export default FlagTypeCell;
