import { ILoadEditViewModel, orderStore } from '@store/orders';
import { CarrierInstructions, Stops, Tracking } from '@views/order/components';
import { Show } from 'solid-js';

import Claim from '../../claim/Claim';
import SendEDIUpdate from '../../sendEdiUpdate/SendEDIUpdate';
import { LoadInfo } from '../LoadInformation';
import { MatchedBanner } from '../MatchedBanner';

export type Props = {
  load: ILoadEditViewModel;
  index: number;
  edi: boolean;
  show214: boolean;
};

export const Layout = (props: Props) => {
  return (
    <>
      <Show when={orderStore.matched} fallback={<></>}>
        <MatchedBanner />
      </Show>
      {props.load.claim !== null && <Claim load={props.load} />}
      <LoadInfo load={props.load} tabIndex={props.index} />
      <CarrierInstructions load={props.load} tabIndex={props.index} />
      <Stops tabIndex={props.index} load={props.load} />
      <Show when={props.load.id > 0 && props.edi && props.show214}>
        <SendEDIUpdate load={props.load} index={props.index} />
      </Show>
      <Tracking />
    </>
  );
};
