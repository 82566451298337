import { BasicModal, Button } from '@components';
import { CheckboxInput } from '@components/forms';
import { closeModal, openModal } from '@store/modals';
import {
  customerStore,
  loadTenderStore,
  orderStore,
  unlinkEDITender,
  updateOrderState,
} from '@store/orders';
import { ListAlt, LockOpen } from '@suid/icons-material';
import { Box, Button as SButton } from '@suid/material';
import { printLog } from '@utils/utils';
import {
  EDI_LOAD_TENDER_ID,
  EDI_LOGS_ID,
  UNLINKED_EDI_TENDER_ID,
} from '@views/order/constants';
import { Show, createSignal } from 'solid-js';

import { EdiLog } from '../models/EdiLog';
import classes from './classes';
import { EDILoadTender } from './EDILoadTender';

const CustomerEDI = () => {
  const cls = classes.customerInfo;
  const [hideX6, setHideX6] = createSignal<boolean>(false);
  const unlinkEDILoadTender = async () => {
    try {
      await unlinkEDITender(
        loadTenderStore.loadTender.shipmentId,
        orderStore.order.id.toString(),
      ).then(() => {
        updateOrderState({ loadTenderId: null });
        closeModal(UNLINKED_EDI_TENDER_ID);
      });
    } catch (err) {
      printLog(err);
    }
  };

  const EdiModalTitle = () => {
    return (
      <Box class="ml-10">
        <CheckboxInput
          label="Hide(X6)En Route Updates"
          checked={hideX6()}
          onChange={() => setHideX6(!hideX6())}
        />
      </Box>
    );
  };

  return (
    <Show when={customerStore.customer.edi}>
      <div class="flex mb-[5px] mt-[5px]">
        <div class={cls.ediLogs}>
          <Button
            variant="contained"
            label="VIEW LOAD TENDER"
            sx={{
              marginLeft: '0px',
              width: '100%',
              borderRadius: '4px 0px 0px 4px',
            }}
            onClick={() => openModal(EDI_LOAD_TENDER_ID)}
            disabled={!Boolean(orderStore.order.loadTenderId)}
          />
          <SButton
            variant="contained"
            disabled={!Boolean(orderStore.order.loadTenderId)}
            sx={{
              borderLeft: '1px solid white',
              borderRadius: '0px 4px 4px 0px',
            }}
            onClick={() => openModal(UNLINKED_EDI_TENDER_ID)}
          >
            <LockOpen class="text-white" />
          </SButton>
        </div>

        <Button
          variant="contained"
          label="VIEW EDI LOG"
          startIcon={<ListAlt />}
          sx={{ marginLeft: '10px', flex: '1' }}
          onClick={() => openModal(EDI_LOGS_ID)}
        />
      </div>

      <BasicModal
        id={UNLINKED_EDI_TENDER_ID}
        header={false}
        width={'700px'}
        onClose={() => closeModal(UNLINKED_EDI_TENDER_ID)}
        onSubmit={() => unlinkEDILoadTender()}
        showClose={false}
        footer={true}
      >
        <span class={cls.unlinkText}>
          Are you sure you want to unlink the load tender from this Order? Doing
          so may cause EDI Updates and Invoices not to work properly.
        </span>
      </BasicModal>
      <EDILoadTender />

      <BasicModal
        id={EDI_LOGS_ID}
        title={'EDI Transaction History'}
        width={'1000px'}
        onClose={() => closeModal(EDI_LOGS_ID)}
        footer={false}
        customizedHeaderContent={<EdiModalTitle />}
      >
        <EdiLog hideX6={hideX6} />
      </BasicModal>
    </Show>
  );
};

export default CustomerEDI;
