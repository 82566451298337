import { ToastType } from '@components';
import { fetchPlaylists, Playlist, setPlaylists } from '@store/orders';
import { handleToast } from '@utils/utils';

import {
  deletePlaylistService,
  fetchCarrierOfficeGroupsCall,
  savePlaylistService,
} from './services';
import { setPlaylistsStore } from './store';

export const getCarrierPlaylists = async (): Promise<Playlist[] | void> => {
  try {
    const resp = await fetchPlaylists();

    if (Boolean(resp)) {
      setPlaylistsStore('playlists', resp || []);
      setPlaylists(resp || []);
      return resp || [];
    }
  } catch (e: unknown) {
    handleToast(ToastType.Error, e as string);
  }
};

export const fetchCarrierOfficeGroups = async (officeId: number) => {
  const res = await fetchCarrierOfficeGroupsCall(officeId);
  return res;
};

export async function deletePlaylist(
  playlistId: number,
  successCallback: () => void,
) {
  const resp = await deletePlaylistService(playlistId);
  if (Boolean(resp)) {
    successCallback();
  }
}

export async function saveCarrierPlaylist(
  playlist: Playlist,
  disableToast = false,
) {
  await savePlaylistService(playlist, disableToast);
}
