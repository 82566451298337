import { Box } from '@suid/material';
import { JSXElement } from 'solid-js';

type PropsT = {
  children: JSXElement;
};

export const Container = (props: PropsT) => {
  return (
    <Box
      class="mb-2"
      borderRadius="8px"
      border="2px solid #026EA180"
      padding="12px 15px"
      backgroundColor="#fff"
      width="100%"
    >
      {props.children}
    </Box>
  );
};
