import { BasicTable, Typography } from '@components';
import {
  fetchPricingForPosting,
  FetchPricingForPostingPayload,
  FetchPricingForPostingResponse,
} from '@store/orders';
import { Close } from '@suid/icons-material';
import { Box, IconButton, Skeleton, TableCell, TableRow } from '@suid/material';
import { formatAmount } from '@utils/utils';
import { Component, createSignal, onMount, Show } from 'solid-js';

import { postingPricingDetailsClasses as cls } from './classes';

type PostingPricingDetailsProps = Omit<
  FetchPricingForPostingPayload,
  'pricingService'
> & {
  setShowPricingDetails: (value: boolean) => void;
};

export const PostingPricingDetails: Component<PostingPricingDetailsProps> = (
  props,
) => {
  const [loading, setLoading] = createSignal(false);
  const [datData, setDatData] = createSignal<FetchPricingForPostingResponse>();
  const [truckstopData, setTruckstopData] =
    createSignal<FetchPricingForPostingResponse>();
  const [armstrongData, setArmstrongData] =
    createSignal<FetchPricingForPostingResponse>();

  const columns = [
    { name: '' },
    { name: 'Low' },
    { name: 'Average' },
    { name: 'High' },
  ];

  const fetchAndSetData = async (
    pricingService: number,
    setData: (data: FetchPricingForPostingResponse) => void,
  ) => {
    const res = await fetchPricingForPosting({
      origination: props.origination,
      destination: props.destination,
      equipment: props.equipment,
      pricingService,
    });
    Boolean(res) && setData(res!);
  };

  onMount(async () => {
    setLoading(true);
    await fetchAndSetData(0, setDatData);
    await fetchAndSetData(1, setTruckstopData);
    await fetchAndSetData(2, setArmstrongData);
    setLoading(false);
  });

  const handleClosePricing = () => {
    props.setShowPricingDetails(false);
  };

  return (
    <>
      <Box
        displayRaw="flex"
        justifyContent="space-between"
        backgroundColor="#E0E0E0"
        paddingX="1rem"
        paddingY="0.5rem"
        alignItems="center"
      >
        <Typography variant="h6">Pricing</Typography>
        <IconButton class={cls.deleteIcon} onClick={handleClosePricing}>
          <Close class={cls.closeIcon} />
        </IconButton>
      </Box>
      <Box py={2}>
        <BasicTable
          children={
            <Show
              when={!loading()}
              fallback={
                <TableRow>
                  <TableCell colspan={4}>
                    <Skeleton height={30} variant="rectangular" class="mb-1" />
                    <Skeleton height={30} variant="rectangular" class="mb-1" />
                    <Skeleton height={30} variant="rectangular" />
                  </TableCell>
                </TableRow>
              }
            >
              <TableRow>
                <TableCell>DAT</TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.lowTotal}>
                    {formatAmount(Number(datData()?.lowTotal))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.avgTotal}>
                    {formatAmount(Number(datData()?.avgTotal))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.highTotal}>
                    {formatAmount(Number(datData()?.highTotal))}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Truckstop</TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.lowTotal}>
                    {formatAmount(Number(truckstopData()?.lowTotal))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.avgTotal}>
                    {formatAmount(Number(truckstopData()?.avgTotal))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.highTotal}>
                    {formatAmount(Number(truckstopData()?.highTotal))}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Armstrong</TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.lowTotal}>
                    {formatAmount(Number(armstrongData()?.lowTotal))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.avgTotal}>
                    {formatAmount(Number(armstrongData()?.avgTotal))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" class={cls.highTotal}>
                    {formatAmount(Number(armstrongData()?.highTotal))}
                  </Typography>
                </TableCell>
              </TableRow>
            </Show>
          }
          columns={columns}
        />
      </Box>
    </>
  );
};
