/* eslint-disable no-console */
import AccessTimeRoundedIcon from '@suid/icons-material/AccessTimeRounded';
import { Box, Stack } from '@suid/material';
import { focusNextElement } from '@utils/utils';
import { DateTime } from 'luxon';
import { Show, createEffect, createSignal } from 'solid-js';

import { Cell } from './Cell';

const hoursArr = new Array(24).fill(0);
const minutesArr = new Array(60).fill(0);

type PropsT = {
  value?: string;
  label?: string;
  handleChange?: (value: string) => void;
  disabled?: boolean;
  size?: 'small' | 'medium';
};

/**
 * A 24h format time input.
 * @example
 * <TimeInput
 *   // The value needs to be a valid ISO date.
 *   value={myDate()}
 *   handleChange={(value) => {
 *     // The value will be in the HH:mm format.
 *   }}
 * />
 * @param props
 */
export const TimeInput = (props: PropsT) => {
  const [hours, setHours] = createSignal<string | undefined>();
  const [minutes, setMinutes] = createSignal<string | undefined>();
  const [showMenu, setShowMenu] = createSignal(false);
  const [selectRef, setRef] = createSignal<HTMLDivElement>();

  const padNum = (num: number): string => {
    if (isNaN(num)) {
      num = 0;
    }

    return `${num >= 10 ? num : `0${num}`}`;
  };

  const handleChange = () => {
    if (props.value !== undefined) {
      const h = parseInt(hours() ?? '0');
      const m = parseInt(minutes() ?? '0');

      if (hours() !== undefined && minutes() !== undefined) {
        props.handleChange?.(`${padNum(h)}:${padNum(m)}`);
      }
    }
  };

  createEffect(() => {
    if (props.value === undefined) {
      return;
    }

    const date = DateTime.fromISO(props.value);
    setHours(padNum(date.hour));
    setMinutes(padNum(date.minute));
  });

  createEffect(() => {
    const ref = selectRef();

    if (ref === undefined) {
      return;
    }

    document.body.addEventListener('click', (e) => {
      if (
        !ref.contains(e.target as Element) &&
        !ref.isEqualNode(e.target as Element) &&
        showMenu()
      ) {
        setShowMenu(false);
      }
    });
  });

  const onHoursInput = (
    e: InputEvent & {
      currentTarget: HTMLInputElement;
      target: HTMLInputElement;
    },
  ) => {
    const number = parseInt(e.target.value);

    if (isNaN(number)) {
      setHours();
      handleChange();
      return;
    } else if (number > 23) {
      focusNextElement();
      const h = hours();
      setHours(parseInt(h ?? '0') < 10 ? `0${h}` : h);
      handleChange();
      e.target.value = hours() ?? '';
      e.preventDefault();
      return;
    } else if (number < 0) {
      setHours('00');
      handleChange();
      e.target.value = hours() ?? '';
      e.preventDefault();
      return;
    }

    setHours(e.target.value);
    handleChange();

    if (number >= 10) {
      focusNextElement();
    }
  };

  const onMinutesInput = (
    e: InputEvent & {
      currentTarget: HTMLInputElement;
      target: HTMLInputElement;
    },
  ) => {
    const number = parseInt(e.target.value);

    if (isNaN(number)) {
      setMinutes();
      handleChange();
      return;
    } else if (number > 59) {
      const m = minutes();
      setMinutes(parseInt(m ?? '0') < 10 ? `0${m}` : m);
      handleChange();
      e.target.value = minutes() ?? '';
      e.preventDefault();
      return;
    } else if (number < 0) {
      setMinutes('00');
      handleChange();
      e.target.value = minutes() ?? '';
      e.preventDefault();
      return;
    }

    setMinutes(e.target.value);
    handleChange();
  };

  return (
    <Box
      sx={{
        border: Boolean(props.disabled)
          ? '1px solid #b8bfc4'
          : '1px solid #80b6cf',
        borderRadius: '5px',
        padding: props.size === 'small' ? '6px 12px' : '14px 16.5px',
        height: props.size === 'small' ? '37px' : '52px',
        '&:focus-within': {
          border: Boolean(props.disabled)
            ? '1px solid #b8bfc4'
            : '2px solid #1b4960',
        },
        position: 'relative',
        color: Boolean(props.disabled) ? 'rgba(0, 0, 0, 0.38)' : 'initial',
      }}
    >
      <Stack direction="row">
        <Stack direction="row" class="time-field" flex={1}>
          <input
            value={hours()}
            class="w-5 border-none outline-0"
            placeholder="--"
            type="number"
            step="1"
            onInput={onHoursInput}
            onBlur={(e) => {
              const h = hours();
              const val = e.target.value;
              setHours(parseInt(h ?? '0') < 10 && val.length < 2 ? `0${h}` : h);
              handleChange();
              e.target.value = hours() ?? '';
            }}
            onFocus={(e) => e.target.select()}
            disabled={props.disabled}
          />
          <Box>:</Box>
          <input
            value={minutes()}
            class="w-6 border-none outline-0"
            placeholder="--"
            type="number"
            onInput={onMinutesInput}
            onFocus={(e) => e.target.select()}
            disabled={props.disabled}
          />
        </Stack>
        <Box>
          <AccessTimeRoundedIcon
            color={Boolean(props.disabled) ? 'disabled' : 'primary'}
            fontSize="small"
            sx={
              props.size === 'small'
                ? {
                    marginTop: '-3px',
                  }
                : undefined
            }
            onClick={() => {
              if (!Boolean(props.disabled)) {
                setShowMenu((prev) => !prev);
              }
            }}
          />
        </Box>
      </Stack>
      <Show when={props.label !== undefined}>
        <Box
          sx={{
            backgroundColor: 'white',
            padding: '0 5px',
            position: 'absolute',
            top: '-10px',
            left: '10px',
            color: Boolean(props.disabled) ? 'rgba(0, 0, 0, 0.38)' : 'initial',
          }}
          class="text-xs text-slate-600"
        >
          {props.label}
        </Box>
      </Show>
      <Box
        sx={{
          position: 'absolute',
          top: '52px',
          left: 0,
          right: 0,
          backgroundColor: 'white',
          zIndex: 3,
          borderRadius: '5px',
          display: showMenu() ? 'block' : 'none',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
          height: '200px',
        }}
        ref={setRef}
      >
        <Stack direction="row">
          <Stack
            direction="column"
            flex={1}
            sx={{
              overflowY: 'auto',
              height: '200px',
              borderTopLeftRadius: '5px',
              borderBottomLeftRadius: '5px',
            }}
          >
            {hoursArr.map((_, i) => {
              return (
                <Cell
                  val={i}
                  selected={i === parseInt(hours() ?? '0')}
                  onClick={() => {
                    setHours(padNum(i));
                    handleChange();
                  }}
                />
              );
            })}
          </Stack>
          <Stack
            direction="column"
            flex={1}
            sx={{
              overflowY: 'auto',
              height: '200px',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
          >
            {minutesArr.map((_, i) => {
              return (
                <Cell
                  val={i}
                  selected={i === parseInt(minutes() ?? '0')}
                  onClick={() => {
                    console.log('-', hours());
                    if (hours() === undefined) {
                      setHours('00');
                    }

                    setMinutes(padNum(i));
                    handleChange();
                  }}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};
