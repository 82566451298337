import {
  Active as ActiveIcon,
  Inactive as InactiveIcon,
  OutOfBusiness as OutOfBusinessIcon,
  Pending as PendingIcon,
  InComplete as IncompleteIcon,
  Conditional as ConditionalIcon,
  ExpiredInsuranceIcon,
} from '@assets';
import { Typography } from '@components';
import { FactoringCompany } from '@store/factoringCompany';
import { Box, Link } from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';

import { factoringCompanyGridClasses as cls } from './classes';

export const FactoringCompanyContact = (data: FactoringCompany) => {
  return (
    <Box class="flex flex-col">
      <Typography variant="h1" class={cls.textBold}>
        {data.primaryContactName}
      </Typography>
      <Typography variant="h1" class={cls.text}>
        <Link href={`tel:${data.primaryContactPhone1}`}>
          {formatPhoneNumber(data.primaryContactPhone1)}
        </Link>
      </Typography>
    </Box>
  );
};

export const FactoringCompanyStatus = (data: FactoringCompany) => {
  const imgSrc = {
    Active: ActiveIcon,
    Inactive: InactiveIcon,
    'Out Of Business': OutOfBusinessIcon,
    Pending: PendingIcon,
    Incomplete: IncompleteIcon,
    Conditional: ConditionalIcon,
    'Expired Insurance': ExpiredInsuranceIcon,
  };

  return (
    <img
      src={
        imgSrc[
          data.status as
            | 'Active'
            | 'Inactive'
            | 'Out Of Business'
            | 'Pending'
            | 'Incomplete'
            | 'Conditional'
            | 'Expired Insurance'
        ]
      }
      alt={data.status}
      class="size-8"
    />
  );
};
