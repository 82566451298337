import { Tab, TabsComponent } from '@components';
import { Box } from '@suid/material';
import { createSignal, Match, Switch } from 'solid-js';

const LoadTenderTabPanel = () => {
  const [activeTab, setActiveTab] = createSignal(0);

  const tabs = ['NEW TENDERS', 'ACCEPTED', 'REJECTED', 'CANCELLED', 'EXPIRED'];
  return (
    <>
      <TabsComponent
        tabs={tabs.map((item) => (
          <Tab>{item}</Tab>
        ))}
        activeTab={activeTab()}
        onTabClick={setActiveTab}
      />
      <Box class="py-2"></Box>
      <Switch>
        <Match when={activeTab() === 0}>
          <h1>New Tenders</h1>
        </Match>
        <Match when={activeTab() === 1}>
          <h1>Accepted</h1>
        </Match>
        <Match when={activeTab() === 2}>
          <h1>Rejected</h1>
        </Match>
        <Match when={activeTab() === 3}>
          <h1>Cancelled</h1>
        </Match>
        <Match when={activeTab() == 4}>
          <h1>Expired</h1>
        </Match>
      </Switch>
    </>
  );
};

export default LoadTenderTabPanel;
