import { Office } from './Office';
import { OfficeDetailContainer } from './officeDetails';

const OfficeRoute = {
  path: '/office',
  component: Office,
};

const OfficeDetailRoute = {
  path: '/office/details/:id?',
  component: OfficeDetailContainer,
};

export { OfficeRoute, OfficeDetailRoute };
