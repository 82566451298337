import { Box, Stack, Typography } from '@suid/material';
import { JSX } from 'solid-js';

type PropsT = {
  name: string;
  data: number | string | JSX.Element;
  isCarrierSelected: boolean;
};

export const SubDetails = (props: PropsT) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Typography
          variant="body2"
          component="div"
          class={
            props.isCarrierSelected
              ? 'text-armstrong-sm'
              : 'text-[#000000] opacity-40 text-sx text-armstrong-sm'
          }
        >
          {props.name}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" component="div">
          {props.data}
        </Typography>
      </Box>
    </Stack>
  );
};
