import * as yup from 'yup';

export const factoringCompanyDetailsSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  mailingAddress1: yup.string().required('Mailing Address is required'),
  mailingCity: yup.string().required('City is required'),
  mailingState: yup.string().required('State is required'),
  mailingZip: yup.string().required('Zip is required'),
  remitTo: yup.string().email('Invalid email ').nullable(),
});
