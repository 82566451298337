import { BasicModal, Card, DialogBox } from '@components';
import { openDialogBox } from '@store/dialogBox';
import { closeModal, openModal } from '@store/modals';
import {
  deleteOfficeDetailsGroup,
  Group,
  officeDetailStore,
} from '@store/office/officeDetails';
import { Add, Delete } from '@suid/icons-material';
import { Box, Divider, Grid, IconButton } from '@suid/material';
import { createSignal, Index, Show } from 'solid-js';

import { OfficeGroup_Delete_Id, OfficeGroup_Model_Id } from '../../constants';
import AddOrEditGroup from './AddOrEditGroup';

const GroupDetails = () => {
  const [isDeleteLoading, setIsDeleteLoading] = createSignal<boolean>(false);
  const [selectedRow, setSelectedRow] = createSignal<Group | null>(null);

  const handleDeleteRow = async (id: number) => {
    try {
      setIsDeleteLoading(true);
      await deleteOfficeDetailsGroup(id);
    } catch (error) {
      throw new Error(`Failed to Delete Group Item: ${error}`);
    } finally {
      setIsDeleteLoading(false);
    }
  };
  const handleClose = () => {
    closeModal(OfficeGroup_Model_Id), setSelectedRow(null);
  };
  return (
    <Card
      loading={officeDetailStore.isLoading}
      startTitle={
        <div>
          Groups
          {
            <Add
              sx={{ cursor: 'pointer' }}
              onClick={() => openModal(OfficeGroup_Model_Id)}
            />
          }
        </div>
      }
    >
      <Box class="h-[320px] overflow-scroll">
        <Index each={officeDetailStore.officeDetail?.groups}>
          {(item) => (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={10}
                  class="cursor-pointer hover:underline"
                  onClick={() => {
                    setSelectedRow(item());
                    openModal(OfficeGroup_Model_Id);
                  }}
                >
                  <Grid item class="!text-[18px] !font-medium">
                    {item().name}
                  </Grid>

                  <Show when={item().leader}>
                    <Grid item class="!text-[14px]">
                      Leader: {item().leader}
                    </Grid>
                  </Show>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      setSelectedRow(item());
                      openDialogBox(OfficeGroup_Delete_Id);
                    }}
                    disabled={isDeleteLoading()}
                  >
                    <Delete class="text-red-700" />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
            </>
          )}
        </Index>
      </Box>
      <DialogBox
        id={OfficeGroup_Delete_Id}
        title={'Are you sure you want to delete this Group item?'}
        onSubmit={async () => {
          const id = selectedRow()?.id;
          if (id !== undefined) {
            await handleDeleteRow(id);
          }
          setSelectedRow(null);
        }}
        onCancel={handleClose}
        onSubmitText="Delete"
      />
      <BasicModal
        id={OfficeGroup_Model_Id}
        title="Add/Edit Group"
        width="600px"
        footer={false}
        onClose={handleClose}
      >
        <AddOrEditGroup selectedRow={selectedRow()} onClose={handleClose} />
      </BasicModal>
    </Card>
  );
};

export default GroupDetails;
