import { isEmpty } from 'lodash';
import * as yup from 'yup';

const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

export const contactSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type are required').notRequired(),
  phone1: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup.string().min(10, 'Phone number must be at least 10 digits');
  }),
  extension1: yup.string().nullable(),
  phone2: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup.string().min(10, 'Phone number must be at least 10 digits');
  }),
  extension2: yup.string().nullable().notRequired(),
  fax: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup.string().min(10, 'Fax number must be at least 10 digits');
  }),
  email: yup.string().nullable().email('Please enter a valid Email'),
  cpn: yup.string().notRequired(),
  emailVerified: yup.boolean().notRequired(),
  phoneVerified: yup.boolean().notRequired(),
  userName: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup
          .string()

          .nullable()
          .notRequired()
      : yup
          .string()
          .trim()
          .min(
            7,
            'Username for a contact must be at least 7 characters and cannot be all whitespaces',
          );
  }),
  password: yup.string().when((value) => {
    return isEmpty(value[0])
      ? yup.string().nullable().notRequired()
      : yup
          .string()
          .matches(
            passwordRegex,
            'Password for a contact must have one upper case letter, one lower case letter, and a digit, and be at least 8 characters in length',
          )
          .matches(
            passwordRegex,
            'Password for a contact must have one upper case letter, one lower case letter, and a digit, and be at least 8 characters in length',
          );
  }),
});
