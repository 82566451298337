import { TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { Button, Grid } from '@suid/material';
import { AttachMoneyOutlined } from '@suid/icons-material';
import { fuelCharges } from '@views/order/constants';
import { validator } from '@felte/validator-yup';
import * as yup from 'yup';
import { closeModal } from '@store/modals';
import { createEffect } from 'solid-js';
import { requestChecks } from '@store/orders/services';
import { formatAmount } from '@utils/utils';

type efsFormType = {
  amount: number;
  description: string;
  max: number;
};

export const EFSModal = (props: {
  quantity: number;
  type: string;
  rate: number;
  setRate: (rate: number) => void;
  lineItemId: number;
}) => {
  const { form, errors, data, setData, reset } = createForm<efsFormType>({
    initialValues: {
      amount: 0,
      description: '',
      max: 0,
    },
    onSubmit: () => {
      props.setRate(0);
      void requestChecks({
        amount: data().amount,
        comment: data().description,
        lineItemId: props.lineItemId,
      });
      reset();
      closeModal('EFSModal');
    },
    extend: validator({
      schema: yup.object().shape({
        amount: yup.number().when('max', ([max]) => {
          return yup
            .number()
            .min(0.1, 'Number must be greater than 0')
            .max(Number(max), 'Amount Must Be Less Than Max');
        }),
        description: yup.string().nullable(),
      }),
    }),
  });

  createEffect(() => {
    const amount = props.rate * props.quantity;
    if (fuelCharges.indexOf(props.type) > -1) {
      setData('max', Math.min(amount * 0.4, 2000));
    } else {
      setData('max', amount);
    }
  });

  return (
    <form ref={form}>
      <div>
        <Grid container sx={{ minHeight: '10vh' }} spacing={2}>
          <Grid item xs={12}>
            <div class={'font-medium text-lg'}>Request Check</div>
          </Grid>
          <Grid item xs={6}>
            <TextInput
              required
              label="Enter New Amount"
              type="number"
              onChange={(v) => {
                setData('amount', Number(v));
              }}
              error={errors().amount}
              icon={<AttachMoneyOutlined />}
              startAdornmentFlag
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              variant="filled"
              InputProps={{
                disableUnderline: true,
                startAdornment: <AttachMoneyOutlined sx={{ marginTop: 2 }} />,
                sx: {
                  borderRadius: 1,
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'green',
                  },
                },
              }}
              disabled
              label="Amount"
              value={formatAmount(data().amount)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              variant="filled"
              InputProps={{
                disableUnderline: true,
                startAdornment: <AttachMoneyOutlined sx={{ marginTop: 2 }} />,
                sx: {
                  borderRadius: 1,
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'red',
                  },
                },
              }}
              disabled
              label="Max"
              value={formatAmount(data().max)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Description"
              onChange={(v: string) => {
                setData('description', v);
              }}
              startAdornmentFlag
            />
          </Grid>
        </Grid>
        <Grid container marginTop={2} spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button class="!rounded-sm" type="submit" variant="contained">
              Request
            </Button>
          </Grid>
          <Grid item>
            <Button
              class="!rounded-sm"
              onClick={() => {
                closeModal('EFSModal');
                props.setRate(0);
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
