import { createStore } from 'solid-js/store';

import {
  ActionNote,
  ATGLookupValue,
  CollectionDetailsState,
  CollectionsTier,
  IComment,
} from './types';
import { INITIAL_COLLECTION_DETAILS_STATE } from './defaultVals';

const [collectionDetailsStore, setCollectionDetailsStore] =
  createStore<CollectionDetailsState>({
    isCollectionLoading: false,
    isLoading: false,
    isError: false,
    errorMessage: '',
    collectionDetails: INITIAL_COLLECTION_DETAILS_STATE,
    actionNotes: {
      actions: [],
      comment: '',
      orderId: 0,
    } as ActionNote,
    collectionTier: [] as CollectionsTier[],
    aTGLookupValue: [] as ATGLookupValue[],
    collectionComments: [] as IComment[],
  });

export { collectionDetailsStore, setCollectionDetailsStore };
