import { TempQuoteModel } from '@store/ltl/types';
import {
  ILoadEditViewModel,
  IOrderViewModel,
  LastUsedTopStopModel,
  OperationTypeV4,
  OrderLineItem,
  orderStore,
  PickUpDropOffItem,
} from '@store/orders';
import { Address, LineItemViewModel } from '@store/orders/v3Types';
import _, { flow } from 'lodash';
import { DateTime } from 'luxon';

export const BuildOrderFromQuote = (
  quote: TempQuoteModel,
  address?: Address,
) => {
  const load = flow([
    buildOrderPortion,
    buildLoad.bind(this, {
      address: address,
      quote: quote,
    }),
  ]);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return load(quote) as IOrderViewModel;
};

const buildOrderPortion = (quote: TempQuoteModel) => {
  return {
    customerId: quote.quoteSpecifics.requestDetails.customerId,
    lineItems: quote.quoteSpecifics.quoteResponse.customerRateQuote
      ? quote.quoteSpecifics.quoteResponse.customerRateQuote.lineItems.map(
          (x) => {
            return {
              operationType: 'Insert',
              rate: x.rate,
              type: x.type,
              description: x.description,
              quantity: x.quantity,
            } as OrderLineItem;
          },
        )
      : quote.quoteSpecifics.quoteResponse.lineItems.map(
          (x: LineItemViewModel) => {
            return {
              operationType: 'Insert',
              rate: x.rate,
              type: x.type,
              description: x.description,
              quantity: x.quantity,
            } as OrderLineItem;
          },
        ),
  } as IOrderViewModel;
};

const buildLoad = (
  param: { quote: TempQuoteModel; address?: Address },
  order: IOrderViewModel,
) => {
  const loadBuildFunc = flow([
    buildLoadPortion,
    buildLoadItems.bind(this, param.quote),
    buildStops.bind(this, param),
    buildQuote.bind(this, param.quote),
    buildLineItems.bind(this, param.quote),
  ]);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const load = loadBuildFunc(param.quote);
  return _.merge({}, { loads: [load] }, order);
};

const buildLoadPortion = (quote: TempQuoteModel): ILoadEditViewModel => {
  return {
    assignedId: 0,
    billingHold: false,
    carrierId: Number(quote.quoteSpecifics.quoteResponse.carrierId),
    canRate: true,
    canVoidLoad: true,
    claim: null,
    id:
      orderStore.order.loads.length > 0
        ? orderStore.order.loads[orderStore.activeTab.index].id
        : 0,
    customerId: quote.quoteSpecifics.requestDetails.customerId ?? 0,
    edi: false,
    mode: 'LTL',
    equipment: 'Beam Trailer',
    enableLoadCarrierRestrictionOverride: false,
    enableTracking: false,
    hasBeenSharedWithCapacity: false,
    hazmatRequired: false,
    hideCustomerName: false,
    loadInformation: undefined,
    ltlQuoteId: quote.ltlQuote.id,
    newLtlQuoteId: quote.Id,
    od: false,
    orderId: 0,
    postingId: null,
    status: 'Assigned',
    quoteItems: [],
    quoteStops: [],
    ramps: false,
    sequence: 1,
    signedRateCon: false,
    synched: false,
    tarpRequired: false,
    teamRequired: false,
    trackingEnabled: false,
    weight: _.sum(
      quote.quoteSpecifics.requestDetails.loadItems.flatMap(
        (x: PickUpDropOffItem) => {
          return x.weight;
        },
      ),
    ),
    quoteId: quote.ltlQuote.quoteId,
    quoteID: quote.ltlQuote.quoteId,
    operationType: 'Insert',
    editable: true,
    isCopied: false,
    carrierActionPlan: false,
  } as ILoadEditViewModel;
};

const buildLoadItems = (quote: TempQuoteModel, load: ILoadEditViewModel) => {
  return _.merge(
    {},
    {
      // eslint-disable-next-line complexity
      items: quote.quoteSpecifics.requestDetails.loadItems.map(
        (x: PickUpDropOffItem) => {
          return {
            operationType: 'Insert' as OperationTypeV4,
            nmfc: x.nmfc,
            class: x.class,
            item: x.type ?? x.item,
            description: x.description,
            quantity: x.quantity,
            charge: x.charge?.toString() ?? '',
            weight: x.weight,
            uom: x.uom,
            type: x.type ?? x.item,
            volume: x.volume ?? 0,
            hazmat: x.hazmat,
            heightInch: x.heightInch ?? 0,
            lengthInch: x.lengthInch ?? 0,
            widthInch: x.widthInch ?? 0,
            pieces: `${x.pieces ?? 0}`,
            displayDimensions: '',
            descriptionWithParanthesis: x.description,
            loadId: 0,
            createdBy: 1,
            loadItemHazmatDetail: {
              hazardClass: x.loadItemHazmatDetail?.hazardClass,
              unNumber: x.loadItemHazmatDetail?.unNumber,
              packingGroup: x.loadItemHazmatDetail?.packingGroup,
              piece: x.loadItemHazmatDetail?.piece,
              contactNumber: x.loadItemHazmatDetail?.contactNumber,
              contactName: x.loadItemHazmatDetail?.contactName,
              loadItemId: 0,
            },
            id: 0,
            orderId: 0,
            pickUpId: -1,
            dropOffId: -2,
          };
        },
      ),
    },
    load,
  );
};

const buildStops = (
  param: { quote: TempQuoteModel; address?: Address },
  load: ILoadEditViewModel,
) => {
  const originAddress = getOriginAddress(param);

  const stops = _.merge(
    {},
    {
      stops: [
        {
          ...originAddress,
          id: -1,
          loadId: 0,
          operationType: 'Insert',
          customerId: param.quote.quoteSpecifics.requestDetails.customerId ?? 0,
          pickUp: true, // the first stop here is the origin
          dropOff: false, // since this is the first stop, its the pickup stop
          needsAppointment: false, // we need to find out if this needs to be true,
          stopDate: param.quote.quoteSpecifics.requestDetails.pickupDate,
          driverInTime: getDriverOutInTime(
            '0',
            param.quote.quoteSpecifics.requestDetails.pickupDate,
          ),
          driverOutTime: getDriverOutInTime(
            '0',
            param.quote.quoteSpecifics.requestDetails.pickupDate,
          ),
          stopDateTime: param.quote.quoteSpecifics.requestDetails.pickupDate,
          arrived: false,
          timeZoneOffset: 0, // we need to see what the correct value is,
          createdDate: DateTime.now().toUTC().toString(),
          stopOrder: 0,
          shouldSendEdiBooleanVals: {
            shouldSendPickupAppointmentAuto214: false,
            shouldSendDropoffAppointmentAuto214: false,
            shouldSendDropoffDriverInAuto214: false,
            shouldSendPickupDriverInAuto214: false,
            shouldSendPickupDriverOutAuto214: false,
            shouldSendDropoffDriverOutAuto214: false,
          },
        },
        {
          id: -2,
          loadId: 0,
          stopOrder: 1,
          operationType: 'Insert',
          driverInTime: Boolean(
            param.quote.quoteSpecifics.quoteResponse.estimatedDeliveryDate,
          )
            ? getDriverOutInTime(
                '0',
                param.quote.quoteSpecifics.quoteResponse
                  .estimatedDeliveryDate as string,
              )
            : getDriverOutInTime(
                (param.quote.quoteSpecifics.quoteResponse.transitDays,
                param.quote.quoteSpecifics.requestDetails.pickupDate),
              ),
          driverOutTime: Boolean(
            param.quote.quoteSpecifics.quoteResponse.estimatedDeliveryDate,
          )
            ? getDriverOutInTime(
                '0',
                param.quote.quoteSpecifics.quoteResponse
                  .estimatedDeliveryDate as string,
              )
            : getDriverOutInTime(
                (param.quote.quoteSpecifics.quoteResponse.transitDays,
                param.quote.quoteSpecifics.requestDetails.pickupDate),
              ),
          customerId: param.quote.quoteSpecifics.requestDetails.customerId ?? 0,
          zip: param.quote.quoteSpecifics.requestDetails.destinationZip,
          city: param.quote.quoteSpecifics.requestDetails.destinationCity,
          countryCode:
            param.quote.quoteSpecifics.requestDetails.destinationCountry,
          state: param.quote.quoteSpecifics.requestDetails.destinationState,
          needsAppointment: false, // see comment above for the pickup stop
          pickUp: false, // this is the second stop and thus the destination
          dropOff: true, // this is the second stop and thuse the destination and dropoof stop
          stopDate:
            param.quote.quoteSpecifics.quoteResponse.estimatedDeliveryDate ??
            param.quote.quoteSpecifics.requestDetails.pickupDate,
          stopDateTime: Boolean(
            param.quote.quoteSpecifics.quoteResponse.estimatedDeliveryDate,
          )
            ? DateTime.fromISO(
                param.quote.quoteSpecifics.quoteResponse
                  .estimatedDeliveryDate as string,
              ).toString()
            : Boolean(param.quote.quoteSpecifics.quoteResponse.transitDays)
              ? DateTime.fromISO(
                  param.quote.quoteSpecifics.requestDetails.pickupDate,
                  { setZone: false },
                )
                  .plus({
                    days: Number(
                      param.quote.quoteSpecifics.quoteResponse.transitDays,
                    ),
                  })
                  .toISO()
              : DateTime.fromISO(
                  param.quote.quoteSpecifics.requestDetails.pickupDate,
                )
                  .plus({ days: 1 })
                  .toString(),
          arrived: false,
          timeZoneOffset: 0, // we need to see what the correct value is
          createdDate: DateTime.now().toUTC().toString(),
          shouldSendEdiBooleanVals: {
            shouldSendPickupAppointmentAuto214: false,
            shouldSendDropoffAppointmentAuto214: false,
            shouldSendDropoffDriverInAuto214: false,
            shouldSendPickupDriverInAuto214: false,
            shouldSendPickupDriverOutAuto214: false,
            shouldSendDropoffDriverOutAuto214: false,
          },
        },
      ] as LastUsedTopStopModel[],
    },
    load,
  );
  return stops;
};

const getDriverOutInTime = (
  expectedTransitDays: string,
  date?: string | null,
) => {
  const days = Number(expectedTransitDays);
  if (date !== null && date !== undefined) {
    const retr = DateTime.fromISO(date, { setZone: false }).plus({
      days: days,
    });
    const adjRetr = retr.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const local = adjRetr.toLocal();
    local.set({
      day: adjRetr.day,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return local.toISO();
  }

  return '';
};

const getOriginAddress = (param: {
  quote: TempQuoteModel;
  address?: Address;
}) => {
  if (param.address) {
    return {
      locationName: param.address.name ?? '',
      zip: param.address.zip,
      city: param.address.city,
      state: param.address.state,
      countryCode: param.address.countryCode,
      contact: param.address.contactName,
      phone: param.address.contactPhone,
      address1: param.address.addressLine1,
      instructions: param.address.specialInstructions,
    };
  }
  return {
    locationName: '',
    zip: param.quote.quoteSpecifics.requestDetails.originZip,
    state: param.quote.quoteSpecifics.requestDetails.originState,
    city: param.quote.quoteSpecifics.requestDetails.originCity,
    countryCode: param.quote.quoteSpecifics.requestDetails.originCountry,
  };
};

const buildQuote = (quote: TempQuoteModel, load: ILoadEditViewModel) => {
  return _.merge(
    {},
    {
      ltlQuote: {
        ...quote.ltlQuote,
        ltlQuoteLoadItems: quote.ltlQuote.ltlQuoteLoadItems,
      },
    },
    load,
  );
};

const buildLineItems = (quote: TempQuoteModel, load: ILoadEditViewModel) => {
  return _.merge(
    {},
    {
      lineItems: quote.quoteSpecifics.quoteResponse.lineItems.map(
        (x: LineItemViewModel) => {
          return {
            id: x.id,
            quantity: x.quantity,
            rate: x.rate,
            readOnly: false,
            synched: false,
            type: x.type,
            description: x.description,
            operationType: 'Insert',
          };
        },
      ) as LineItemViewModel[],
    },
    load,
  );
};
