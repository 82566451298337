import * as yup from 'yup';

export const officeDetailSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, 'Name must be 50 characters or less')
    .required('Name is required'),
  description: yup
    .string()
    .nullable()
    .max(300, 'Description must be 300 characters or less'),
  commissionPercentage: yup
    .number()
    .nullable()
    .transform((value: number) => (isNaN(value) ? null : value))
    .positive('Commission must be positive')
    .test('is-decimal', 'Commission must be a decimal', (value) => {
      if (value === null) {
        return true;
      }
      if (typeof value !== 'number' || isNaN(value)) {
        return false;
      }
      return value % 1 !== 0;
    }),
  accountingGroupId: yup.string().required('Accounting Group is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().required('Zip is required'),
  address1: yup
    .string()
    .required('Address Line 1 is required')
    .max(50, 'Address Line 1 must be 50 characters or less'),
  address2: yup
    .string()
    .nullable()
    .max(50, 'Address Line 2 must be 50 characters or less'),
  notes: yup
    .string()
    .nullable()
    .max(500, 'Notes must be 500 characters or less'),
});
