import { Address, CarrierDetails } from '@store/carriers';
import * as yup from 'yup';

export const carrierDetailsAdjustmentSchema = yup.object().shape({
  adjustments: yup.array().of(
    yup.object().shape({
      account: yup.number().required('Account is required'),
      amount: yup.string().required('Amount is required'),
      description: yup.string().required('Description is required'),
      loadNumber: yup.string().required('Load number is required'),
      carrierId: yup.number().required('Carrier ID is required'),
    }),
  ),
});

export const carrierDetailsSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  ltlTerminalLink: yup
    .string()
    .nullable()
    .url('Invalid URL format')
    .test(
      'is-valid-url',
      'Invalid URL, please ensure the URL is entered in correct format',
      (value) => {
        if (['', null, undefined].includes(value)) {
          return true;
        }
        const pattern = new RegExp(
          '^(http(s)?:\\/\\/)' +
            '((\\bw{3}\\.\\b))?' +
            '[-a-zA-Z0-9@@:%._+~#=]{1,256}\\.' +
            '[a-zA-Z0-9()]{1,6}' +
            '\\b(?:[-a-zA-Z0-9()@@:%_\\+.~#?&\\/=]*)$',
        );
        return pattern.test(value as string);
      },
    ),
  mc: yup.string().nullable(),
  remitTo: yup.string().email('Invalid email').nullable(),
  customErrors: yup.string().when(() => {
    return yup
      .string()
      .nullable()
      .test({
        name: 'customErrors',
        test: function () {
          const { createError, parent } = this;
          const carrierDetails = parent as CarrierDetails;
          const errors = [];
          const regex = new RegExp(/^\d{3}-\d{2}-\d{4}$|\d{2}-\d{7}$/);

          if (!Boolean(carrierDetails.mc) && !Boolean(carrierDetails.dot)) {
            errors.push('You must specify either an MC number or DOT number.');
          }

          if (!regex.test(carrierDetails.taxId)) {
            errors.push(
              'Please enter a valid Social Security Number 000-00-0000 or Employer Identification Number 00-0000000.',
            );
          }

          if (
            carrierDetails.factor &&
            carrierDetails.usaAddress.enabled &&
            !Boolean(carrierDetails.usaAddress.factoringCompanyId)
          ) {
            errors.push(
              'If the carrier factors, you must assign a factoring company to the US address.',
            );
          }
          if (
            carrierDetails.factor &&
            carrierDetails.canAddress.enabled &&
            !Boolean(carrierDetails.canAddress.factoringCompanyId)
          ) {
            errors.push(
              'If the carrier factors, you must assign a factoring company to the Canadian address.',
            );
          }
          if (
            carrierDetails.vendorHold &&
            (!Boolean(carrierDetails.vendorHoldReason) ||
              !Boolean(carrierDetails.vendorHoldDescription))
          ) {
            errors.push('Please enter a Vendor Hold Reason and Description.');
          }
          if (errors.length > 0) {
            return createError({
              message: errors.join(' | '),
            });
          }
          return true;
        },
      });
  }),
  usaAddress: yup.object().shape({
    physicalAddress1: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'Physical Address is required',
            });
          }
          return true;
        },
      }),
    physicalCity: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'City is required',
            });
          }
          return true;
        },
      }),
    physicalZip: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'Zip is required',
            });
          }
          return true;
        },
      }),
    physicalStateOrProvince: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;

          // Check if the address is enabled and the value is not provided
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'State is required',
            });
          }

          // Check if the value contains any numbers
          if (/\d/.test(value ?? '')) {
            return this.createError({
              message: 'State cannot contain numbers',
            });
          }

          return true;
        },
      }),
    mailingAddress1: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);
          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'Mailing Address is required',
            });
          }
          return true;
        },
      }),
    mailingCity: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);
          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'City is required',
            });
          }
          return true;
        },
      }),
    mailingZip: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);
          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'Zip is required',
            });
          }
          return true;
        },
      }),
    mailingStateOrProvince: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);
          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'State is required',
            });
          }

          if (/\d/.test(value)) {
            return this.createError({
              message: 'State cannot contain numbers',
            });
          }
          return true;
        },
      }),
    achRouting: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && Boolean(value) && value?.length !== 9) {
            return this.createError({
              message: 'Routing number must be 9 digits',
            });
          }
          return true;
        },
      }),
  }),
  canAddress: yup.object().shape({
    physicalAddress1: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'Physical Address is required',
            });
          }
          return true;
        },
      }),
    physicalCity: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'City is required',
            });
          }
          return true;
        },
      }),
    physicalZip: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'Zip is required',
            });
          }
          return true;
        },
      }),
    physicalStateOrProvince: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && !Boolean(value)) {
            return this.createError({
              message: 'State is required',
            });
          }
          return true;
        },
      }),
    mailingAddress1: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);

          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'Mailing Address is required',
            });
          }
          return true;
        },
      }),
    mailingCity: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);
          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'City is required',
            });
          }
          return true;
        },
      }),
    mailingZip: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);
          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'Zip is required',
            });
          }
          return true;
        },
      }),
    mailingStateOrProvince: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent, from } = this;
          const address = parent as Address;
          const mainParent = from && (from[1].value as CarrierDetails);
          if (
            address.enabled &&
            mainParent &&
            !Boolean(mainParent.factor) &&
            !Boolean(value)
          ) {
            return this.createError({
              message: 'State is required',
            });
          }
          return true;
        },
      }),
    achRouting: yup
      .string()
      .nullable()
      .test({
        test: function (value) {
          const { parent } = this;
          const address = parent as Address;
          if (address.enabled && Boolean(value) && value?.length !== 9) {
            return this.createError({
              message: 'Routing number must be 9 digits',
            });
          }
          return true;
        },
      }),
  }),
});
