import { v3ODataClient } from '@api/apiClient';

import { OfficeInfo } from './types';

export async function fetchAllOffices(params: {
  $format: string;
  $orderby: string;
}) {
  try {
    const response = await v3ODataClient.get('/Offices', { params });
    if ('value' in response) {
      return response.value as OfficeInfo[];
    }
  } catch (error: unknown) {
    throw new Error('Fetching offices failed');
  }
}
