﻿import { ValueFormatterFunc } from '@ag-grid-community/core';
import { DateTime } from 'luxon';
export const fullDateFormatterYear: ValueFormatterFunc<unknown, Date> = (
  param,
) => {
  const date = param.value;
  if (date === undefined || date === null) {
    return '';
  }

  // @ts-expect-error without this being treated as a string none of this works
  const dateTime = DateTime.fromFormat(date, 'M/d/yyyy, h:mm:ss a', {
    zone: 'utc',
  });

  const ndate = dateTime.toLocal();
  if (!ndate.isValid) {
    return '';
  }
  return `${ndate.toFormat('MM/dd/yyyy - T')}`;
};

export const editHistoryColumns = [
  {
    field: 'timeStamp',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    datatype: 'string',
    valueFormatter: fullDateFormatterYear,
  },
  {
    field: 'action',
    enableRowGroup: true,
  },
  {
    field: 'editedBy',
    enableRowGroup: true,
  },
  {
    field: 'field',
    enableRowGroup: true,
  },
  {
    field: 'historyType',
    enableRowGroup: true,
  },
  {
    field: 'description',
    enableRowGroup: true,
  },
  {
    field: 'oldValue',
    headerName: 'Old Value',
    enableRowGroup: true,
  },
  {
    field: 'newValue',
    headerName: 'New Value',
    enableRowGroup: true,
  },
];
