import { officeStore } from '@store/office';
import { getAllOfficesData } from '@store/office/action';
import { Layout } from '@layouts/index';
import { userStore } from '@store/user';
import { Show } from 'solid-js';
import { intersection } from 'lodash';
import { userRoles } from '@constants/common';

import { OfficeGrid } from './OfficeGrid';
import { OfficeHeader } from './OfficeHeader';

export const Office = () => {
  const isOfficeVisible = () => {
    return (
      intersection(userStore.user.roles, [
        userRoles.ADMIN,
        userRoles.SUPER_ADMIN,
        userRoles.ADMIN_LITE,
      ]).length > 0
    );
  };

  return (
    <Layout>
      <Show when={isOfficeVisible()}>
        <OfficeHeader />
        <OfficeGrid
          items={officeStore.offices}
          onGridReady={async () => {
            await getAllOfficesData();
          }}
        />
      </Show>
    </Layout>
  );
};
